import { CheckRadioBox } from '../../../../types/themeTypes';

export default {
    checkRadioBoxPadding: 33,
    checkRadioBoxFontSize: 13,
    checkRadioBoxIconSize: {
        width: 18,
        height: 18,
    },
    checkRadioBoxIconPosition: {
        left: -33,
        top: 'calc(50% - 9px)',
    },
    radioBorderRadius: '50%',
    checkedRadioIconPosition: {
        left: -29,
        top: 'calc(50% - 5px)',
    },
    checkedRadioIconSize: {
        width: 10,
        height: 10,
    },

    checkboxBorderRadius: 3,
} as CheckRadioBox;
