import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { di } from 'react-magnetic-di';
import styled from 'styled-components';
import { ExpertiseTaskModel } from '../../../../models';
import { ExpertiseTaskHeaderDescription as ExpertiseTaskHeaderDescriptionInj } from '../ExpertiseTaskHeaderDescription';
import { TaskViewHeaderPropertiesContainer as TaskViewHeaderPropertiesContainerInj } from './ExpertiseTaskViewPage.styled';

export type ExpertiseTaskViewHeaderDescriptionProps = {
    expertiseTaskModel: ExpertiseTaskModel;
    collapsed: boolean;
    toggleIsOpen: () => void;
    toggleIsOpenStartDateDialog: () => void;
    toggleIsOpenDueDateDialog: () => void;
};

export const ExpertiseTaskViewHeaderDescriptionGridStyled = styled(({ collapsed, ...props }) => <Grid {...props} />)`
    overflow: hidden;
    transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    max-height: ${({ collapsed }): string => (collapsed ? '0vh' : '50vh')};
`;

export const ExpertiseTaskViewHeaderDescription = (props: ExpertiseTaskViewHeaderDescriptionProps): JSX.Element => {
    const [ExpertiseTaskHeaderDescription] = di(
        [ExpertiseTaskHeaderDescriptionInj],
        ExpertiseTaskViewHeaderDescription,
    );
    const [TaskViewHeaderPropertiesContainer] = di(
        [TaskViewHeaderPropertiesContainerInj],
        ExpertiseTaskViewHeaderDescription,
    );

    const {
        expertiseTaskModel,
        collapsed,
        toggleIsOpen,
        toggleIsOpenStartDateDialog,
        toggleIsOpenDueDateDialog,
    } = props;
    return (
        <ExpertiseTaskViewHeaderDescriptionGridStyled item collapsed={collapsed}>
            <TaskViewHeaderPropertiesContainer>
                <Container maxWidth="lg">
                    <ExpertiseTaskHeaderDescription
                        toggleIsOpen={toggleIsOpen}
                        toggleIsOpenStartDateDialog={toggleIsOpenStartDateDialog}
                        toggleIsOpenDueDateDialog={toggleIsOpenDueDateDialog}
                        expertiseTaskModel={expertiseTaskModel}
                    />
                </Container>
            </TaskViewHeaderPropertiesContainer>
        </ExpertiseTaskViewHeaderDescriptionGridStyled>
    );
};
