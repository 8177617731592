import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import clientRoute from '../../../../../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../../../components';
import { ExpertiseTaskViewModel } from '../../../../../models';
import { ExpertiseTaskSubject as ExpertiseTaskSubjectInj } from '../ExpertiseTaskSubject';
import { ExpertiseTaskSubjectWithoutFiles } from '../ExpertiseTaskSubjectWithoutFiles';
import { ExpertiseTaskView as ExpertiseTaskViewInj } from '../ExpertiseTaskView';
import {
    TaskViewTabsContainer as TaskViewTabsContainerInj,
    TaskViewTabsContentContainer as TaskViewTabsContentContainerInj,
} from '../ExpertiseTaskViewPage.styled';
import { PanelHorizontal as PanelHorizontalInj } from '../PanelHorizontal';
import { ExpertiseTaskViewTabButtons as ExpertiseTaskViewTabButtonsInj } from './ExpertiseTaskViewTabButtons';

export const getAdditionalTaskViewRoutesPagesInj = (reloadKey: number): JSX.Element => <React.Fragment />;

export type ExpertiseTaskViewRoutedTabs = {
    id: string;
    reloadKey: number;
    isTaskFullWidth: boolean;
    isReportFullWidth: boolean;
    expertiseTaskViewModel: ExpertiseTaskViewModel;
};

export const ExpertiseTaskViewRoutedTabs = observer(
    (props: ExpertiseTaskViewRoutedTabs): JSX.Element => {
        const [getAdditionalTaskViewRoutesPages] = di(
            [getAdditionalTaskViewRoutesPagesInj],
            ExpertiseTaskViewRoutedTabs,
        );
        const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTaskViewRoutedTabs);
        const [ExpertiseTaskSubject] = di([ExpertiseTaskSubjectInj], ExpertiseTaskViewRoutedTabs);
        const [ExpertiseTaskView] = di([ExpertiseTaskViewInj], ExpertiseTaskViewRoutedTabs);
        const [TaskViewTabsContainer] = di([TaskViewTabsContainerInj], ExpertiseTaskViewRoutedTabs);
        const [TaskViewTabsContentContainer] = di([TaskViewTabsContentContainerInj], ExpertiseTaskViewRoutedTabs);
        const [PanelHorizontal] = di([PanelHorizontalInj], ExpertiseTaskViewRoutedTabs);
        const [ExpertiseTaskViewTabButtons] = di([ExpertiseTaskViewTabButtonsInj], ExpertiseTaskViewRoutedTabs);

        const [withSubjectFilesTab] = useFeature('withSubjectFilesTab');

        const { id, isReportFullWidth, isTaskFullWidth, reloadKey, expertiseTaskViewModel } = props;

        const allowedTabs = (allowed: boolean): JSX.Element => {
            return <ExpertiseTaskViewTabButtons allowed={allowed} id={id} />;
        };

        const allowedTabsContent = (allowed: boolean): JSX.Element =>
            allowed ? (
                withSubjectFilesTab ? (
                    <React.Fragment>
                        <Route path={clientRoute.expertiseTaskSubject} exact>
                            <ExpertiseTaskSubjectWithoutFiles key={reloadKey} />
                        </Route>
                        <Route path={clientRoute.expertiseTaskFiles} exact>
                            <ExpertiseTaskSubject key={reloadKey} isOnlyFiles={true} />
                        </Route>
                    </React.Fragment>
                ) : (
                    <Route path={clientRoute.expertiseTaskSubject} exact>
                        <ExpertiseTaskSubject key={reloadKey} />
                    </Route>
                )
            ) : (
                <Redirect to={generatePath(clientRoute.expertiseTask, { id })} />
            );

        return (
            <PanelHorizontal
                key={reloadKey}
                fullWidth={isTaskFullWidth}
                isOtherFullWidth={isReportFullWidth}
                items={[
                    <TaskViewTabsContainer item key="tabs">
                        <AuthorizationCheck
                            entityCode={entities.ExpertiseTask}
                            permCode={permissions.ExpertiseTask.ViewSubject}
                            entityId={id}
                        >
                            {allowedTabs}
                        </AuthorizationCheck>
                    </TaskViewTabsContainer>,
                    <TaskViewTabsContentContainer item key="tabs-content">
                        <Switch>
                            <Route path={clientRoute.expertiseTask} exact>
                                <ExpertiseTaskView key={reloadKey} taskViewModel={expertiseTaskViewModel} />
                            </Route>
                            <AuthorizationCheck
                                entityCode={entities.ExpertiseTask}
                                permCode={permissions.ExpertiseTask.ViewSubject}
                                entityId={id}
                            >
                                {allowedTabsContent}
                            </AuthorizationCheck>
                            {getAdditionalTaskViewRoutesPages(reloadKey)}
                        </Switch>
                    </TaskViewTabsContentContainer>,
                ]}
                leftSide={true}
            />
        );
    },
);
