import { Box, Button, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink, useParams } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { PasswordField as PasswordFieldInj, RedirectByRole as RedirectByRoleInj } from '../../components';
import { useStore } from '../../hooks';
import { NewPasswordModel } from '../../models';
import { TotBackground as TotBackgroundInj } from '../../TotBackground';

type RouteParams = {
    token: string | undefined;
};

export const NewPasswordPage = observer((): JSX.Element => {
    const [PasswordField] = di([PasswordFieldInj], NewPasswordPage);
    const [TotBackground] = di([TotBackgroundInj], NewPasswordPage);
    const [RedirectByRole] = di([RedirectByRoleInj], NewPasswordPage);

    const { token } = useParams<RouteParams>();
    const { authenticationStore } = useStore();

    const [newPasswordModel] = useState<NewPasswordModel>(() => {
        const newModel = authenticationStore.newPasswordModel;
        newModel.token = token;
        return newModel;
    });

    return (
        <TotBackground withBackdrop={true}>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Container className="t-new-password-page" maxWidth="xs">
                    <Paper elevation={24}>
                        <Box p={12}>
                            <Grid container justify="center">
                                <Grid item>
                                    <Typography variant="h5" className="t-new-password-title">
                                        <Box fontWeight="fontWeightBold">
                                            <FormattedMessage id="authentication.newPasswordTitle" />
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box pt={8}>
                                <form noValidate onSubmit={newPasswordModel.updatePassword}>
                                    <Grid container spacing={6} direction="column">
                                        {newPasswordModel.failedMessage && (
                                            <Grid item>
                                                <Typography className="t-failed-message" color="error">
                                                    {newPasswordModel.failedMessage}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <PasswordField
                                                label={<FormattedMessage id="authentication.newPassword" />}
                                                model={newPasswordModel.newPasswordModel}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <PasswordField
                                                label={<FormattedMessage id="authentication.repeatPassword" />}
                                                model={newPasswordModel.repeatPasswordModel}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className="t-confirm"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                            >
                                                <FormattedMessage id="authentication.confirm" />
                                            </Button>
                                        </Grid>

                                        <Grid item container justify="center">
                                            <Link className="t-login" component={RouterLink} to={clientRoute.login}>
                                                <Typography variant="caption">
                                                    <FormattedMessage id="authentication.login" />
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Box>
                    </Paper>
                    {newPasswordModel.changedSucceed && <RedirectByRole />}
                </Container>
            </Grid>
        </TotBackground>
    );
});
