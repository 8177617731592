import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RowData as RowDataInj, SyslogLink as SyslogLinkInj } from '../../../../components';
import { SubjectModel } from '../../../../models';
import { getOrElse } from '../../../../utils';

export type SubjectHeaderLeftProps = {
    identifier: SubjectModel['identifier'];
    stateTitle: SubjectModel['metaInfo']['stateTitle'];
    created: SubjectModel['metaInfo']['created'];
};

export const SubjectHeaderLeft = (props: SubjectHeaderLeftProps): JSX.Element => {
    const [RowData] = di([RowDataInj], SubjectHeaderLeft);
    const [SyslogLink] = di([SyslogLinkInj], SubjectHeaderLeft);

    const { identifier, stateTitle, created } = props;
    const intl = useIntl();

    const identifierValue = identifier || intl.formatMessage({ id: 'common.withoutNumber' });
    const createdValue = getOrElse(created, intl.formatDate(created), '-');

    return (
        <Grid container spacing={2}>
            <Grid item container spacing={7}>
                <Grid item>
                    <RowData label={intl.formatMessage({ id: 'subject.fields.identifier' })} value={identifierValue} />
                </Grid>
                <Grid item>
                    <RowData label={intl.formatMessage({ id: 'subject.fields.state' })} value={stateTitle} />
                </Grid>
                <Grid item>
                    <SyslogLink />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item>
                    <RowData label={intl.formatMessage({ id: 'subject.fields.created' })} value={createdValue} />
                </Grid>
            </Grid>
        </Grid>
    );
};
