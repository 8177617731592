import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { TransitionDialogProps } from '../../types';
import { ConfirmationDialog as ConfirmationDialogInj } from '../ConfirmationDialog';

export const LifeCycleTransitionConfirmationDialog = observer((props: TransitionDialogProps): JSX.Element => {
    const [ConfirmationDialog] = di([ConfirmationDialogInj], LifeCycleTransitionConfirmationDialog);

    const {
        transition,
        isModalOpen,
        setModalIsClosed,
        lifeCycleTransition,
        handleErrorTransition,
        handleSuccessTransition,
    } = props;

    const intl = useIntl();

    const handleConfirm = (): Promise<void> => {
        return lifeCycleTransition().then(handleSuccessTransition).catch(handleErrorTransition);
    };

    const { toStateTitle, params } = transition;
    const confirmationDialogMessage = intl.formatMessage(
        { id: 'common.lifeCycleConfirmText' },
        { toState: toStateTitle },
    );

    return (
        <ConfirmationDialog
            id="confirm-transition"
            onCancel={setModalIsClosed}
            onConfirm={handleConfirm}
            message={confirmationDialogMessage}
            open={isModalOpen}
            title={intl.formatMessage({ id: 'common.lifeCycleConfirmTitle' })}
            keepMounted
            confirmText={params.title}
        />
    );
});
