import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { TotObjectTransitions as TotObjectTransitionsInj } from '../../../components';
import { TransitionsDTO } from '../../../models';

export type TemplateOfExpertiseControlPanelRightProps = {
    onSubmit: () => void;
    id: string;
    goToReadPage: () => void;
    getTransitionButtons: (templateId: string) => Promise<TransitionsDTO>;
    onLifeCycleTransition: (transitionId: string, taskId: string, validate?: boolean | undefined) => Promise<void>;
};

export const TemplateOfExpertiseControlPanelRight = (props: TemplateOfExpertiseControlPanelRightProps): JSX.Element => {
    const [TotObjectTransitions] = di([TotObjectTransitionsInj], TemplateOfExpertiseControlPanelRight);

    const { onSubmit, id, goToReadPage, getTransitionButtons, onLifeCycleTransition } = props;

    return (
        <Grid spacing={3} alignItems="center" justify="center" container>
            <Switch>
                <Route exact path={clientRoute.templateOfExpertiseCreate}>
                    <Grid item>
                        <Button
                            variant="contained"
                            component={NavLink}
                            to={generatePath(clientRoute.templatesOfExpertise)}
                        >
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route exact path={clientRoute.templateOfExpertiseEdit}>
                    <Grid item>
                        <Button
                            variant="contained"
                            component={NavLink}
                            to={generatePath(clientRoute.templateOfExpertise, {
                                id,
                            })}
                        >
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route exact path={clientRoute.templateOfExpertise}>
                    <Grid item>
                        <Grid item container spacing={3}>
                            <TotObjectTransitions
                                objectId={id}
                                updateObjectPage={goToReadPage}
                                getTransitions={getTransitionButtons}
                                lifeCycleTransition={onLifeCycleTransition}
                            />
                        </Grid>
                    </Grid>
                </Route>
            </Switch>
        </Grid>
    );
};
