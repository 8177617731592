import { IconButton, SvgIcon } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { di } from 'react-magnetic-di';
import {
    ActionMenuItem as ActionMenuItemInj,
    ConfirmationDialog as ConfirmationDialogInj,
    MenuButton as MenuButtonInj,
} from '../../components';
import { useModal, useStore } from '../../hooks';
import { ReactComponent as DotMenu } from '../../resources/images/icons/dot-menu.svg';
import { ExpertiseTaskRegistryRow } from '../../store';
import { getPopoverOriginConfig } from '../../utils';
import { ExpertiseTaskConfirmationButtons } from './ExpertiseTaskConfirmationButtons';
import { useExpertiseTaskPermissionsActionButtons as useExpertiseTaskPermissionsActionButtonsInj } from './useExpertiseTaskPermissionsActionButtons';
import { useExpertiseTaskSettingsConfirmDialog as useExpertiseTaskSettingsConfirmDialogInj } from './useExpertiseTaskSettingsConfirmDialog';

export type ExpertiseTaskRegistryActionsBtnsProps = {
    expertiseTaskRow: ExpertiseTaskRegistryRow;
    reloadData: () => void;
    isConfirm?: boolean;
    setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
    isRowActionsFirst?: boolean;
};

export const ExpertiseTaskRegistryActionsBtns = observer(
    (props: ExpertiseTaskRegistryActionsBtnsProps): JSX.Element => {
        const [ConfirmationDialog] = di([ConfirmationDialogInj], ExpertiseTaskRegistryActionsBtns);
        const [MenuButton] = di([MenuButtonInj], ExpertiseTaskRegistryActionsBtns);
        const [ActionMenuItem] = di([ActionMenuItemInj], ExpertiseTaskRegistryActionsBtns);

        const [useExpertiseTaskPermissionsActionButtons] = di(
            [useExpertiseTaskPermissionsActionButtonsInj],
            ExpertiseTaskRegistryActionsBtns,
        );
        const [useExpertiseTaskSettingsConfirmDialog] = di(
            [useExpertiseTaskSettingsConfirmDialogInj],
            ExpertiseTaskRegistryActionsBtns,
        );

        const { expertiseTaskRow, reloadData, isConfirm, setIsConfirm, isRowActionsFirst } = props;
        const { id } = expertiseTaskRow;
        const { intlStore: intl } = useStore();

        const { isModalOpen, setModalIsOpen, setModalIsClosed } = useModal();

        const [permissionsData, reloadPermission] = useExpertiseTaskPermissionsActionButtons({ rowId: id });
        const [deleteExpertiseTaskRequest] = permissionsData;

        const { expertiseTaskStore } = useStore();

        useEffect(() => {
            if (setIsConfirm && isConfirm) {
                setIsConfirm(false);
                reloadData();
                reloadPermission();
            }
        }, [isConfirm]);

        const onDeleteConfirm = (): Promise<void> => {
            return expertiseTaskStore.deleteExpertiseTask(id).finally(() => {
                reloadData();
                setModalIsClosed();
            });
        };

        const [settings, setSettings] = useExpertiseTaskSettingsConfirmDialog({
            delete: onDeleteConfirm,
        });

        const renderActionItems = useCallback((): ((hideMenu?: () => void) => ReactNode[]) => {
            return (hideMenu): ReactNode[] => {
                const onDeleteClick = (): void => {
                    setSettings(ExpertiseTaskConfirmationButtons.delete);
                    hideMenu && hideMenu();
                    setModalIsOpen();
                };

                return [
                    <React.Fragment key="actionBtns">
                        {deleteExpertiseTaskRequest && (
                            <ActionMenuItem messageId="common.delete" onClick={onDeleteClick} />
                        )}
                    </React.Fragment>,
                ];
            };
        }, [permissionsData, expertiseTaskRow]);

        const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
            const atLeastOneAllowed = permissionsData.some((isPermissionAllowed) => isPermissionAllowed);
            return atLeastOneAllowed ? (
                <IconButton onClick={onClick}>
                    <SvgIcon>
                        <DotMenu />
                    </SvgIcon>
                </IconButton>
            ) : (
                <React.Fragment />
            );
        };

        const { anchorOrigin, transformOrigin } = isRowActionsFirst
            ? getPopoverOriginConfig('bottom-right')
            : getPopoverOriginConfig('bottom-left');

        return (
            <React.Fragment>
                <MenuButton
                    renderButton={renderActionsButton}
                    renderMenuItems={renderActionItems()}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                />
                <ConfirmationDialog
                    id={settings.id}
                    open={isModalOpen}
                    title={intl.formatMessage(settings.title)}
                    message={intl.formatMessage(settings.message, { number: expertiseTaskRow.customData.number })}
                    onConfirm={settings.onConfirm}
                    onCancel={setModalIsClosed}
                    keepMounted
                />
            </React.Fragment>
        );
    },
);
