import { action, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { SeverityType } from '../../store';

export type MessageType = {
    severity: SeverityType;
    title: string;
};

export class NotificationModel {
    @observable id: string = uuidv4();
    @observable title = '';
    @observable severity: SeverityType = 'success';
    @observable isOpen = true;

    @action.bound
    closeNotification(): void {
        this.isOpen = false;
    }

    @action.bound
    load(dto: MessageType): void {
        this.severity = dto.severity;
        this.title = dto.title;
    }
}
