import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    TotObjectTransitions as TotObjectTransitionsInj,
} from '../../../components';
import { CampaignStore } from '../../../store';
import { ExpertiseCreateDialog as ExpertiseCreateDialogInj } from '../../expertise';
import { CampaignPageRouteParams } from '../CampaignPage';

export type ControlPanelRightProps = {
    id: CampaignPageRouteParams['id'];
    reloadKey: number;
    reloadIncrement: () => void;
    updateCampaignStatistic: () => void;
    setModalIsOpen: () => void;
    isModalOpen: boolean;
    campaignTitle: string;
    campaignIdentifier: string;
    setModalIsClosed: () => void;
    goToReadPage: () => void;
    campaignStore: CampaignStore;
    onSubmit: () => void;
    lifeCycleTransition: (transitionId: string, campaignId: string) => Promise<void>;
};

export const ControlPanelRight = (props: ControlPanelRightProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ControlPanelRight);
    const [TotObjectTransitions] = di([TotObjectTransitionsInj], ControlPanelRight);
    const [ExpertiseCreateDialog] = di([ExpertiseCreateDialogInj], ControlPanelRight);

    const {
        id,
        reloadKey,
        setModalIsOpen,
        isModalOpen,
        campaignTitle,
        campaignIdentifier,
        setModalIsClosed,
        goToReadPage,
        campaignStore,
        lifeCycleTransition,
        onSubmit,
        reloadIncrement,
        updateCampaignStatistic,
    } = props;

    const campaignIdTitleNumber = { id, title: campaignTitle, number: campaignIdentifier };

    const handleExpertiseCreateDialogClose = (): void => {
        setModalIsClosed();
        updateCampaignStatistic();
        reloadIncrement();
    };

    return (
        <Grid container>
            <Switch>
                <Route path={clientRoute.campaignCreate} exact>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.campaigns)}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.campaignEdit} exact>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.campaign, { id })}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.campaign} exact>
                    <Grid item>
                        <Grid container direction="row" spacing={3} wrap="nowrap">
                            <Grid item>
                                <AuthorizationCheck
                                    key={reloadKey}
                                    entityCode={entities.ExpertiseCampaign}
                                    entityId={id}
                                    permCode={permissions.ExpertiseCampaign.AddExpertises}
                                >
                                    <Button variant="contained" onClick={setModalIsOpen}>
                                        <FormattedMessage id="expertise.createExpertises" />
                                    </Button>
                                </AuthorizationCheck>
                                <ExpertiseCreateDialog
                                    open={isModalOpen}
                                    byCampaign
                                    campaign={campaignIdTitleNumber}
                                    onClose={handleExpertiseCreateDialogClose}
                                />
                            </Grid>
                            <TotObjectTransitions
                                objectId={id}
                                updateObjectPage={goToReadPage}
                                getTransitions={campaignStore.getLifeCycleTransitions}
                                lifeCycleTransition={lifeCycleTransition}
                            />
                        </Grid>
                    </Grid>
                </Route>
            </Switch>
        </Grid>
    );
};
