import { Grid, ThemeProvider } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { createLandingThemeCreator, LandingThemeCreator } from '../../createThemes';
import { useStore } from '../../hooks';
import { StartAuthHeaderLink as StartAuthHeaderLinkInj } from './StartAuthHeaderLink';

export const StartPageHeaderLinks = observer((): JSX.Element => {
    const [StartAuthHeaderLink] = di([StartAuthHeaderLinkInj], StartPageHeaderLinks);

    const rootStore = useStore();
    const landingThemeCreator: LandingThemeCreator = createLandingThemeCreator(rootStore);

    return (
        <ThemeProvider theme={landingThemeCreator}>
            <Grid container direction="row" alignItems="center" justify="flex-end">
                <StartAuthHeaderLink />
            </Grid>
        </ThemeProvider>
    );
});
