import { History } from 'history';
import { action, observable } from 'mobx';
import apiConfigs from '../../apiConfigs';
import clientRoute from '../../clientRoute';
import { Api } from '../../store';
import { handleAxiosErrorByResponseStatus } from '../../utils';
import { RowsData, TableModel, TableQueryData } from '../list';

export interface TemplatesOfExpertiseRowDTO {
    id: string;
    title: string;
    created: string; //date
    state: string;
}

export class TemplatesOfExpertiseListModel extends TableModel<TemplatesOfExpertiseRowDTO, {}, {}> {
    @observable protected api: Api;
    @observable history: History;

    constructor(api: Api, history: History) {
        super({}, {});

        this.api = api;
        this.history = history;
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<TemplatesOfExpertiseRowDTO>> {
        return this.api
            .client(apiConfigs.templatesOfExpertiseListData(queryData))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }
}
