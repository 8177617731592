import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TemplatesOfExpertiseModel } from '../../../models';

export type TemplateOfExpertiseHeaderTitleProps = {
    title: TemplatesOfExpertiseModel['title'];
};

export const TemplateOfExpertiseHeaderTitle = (props: TemplateOfExpertiseHeaderTitleProps): JSX.Element => {
    const { title } = props;
    return (
        <Grid item>
            <Typography variant="h1">{title}</Typography>
        </Grid>
    );
};
