const subject: Record<string, string | Record<string, string>> = {
    subjectListTitle: 'Expertise objects',
    createSubject: 'Create object',
    newSubject: 'New object',
    allFiles: 'All documents for {title}',
    downloadAllFiles: 'Download all files',
    fields: {
        title: 'Title',
        identifier: 'Number',
        created: 'Created',
        state: 'State',
        campaign: 'Campaign',
        expertise: 'Expertise',
        withoutExpertise: 'Objects without expertise',

        author: 'Author',
        lastModified: 'Last modified',
        category: 'Category',
        lifecycle: 'Lifecycle',
        form: 'Form',
    },
    actions: {
        deleteSubject: 'Delete object',
        editSubject: 'Edit object',
    },
    registryPagingInfo:
        'Total {count, number} object{count, plural, one {} other {s}}. ' +
        'Displayed from {from, number} to {to, number}',
    blocks: {
        serviceInfo: 'Service information',
    },
    confirmDeletionInfoText: 'Are you sure you want to delete the expertise object "{title}"?',
};

export default subject;
