import {
    Button,
    Grid,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, RouteComponentProps, Switch, useParams } from 'react-router-dom';
import {
    ActionsButton as ActionsButtonInj,
    AuthorizationCheck as AuthorizationCheckInj,
    AuthorizedRoute as AuthorizedRouteInj,
    DeleteActionMenuItem as DeleteActionMenuItemInj,
    MenuButton as MenuButtonInj,
} from '../../components';
import { PlanEntryListModel } from '../../models';
import { PlanEntryDTO } from '../../store';
import { PlanEntryFormDialog as PlanEntryFormDialogInj } from './PlanEntryFormDialog';

export type ExpertisePlanProps = {
    createPath: string;
    editPath: string;
    ownerPagePath: string;
    ownerEntityCode: string;
    editPermission: string;
    model: PlanEntryListModel;
};

export type ExpertisePlanRouteParams = {
    id: string;
    entryId: string;
};

export const ExpertisePlan = observer((props: ExpertisePlanProps): JSX.Element => {
    const [ActionsButton] = di([ActionsButtonInj], ExpertisePlan);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertisePlan);
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], ExpertisePlan);
    const [AuthorizedRoute] = di([AuthorizedRouteInj], ExpertisePlan);
    const [MenuButton] = di([MenuButtonInj], ExpertisePlan);
    const [PlanEntryFormDialog] = di([PlanEntryFormDialogInj], ExpertisePlan);

    const { editPermission, editPath, ownerEntityCode, ownerPagePath, createPath, model } = props;
    const { id: ownerId } = useParams<ExpertisePlanRouteParams>();

    const renderActionItems = (entry: PlanEntryDTO, index: number): (() => ReactNode[]) => {
        return (): JSX.Element[] => [
            <MenuItem
                dense
                key={'edit'}
                tabIndex={0}
                button={true}
                component={NavLink}
                to={generatePath(editPath, { id: ownerId, entryId: entry.id })}
            >
                <FormattedMessage id="common.edit" />
            </MenuItem>,
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                wrappedComponentProps={{ tabIndex: 1 }}
                title={<FormattedMessage id="common.confirmDeletion" />}
                message={<FormattedMessage id="templatesOfExpertise.plan.confirmDeletionInfoText" values={{ index }} />}
                onConfirm={model.deleteEntry(entry.id)}
            />,
        ];
    };

    const renderActions = (entry: PlanEntryDTO, index: number): JSX.Element => {
        return (
            <MenuButton
                disablePortal={true}
                renderButton={ActionsButton}
                renderMenuItems={renderActionItems(entry, index)}
            />
        );
    };

    return (
        <Grid container direction="column" spacing={5}>
            <Switch>
                <AuthorizedRoute
                    entityCode={ownerEntityCode}
                    entityId={ownerId}
                    permCode={editPermission}
                    path={createPath}
                >
                    <PlanEntryFormDialog
                        getEntryModel={model.newEntry}
                        listModel={model}
                        ownerPagePath={ownerPagePath}
                    />
                </AuthorizedRoute>
                <AuthorizedRoute
                    entityCode={ownerEntityCode}
                    entityId={ownerId}
                    permCode={editPermission}
                    path={editPath}
                    render={(route: RouteComponentProps<ExpertisePlanRouteParams>): JSX.Element => (
                        <PlanEntryFormDialog
                            getEntryModel={() => model.getEntry(route.match.params.entryId)}
                            listModel={model}
                            ownerPagePath={ownerPagePath}
                        />
                    )}
                />
            </Switch>
            <Grid item container direction="row" justify="space-between">
                <Grid item>
                    <Typography variant="h3">
                        <FormattedMessage id="templatesOfExpertise.plan.listTitle" />
                    </Typography>
                </Grid>
                <Grid item>
                    <AuthorizationCheck entityCode={ownerEntityCode} entityId={ownerId} permCode={editPermission}>
                        <Button
                            color="primary"
                            variant="contained"
                            component={NavLink}
                            to={generatePath(createPath, { id: ownerId })}
                        >
                            <FormattedMessage id="templatesOfExpertise.plan.createEntry" />
                        </Button>
                    </AuthorizationCheck>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="templatesOfExpertise.plan.fields.number" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="templatesOfExpertise.plan.fields.viewPoint" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="templatesOfExpertise.plan.fields.planCountTasks" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="templatesOfExpertise.plan.fields.acceptanceDays" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="templatesOfExpertise.plan.fields.deadlineDays" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="templatesOfExpertise.plan.fields.reportForm" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="templatesOfExpertise.plan.fields.taskForm" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <FormattedMessage id="templatesOfExpertise.plan.fields.processTitle" />
                                    </Typography>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {model.entries.map((entry, index) => {
                                const entryIndex = index + 1;
                                return (
                                    <TableRow key={entry.id} hover>
                                        <TableCell>{entryIndex}</TableCell>
                                        <TableCell>{entry.viewPoint || entry.taskType}</TableCell>
                                        <TableCell>
                                            <FormattedMessage
                                                id="common.fromToNumber"
                                                values={{ from: entry.tasksMin, to: entry.tasksMax }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {(entry.acceptanceDays && (
                                                <FormattedMessage
                                                    id="common.amountOfDays"
                                                    values={{ count: entry.acceptanceDays }}
                                                />
                                            )) ||
                                                (entry.acceptance && <FormattedDate value={entry.acceptance} />)}
                                        </TableCell>
                                        <TableCell>
                                            {(entry.deadlineDays && (
                                                <FormattedMessage
                                                    id="common.amountOfDays"
                                                    values={{ count: entry.deadlineDays }}
                                                />
                                            )) ||
                                                (entry.deadline && <FormattedDate value={entry.deadline} />)}
                                        </TableCell>
                                        <TableCell>{entry.reportFormCode}</TableCell>
                                        <TableCell>{entry.taskFormCode}</TableCell>
                                        <TableCell>{entry.processTitle}</TableCell>
                                        <AuthorizationCheck
                                            entityCode={ownerEntityCode}
                                            entityId={ownerId}
                                            permCode={editPermission}
                                        >
                                            <TableCell>{renderActions(entry, entryIndex)}</TableCell>
                                        </AuthorizationCheck>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
});
