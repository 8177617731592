import { Grid, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities } from '../../../../authSchemeConfig';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    IconButtonLarge as IconButtonLargeInj,
} from '../../../../components';
import { ReactComponent as EditIcon } from '../../../../resources/images/icons/edit.svg';

export type ExpertiseTaskAuthEditFieldProps = {
    toggleIsOpenDialog?: () => void;
    messageId: string;
    permCode: string;
    entityId?: string;
};

export const ExpertiseTaskAuthEditField = observer((props: ExpertiseTaskAuthEditFieldProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTaskAuthEditField);
    const [IconButtonLarge] = di([IconButtonLargeInj], ExpertiseTaskAuthEditField);

    const { toggleIsOpenDialog, messageId, entityId, permCode } = props;

    return (
        <React.Fragment>
            {toggleIsOpenDialog && (
                <AuthorizationCheck entityCode={entities.ExpertiseTask} permCode={permCode} entityId={entityId}>
                    <Grid item>
                        <Tooltip title={<FormattedMessage id={messageId} />}>
                            <div>
                                <IconButtonLarge onClick={toggleIsOpenDialog}>
                                    <EditIcon />
                                </IconButtonLarge>
                            </div>
                        </Tooltip>
                    </Grid>
                </AuthorizationCheck>
            )}
        </React.Fragment>
    );
});
