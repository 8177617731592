import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { BottomControlPanel } from '../../../../components';
import { useModal, useStore } from '../../../../hooks';
import { SubjectModel } from '../../../../models';
import { SubjectPageRouteParams } from '../SubjectPage';
import { SubjectControlPanelLeft as SubjectControlPanelLeftInj } from './SubjectControlPanelLeft';
import { SubjectControlPanelRight as SubjectControlPanelRightInj } from './SubjectControlPanelRight';

export type SubjectControlPanelProps = {
    subjectModel: SubjectModel;
    onSubmit: () => void;
    onLifeCycleTransition(transitionId: string, requestId: string, validate?: boolean): Promise<void>;
};

export const SubjectControlPanel = observer((props: SubjectControlPanelProps): JSX.Element => {
    const [SubjectControlPanelRight] = di([SubjectControlPanelRightInj], SubjectControlPanel);
    const [SubjectControlPanelLeft] = di([SubjectControlPanelLeftInj], SubjectControlPanel);

    const { subjectModel, onSubmit, onLifeCycleTransition } = props;
    const { subjectStore } = useStore();
    const history = useHistory();
    const { id } = useParams<SubjectPageRouteParams>();
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

    const deleteSubject = (): Promise<void> => {
        return subjectStore.deleteSubject(subjectModel.id).then(() => {
            history.push(generatePath(clientRoute.subjects));
        });
    };

    const goToSubjectReadPage = (): void => {
        history.push(generatePath(clientRoute.subject, { id }));
    };

    return (
        <BottomControlPanel
            left={<SubjectControlPanelLeft id={id} title={subjectModel.title} deleteSubject={deleteSubject} />}
            right={
                <SubjectControlPanelRight
                    onSubmit={onSubmit}
                    setModalIsOpen={setModalIsOpen}
                    id={id}
                    isModalOpen={isModalOpen}
                    subjectModel={subjectModel}
                    setModalIsClosed={setModalIsClosed}
                    goToSubjectReadPage={goToSubjectReadPage}
                    subjectStore={subjectStore}
                    onLifeCycleTransition={onLifeCycleTransition}
                />
            }
        />
    );
});
