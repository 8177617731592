const common: Record<string, string | Record<string, string>> = {
    edit: 'Редактировать',
    close: 'Закрыть',
    error: 'Ошибка',
    errorText: 'Что-то пошло не так',
    save: 'Сохранить',
    delete: 'Удалить',
    cancel: 'Отмена',
    confirm: 'Подтвердить',
    noData: 'Нет данных',
    noOptions: 'Нет доступных вариантов',
    from: 'c',
    to: 'до',
    fromToNumber: 'от {from, number} до {to, number}',
    until: 'по',
    State: 'Состояние',
    result: 'Результат',
    copy: 'Копировать',
    clear: 'Очистить',
    rowsPerPage: 'Отображать по:',
    pagingInfo: 'Отображено c {from, number} по {to, number}. Всего элементов {count, number}',
    resetFilters: 'Сбросить',
    filters: 'Фильтры',
    all: 'Все',
    actions: 'Действия',
    export: 'Выгрузить',
    confirmDeletion: 'Подтвердите удаление',
    saveChanges: 'Сохранить изменения',
    dropzoneText: 'Перетащите или выберите файл',
    pageNotFound: 'Страница не найдена',
    actionNotAllowed: 'У вас недостаточно прав для совершения данного действия',
    errorOccurred: 'Произошла ошибка',
    withoutNumber: 'Без номера',
    readonlyValidationMsg: 'Форма заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
    lifeCycleConfirmTitle: 'Изменение статуса',
    lifeCycleConfirmText: 'Статус будет изменен на "{toState}"',
    amountOfDays: '{count, number} {count, plural, one {день} few {дня} other {дней}}',
    serviceInfo: 'Служебная информация',
    serviceInfoFields: {
        created: 'Дата создания',
        state: 'Состояние',
        author: 'Автор',
        lastModified: 'Последнее изменение',
        lifeCycle: 'Жизненный цикл',
        form: 'Форма',
    },
    invalidDateMessage: 'Недопустимый формат даты',
    maxDateMessage: 'Дата не может быть позже текущей даты',
    consentDataUsePolicy: 'Согласие на обработку персональных данных',
    pageLeaveMessage: 'Вы уверены, что хотите покинуть страницу?',
    downloadFiles: 'Скачать файлы',
    downloadFile: 'Скачать {fileName}',
    downloadInstructions: 'Скачать инструкцию',
    formNotFound: 'Форма не найдена',
};

export default common;
