import { SidebarVars } from '../../../types/themeTypes';

const width = 312;
const widthXl = 321;
const offsetVertical = 14;
const errorSize = 24;
const collapseIconWidth = 36;

export default {
    width,
    widthXl,
    offsetVertical,
    errorSize,
    collapseIconWidth,
} as SidebarVars;
