export default class ElementPositionUtils {
    // prevElementHeight и nextElementHeight должны быть заданы,
    // если на странице присутствуют значимые для element "position: fixed"-элементы
    static isFullElementInView<T extends HTMLElement>(
        element: T | null,
        prevElementHeight: number = 0,
        nextElementHeight: number = 0,
    ): boolean {
        if (element !== null) {
            const scrollTop = ElementPositionUtils.getScrollTop();
            const pageTop = scrollTop + prevElementHeight;
            const pageBottom = pageTop + window.innerHeight - nextElementHeight;
            const elementTop = element.getBoundingClientRect().top + scrollTop;
            const elementBottom = elementTop + element.offsetHeight;
            return elementTop > pageTop && elementBottom < pageBottom;
        } else {
            return false;
        }
    }

    // prevElementHeight и nextElementHeight должны быть заданы,
    // если на странице присутствуют значимые для element "position: fixed"-элементы
    static isPartOfElementInView<T extends HTMLElement>(
        element: T | null,
        prevElementHeight: number = 0,
        nextElementHeight: number = 0,
    ): boolean {
        if (element !== null) {
            const scrollTop = ElementPositionUtils.getScrollTop();
            const pageTop = scrollTop + prevElementHeight;
            const pageBottom = pageTop + window.innerHeight - nextElementHeight;
            const elementTop = element.getBoundingClientRect().top + scrollTop;
            const elementBottom = elementTop + element.offsetHeight;
            return elementTop < pageBottom && elementBottom > pageTop;
        } else {
            return false;
        }
    }

    static isTopOfElementInView<T extends HTMLElement>(element: T | null, prevElementHeight: number = 0): boolean {
        if (element !== null) {
            const scrollTop = ElementPositionUtils.getScrollTop();
            const pageTop = scrollTop + prevElementHeight;
            const elementTop = element.getBoundingClientRect().top + scrollTop;
            return elementTop > pageTop;
        } else {
            return false;
        }
    }

    static getElementOffsetHeightWithMargin<T extends HTMLElement>(element: T | null): number {
        if (element !== null) {
            const styles = window.getComputedStyle(element);
            const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
            return Math.round(element.offsetHeight + margin);
        } else {
            return 0;
        }
    }

    static getScrollTop(): number {
        return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    }
}
