import { Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../../hooks';
import { ExpertiseTaskPrintFormsDTO, ExpertiseTaskPrintFormsModel, PrintFormFileDTO } from '../../../../../models';
import { TableToolbar as TableToolbarInj } from './ExpertiseTaskPrintForms.styled';
import { ExpertiseTaskPrintFormsTable as ExpertiseTaskPrintFormsTableInj } from './expertise-task-print-forms-table/ExpertiseTaskPrintFormsTable';

type RouteParams = {
    id: string;
};

export const ExpertiseTaskPrintForms = observer(
    (): JSX.Element => {
        const [TableToolbar] = di([TableToolbarInj], ExpertiseTaskPrintForms);
        const [ExpertiseTaskPrintFormsTable] = di([ExpertiseTaskPrintFormsTableInj], ExpertiseTaskPrintForms);

        const [printForms] = useState<ExpertiseTaskPrintFormsModel>(new ExpertiseTaskPrintFormsModel());
        const { expertiseTaskStore } = useStore();
        const { id: expertiseTaskId } = useParams<RouteParams>();

        useEffect(() => {
            expertiseTaskStore.loadPrintForms(expertiseTaskId).then((printFormsDTO: ExpertiseTaskPrintFormsDTO) => {
                printForms.load(printFormsDTO);
            });
        }, [expertiseTaskId]);

        const downloadPf = (file: PrintFormFileDTO) => (): void => {
            expertiseTaskStore.downloadPrintFormFile(file);
        };

        return (
            <Paper elevation={1}>
                <TableToolbar>
                    <Typography variant="h3">
                        <FormattedMessage id="expertiseTask.printFormsTable.title" />
                    </Typography>
                </TableToolbar>
                <ExpertiseTaskPrintFormsTable downloadPf={downloadPf} printForms={printForms} />
            </Paper>
        );
    },
);
