import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { PageHeader as PageHeaderInj } from '../../../../components';
import { ExpertiseTaskModel } from '../../../../models';
import { ExpertiseTaskHeaderDescriptionLeft as ExpertiseTaskHeaderDescriptionLeftInj } from './ExpertiseTaskHeaderDescriptionLeft';
import { ExpertiseTaskHeaderDescriptionRight as ExpertiseTaskHeaderDescriptionRightInj } from './ExpertiseTaskHeaderDescriptionRight';

export type ExpertiseTaskHeaderDescriptionProps = {
    expertiseTaskModel: ExpertiseTaskModel;
    toggleIsOpen?: () => void;
    toggleIsOpenStartDateDialog?: () => void;
    toggleIsOpenDueDateDialog?: () => void;
};

export const ExpertiseTaskHeaderDescription = observer((props: ExpertiseTaskHeaderDescriptionProps): JSX.Element => {
    const [ExpertiseTaskHeaderDescriptionLeft] = di(
        [ExpertiseTaskHeaderDescriptionLeftInj],
        ExpertiseTaskHeaderDescription,
    );
    const [ExpertiseTaskHeaderDescriptionRight] = di(
        [ExpertiseTaskHeaderDescriptionRightInj],
        ExpertiseTaskHeaderDescription,
    );
    const [PageHeader] = di([PageHeaderInj], ExpertiseTaskHeaderDescription);

    const { expertiseTaskModel, toggleIsOpen, toggleIsOpenStartDateDialog, toggleIsOpenDueDateDialog } = props;
    const { expert, expertCandidate, deadline, metaInfo, expertise, viewPoint, started, id } = expertiseTaskModel;
    const { stateTitle } = metaInfo;
    const expertId = expert.userId;
    const expertCandidateId = expertCandidate.userId;

    return (
        <PageHeader
            left={
                <ExpertiseTaskHeaderDescriptionLeft
                    entityId={id}
                    started={started}
                    toggleIsOpenStartDateDialog={toggleIsOpenStartDateDialog}
                    toggleIsOpenDueDateDialog={toggleIsOpenDueDateDialog}
                    deadline={deadline}
                    stateTitle={stateTitle}
                    expertise={expertise}
                    viewPoint={viewPoint}
                />
            }
            right={
                <ExpertiseTaskHeaderDescriptionRight
                    expertId={expertId}
                    expert={expert}
                    expertCandidateId={expertCandidateId}
                    expertiseTaskModel={expertiseTaskModel}
                    expertCandidate={expertCandidate}
                    toggleIsOpen={toggleIsOpen}
                />
            }
        />
    );
});
