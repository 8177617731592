import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { env, features, history, locales, themeOverrides } from './module';
import { Root } from './Root';
import { rootElement } from './rootElement';

ReactDOM.render(
    <Root env={env} features={features} locales={locales} themeOverrides={themeOverrides} history={history} />,
    rootElement,
);
