import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { permissionsConfig } from '../../../../authSchemeConfig';
import {
    ActionsButton as ActionsButtonInj,
    AuthorizationCheck as AuthorizationCheckInj,
    MenuButton as MenuButtonInj,
} from '../../../../components';
import { useStore } from '../../../../hooks';
import { UserInfoMessageValues, UserTableRow } from '../../../../types';
import { getPopoverOriginConfig } from '../../../../utils';
import { UserAssignPasswordDialog as UserAssignPasswordDialogInj } from './UserAssignPasswordDialog';
import { UserStatusButton as UserStatusButtonInj } from './UserStatusButton';

export type UserRegistryActionsBtnsProps = {
    userTableRow: UserTableRow;
    reloadData: () => void;
    isRowActionsFirst?: boolean;
};

export const UserRegistryActionsBtns = observer((props: UserRegistryActionsBtnsProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserRegistryActionsBtns);
    const [MenuButton] = di([MenuButtonInj], UserRegistryActionsBtns);
    const [ActionsButton] = di([ActionsButtonInj], UserRegistryActionsBtns);
    const [UserAssignPasswordDialog] = di([UserAssignPasswordDialogInj], UserRegistryActionsBtns);
    const [UserStatusButton] = di([UserStatusButtonInj], UserRegistryActionsBtns);

    const { userTableRow, reloadData, isRowActionsFirst } = props;
    const [userAssignPassword] = useFeature('userAssignPassword');
    const [isWithAssignPassword, setIsWithAssignPassword] = useState<boolean>(false);
    const { id, customData } = userTableRow;
    const { login, name } = customData;
    const { authorizationStore } = useStore();
    const userInfoMessageValues: UserInfoMessageValues = {
        login: login.title,
        name: name.title,
    };

    useEffect((): void => {
        userAssignPassword &&
            authorizationStore.check(permissionsConfig.accountAdministration).then(setIsWithAssignPassword);
    }, [userAssignPassword]);

    const renderActionItems = useCallback(
        (hideMenu: () => void): ReactNode[] => {
            return [
                <UserStatusButton key="add" userTableRow={userTableRow} reloadData={reloadData} hideMenu={hideMenu} />,
                isWithAssignPassword && (
                    <UserAssignPasswordDialog
                        id={id}
                        tabIndex={1}
                        userInfoMessageValues={userInfoMessageValues}
                        hideMenu={hideMenu}
                    />
                ),
            ];
        },
        [userTableRow, userInfoMessageValues, id, reloadData],
    );

    const { anchorOrigin, transformOrigin } = isRowActionsFirst
        ? getPopoverOriginConfig('bottom-right')
        : getPopoverOriginConfig('bottom-left');

    return (
        <AuthorizationCheck {...permissionsConfig.updateUser(id)}>
            <MenuButton
                disablePortal={true}
                renderButton={ActionsButton}
                renderMenuItems={renderActionItems}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            />
        </AuthorizationCheck>
    );
});
