import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RowData as RowDataInj, SyslogLink as SyslogLinkInj } from '../../../components';
import { CategoryOfSubjectsModel } from '../../../models';
import { getOrElse } from '../../../utils';

export type CategoryOfSubjectsPageHeaderLeftProps = {
    created: CategoryOfSubjectsModel['metaInfo']['created'];
    modified: CategoryOfSubjectsModel['metaInfo']['modified'];
};

export const CategoryOfSubjectsPageHeaderLeft = observer(
    (props: CategoryOfSubjectsPageHeaderLeftProps): JSX.Element => {
        const [RowData] = di([RowDataInj], CategoryOfSubjectsPageHeaderLeft);
        const [SyslogLink] = di([SyslogLinkInj], CategoryOfSubjectsPageHeaderLeft);

        const { created, modified } = props;
        const intl = useIntl();

        const categoryValue = getOrElse(created, intl.formatDate(created), '-');
        const lastModifiedValue = getOrElse(modified, intl.formatDate(modified), '-');

        return (
            <React.Fragment>
                <Grid item>
                    <RowData label={intl.formatMessage({ id: 'category.fields.created' })} value={categoryValue} />
                </Grid>
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'category.fields.lastModified' })}
                        value={lastModifiedValue}
                    />
                </Grid>
                <Grid item>
                    <SyslogLink />
                </Grid>
            </React.Fragment>
        );
    },
);
