import { withTheme, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const StyledButton = withTheme(styled(Button)`
    font-size: 30px;
    font-weight: 900;
    color: ${({ theme }): string => theme.variables.palette.accentDark};
    line-height: 1.2;
    background: none;
    padding: 0;
    margin: 0;
    min-width: 0;
    &:hover {
        background: none;
        color: ${({ theme }): string => theme.variables.palette.accentContrast};
    }
`);

export const StyledTypography = withTheme(styled(Typography)`
    font-size: 30px;
    font-weight: 900;
    color: ${({ theme }): string => theme.variables.palette.textMain};
    line-height: 1.2;
`);
