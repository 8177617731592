import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AuthenticationError = (): JSX.Element => {
    return (
        <Paper elevation={24}>
            <Box p={12}>
                <Typography variant="h5">
                    <Box fontWeight="fontWeightBold">
                        <FormattedMessage id="authentication.error" />
                    </Box>
                </Typography>
            </Box>
        </Paper>
    );
};
