import { Grid } from '@material-ui/core';
import { FormApi, MultiLangFormEdit } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import clientRoute from '../../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    AuthorizedRoute as AuthorizedRouteInj,
    TotObjectMain as TotObjectMainInj,
} from '../../../../components';
import { maxWidth100 } from '../../../../constants';
import { useStore } from '../../../../hooks';
import { ExpertiseTaskModel } from '../../../../models';
import { ExpertiseTaskServiceInfo as ExpertiseTaskServiceInfoInj } from '../ExpertiseTaskServiceInfo';
import { ExpertiseTaskControlPanel as ExpertiseTaskControlPanelInj } from './ExpertiseTaskControlPanel';
import { ExpertiseTaskEditHeader as ExpertiseTaskEditHeaderInj } from './ExpertiseTaskEditHeader';

const queryString = require('query-string');

export type ExpertiseTaskPageRouteParams = {
    id: string;
    backUrl: string;
};

export const ExpertiseTaskEditPage = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTaskEditPage);
    const [AuthorizedRoute] = di([AuthorizedRouteInj], ExpertiseTaskEditPage);
    const [TotObjectMain] = di([TotObjectMainInj], ExpertiseTaskEditPage);
    const [ExpertiseTaskServiceInfo] = di([ExpertiseTaskServiceInfoInj], ExpertiseTaskEditPage);
    const [ExpertiseTaskControlPanel] = di([ExpertiseTaskControlPanelInj], ExpertiseTaskEditPage);
    const [ExpertiseTaskEditHeader] = di([ExpertiseTaskEditHeaderInj], ExpertiseTaskEditPage);

    const { expertiseTaskStore, intlStore, history } = useStore();
    const { id } = useParams<ExpertiseTaskPageRouteParams>();
    const [formApi, setFormApi] = useState<FormApi>();
    const [expertiseTaskModel] = useState<ExpertiseTaskModel>(expertiseTaskStore.getExpertiseTaskModel(id));
    const { formModel } = expertiseTaskModel;
    const location = useLocation();

    const backUrl: string = useMemo(() => {
        const queries = queryString.parse(location.search);
        return queries.backUrl || generatePath(clientRoute.expertiseTask, { id });
    }, []);

    const updateCard = (): void => {
        expertiseTaskStore.loadExpertiseTaskDTO(id).then(expertiseTaskModel.loadCard);
    };

    useEffect(() => {
        updateCard();
    }, [intlStore.locale]);

    const onFormReady = (form: FormApi): void => {
        setFormApi(form);
    };

    const onSubmit = (): void => {
        if (formApi && formApi.validate()) {
            formApi
                .submit(false)
                .then(() => {
                    return expertiseTaskStore.saveExpertiseTask(id, formApi.getSubmissionWithAdditionalInfo());
                })
                .then(() => {
                    history.push(generatePath(clientRoute.expertiseTask, { id }));
                });
        }
    };

    const goBack = (): void => {
        history.push(backUrl);
    };

    return (
        <AuthorizedRoute
            path={clientRoute.expertiseTaskEdit}
            key={clientRoute.expertiseTaskEdit}
            exact
            entityCode={entities.ExpertiseTask}
            permCode={permissions.ExpertiseTask.Edit}
            entityId={id}
        >
            <Grid container direction="column">
                <Grid item>
                    <ExpertiseTaskEditHeader expertiseTaskModel={expertiseTaskModel} />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                <Grid item>
                                    <ExpertiseTaskServiceInfo metaInfoModel={expertiseTaskModel.metaInfo} />
                                </Grid>
                            </AuthorizationCheck>
                            <Grid item style={maxWidth100}>
                                <MultiLangFormEdit intlStore={intlStore} form={formModel} onFormReady={onFormReady} />
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectMain>
                <Grid item>
                    <ExpertiseTaskControlPanel onSubmit={onSubmit} goBack={goBack} />
                </Grid>
            </Grid>
        </AuthorizedRoute>
    );
});
