import { action, observable } from 'mobx';
import { ExpertiseDTO } from '../../store';
import { IdTitle } from '../../types';
import { IdTitleNumber } from '../IdTitleNumber';
import { MetaInfoModel } from '../MetaInfo';
import { UserNameModel } from '../person';

export class ExpertiseModel {
    @observable id: string;
    @observable identifier = '';
    @observable subject?: IdTitleNumber;
    @observable campaign?: IdTitle;
    @observable template?: IdTitle;
    @observable curator: UserNameModel = new UserNameModel();
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();

    constructor(id: string) {
        this.id = id;
    }

    @action.bound
    load(dto: ExpertiseDTO): ExpertiseModel {
        const { curator, metaInfo, ...other } = dto;
        this.curator.load(curator);
        this.metaInfo.load(metaInfo);
        Object.assign(this, other);
        return this;
    }
}
