import { Grid, GridProps, IconButton } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { transitionDefault } from '../../themes/pages/app/vars/transitions';

type StyledSidebarProps = {
    open: boolean;
} & GridProps;

export const StyledSidebar = withTheme(
    styled(Grid)<StyledSidebarProps>`
        width: ${({ theme, open }) =>
            open ? theme.variables.sidebar.width + theme.spacing(5) * 2 : theme.variables.sidebar.collapseIconWidth}px;
        transition: ${transitionDefault};

        .sidebar {
            position: relative;
            right: 0;
            top: ${({ theme }) => theme.variables.sidebar.offsetVertical}px;
            max-width: ${({ theme }) => theme.variables.sidebar.width}px;
            width: 100%;
            max-height: ${({ theme }) => {
                const offset =
                    theme.variables.sidebar.offsetVertical * 2 +
                    theme.variables.requestPanel.height +
                    theme.variables.footer.height;
                return `calc(100vh - ${offset}px)`;
            }};
            overflow-y: auto;

            &.sticky {
                position: fixed;
                right: auto;
                z-index: 1;
            }

            .sidebar-list {
                position: relative;
                padding-left: ${({ theme }) =>
                    theme.variables.button.sizes.medium + theme.variables.sidebar.errorSize}px;
                margin: 0;

                &--closed {
                    overflow: hidden;
                    display: none;
                }
            }

            .sidebar-link {
                word-break: break-word;
            }

            .sidebar-item,
            .sidebar-subitem {
                display: flex;
                align-items: center;
                position: relative;
                overflow: visible;
                border: none;
                min-height: ${({ theme }) => theme.variables.button.sizes.medium}px;
            }

            .sidebar-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: ${({ theme }) => theme.spacing(3)}px;
                padding-left: ${({ theme }) => theme.spacing(2)}px;

                &.open,
                &:last-child {
                    padding-bottom: 0;
                }

                &:not(.has-subitems) {
                    padding-right: ${({ theme }) => theme.variables.button.sizes.medium}px;
                }
            }

            .sidebar-subitem {
                padding-right: ${({ theme }) => theme.variables.button.sizes.medium}px;
                padding-left: ${({ theme }) => theme.spacing(2)}px;

                &.last {
                    padding-bottom: ${({ theme }) => theme.spacing(3)}px;
                }
            }
        }

        ${({ theme }) => theme.breakpoints.up('xxl')} {
            width: ${({ theme }) => theme.variables.sidebar.widthXl};

            .sidebar {
                width: ${({ theme }) => theme.variables.sidebar.widthXl};
            }
        }
    `,
);

export const ErrorButton = withTheme(
    styled(IconButton)`
        color: ${({ theme }) => theme.palette.common.white} !important;
        width: ${({ theme }) => theme.variables.sidebar.errorSize}px !important;
        height: ${({ theme }) => theme.variables.sidebar.errorSize}px !important;
        font-weight: bold;

        .MuiIconButton-label {
            border-radius: 50%;
            border: 1px solid ${({ theme }) => theme.palette.error.main};
            background: ${({ theme }) => theme.palette.error.main};

            &:hover {
                color: ${({ theme }) => theme.palette.error.main} !important;
                background-color: transparent !important;
            }
        }
    `,
);

export const ErrorButtonContainer = withTheme(
    styled.div`
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: ${({ theme }) => -theme.variables.sidebar.errorSize + theme.spacing(1)}px;
        width: ${({ theme }) => theme.variables.sidebar.errorSize}px;
        height: ${({ theme }) => theme.variables.button.sizes.medium}px;
    `,
);

export const SidebarCollapseButton = withTheme(
    styled(IconButton)`
        margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    `,
);

export const SidebarIconWrapper = withTheme(styled.div`
    position: absolute;
    top: 0;
    left: ${({ theme }) => -theme.variables.button.sizes.medium - theme.variables.sidebar.errorSize}px;
    width: ${({ theme }) => theme.variables.button.sizes.medium}px;
    height: ${({ theme }) => theme.variables.button.sizes.medium}px;
    border-radius: ${({ theme }) => theme.variables.button.sizes.medium / 2}px;
    background: ${({ theme }) => theme.variables.palette.panel.markerBackgroundColor};

    & svg {
        margin-left: 9px;
        margin-top: 7px;
        fill: ${({ theme }) => theme.variables.palette.panel.markerColor};
    }
`);
