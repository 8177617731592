import { FormDTO, FormModel } from '@platform/formiojs-react';
import { action, computed, IObservableArray, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { IntlStore, PlanEntryDTO } from '../../store';
import { CodeTitle, CodeTitleNull } from '../CodeTitle';
import { EntityCreatedDTO } from '../EntityCreatedDTO';

export class PlanEntryModel {
    @observable id = '';
    @observable formModel: FormModel = new FormModel(this.id);
    @observable taskFormCode?: string;
    @observable taskFormModel?: FormModel;
    @observable taskForm: CodeTitleNull = null;

    @observable taskFormSelectData: IObservableArray<CodeTitle>;
    formByCode: (code: string) => Promise<FormDTO>;

    @observable validationStarted = false;

    @observable intl: IntlStore;

    constructor(
        taskFormSelectData: IObservableArray<CodeTitle>,
        formByCode: (code: string) => Promise<FormDTO>,
        intl: IntlStore,
    ) {
        this.taskFormSelectData = taskFormSelectData;
        this.formByCode = formByCode;
        this.intl = intl;
    }

    @action.bound
    load(dto: PlanEntryDTO): PlanEntryModel {
        this.id = dto.id;
        const { formInfo, taskFormInfo, taskFormCode } = dto;

        if (formInfo) {
            this.formModel = new FormModel(dto.id).load(formInfo);
        }

        if (taskFormInfo) {
            this.taskFormModel = new FormModel(dto.id).load(taskFormInfo);
        }

        if (taskFormCode) {
            this.taskFormCode = taskFormCode;
        }
        return this;
    }

    @action.bound
    loadCreated(dto: EntityCreatedDTO): PlanEntryModel {
        this.id = dto.id;
        if (dto.form) {
            const { form, submission, i18n } = dto.form;
            this.formModel = new FormModel(dto.id).load({ form, submission, i18n });
        }
        return this;
    }

    @action.bound
    onChangeSelect(event: ChangeEvent<{}>, value: CodeTitleNull): void {
        const newCode = value?.code;
        if (!!newCode && this.taskFormCode !== newCode) {
            this.formByCode(newCode).then((form) => {
                this.taskFormModel = new FormModel(this.id).load(form);
            });
        }
        this.taskFormCode = newCode;
        this.taskForm = value;
    }

    @action.bound
    setTaskForm(form: CodeTitleNull): void {
        this.taskForm = form;
    }

    @computed
    get errorTaskForm(): string {
        if (this.validationStarted && !this.taskFormCode) {
            return this.intl.formatMessage('validation.required');
        }
        return '';
    }

    @computed
    get isValid(): boolean {
        return !this.errorTaskForm;
    }
}
