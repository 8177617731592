import downloadFile from 'js-file-download';
import { action, observable } from 'mobx';
import { tabSize } from '../constants';
import { ConsoleStore, RootStore } from '../store';

export class ConsoleModel {
    @observable private rootStore: RootStore;
    @observable private consoleStore: ConsoleStore;

    @observable code = '';
    @observable result = '';
    @observable error = '';

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.consoleStore = rootStore.consoleStore;
    }

    @action.bound
    runScript(): Promise<void> {
        this.clearResult();

        return this.consoleStore
            .runScript(this.code)
            .then((result) => {
                const { blob, isFile, fileName } = result;

                if (isFile) {
                    downloadFile(blob, fileName);
                    return;
                }

                blob.text().then((text: string) => {
                    this.setResult(text);
                });
            })
            .catch((error) => {
                const errorBlob = error.response.data;
                errorBlob.text().then((errors: any) => {
                    const errorsParse = JSON.parse(errors);
                    this.setError(JSON.stringify(errorsParse, null, tabSize));
                });
            });
    }

    @action.bound
    copyResult(): void {
        navigator.clipboard.writeText(this.error || this.result);
    }

    @action.bound
    clearResult(): void {
        this.result = '';
        this.error = '';
    }

    @action.bound
    setCode(code: string): void {
        this.code = code;
    }

    @action.bound
    setResult(result: string): void {
        this.result = result;
    }

    @action.bound
    setError(error: string): void {
        this.error = error;
    }
}
