import {
    LabelDisplayedRowsArgs,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { TotTablePagination as TotTablePaginationInj } from '../../../components';
import { ExpertiseTaskListModel } from '../../../models';
import { ExpertiseTaskListOldHeaderTable as ExpertiseTaskListOldHeaderTableInj } from './ExpertiseTaskListOldHeaderTable';
import { ExpertiseTaskListOldTableRow as ExpertiseTaskListTableRowInj } from './ExpertiseTaskListOldTableRow';

export type ExpertiseTaskListOldTableProps = {
    tableModel: ExpertiseTaskListModel;
};

export const expertiseTaskNumberWidthCell = { width: '16%' };
export const expertiseTaskWidthCell = { width: '12%' };

export const ExpertiseTaskListOldTable = observer((props: ExpertiseTaskListOldTableProps): JSX.Element => {
    const [TotTablePagination] = di([TotTablePaginationInj], ExpertiseTaskListOldTable);
    const [ExpertiseTaskListTableRow] = di([ExpertiseTaskListTableRowInj], ExpertiseTaskListOldTable);
    const [ExpertiseTaskListOldHeaderTable] = di([ExpertiseTaskListOldHeaderTableInj], ExpertiseTaskListOldTable);

    const { tableModel } = props;
    const intl = useIntl();

    const renderBody: JSX.Element[] = tableModel.rows.map((row) => {
        return <ExpertiseTaskListTableRow key={row.id} row={row} />;
    });

    const getLabelDisplayedRows = (p: LabelDisplayedRowsArgs): string => {
        return intl.formatMessage(
            {
                id: 'expertiseTask.registryPagingInfo',
            },
            {
                to: p.to,
                from: p.from,
                count: p.count,
            },
        );
    };

    return (
        <TableContainer component={Paper}>
            {tableModel.isLoading && <LinearProgress />}
            <Table>
                <TableHead>
                    <ExpertiseTaskListOldHeaderTable tableModel={tableModel} />
                </TableHead>
                <TableBody>{renderBody}</TableBody>
                <TableFooter>
                    <TableRow>
                        <TotTablePagination
                            count={tableModel.rowsCount}
                            page={tableModel.pageNumber}
                            onChangePage={tableModel.onChangePage}
                            onChangeRowsPerPage={tableModel.onChangePageSize}
                            rowsPerPage={tableModel.pageSize}
                            rowsPerPageOptions={tableModel.pageSizeOptions}
                            labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                            labelDisplayedRows={getLabelDisplayedRows}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
});
