import { Formik } from 'formik';
import { toJS } from 'mobx';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { ErrorDialog as ErrorDialogInj, ErrorMessage as ErrorMessageInj } from '../../../../components';
import { useError, useYup } from '../../../../hooks';
import { CodeTitle } from '../../../../models';
import { en, ru, UserForCreate } from '../../../../store';
import { userAddForm } from './UserAddForm';

export type UserAddDialogProps = {
    open: boolean;
    setModalIsClosed: () => void;
    createUser: (user: UserForCreate) => Promise<string>;
    userRoleList: CodeTitle[];
};

export type UserAddDialogFormValues = {
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    enFirstName: string;
    enLastName: string;
    enMiddleName: string;
    englishUser: boolean;
    roles: CodeTitle[];
};

const initialValues: UserAddDialogFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    enFirstName: '',
    enLastName: '',
    enMiddleName: '',
    englishUser: false,
    roles: [],
};

export const UserAddDialog = (props: UserAddDialogProps): JSX.Element => {
    const [ErrorDialog] = di([ErrorDialogInj], UserAddDialog);
    const [ErrorMessage] = di([ErrorMessageInj], UserAddDialog);

    const { open, setModalIsClosed, createUser, userRoleList } = props;
    const { Yup } = useYup();
    const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();
    const history = useHistory();

    const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        roles: Yup.array().min(1),
    });

    const handleSubmit = async (values: UserAddDialogFormValues): Promise<void> => {
        const { firstName, lastName, middleName, enFirstName, enLastName, enMiddleName, email, englishUser, roles } =
            values;

        const user: UserForCreate = {
            lastName,
            firstName,
            middleName,
            email,
            lang: englishUser ? en : ru,
            names: [
                {
                    lastName,
                    firstName,
                    middleName,
                    lang: ru,
                },
                {
                    lastName: enLastName,
                    firstName: enFirstName,
                    middleName: enMiddleName,
                    lang: en,
                },
            ],
            roles: roles.map((item) => item.code),
        };

        try {
            const userId = await createUser(user);
            history.push(
                generatePath(clientRoute.user, {
                    id: userId,
                }),
            );
        } catch (error) {
            const errorText = ErrorMessage(error);
            setErrorIsOpen(errorText);
        }
    };

    const formikForm = userAddForm(open, toJS(userRoleList), setModalIsClosed);

    return (
        <React.Fragment>
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            <Formik initialValues={initialValues} validationSchema={schema} enableReinitialize onSubmit={handleSubmit}>
                {formikForm}
            </Formik>
        </React.Fragment>
    );
};
