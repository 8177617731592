import { useState } from 'react';

export type UseReload = {
    reloadKey: number;
    reloadIncrement: () => void;
};

export const useReload = (): UseReload => {
    const [reloadKey, setReloadKey] = useState<number>(0);
    const reloadIncrement = (): void => setReloadKey(reloadKey + 1);

    return { reloadKey, reloadIncrement };
};
