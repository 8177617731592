import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { GetAppOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import {
    ActionsButton as ActionsButtonInj,
    AuthorizationCheck as AuthorizationCheckInj,
    MenuButton as MenuButtonInj,
} from '../../components';
import { useStore } from '../../hooks';
import { PfTemplateListModel } from '../../models';
import { PfTemplateDTO } from '../../store';
import { PfTemplateCreateDialog as PfTemplateCreateDialogInj } from './PfTemplateDialog';

export const PfTemplateListPage = observer((): JSX.Element => {
    const [PfTemplateCreateDialog] = di([PfTemplateCreateDialogInj], PfTemplateListPage);
    const [ActionsButton] = di([ActionsButtonInj], PfTemplateListPage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], PfTemplateListPage);
    const [MenuButton] = di([MenuButtonInj], PfTemplateListPage);

    const { pfTemplateStore } = useStore();
    const model = useMemo(() => new PfTemplateListModel(), []);
    const { pfTemplateList } = model;

    const handleReloadTemplateList = useCallback(() => {
        pfTemplateStore.loadTemplateList().then(model.load);
    }, [model, pfTemplateStore]);

    useEffect(() => {
        handleReloadTemplateList();
    }, [handleReloadTemplateList]);

    const handleDownloadTemplateFile = (pfTemplate: PfTemplateDTO): (() => void) => {
        return () => pfTemplateStore.downloadTemplateFile(pfTemplate.fileDTO);
    };

    const renderActions = (pfTemplate: PfTemplateDTO): (() => ReactNode[]) => {
        return () => [
            <MenuItem
                dense
                key={'edit'}
                tabIndex={0}
                button={true}
                component={NavLink}
                to={generatePath(clientRoute.pfTemplateEdit, { id: pfTemplate.id })}
            >
                <FormattedMessage id="common.edit" />
            </MenuItem>,
        ];
    };

    return (
        <Container maxWidth="lg">
            <Box pt={5} pb={5}>
                <Grid container direction="column">
                    <Box pb={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="pfTemplate.listTitle" />
                                </Typography>
                            </Grid>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.CreatePfTemplate}
                            >
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        component={NavLink}
                                        to={clientRoute.pfTemplateCreate}
                                    >
                                        <FormattedMessage id="pfTemplate.create" />
                                    </Button>
                                </Grid>
                            </AuthorizationCheck>
                        </Grid>
                    </Box>
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="pfTemplate.fields.title" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <FormattedMessage id="pfTemplate.fields.created" />
                                            </Typography>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pfTemplateList.map((pfTemplate) => {
                                        return (
                                            <TableRow key={pfTemplate.id} hover>
                                                <TableCell>{pfTemplate.title}</TableCell>
                                                <TableCell>
                                                    <FormattedDate value={pfTemplate.created} />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Grid container justify="flex-end">
                                                        <Tooltip
                                                            title={
                                                                <FormattedMessage id="pfTemplate.actions.download" />
                                                            }
                                                        >
                                                            <div>
                                                                <IconButton
                                                                    onClick={handleDownloadTemplateFile(pfTemplate)}
                                                                >
                                                                    <GetAppOutlined />
                                                                </IconButton>
                                                            </div>
                                                        </Tooltip>
                                                        <AuthorizationCheck
                                                            entityCode={entities.System}
                                                            permCode={permissions.System.EditPfTemplate}
                                                        >
                                                            <MenuButton
                                                                disablePortal={true}
                                                                renderButton={ActionsButton}
                                                                renderMenuItems={renderActions(pfTemplate)}
                                                            />
                                                        </AuthorizationCheck>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            <Route path={clientRoute.pfTemplateCreate} key={clientRoute.pfTemplateCreate}>
                <PfTemplateCreateDialog
                    pfTemplateList={pfTemplateList}
                    pageTitleId="pfTemplate.create"
                    templateUpdateMethod={pfTemplateStore.create}
                    updateTemplateListMethod={handleReloadTemplateList}
                />
            </Route>
            <Route path={clientRoute.pfTemplateEdit} key={clientRoute.pfTemplateEdit}>
                <PfTemplateCreateDialog
                    pfTemplateList={pfTemplateList}
                    pageTitleId="pfTemplate.edit"
                    templateUpdateMethod={pfTemplateStore.update}
                    updateTemplateListMethod={handleReloadTemplateList}
                />
            </Route>
        </Container>
    );
});
