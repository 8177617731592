import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RowData as RowDataInj, SyslogLink as SyslogLinkInj } from '../../../components';
import { CampaignModel } from '../../../models';
import { getOrElse } from '../../../utils';

export type CampaignHeaderLeftProps = {
    identifier: CampaignModel['identifier'];
    created: CampaignModel['metaInfo']['created'];
    stateTitle: CampaignModel['metaInfo']['stateTitle'];
    managerName: CampaignModel['manager']['name'];
};

export const CampaignHeaderLeft = observer((props: CampaignHeaderLeftProps): JSX.Element => {
    const [RowData] = di([RowDataInj], CampaignHeaderLeft);
    const [SyslogLink] = di([SyslogLinkInj], CampaignHeaderLeft);

    const { identifier, created, stateTitle, managerName } = props;
    const intl = useIntl();

    const identifierValue = identifier || intl.formatMessage({ id: 'common.withoutNumber' });
    const createdValue = getOrElse(created, intl.formatDate(created), '-');

    return (
        <React.Fragment>
            <Grid item>
                <RowData label={intl.formatMessage({ id: 'campaign.fields.identifier' })} value={identifierValue} />
            </Grid>
            <Grid item>
                <RowData label={intl.formatMessage({ id: 'campaign.fields.created' })} value={createdValue} />
            </Grid>
            <Grid item>
                <RowData label={intl.formatMessage({ id: 'campaign.fields.state' })} value={stateTitle} />
            </Grid>
            <Grid item>
                <SyslogLink />
            </Grid>
            <Grid item>
                <RowData label={intl.formatMessage({ id: 'campaign.fields.responsible' })} value={managerName} />
            </Grid>
        </React.Fragment>
    );
});
