import { FormioDateTime } from '../../../../types/themeTypes';

export default {
    formControl: {
        borderRadius: '4px !important',
    },

    inputGroupAddon: {
        width: '40px',
        height: '40px',
        top: 'calc(50% - 20px)',
        right: '10px',
    },
} as FormioDateTime;
