import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import {
    ActionsButton as ActionsButtonInj,
    AuthorizationCheck as AuthorizationCheckInj,
    DeleteActionMenuItem as DeleteActionMenuItemInj,
    MenuButton as MenuButtonInj,
    TotTablePagination as TotTablePaginationInj,
} from '../../components';
import { useLoading, useStore } from '../../hooks';
import { CategoriesOfSubjectsListModel, CategoriesOfSubjectsRowDTO } from '../../models';

export const CategoriesOfSubjectsListPage = observer((): JSX.Element => {
    const [ActionsButton] = di([ActionsButtonInj], CategoriesOfSubjectsListPage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], CategoriesOfSubjectsListPage);
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], CategoriesOfSubjectsListPage);
    const [MenuButton] = di([MenuButtonInj], CategoriesOfSubjectsListPage);
    const [TotTablePagination] = di([TotTablePaginationInj], CategoriesOfSubjectsListPage);

    const { api, categoriesOfSubjectsStore, history } = useStore();
    const intl = useIntl();

    const tableModel = useMemo(() => new CategoriesOfSubjectsListModel(api), [api]);

    useEffect(() => {
        return tableModel.dispose;
    }, [tableModel.dispose]);

    const { isLoading, enableLoading, disableLoading } = useLoading();
    const createCategoryOfSubjects = async (): Promise<void> => {
        try {
            enableLoading();
            const id = await categoriesOfSubjectsStore.createCategoryOfSubjects();
            history.push(generatePath(clientRoute.categoryCreate, { id }));
        } catch (error) {
            disableLoading();
        }
    };

    const deleteCategoryOfSubjects = (categoryRow: CategoriesOfSubjectsRowDTO): (() => Promise<void>) => {
        return (): Promise<void> => {
            return categoriesOfSubjectsStore.deleteCategoryOfSubjects(categoryRow.id).then(tableModel.reloadData);
        };
    };

    const onNotAllowed = (): void => {
        history.replace(clientRoute.notAllowed);
    };

    const renderActionItems = (categoryRow: CategoriesOfSubjectsRowDTO): (() => ReactNode[]) => {
        return (): ReactNode[] => [
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                wrappedComponentProps={{ tabIndex: 0 }}
                title={<FormattedMessage id="common.confirmDeletion" />}
                message={
                    <FormattedMessage id="category.confirmDeletionInfoText" values={{ title: categoryRow.title }} />
                }
                onConfirm={deleteCategoryOfSubjects(categoryRow)}
            />,
        ];
    };

    const renderActions = (categoryRow: CategoriesOfSubjectsRowDTO): JSX.Element => {
        return (
            <MenuButton
                disablePortal={true}
                renderButton={ActionsButton}
                renderMenuItems={renderActionItems(categoryRow)}
            />
        );
    };

    return (
        <AuthorizationCheck
            entityCode={entities.System}
            permCode={permissions.System.Administration}
            onNotAllowed={onNotAllowed}
        >
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column">
                        <Box pb={10}>
                            <Grid item container direction="row" justify="space-between">
                                <Grid item>
                                    <Typography variant="h1">
                                        <FormattedMessage id="category.categoryListTitle" />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={isLoading}
                                        color="primary"
                                        variant="contained"
                                        onClick={createCategoryOfSubjects}
                                    >
                                        <FormattedMessage id="category.createCategory" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {tableModel.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '85%' }}>
                                                <Typography>
                                                    <FormattedMessage id="category.fields.title" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '15%' }}>
                                                <Typography>
                                                    <FormattedMessage id="category.fields.created" />
                                                </Typography>
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableModel.rows.map((c) => {
                                            return (
                                                <TableRow key={c.id} hover>
                                                    <TableCell>
                                                        <Link
                                                            component={NavLink}
                                                            underline="none"
                                                            to={generatePath(clientRoute.category, { id: c.id })}
                                                        >
                                                            {c.title}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormattedDate value={c.created} />
                                                    </TableCell>
                                                    <TableCell>{renderActions(c)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TotTablePagination
                                                count={tableModel.rowsCount}
                                                page={tableModel.pageNumber}
                                                onChangePage={tableModel.onChangePage}
                                                onChangeRowsPerPage={tableModel.onChangePageSize}
                                                rowsPerPage={tableModel.pageSize}
                                                rowsPerPageOptions={tableModel.pageSizeOptions}
                                                labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                                labelDisplayedRows={(p): string =>
                                                    intl.formatMessage(
                                                        { id: 'category.pagingInfo' },
                                                        { to: p.to, from: p.from, count: p.count },
                                                    )
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </AuthorizationCheck>
    );
});
