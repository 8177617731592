import { Checkbox, TextField } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import styled from 'styled-components';
import { checkboxProps } from '../../constants';
import { GroupedIdTitle, IdTitleParent } from '../../models';
import { EllipsisBox as EllipsisBoxInj } from '../../pages';
import { ReactComponent as DeleteChipIcon } from '../../resources/images/icons/deleteChipIcon.svg';
import { IdTitle } from '../../types';
import { ListboxComponent as ListboxComponentInj } from './VirtualizationListBox';

const SelectCheckbox = withTheme(
    styled(Checkbox)`
        &.MuiCheckbox-colorPrimary {
            padding: 0;
            color: ${({ theme }): string => theme.variables.palette.mainMiddleLight};
            margin-right: ${({ theme }): string => `${theme.spacing(2)}px`};
        }
        &.MuiCheckbox-colorPrimary.Mui-checked {
            color: ${({ theme }): string => theme.variables.palette.main};
        }
    `,
);

type AutoCompleteProps = {
    disableClearable: boolean;
    freeSolo: boolean;
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    limitTags?: number;
};

type SelectData = Array<IdTitle | IdTitleParent> | GroupedIdTitle;

export type SelectProps = {
    values: string[];
    label: ReactNode | JSX.Element;
    placeholder?: string;
    selectData: SelectData;
    onChange: (e: ChangeEvent<{}>, value: string[]) => void;
    textFieldProps: TextFieldProps;
    autoCompleteProps: AutoCompleteProps;
    virtualized?: boolean;
    correctLengthOptionLabel?: number;
};

export const Select = (props: SelectProps): JSX.Element => {
    const [EllipsisBox] = di([EllipsisBoxInj], Select);
    const [ListboxComponent] = di([ListboxComponentInj], Select);

    const {
        values,
        label,
        placeholder,
        selectData,
        onChange,
        textFieldProps,
        autoCompleteProps,
        virtualized,
        correctLengthOptionLabel,
    } = props;

    const groupByFunc = (option: string): string => {
        const selectDataGrouped = selectData as GroupedIdTitle;
        return getTitle(option, selectDataGrouped, true);
    };

    const renderOptionArrayData = (option: string, { selected }: { selected: boolean }): JSX.Element => {
        const selectDataArray = selectData as Array<IdTitle | IdTitleParent>;
        return (
            <React.Fragment>
                <SelectCheckbox {...checkboxProps} checked={selected} />
                {virtualized ? (
                    <EllipsisBox
                        text={getArrayDataTitle(option, selectDataArray)}
                        {...(correctLengthOptionLabel && { correctLength: correctLengthOptionLabel })}
                    />
                ) : (
                    getArrayDataTitle(option, selectDataArray)
                )}
            </React.Fragment>
        );
    };

    const renderOptionGroupedData = (option: string, { selected }: { selected: boolean }): JSX.Element => {
        const selectDataGrouped = selectData as GroupedIdTitle;
        return (
            <React.Fragment>
                <SelectCheckbox {...checkboxProps} checked={selected} />
                {virtualized ? (
                    <EllipsisBox
                        text={getTitle(option, selectDataGrouped, false)}
                        {...(correctLengthOptionLabel && { correctLength: correctLengthOptionLabel })}
                    />
                ) : (
                    getTitle(option, selectDataGrouped, false)
                )}
            </React.Fragment>
        );
    };

    const getOptionLabelArrayData = (option: string): string => {
        const selectDataArray = selectData as Array<IdTitle | IdTitleParent>;
        return getArrayDataTitle(option, selectDataArray);
    };

    const getOptionLabelGroupedData = (option: string): string => {
        const selectDataGrouped = selectData as GroupedIdTitle;
        return getTitle(option, selectDataGrouped, false);
    };

    const renderInput = (params: object): JSX.Element => {
        return (
            <TextField
                {...params}
                {...textFieldProps}
                label={label}
                placeholder={values.length && placeholder ? '' : placeholder}
            />
        );
    };

    const isArrayFunc = (data: SelectData): boolean => {
        const array = '[object Array]';
        return Object.prototype.toString.call(data) === array;
    };

    const idArray = (selectData: Array<IdTitle | IdTitleParent>): Array<string> => {
        return selectData.map((o) => o.id);
    };

    const idArrayGroupedData = (selectData: GroupedIdTitle): Array<string> => {
        const idArray: Array<string> = [];
        Object.keys(selectData).forEach((groupName) => {
            selectData[groupName].forEach((i) => {
                idArray.push(i.id);
            });
        });
        return idArray;
    };

    const getArrayDataTitle = (option: string, data: Array<IdTitle | IdTitleParent>): string => {
        const selectDataItem = data.find((o) => o.id === option);
        return selectDataItem ? selectDataItem.title : '';
    };

    const getTitle = (option: string, data: GroupedIdTitle, isGroupBy: boolean): string => {
        let title = '';
        Object.keys(data).find((groupName) => {
            const groupedItem = data[groupName].find((itemGroup) => itemGroup.id === option);
            if (groupedItem) {
                title = isGroupBy ? groupName : groupedItem.title;
                return true;
            } else {
                return false;
            }
        });
        return title;
    };

    const isArray = isArrayFunc(selectData);
    const selectDataArray = selectData as Array<IdTitle | IdTitleParent>;
    const selectDataGrouped = selectData as GroupedIdTitle;
    const options = isArray ? idArray(selectDataArray) : idArrayGroupedData(selectDataGrouped);
    let groupBy = undefined;
    let renderOption = renderOptionArrayData;
    let getOptionLabel = getOptionLabelArrayData;
    if (!isArray) {
        groupBy = groupByFunc;
        renderOption = renderOptionGroupedData;
        getOptionLabel = getOptionLabelGroupedData;
    }
    return (
        <Autocomplete
            {...autoCompleteProps}
            multiple
            {...(virtualized && {
                ListboxComponent: ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>,
            })}
            popupIcon={<ExpandMoreIcon />}
            value={values}
            ChipProps={{
                deleteIcon: <DeleteChipIcon />,
                size: 'small',
            }}
            groupBy={groupBy}
            noOptionsText={<FormattedMessage id="common.noOptions" />}
            disableCloseOnSelect={true}
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={onChange}
            renderOption={renderOption}
            renderInput={renderInput}
        />
    );
};
