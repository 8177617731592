import { action, observable } from 'mobx';
import { ExpertiseTaskDTO, IntlStore, NotificationStore } from '../../store';
import { IdTitle } from '../../types';
import { DateUtils } from '../../utils';
import { ExpertiseTaskModel } from './ExpertiseTaskModel';

export type ExpertiseTaskViewDTO = {
    taskInfo: ExpertiseTaskDTO;
    reportsInfo?: ReportsRowDTO[];
};

export type ReportsRowDTO = {
    report: IdTitle;
    deadline?: string; //date
    completed?: string; //date
};

export type ReportsRow = {
    report: IdTitle;
    deadline?: Date;
    completed?: Date;
};

export class ExpertiseTaskViewModel {
    @observable id: string;
    @observable taskModel: ExpertiseTaskModel;
    @observable reportsTable?: ReportsRow[];
    @observable notificationStore: NotificationStore;
    @observable intlStore: IntlStore;

    constructor(id: string, notificationStore: NotificationStore, intlStore: IntlStore) {
        this.id = id;
        this.taskModel = new ExpertiseTaskModel(id);
        this.notificationStore = notificationStore;
        this.intlStore = intlStore;
    }

    @action.bound
    load(dto: ExpertiseTaskViewDTO): void {
        const { taskInfo, reportsInfo } = dto;
        if (!taskInfo.formInfo) {
            this.notificationStore.onError(this.intlStore.formatMessage('common.formNotFound'));
        }

        this.taskModel.load(taskInfo);
        reportsInfo && (this.reportsTable = reportsInfo.map(this.mapReportsRowDTO));
    }

    mapReportsRowDTO(dto: ReportsRowDTO): ReportsRow {
        const deadline = new Date(dto.deadline || '');
        const completed = new Date(dto.completed || '');

        const dateDTO = {
            ...(DateUtils.isValidDate(deadline) && { deadline }),
            ...(DateUtils.isValidDate(completed) && { completed }),
        };

        return {
            report: dto.report,
            ...dateDTO,
        };
    }
}
