import { Portal } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { MouseEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAntiDoubleClick } from '../hooks';

export type ConfirmationDialogProps = {
    id: string;
    keepMounted: boolean;
    open: boolean;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    title: ReactNode;
    message: ReactNode;
    confirmText?: string;
};

export const ConfirmationDialog = (props: ConfirmationDialogProps): JSX.Element => {
    const { confirmText, onConfirm, onCancel, open, title, message, ...other } = props;
    const [isLoading, startIcon, onSubmitHandler] = useAntiDoubleClick(onConfirm);

    const handleConfirm = (event: MouseEvent<HTMLButtonElement>): void => {
        onSubmitHandler(undefined);
    };

    return (
        <Portal>
            <Dialog fullWidth={true} maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open} {...other}>
                <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
                <DialogContent>{message}</DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onCancel} color="primary">
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        disabled={isLoading}
                        startIcon={startIcon}
                        onClick={handleConfirm}
                        color="primary"
                        variant="contained"
                    >
                        {confirmText || <FormattedMessage id={'common.confirm'} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
};
