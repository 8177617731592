import { action, observable } from 'mobx';
import { ChangeEvent } from 'react';
import apiConfigs from '../../apiConfigs';
import { Api, RootStore } from '../../store';

export class SelectionOfExpertListModel {
    @observable private rootStore: RootStore;
    @observable private api: Api;
    @observable id = '';
    @observable expertId = '';
    @observable isError = false;

    constructor(id: string, rootStore: RootStore) {
        this.api = rootStore.api;
        this.rootStore = rootStore;
        this.id = id;
    }

    @action.bound
    onSubmitCreator(onClose: () => void): () => Promise<void> {
        return () => {
            if (this.expertId) {
                return this.saveExpertOnTask(this.id, this.expertId)
                    .then(() => {
                        onClose();
                    })
                    .catch(this.setIsError);
            }

            this.setIsError();
            return Promise.reject();
        };
    }

    @action.bound
    onChecked(event: ChangeEvent<HTMLInputElement>, checked: boolean): void {
        if (checked) {
            this.setExpertId(event.target.value);
            if (this.isError) {
                this.dropIsError();
            }
        }
    }

    @action.bound
    saveExpertOnTask(taskId: string, expertId: string): Promise<void> {
        return this.api.client(apiConfigs.saveExpertOnTask(taskId, expertId)).then((r) => r.data);
    }

    @action.bound
    dropIsError(): void {
        this.isError = false;
    }

    @action.bound
    setExpertId(expertId: string): void {
        this.expertId = expertId;
    }

    @action.bound
    setIsError(): void {
        this.isError = true;
    }
}
