export default class ScrollUtils {
    static scrollToElementById(id: string): void {
        const element: HTMLElement | null = document.querySelector('#' + id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }
}
