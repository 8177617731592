import { action, observable } from 'mobx';
import { ExpertiseTaskStore } from '../../store';
import { IdTitle } from '../../types';
import { DateUtils } from '../../utils';
import { CodeTitle } from '../CodeTitle';
import { UserNameModel, UserPersonDTO } from '../person';

export interface ExpertiseTasksRow {
    id: string;
    identifier: string;
    planEntry: IdTitle;
    viewPoint?: string;
    expertName: string;
    expertCandidateName: string;
    acceptance?: Date;
    deadline?: Date;
    planAcceptanceDays?: number;
    planAcceptance?: Date;
    planDeadlineDays: number;
    planDeadline?: Date;
    completed?: Date;
    state: string;
    taskType?: CodeTitle;
}

// dto записи в блоке заданий на экспертизу на странице экспертизы
export interface ExpertiseTasksRowDTO {
    id: string;
    identifier: string;
    planEntry: IdTitle;
    viewPoint?: string;
    expert?: UserPersonDTO;
    expertCandidate?: UserPersonDTO;
    acceptance?: string;
    deadline?: string;
    planAcceptanceDays?: number;
    planAcceptance?: string;
    planDeadlineDays: number;
    planDeadline?: string;
    completed?: string;
    state: string;
    taskType?: CodeTitle;
}

export class ExpertiseTasksListByExpertiseModel {
    @observable id: string;
    @observable store: ExpertiseTaskStore;
    @observable expertiseTasks: ExpertiseTasksRow[] = [];

    constructor(id: string, store: ExpertiseTaskStore) {
        this.id = id;
        this.store = store;
        this.loadExpertiseTasksList();
    }

    @action.bound
    loadExpertiseTasksList(): void {
        this.store
            .loadExpertiseTasksListByExpertise(this.id)
            .then((tasks) => (this.expertiseTasks = tasks.map(this.mapExpertiseTasksDTO)));
    }

    @action.bound
    deleteTask(id: string): () => Promise<void> {
        return async (): Promise<void> => {
            await this.store.deleteExpertiseTask(id);
            await this.loadExpertiseTasksList();
        };
    }

    @action.bound
    mapExpertiseTasksDTO(dto: ExpertiseTasksRowDTO): ExpertiseTasksRow {
        //todo: это зачем? от бэка прилетают явные Date
        const acceptance = new Date(dto.acceptance || '');
        const deadline = new Date(dto.deadline || '');
        const completed = new Date(dto.completed || '');
        const planAcceptance = new Date(dto.planAcceptance || '');
        const planDeadline = new Date(dto.planDeadline || '');
        const expertName = dto.expert ? new UserNameModel().load(dto.expert).name : '';
        const expertCandidateName = dto.expertCandidate ? new UserNameModel().load(dto.expertCandidate).name : '';

        const dateDTO = {
            ...(DateUtils.isValidDate(acceptance) && { acceptance }),
            ...(DateUtils.isValidDate(deadline) && { deadline }),
            ...(DateUtils.isValidDate(completed) && { completed }),
        };

        return {
            id: dto.id,
            identifier: dto.identifier,
            planEntry: dto.planEntry,
            viewPoint: dto.viewPoint,
            planAcceptanceDays: dto.planAcceptanceDays,
            planAcceptance: planAcceptance,
            planDeadlineDays: dto.planDeadlineDays,
            planDeadline: planDeadline,
            state: dto.state,
            taskType: dto.taskType,
            expertName,
            expertCandidateName,
            ...dateDTO,
        };
    }
}
