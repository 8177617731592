const campaign: Record<string, string | Record<string, string>> = {
    campaignListTitle: 'Campaigns',
    createCampaign: 'Create campaign',
    newCampaign: 'New campaign',
    editCampaign: 'Edit campaign',
    fields: {
        title: 'Title',
        identifier: 'Number',
        created: 'Created',
        state: 'State',
        responsible: 'Responsible',
        author: 'Author',
        lastModified: 'Last modified',
        lifecycle: 'Lifecycle',
    },
    subjects: {
        title: 'Subjects of expertise',
        total: 'Total',
        withoutExpertise: 'Without expertise',
        active: 'Current',
        withExpertise: 'With expertise',
    },
    expertises: {
        title: 'Expertises',
        total: 'Total',
        active: 'In progress',
        done: 'Done',
        canceled: 'Canceled',
    },
    tasks: {
        title: 'Tasks of expertise',
        total: 'Total',
        withoutExpert: 'Without expertise',
        responseWaiting: 'Expect an answer',
        startWaiting: 'Expect an start',
        active: 'In progress',
        overdue: 'Overdue',
        done: 'Done',
        notCompleted: 'Not completed',
    },
    registryPagingInfo: 'Total {count, number} campaigns. Displayed from {from, number} to {to, number}',
    blocks: {
        serviceInfo: 'Service information',
        expertiseObjects: 'Objects',
        expertise: 'Expertise',
        tasks: 'Tasks',
    },
    actions: {
        deleteCampaign: 'Delete campaign',
    },
    confirmDeletionInfoText: 'Are you sure you want to delete the campaign "{title}"?',
};

export default campaign;
