import { Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import clientRoute from '../../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    IconButtonLarge as IconButtonLargeInj,
    TotObjectDeleteButton as TotObjectDeleteButtonInj,
} from '../../../../components';
import { SubjectModel } from '../../../../models';
import { ReactComponent as EditIcon } from '../../../../resources/images/icons/edit.svg';
import { SubjectPageRouteParams } from '../SubjectPage';

export type SubjectControlPanelLeftProps = {
    id: SubjectPageRouteParams['id'];
    title: SubjectModel['title'];
    deleteSubject: () => Promise<void>;
};

export const SubjectControlPanelLeft = (props: SubjectControlPanelLeftProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], SubjectControlPanelLeft);
    const [IconButtonLarge] = di([IconButtonLargeInj], SubjectControlPanelLeft);
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], SubjectControlPanelLeft);

    const { id, title, deleteSubject } = props;
    return (
        <Grid spacing={3} alignItems="center" justify="center" container>
            <Switch>
                <Route key={clientRoute.subject} path={clientRoute.subject} exact>
                    <AuthorizationCheck
                        entityCode={entities.ExpertiseSubject}
                        permCode={permissions.ExpertiseSubject.Delete}
                        entityId={id}
                    >
                        <Grid item>
                            <TotObjectDeleteButton
                                id="delete-subject"
                                title={<FormattedMessage id="common.confirmDeletion" />}
                                message={<FormattedMessage id="subject.confirmDeletionInfoText" values={{ title }} />}
                                onConfirm={deleteSubject}
                                wrappedComponentProps={{
                                    tooltipTitleId: 'subject.actions.deleteSubject',
                                }}
                            />
                        </Grid>
                    </AuthorizationCheck>
                    <AuthorizationCheck
                        entityCode={entities.ExpertiseSubject}
                        permCode={permissions.ExpertiseSubject.Edit}
                        entityId={id}
                    >
                        <Grid item>
                            <Tooltip title={<FormattedMessage id="subject.actions.editSubject" />}>
                                <div>
                                    <IconButtonLarge
                                        component={NavLink}
                                        to={generatePath(clientRoute.subjectEdit, {
                                            id,
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButtonLarge>
                                </div>
                            </Tooltip>
                        </Grid>
                    </AuthorizationCheck>
                </Route>
            </Switch>
        </Grid>
    );
};
