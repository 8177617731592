import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import clientRoute from '../../../clientRoute';

export type CategoryOfSubjectsControlPanelRightProps = {
    onSubmit: () => void;
    categoryOfSubjectsId: string;
};

export const CategoryOfSubjectsControlPanelRight = (props: CategoryOfSubjectsControlPanelRightProps): JSX.Element => {
    const { onSubmit, categoryOfSubjectsId } = props;

    return (
        <Grid spacing={3} alignItems="center" justify="center" container wrap="nowrap">
            <Switch>
                <Route exact path={clientRoute.categoryCreate}>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.categories)}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route exact path={clientRoute.categoryEdit}>
                    <Grid item>
                        <Button
                            variant="contained"
                            component={NavLink}
                            to={generatePath(clientRoute.category, {
                                id: categoryOfSubjectsId,
                            })}
                        >
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
            </Switch>
        </Grid>
    );
};
