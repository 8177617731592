import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { PageHeader as PageHeaderInj, TotObjectHeader as TotObjectHeaderInj } from '../../../../components';
import { SubjectModel } from '../../../../models';
import { SubjectHeaderLeft as SubjectHeaderLeftInj } from './SubjectHeaderLeft';
import { SubjectHeaderRight as SubjectHeaderRightInj } from './SubjectHeaderRight';
import { SubjectHeaderTitle as SubjectHeaderTitleInj } from './SubjectHeaderTitle';

export type SubjectHeaderProps = {
    subjectModel: SubjectModel;
};

export const SubjectHeader = observer((props: SubjectHeaderProps): JSX.Element => {
    const [SubjectHeaderTitle] = di([SubjectHeaderTitleInj], SubjectHeader);
    const [SubjectHeaderLeft] = di([SubjectHeaderLeftInj], SubjectHeader);
    const [SubjectHeaderRight] = di([SubjectHeaderRightInj], SubjectHeader);
    const [PageHeader] = di([PageHeaderInj], SubjectHeader);
    const [TotObjectHeader] = di([TotObjectHeaderInj], SubjectHeader);

    const { subjectModel } = props;
    const { campaign, expertise, title, identifier, metaInfo } = subjectModel;
    const { stateTitle, created } = metaInfo;
    return (
        <TotObjectHeader>
            <PageHeader
                title={<SubjectHeaderTitle title={title} />}
                left={<SubjectHeaderLeft identifier={identifier} stateTitle={stateTitle} created={created} />}
                right={<SubjectHeaderRight campaign={campaign} expertise={expertise} />}
            />
        </TotObjectHeader>
    );
});
