import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Link } from '@material-ui/core';

export const HeaderLink = withTheme(
    styled(Link)`
        font-family: 'PT Sans', sans-serif;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 1.2px;
    `,
);
