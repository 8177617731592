import { Preset, TTable, TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../../components';
import { useStore } from '../../../../hooks';
import { RegistryType, UserTableRow } from '../../../../types';

export type UserRegistryTableProps = {
    setRowActions?: (row: UserTableRow, reloadData: () => void) => JSX.Element;
};

const registryCode = RegistryType.account;

export const UserRegistryTable = observer((props: UserRegistryTableProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserRegistryTable);

    const { setRowActions } = props;
    const { presetStore, catalogStore, userStore, registryStore } = useStore();
    const { fetchData, fetchColumns, downloadCreator } = registryStore;
    const intl = useIntl();

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
        presetStore.saveListPresets(settings);

    const download = downloadCreator(intl.formatMessage({ id: 'users.listTitle' }), registryCode);

    return (
        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
            {(allowed: boolean): JSX.Element => (
                <TTable<UserTableRow>
                    registryCode={registryCode}
                    fetchData={fetchData}
                    fetchColumns={fetchColumns}
                    fetchCatalog={catalogStore.fetchCatalogForTTable}
                    presetsSetting={presetStore}
                    fetchSelectDataByUrl={catalogStore.fetchSelectDataByUrl}
                    upload={download}
                    lang={intl.locale}
                    saveListPresets={saveListPresets}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    rowActions={setRowActions}
                />
            )}
        </AuthorizationCheck>
    );
});
