import { Box, Container, createStyles, Grid, makeStyles, Paper } from '@material-ui/core';
import { useFeature } from 'feature-toggle-jsx';
import React, { PropsWithChildren } from 'react';
import { di } from 'react-magnetic-di';
import { TotBackground as TotBackgroundInj } from '../TotBackground';

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            height: '100%',
        },
    }),
);

export type WithBackgroundPageWrapperProps = PropsWithChildren<{}>;

export const WithBackgroundPageWrapper = (props: WithBackgroundPageWrapperProps): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], WithBackgroundPageWrapper);

    const { children } = props;
    const classes = useStyles();
    const [customBackground, config] = useFeature('customBackground');
    return (
        <TotBackground>
            <Container maxWidth="lg" className={classes.container}>
                {!!config.src ? (
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        className={classes.container}
                    >
                        <Container maxWidth="xs">
                            <Paper elevation={0}>
                                <Box p={12} textAlign="center">
                                    {children}
                                </Box>
                            </Paper>
                        </Container>
                    </Grid>
                ) : (
                    children
                )}
            </Container>
        </TotBackground>
    );
};
