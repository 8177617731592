import { Box, Container, Grid, ThemeProvider } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { useFeature } from 'feature-toggle-jsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import styled from 'styled-components';
import { createLandingThemeCreator, LandingThemeCreator } from '../../createThemes';
import { useStore } from '../../hooks';
import { TotBackground as TotBackgroundInj } from '../../TotBackground';

const StyledContainer = withTheme(
    styled(Container)`
        text-align: center;
    `,
);

// todo: вынести в landingTheme в Typography, если не будет других кандидатов
const TotXTypography = withTheme(
    styled.h1`
        font-family: 'Roboto', sans-serif;
        margin: 0;
        font-size: 75px;
        line-height: 1.08;
        font-weight: 900;
        letter-spacing: normal;

        span:nth-child(2) {
            display: none;
        }

        &:hover {
            span:first-child {
                display: none;
            }

            span:nth-child(2) {
                display: inline;
            }
        }
    `,
);

const ICSTypography = withTheme(
    styled.h2`
        font-family: 'Roboto', sans-serif;
        margin: 0;
        font-size: 24px;
        line-height: 1.34;
        font-weight: 700;
        letter-spacing: normal;
    `,
);

const LandingTypography = withTheme(
    styled(Box)`
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 1.67;
        font-weight: 300;
        letter-spacing: 0.27px;
    `,
);

export const StartPage = (): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], StartPage);

    const rootStore = useStore();
    const [startPageDefaultText] = useFeature('startPageDefaultText');
    const landingThemeCreator: LandingThemeCreator = createLandingThemeCreator(rootStore);

    return (
        <TotBackground>
            <ThemeProvider theme={landingThemeCreator}>
                <Grid container item direction="column" alignItems="center" justify="center">
                    <StyledContainer maxWidth="xs">
                        <Box mr={6}>
                            {startPageDefaultText && (
                                <React.Fragment>
                                    <TotXTypography>
                                        <FormattedMessage id="startPage.productNameShort" />
                                    </TotXTypography>
                                    <ICSTypography>
                                        <FormattedMessage id="startPage.productName" />
                                    </ICSTypography>
                                    <LandingTypography mt={3} component="p">
                                        <FormattedMessage id="startPage.productDescription" />
                                    </LandingTypography>
                                </React.Fragment>
                            )}
                        </Box>
                    </StyledContainer>
                </Grid>
            </ThemeProvider>
        </TotBackground>
    );
};
