import { computed, observable } from 'mobx';
import { JsonFilterValue } from '../../types/filter/FilterValue';

export abstract class Filter<T> {
    @observable value: T;

    protected constructor(defaultValue: T) {
        this.value = defaultValue;
    }

    abstract checkActive(value: T): boolean;

    abstract clear(): void;

    abstract get asJson(): JsonFilterValue;

    @computed
    get isActive(): boolean {
        return this.checkActive(this.value);
    }
}
