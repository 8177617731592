import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';

const globalInterval = 1800000;

export type ExpertiseTaskReportEditWrapperProps = {
    formIsChanged?: boolean;
    children: React.ReactNode;
};

export const ExpertiseTaskReportEditWrapper = observer(
    (props: ExpertiseTaskReportEditWrapperProps): JSX.Element => {
        const { children, formIsChanged } = props;
        const intl = useIntl();
        const [isSnackOpen, setIsSnackOpen] = useState<boolean>(false);

        const snackToggle = (): void => {
            setIsSnackOpen(!isSnackOpen);
        };

        useEffect(() => {
            let interval: ReturnType<typeof setTimeout>;

            const beforeUnloadHandler = (e: BeforeUnloadEvent): string => {
                const message = intl.formatMessage({ id: 'common.pageLeaveMessage' });
                e.preventDefault();
                e.returnValue = message;
                return message;
            };

            if (formIsChanged) {
                window.addEventListener('beforeunload', beforeUnloadHandler);
                interval = setInterval(snackToggle, globalInterval);
            }

            return () => {
                if (formIsChanged) {
                    window.removeEventListener('beforeunload', beforeUnloadHandler);
                    clearInterval(interval);
                }
            };
        }, [formIsChanged, intl]);

        return (
            <React.Fragment>
                {formIsChanged && (
                    <Prompt
                        message={(location) => {
                            return location.pathname.startsWith('/tasks/') && !location.pathname.endsWith('/edit')
                                ? true
                                : intl.formatMessage({ id: 'common.pageLeaveMessage' });
                        }}
                    />
                )}
                {children}
                <Snackbar open={isSnackOpen} autoHideDuration={5000} onClose={snackToggle}>
                    <Alert severity="warning">
                        <FormattedMessage id="expertiseTask.saveWarning" />
                    </Alert>
                </Snackbar>
            </React.Fragment>
        );
    },
);
