import { FormDTO, FullSubmission } from '@platform/formiojs-react';
import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import clientRoute from '../clientRoute';
import { CategoryOfSubjectsModel, MetaInfoDTO } from '../models';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export interface CategoryOfSubjectsDTO {
    id: string;
    title: string;
    metaInfo: MetaInfoDTO;
    formInfo: FormDTO;
}

export class CategoriesOfSubjectsStore {
    @observable protected api: Api;
    @observable rootStore: RootStore;
    @observable showValidation = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    saveChangeCategoryOfSubjects(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveChangeCategoryOfSubjects(id, submission)).then((r) => r.data);
    }

    @action.bound
    getCategoryOfSubjectsModel(id: string): CategoryOfSubjectsModel {
        const model = new CategoryOfSubjectsModel(id);
        this.loadCategoryOfSubjectsDTO(id).then(model.load);
        return observable(model);
    }

    @action.bound
    loadCategoryOfSubjectsDTO(id: string): Promise<CategoryOfSubjectsDTO> {
        return this.api.client(apiConfigs.loadCategoryOfSubjectsDTO(id)).then((r) => r.data);
    }

    @action.bound
    createCategoryOfSubjects(): Promise<string> {
        return this.api
            .client(apiConfigs.createCategoryOfSubjects)
            .then((r) => r.data.id)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    deleteCategoryOfSubjects(id: string): Promise<void> {
        return this.api
            .client(apiConfigs.deleteCategoryOfSubjects(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }
}

export const getCategoriesOfSubjectsStore = (): any => {
    const [_CategoriesOfSubjectsStore] = di([CategoriesOfSubjectsStore], getCategoriesOfSubjectsStore);
    return _CategoriesOfSubjectsStore;
};
