import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//TODO: интегрировать в intlStore
import { ru } from 'date-fns/locale';
import { FeaturesProvider, useFeature } from 'feature-toggle-jsx';
import { observer, Provider } from 'mobx-react';
import React from 'react';
import { di, DiProvider } from 'react-magnetic-di';
import { Router } from 'react-router-dom';
import { App } from './App';
import { TotIntlProvider, YMetrika } from './components';
import { createAppTheme } from './createThemes';
import { useStore } from './hooks';
import { RootStore as RootStoreInj } from './store/RootStore';
import { StoreContext } from './StoreContext';
import { AppTheme } from './themes';
import { RootStoreProps } from './types';

const RootUnwrapped = observer((): JSX.Element => {
    const { appTheme } = useStore();
    const [yaMetrika] = useFeature('yaMetrika');
    return (
        <React.Fragment>
            {yaMetrika && <YMetrika />}
            <ThemeProvider theme={appTheme as AppTheme}>
                <TotIntlProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                        <App />
                    </MuiPickersUtilsProvider>
                </TotIntlProvider>
            </ThemeProvider>
        </React.Fragment>
    );
});

const RootWithFeatures = observer((): JSX.Element => {
    const { features } = useStore();
    return (
        <FeaturesProvider features={features as any}>
            <RootUnwrapped />
        </FeaturesProvider>
    );
});

const RootWithStore = (props: RootStoreProps): JSX.Element => {
    const [RootStore] = di([RootStoreInj], RootWithStore);
    const store = new RootStore(props);
    const theme = createAppTheme(store, props.createAppThemeOptions);
    store.setAppTheme(theme);

    const { formioStore } = store;
    const { addMultilevelCatalog } = formioStore;
    addMultilevelCatalog();

    return (
        <Router history={store.history}>
            <Provider {...store}>
                <StoreContext.Provider value={store}>
                    <RootWithFeatures />
                </StoreContext.Provider>
            </Provider>
        </Router>
    );
};

export const Root = (props: RootStoreProps): JSX.Element => {
    return (
        <DiProvider use={props.use || []}>
            <RootWithStore {...props} />
        </DiProvider>
    );
};
