import { observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import clientRoute from '../clientRoute';
import { useStore } from '../hooks';
import { AuthorizationCheckQuery } from '../store';

type RenderChildren = (allowed: boolean) => JSX.Element;

export type AuthorizationCheckProps = AuthorizationCheckQuery & {
    pendingElement?: JSX.Element;
    onAllowed?: () => void;
    errorElement?: JSX.Element;
    onNotAllowed?: () => void;
    children?: RenderChildren | JSX.Element;
    isWithRedirect?: boolean;
};

export const AuthorizationCheck = observer((props: AuthorizationCheckProps): JSX.Element => {
    const {
        onAllowed,
        entityCode,
        permCode,
        entityId,
        children,
        pendingElement,
        errorElement,
        onNotAllowed,
        isWithRedirect,
    } = props;
    const { authorizationStore } = useStore();
    const [allowed, setAllowed] = useState<Promise<boolean>>(Promise.resolve(false));

    const checkIsAllowed = useCallback(() => {
        return authorizationStore.check({ entityCode, permCode, entityId }).then((allowedRes) => {
            if (allowedRes) {
                onAllowed && onAllowed();
            } else {
                onNotAllowed && onNotAllowed();
            }
            return allowedRes;
        });
    }, [entityCode, permCode, entityId, onAllowed]);

    useEffect(() => {
        setAllowed(checkIsAllowed());
    }, [checkIsAllowed]);

    const renderChildren = useCallback(
        (allowed: boolean): JSX.Element => {
            if (typeof children === 'function') {
                const render = children as RenderChildren;
                return render(allowed);
            }
            if (allowed) {
                return children as JSX.Element;
            }
            return isWithRedirect ? <Redirect to={clientRoute.notAllowed} /> : <React.Fragment />;
        },
        [children],
    );

    return fromPromise(allowed).case({
        pending: () => pendingElement || <React.Fragment />,
        fulfilled: renderChildren,
        rejected: () => errorElement || <React.Fragment />,
    });
});
