const startPage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        login: 'Sign in',
    },
    productNameShort: 'TOT Expert',
    productName: 'Expertise processes management system',
    productDescription: 'Control your processes of score processes and objects from application to conclusion',
};

export default startPage;
