import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { PageHeader, TotObjectHeader } from '../../../components';
import { TemplatesOfExpertiseModel } from '../../../models';
import { TemplateOfExpertiseHeaderLeft as TemplateOfExpertiseHeaderLeftInj } from './TemplateOfExpertiseHeaderLeft';
import { TemplateOfExpertiseHeaderTitle as TemplateOfExpertiseHeaderTitleInj } from './TemplateOfExpertiseHeaderTitle';

type TemplatesOfExpertiseHeaderProps = {
    templatesOfExpertiseModel: TemplatesOfExpertiseModel;
};

export const TemplateOfExpertiseHeader = observer((props: TemplatesOfExpertiseHeaderProps): JSX.Element => {
    const [TemplateOfExpertiseHeaderTitle] = di([TemplateOfExpertiseHeaderTitleInj], TemplateOfExpertiseHeader);
    const [TemplateOfExpertiseHeaderLeft] = di([TemplateOfExpertiseHeaderLeftInj], TemplateOfExpertiseHeader);

    const { templatesOfExpertiseModel } = props;
    const { title, metaInfo } = templatesOfExpertiseModel;
    const { created, stateTitle } = metaInfo;
    return (
        <TotObjectHeader>
            <PageHeader
                title={<TemplateOfExpertiseHeaderTitle title={title} />}
                left={<TemplateOfExpertiseHeaderLeft created={created} stateTitle={stateTitle} />}
            />
        </TotObjectHeader>
    );
});
