import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    Link,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination/TablePagination';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { permissionsConfig } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    ActionsButton as ActionsButtonInj,
    AuthorizationCheck as AuthorizationCheckInj,
    AuthorizationCheckAll as AuthorizationCheckAllInj,
    DeleteActionMenuItem as DeleteActionMenuItemInj,
    ExportButton as ExportButtonInj,
    MenuButton as MenuButtonInj,
    TotTablePagination as TotTablePaginationInj,
} from '../../../components';
import { useStore } from '../../../hooks';
import { SubjectListModel as SubjectListModelInj, SubjectRow } from '../../../models';
import { ReactComponent as ClearFilter } from '../../../resources/images/icons/clear-filter.svg';
import { AuthorizationCheckQuery } from '../../../store';
import { IdTitle } from '../../../types';
import { CreateExpertiseButton as CreateExpertiseButtonInj } from './CreateExpertiseButton';
import { CreateSubjectButton as CreateSubjectButtonInj } from './CreateSubjectButton';
import { SubjectListFilterPanel as SubjectListFilterPanelInj } from './SubjectListFilterPanel';

export const renderSubjectListHeadInj = (tableModel: SubjectListModelInj): JSX.Element => {
    const { sorting } = tableModel;
    return (
        <TableRow>
            <TableCell>
                <TableSortLabel
                    active={sorting.identifier.isActive}
                    direction={sorting.identifier.direction}
                    onClick={tableModel.changeSorting(sorting.identifier)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.identifier" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.title.isActive}
                    direction={sorting.title.direction}
                    onClick={tableModel.changeSorting(sorting.title)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.title" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.created.isActive}
                    direction={sorting.created.direction}
                    onClick={tableModel.changeSorting(sorting.created)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.created" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <Typography>
                    <FormattedMessage id="subject.fields.state" />
                </Typography>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.campaign.isActive}
                    direction={sorting.campaign.direction}
                    onClick={tableModel.changeSorting(sorting.campaign)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.campaign" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.category.isActive}
                    direction={sorting.category.direction}
                    onClick={tableModel.changeSorting(sorting.category)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.category" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell>
                <TableSortLabel
                    active={sorting.expertise.isActive}
                    direction={sorting.expertise.direction}
                    onClick={tableModel.changeSorting(sorting.expertise)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="subject.fields.expertise" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell />
        </TableRow>
    );
};

export const renderSubjectListBodyInj = (
    tableModel: SubjectListModelInj,
    renderActions: (subjectRow: SubjectRow) => ReactNode,
): JSX.Element[] => {
    return tableModel.rows.map((c) => (
        <TableRow key={c.id} hover>
            <TableCell>
                <Link component={NavLink} underline="none" to={generatePath(clientRoute.subject, { id: c.id })}>
                    {c.identifier || <FormattedMessage id="common.withoutNumber" />}
                </Link>
            </TableCell>
            <TableCell>{c.title}</TableCell>
            <TableCell>{c.created && <FormattedDate value={c.created} />}</TableCell>
            <TableCell>{c.state}</TableCell>
            <TableCell>
                {c.campaign && (
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.campaign, {
                            id: c.campaign.id,
                        })}
                    >
                        {c.campaign.title}
                    </Link>
                )}
            </TableCell>
            <TableCell>
                {c.category && (
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.category, {
                            id: c.category.id,
                        })}
                    >
                        {c.category.title}
                    </Link>
                )}
            </TableCell>
            <TableCell>
                {c.expertise && (
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.expertise, {
                            id: c.expertise.id,
                        })}
                    >
                        {c.expertise.title}
                    </Link>
                )}
            </TableCell>
            <TableCell>{renderActions(c)}</TableCell>
        </TableRow>
    ));
};

const wrappedComponentProps = { tabIndex: 0 };
const resetFiltersIcon: JSX.Element = (
    <SvgIcon>
        <ClearFilter />
    </SvgIcon>
);

export const SubjectListPage = observer((): JSX.Element => {
    const [SubjectListModel] = di([SubjectListModelInj], SubjectListPage);
    const [SubjectListFilterPanel] = di([SubjectListFilterPanelInj], SubjectListPage);
    const [renderSubjectListHead] = di([renderSubjectListHeadInj], SubjectListPage);
    const [renderSubjectListBody] = di([renderSubjectListBodyInj], SubjectListPage);
    const [ActionsButton] = di([ActionsButtonInj], SubjectListPage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], SubjectListPage);
    const [AuthorizationCheckAll] = di([AuthorizationCheckAllInj], SubjectListPage);
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], SubjectListPage);
    const [MenuButton] = di([MenuButtonInj], SubjectListPage);
    const [TotTablePagination] = di([TotTablePaginationInj], SubjectListPage);
    const [CreateExpertiseButton] = di([CreateExpertiseButtonInj], SubjectListPage);
    const [ExportButton] = di([ExportButtonInj], SubjectListPage);
    const [CreateSubjectButton] = di([CreateSubjectButtonInj], SubjectListPage);

    const rootStore = useStore();
    const { subjectStore, history, intlStore } = rootStore;
    const tableModel = useMemo(() => new SubjectListModel(rootStore), [history]);
    const intl = useIntl();

    useEffect(() => {
        return tableModel.dispose;
    }, [tableModel.dispose]);

    useEffect(() => {
        tableModel.reloadData();
        tableModel.getStateFilterData();
    }, [tableModel, intlStore.locale]);

    const deleteSubject = (subjectRow: SubjectRow): (() => Promise<void>) => {
        return () => {
            return subjectStore.deleteSubject(subjectRow.id).then(tableModel.reloadData);
        };
    };

    const renderActionItems = (subjectRow: SubjectRow): (() => ReactNode[]) => {
        const { id } = subjectRow;
        const subject: IdTitle = {
            id,
            title: `${subjectRow.identifier} ${subjectRow.title}`,
        };

        return () => [
            <AuthorizationCheck key="create-expertise" {...permissionsConfig.createExpertiseForExpertiseSubject(id)}>
                <CreateExpertiseButton tabIndex={0} subject={subject} />
            </AuthorizationCheck>,
            <AuthorizationCheck key="delete-subject" {...permissionsConfig.deleteExpertiseSubject(id)}>
                <DeleteActionMenuItem
                    id="delete"
                    wrappedComponentProps={wrappedComponentProps}
                    title={intl.formatMessage({ id: 'common.confirmDeletion' })}
                    message={intl.formatMessage(
                        { id: 'subject.confirmDeletionInfoText' },
                        { title: subjectRow.identifier },
                    )}
                    onConfirm={deleteSubject(subjectRow)}
                />
            </AuthorizationCheck>,
        ];
    };

    const renderActions = (subjectRow: SubjectRow): ReactNode => {
        const renderMenuItems = renderActionItems(subjectRow);
        const queries: Record<string, AuthorizationCheckQuery> = {
            createExpertiseForExpertiseSubject: permissionsConfig.createExpertiseForExpertiseSubject(subjectRow.id),
            deleteExpertiseSubject: permissionsConfig.deleteExpertiseSubject(subjectRow.id),
        };

        return (
            <AuthorizationCheckAll or queries={queries}>
                <MenuButton disablePortal={true} renderButton={ActionsButton} renderMenuItems={renderMenuItems} />
            </AuthorizationCheckAll>
        );
    };

    const filename = `${intl.formatMessage({ id: 'subject.subjectListTitle' })}.xlsx`;
    const labelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs): string => {
        const { to, from, count } = paginationInfo;
        return intl.formatMessage({ id: 'subject.registryPagingInfo' }, { to, from, count });
    };

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <Box pt={5} pb={5}>
                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="subject.subjectListTitle" />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={resetFiltersIcon}
                                        onClick={tableModel.clearFilters}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <ExportButton
                                        queryData={tableModel.queryData}
                                        loadRegisterList={subjectStore.exportListXls}
                                        filename={filename}
                                    />
                                </Grid>
                                <Grid item>
                                    <CreateSubjectButton />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <SubjectListFilterPanel model={tableModel} />
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper}>
                                {tableModel.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>{renderSubjectListHead(tableModel)}</TableHead>
                                    <TableBody>{renderSubjectListBody(tableModel, renderActions)}</TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TotTablePagination
                                                count={tableModel.rowsCount}
                                                page={tableModel.pageNumber}
                                                onChangePage={tableModel.onChangePage}
                                                onChangeRowsPerPage={tableModel.onChangePageSize}
                                                rowsPerPage={tableModel.pageSize}
                                                rowsPerPageOptions={tableModel.pageSizeOptions}
                                                labelRowsPerPage={intl.formatMessage({ id: 'common.rowsPerPage' })}
                                                labelDisplayedRows={labelDisplayedRows}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
});
