import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { useFeature } from 'feature-toggle-jsx';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link, useHistory } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { WithBackgroundPageWrapper as WithBackgroundPageWrapperInj } from '../../components';
import { useStore } from '../../hooks';
import { ReactComponent as QuestionIcon } from '../../resources/images/icons/question-circle.svg';

export const useErrorPageStyles = makeStyles((theme) =>
    createStyles({
        container: {
            color: theme.variables.palette.mainBlueDarkest,
            height: '100%',
        },
        link: {
            display: 'inline',
            color: theme.variables.palette.mainBlueBright,
            cursor: 'pointer',
        },
    }),
);

export type ErrorPageProps = {
    errorCode: string;
    errorText: ReactNode;
};

export const ErrorPage = (props: ErrorPageProps): JSX.Element => {
    const [WithBackgroundPageWrapper] = di([WithBackgroundPageWrapperInj], ErrorPage);

    const { errorCode, errorText } = props;
    const classes = useErrorPageStyles();
    const rootStore = useStore();
    const { intl } = rootStore.intlStore;
    const history = useHistory();
    const [instructions] = useFeature('instructions');
    const [customBackground, config] = useFeature('customBackground');
    const hasBackgroundSrc = !!config.src;

    return (
        <WithBackgroundPageWrapper>
            <Grid
                container
                direction="column"
                wrap="nowrap"
                alignItems="center"
                justify="space-between"
                className={classes.container}
            >
                <Grid item container direction="column" justify="center" alignItems="center" xs={8} spacing={4}>
                    <Grid item>
                        <Typography>
                            <Box fontSize="80px" fontWeight={600} component="span" lineHeight="normal">
                                {errorCode}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" component="p">
                            <Box
                                fontSize={hasBackgroundSrc ? '18px' : '24px'}
                                component="span"
                                lineHeight="normal"
                                mb={2}
                            >
                                {errorText}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="primary" component="div">
                            <Box lineHeight="normal">
                                <FormattedMessage id="notFoundPage.goTo" />
                                <Link color="inherit" to={clientRoute.root}>
                                    <FormattedMessage id="notFoundPage.main" />
                                </Link>
                                <React.Fragment>
                                    <FormattedMessage id="notFoundPage.orReturn" />
                                    <a className={classes.link} color="inherit" onClick={history.goBack}>
                                        <FormattedMessage id="notFoundPage.back" />
                                    </a>
                                </React.Fragment>
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                {instructions && (
                    <Grid item>
                        <Box mb="40px">
                            <a
                                color="inherit"
                                className={classes.link}
                                download={intl.formatMessage({ id: 'common.instructionFileName' })}
                                href={intl.formatMessage({ id: 'common.instructionSrc' })}
                            >
                                <Grid container alignItems="center" spacing={2}>
                                    <QuestionIcon />
                                    <Box pl="5px">
                                        <FormattedMessage id="common.downloadInstructions" />
                                    </Box>
                                </Grid>
                            </a>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </WithBackgroundPageWrapper>
    );
};
