import { Box, Divider, IconButton, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import { useStore } from '../../hooks';
import { ReactComponent as UserIcon } from '../../resources/images/icons/user-plus.svg';
import { AuthorizationCheck as AuthorizationCheckInj } from '../AuthorizationCheck';
import { MenuButton as MenuButtonInj } from '../buttons';

export const UserButton = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserButton);
    const [MenuButton] = di([MenuButtonInj], UserButton);

    const { authenticationStore, personStore, api } = useStore();
    const { user, person } = personStore;
    const userId = user.id;
    const personLastNameWithInitials = person.lastNameWithInitials;
    const history = useHistory();

    const handleLogout = (hideMenu: () => void) => async (): Promise<void> => {
        await authenticationStore.logout().then(personStore.clearPerson).catch(hideMenu);
        await api.authVerify();
        await personStore.getInfo();
    };

    const renderMenuItems =
        (withProfile: boolean) =>
        (hideMenu: () => void): ReactNode[] => {
            const onProfileClick = (): void => {
                hideMenu();
                history.push(
                    generatePath(clientRoute.user, {
                        id: userId,
                    }),
                );
            };

            return [
                <Box mb={2} key={'person'}>
                    <MenuItem dense button={false} tabIndex={-1}>
                        <Box pb={2} fontWeight="fontWeightBold">
                            {personLastNameWithInitials}
                        </Box>
                    </MenuItem>
                    {withProfile && (
                        <MenuItem dense tabIndex={0} onClick={onProfileClick}>
                            <FormattedMessage id="users.profile" />
                        </MenuItem>
                    )}
                </Box>,
                <Box key={'divider'} mb={2}>
                    <Divider />
                </Box>,
                <MenuItem dense key={'logout'} button={true} onClick={handleLogout(hideMenu)} tabIndex={0}>
                    <FormattedMessage id="authentication.logout" />
                </MenuItem>,
            ];
        };

    const renderButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        return (
            <IconButton color="secondary" onClick={onClick}>
                <UserIcon />
            </IconButton>
        );
    };

    return (
        <React.Fragment>
            {userId && (
                <AuthorizationCheck entityCode={entities.Account} permCode={permissions.User.View} entityId={userId}>
                    {(allowed) => (
                        <MenuButton
                            renderButton={renderButton}
                            renderMenuItems={renderMenuItems(allowed)}
                            reloadKey={personLastNameWithInitials}
                        />
                    )}
                </AuthorizationCheck>
            )}
        </React.Fragment>
    );
});
