import { FormDTO, FullSubmission } from '@platform/formiojs-react';
import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import clientRoute from '../clientRoute';
import {
    CampaignLinksModel,
    CampaignLinksModelDTO,
    CampaignModel,
    CampaignStatisticModel,
    MetaInfoDTO,
    TransitionsDTO,
    UserPersonDTO,
} from '../models';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export interface CampaignDTO {
    id: string;
    title: string;
    identifier: string;
    manager: UserPersonDTO;
    metaInfo: MetaInfoDTO;
    formInfo: FormDTO;
}

export interface CampaignSubjects {
    total: number;
    active: number;
    withExpertise: number;
    withoutExpertise: number;
}
export interface CampaignExpertise {
    total: number;
    active: number;
    done: number;
    canceled: number;
}

export interface CampaignTasks {
    total: number;
    withoutExpert: number;
    responseWaiting: number;
    startWaiting: number;
    active: number;
    overdue: number;
    done: number;
    notCompleted: number;
}

export interface CampaignStatisticDTO {
    subjects: CampaignSubjects;
    expertise: CampaignExpertise;
    tasks: CampaignTasks;
}

export class CampaignStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;
    @observable showValidation = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    getCampaignStatistic(id: string): Promise<CampaignStatisticDTO> {
        return this.api.client(apiConfigs.campaignStatistic(id)).then((r) => r.data);
    }

    @action.bound
    getCampaignLinks(campaignId: string): Promise<CampaignLinksModelDTO> {
        return this.api.client(apiConfigs.campaignLinks(campaignId)).then((r) => r.data);
    }

    @action.bound
    getCampaignModel(id: string): CampaignModel {
        const model = new CampaignModel(id);
        this.loadCampaign(id).then(model.load);
        return observable(model);
    }

    @action.bound
    getCampaignStatisticModel(id: string): CampaignStatisticModel {
        const model = new CampaignStatisticModel();
        this.getCampaignStatistic(id).then(model.load);
        return observable(model);
    }

    @action.bound
    getCampaignLinksModel(id: string): CampaignLinksModel {
        const model = new CampaignLinksModel();
        this.getCampaignLinks(id).then(model.load);
        return observable(model);
    }

    @action.bound
    loadCampaign(id: string): Promise<CampaignDTO> {
        return this.api
            .client(apiConfigs.campaign(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    createCampaign(): Promise<string> {
        return this.api
            .client(apiConfigs.createCampaign)
            .then((r) => r.data.id)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    editCampaign(id: string, submission: FullSubmission): Promise<CampaignDTO> {
        return this.api
            .client(apiConfigs.editCampaign(id, submission))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    deleteCampaign(id: string): Promise<void> {
        return this.api
            .client(apiConfigs.deleteCampaign(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    getLifeCycleTransitions(campaignId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.campaignLifeCycleTransitions(campaignId)).then((r) => r.data);
    }

    @action.bound
    lifeCycleTransition(transitionId: string, campaignId: string, updateReloadKey: () => void): Promise<void> {
        return this.api.client(apiConfigs.campaignLifeCycleTransition(transitionId, campaignId)).then((r) => {
            updateReloadKey();
            return r.data;
        });
    }
}

export const getCampaignStore = (): any => {
    const [_CampaignStore] = di([CampaignStore], getCampaignStore);
    return _CampaignStore;
};
