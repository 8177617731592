import { Box, createStyles, makeStyles, Portal, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { SeverityType } from '../../store';

export type SnackbarComponentProps = {
    text: string;
    severity: SeverityType;
    index: number;
    closeMessage: () => void;
    isOpen: boolean;
};

const topPositionMultiplier = 69;
const topGap = 10;

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            wordBreak: 'break-word',
            maxWidth: '400px',
        },
    }),
);

export const SnackbarComponent = (props: SnackbarComponentProps): JSX.Element => {
    const { severity, text, index, closeMessage, isOpen } = props;
    const top = topGap + index * topPositionMultiplier;
    const classes = useStyles();

    return (
        <Portal>
            <Snackbar
                className="t-notification"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isOpen}
                style={{ top: `${top}px` }}
            >
                <Alert variant="filled" severity={severity} onClose={closeMessage}>
                    <Box className={classes.box}>{text}</Box>
                </Alert>
            </Snackbar>
        </Portal>
    );
};
