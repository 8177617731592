import { PrimitiveType } from 'intl-messageformat';
import { action, computed, observable } from 'mobx';
import { createIntl, createIntlCache, IntlCache, IntlShape } from 'react-intl';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import { Api } from './Api';
import { RootStore } from './RootStore';

export const ru = 'ru';
export const en = 'en';
export type TotLocale = 'ru' | 'en';
// todo: title документа через env
const enTitle = 'Expert system of scientific research projects';
const ruTitle = 'Система экспертизы научно-технических программ и проектов';
const TOT_X_LANG_LOCALIZATION = 'TotXLangLocalization';

type MessageFormatter = (id: string, values?: Record<string, PrimitiveType>) => string;

let browserLanguage = window.navigator
    ? window.navigator.language || (window.navigator as any).userLanguage || (window.navigator as any).systemLanguage
    : en;
browserLanguage = browserLanguage.substr(0, 2).toLowerCase();
export const defaultLocale = browserLanguage === ru ? ru : en;
export const defaultFormsLocale = ru;
export type Locales = {
    ru: Record<string, string>;
    en: Record<string, string>;
};

export class IntlStore {
    private savedLocale: string | null = localStorage.getItem(TOT_X_LANG_LOCALIZATION);
    @observable private messagesCache: Record<string, Record<string, string>> = {};
    private readonly intlCache: IntlCache;
    @observable intl: IntlShape;

    @observable private rootStore: RootStore;
    @observable protected api: Api;
    @observable locale: string;

    locales: TotLocale[] = [ru, en];

    @observable formatMessage: MessageFormatter;

    constructor(rootStore: RootStore, locales: Locales) {
        this.messagesCache = locales;
        this.rootStore = rootStore;
        this.locale = this.savedLocale || defaultLocale;
        document.documentElement.lang = this.locale;
        this.intlCache = createIntlCache();
        this.intl = createIntl({ locale: this.locale, messages: this.messages, defaultLocale }, this.intlCache);
        this.formatMessage = this.changeMessageFormatter();
        this.api = rootStore.api;
        // document.title = this.locale === ru ? ruTitle : enTitle;
    }

    @action.bound
    changeMessageFormatter(): MessageFormatter {
        return (id: string, values?: Record<string, PrimitiveType>): string => {
            return this.intl.formatMessage({ id }, values);
        };
    }

    @action.bound
    changeLocale(locale: string): void {
        if (this.api.authStatus === 'ok') {
            this.api.client(apiConfigs.setLang(locale)).then(() => {
                this.setLocalLocalization(locale);
            });
        } else {
            this.setLocalLocalization(locale);
        }
    }

    @action.bound
    setLocalLocalization(locale: string): void {
        localStorage.setItem(TOT_X_LANG_LOCALIZATION, locale);
        this.locale = locale;
        this.intl = createIntl({ locale: this.locale, messages: this.messages }, this.intlCache);
        this.formatMessage = this.changeMessageFormatter();
        document.documentElement.lang = locale;
        // document.title = this.locale === ru ? ruTitle : enTitle;
    }

    @computed
    get messages(): Record<string, string> {
        return this.messagesCache[this.locale];
    }

    extendMessages(extendMessages: Record<string, string>) {
        this.messagesCache[this.locale] = { ...this.messagesCache[this.locale], ...extendMessages };
    }
}

export const getIntlStore = (): any => {
    const [_IntlStore] = di([IntlStore], getIntlStore);
    return _IntlStore;
};
