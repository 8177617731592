import { action, observable } from 'mobx';
import apiRouteConfigs from '../../apiConfigs';
import { Api, IntlStore, PersonStore, RootStore } from '../../store';
import { AuthStatus } from '../../types';

export interface ConfirmEmailDTO {
    token?: string;
    lang: string;
}

export class RegistrationConfirmModel {
    @observable protected api: Api;
    @observable intl: IntlStore;
    @observable personStore: PersonStore;
    @observable status?: 'pending' | 'success' | 'expired' | 'not-found';

    constructor(rootStore: RootStore) {
        this.api = rootStore.api;
        this.intl = rootStore.intlStore;
        this.personStore = rootStore.personStore;
    }

    @action.bound
    async confirmEmail(token?: string): Promise<void> {
        try {
            this.status = 'pending';
            const confirmEmailDTO = {
                token,
                lang: this.intl.locale,
            };
            await this.api.client(apiRouteConfigs.confirmEmail(confirmEmailDTO));
            await this.personStore.getInfo();
            this.api.setAuthStatus(AuthStatus.ok);
            this.status = 'success';
        } catch (error) {
            if (error.response) {
                if (error.response.status === 410) {
                    this.status = 'expired';
                }
                if (error.response.status === 404) {
                    this.status = 'not-found';
                }
            }
        }
    }
}
