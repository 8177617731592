import { Box, Grid, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../hooks';
import { StartPageHeaderLinks as StartPageHeaderLinksInj } from '../../pages';
import { ReactComponent as BurgerIconOpen } from '../../resources/images/icons/burger-icon-open.svg';
import { ReactComponent as BurgerIconClosed } from '../../resources/images/icons/burger-icon.svg';
import { AuthStatus } from '../../types';
import { BurgerMenu as BurgerMenuInj } from './BurgerMenu';
import { HeaderLinksList as HeaderLinksListInj } from './HeaderLinksList';
import { UserButton as UserButtonInj } from './UserButton';

export type AppHeaderLinksProps = {
    isOpenMenu: boolean;
    handlerBurgerButton: () => void;
    closeBurgerMenu: () => void;
    isSmSize: boolean;
    isMdSize: boolean;
};

export const AppHeaderLinks = observer((props: AppHeaderLinksProps): JSX.Element => {
    const [StartPageHeaderLinks] = di([StartPageHeaderLinksInj], AppHeaderLinks);
    const [BurgerMenu] = di([BurgerMenuInj], AppHeaderLinks);
    const [UserButton] = di([UserButtonInj], AppHeaderLinks);
    const [HeaderLinksList] = di([HeaderLinksListInj], BurgerMenu);

    const { isOpenMenu, handlerBurgerButton, closeBurgerMenu } = props;
    const { headerLinksStore, personStore, api } = useStore();
    const { user } = personStore;
    const userId = user.id;
    const { mainLinksConfigs, burgerLinksIsAllowed } = headerLinksStore;

    useEffect(() => {
        return closeBurgerMenu;
    }, []);

    switch (api.authStatus) {
        case AuthStatus.pending:
            return <React.Fragment />;
        case AuthStatus.ok:
            return (
                <Grid container direction="row" justify="flex-end" spacing={3}>
                    <Grid item>
                        <Grid
                            key={userId}
                            container
                            direction="row"
                            alignItems="center"
                            justify="center"
                            spacing={3}
                            wrap="nowrap"
                        >
                            <HeaderLinksList links={mainLinksConfigs} />
                        </Grid>
                        <Box pt={3}>
                            <BurgerMenu isOpenMenu={isOpenMenu} />
                        </Box>
                    </Grid>
                    <Grid item>
                        {burgerLinksIsAllowed && (
                            <IconButton color="secondary" onClick={handlerBurgerButton}>
                                {isOpenMenu ? <BurgerIconOpen /> : <BurgerIconClosed />}
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item key={userId}>
                        <UserButton />
                    </Grid>
                </Grid>
            );
        case AuthStatus.unauthorized:
            return <StartPageHeaderLinks />;
    }
});
