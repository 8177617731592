import { Box, Fade, Grid, Paper, Popper } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectHeaderLabel as TotObjectHeaderLabelInj,
    TotObjectHeaderValue as TotObjectHeaderValueInj,
} from '../../../components';
import { Contacts } from '../../../store';

export type CuratorInfoProps = {
    name?: string;
    contacts: Contacts;
};

export const CuratorInfo = observer((props: CuratorInfoProps): JSX.Element => {
    const [TotObjectHeaderLabel] = di([TotObjectHeaderLabelInj], CuratorInfo);
    const [TotObjectHeaderValue] = di([TotObjectHeaderValueInj], CuratorInfo);

    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>();

    const setAnchorEl = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorElement(event.currentTarget);
    };

    const deleteAnchorEl = (): void => {
        setAnchorElement(null);
    };

    const { name, contacts } = props;
    return (
        <Box onPointerEnter={setAnchorEl} onPointerLeave={deleteAnchorEl}>
            <InfoOutlinedIcon />
            <Popper open={!!anchorElement} anchorEl={anchorElement} transition style={{ zIndex: 1102 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={200}>
                        <Paper>
                            <Box padding={3}>
                                <Grid container direction="column">
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        alignItems="baseline"
                                        spacing={2}
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <TotObjectHeaderLabel>
                                                <FormattedMessage id="expertiseTask.curator.name" />
                                            </TotObjectHeaderLabel>
                                        </Grid>
                                        <Grid item>
                                            <TotObjectHeaderValue>{name || '-'}</TotObjectHeaderValue>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        alignItems="baseline"
                                        spacing={2}
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <TotObjectHeaderLabel>
                                                <FormattedMessage id="expertiseTask.curator.phone" />
                                            </TotObjectHeaderLabel>
                                        </Grid>
                                        <Grid item>
                                            <TotObjectHeaderValue>{contacts.phone || '-'}</TotObjectHeaderValue>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        alignItems="baseline"
                                        spacing={2}
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <TotObjectHeaderLabel>Email</TotObjectHeaderLabel>
                                        </Grid>
                                        <Grid item>
                                            <TotObjectHeaderValue>{contacts.email || '-'}</TotObjectHeaderValue>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Box>
    );
});
