import { Grid } from '@material-ui/core';
import { FormApi } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    ServiceInfoPanel as ServiceInfoPanelInj,
    TotObjectForm as TotObjectFormInj,
    TotObjectMain as TotObjectMainInj,
} from '../../components';
import { maxWidth100 } from '../../constants';
import { useReload, useStore } from '../../hooks';
import { CategoryOfSubjectsControlPanel as CategoryOfSubjectsControlPanelInj } from './CategoryOfSubjectsControlPanel';
import { CategoryOfSubjectsHeader as CategoryOfSubjectsHeaderInj } from './CategoryOfSubjectsHeader';

export type CategoryOfSubjectsPageRouteParams = {
    id: string;
};

export const CategoryOfSubjectsPage = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], CategoryOfSubjectsPage);
    const [ServiceInfoPanel] = di([ServiceInfoPanelInj], CategoryOfSubjectsPage);
    const [TotObjectForm] = di([TotObjectFormInj], CategoryOfSubjectsPage);
    const [TotObjectMain] = di([TotObjectMainInj], CategoryOfSubjectsPage);
    const [CategoryOfSubjectsControlPanel] = di([CategoryOfSubjectsControlPanelInj], CategoryOfSubjectsPage);
    const [CategoryOfSubjectsHeader] = di([CategoryOfSubjectsHeaderInj], CategoryOfSubjectsPage);

    const { categoriesOfSubjectsStore, history, intlStore, notificationStore } = useStore();
    const { reloadKey, reloadIncrement } = useReload();
    const { id } = useParams<CategoryOfSubjectsPageRouteParams>();
    const categoryOfSubjectsModel = useMemo(() => categoriesOfSubjectsStore.getCategoryOfSubjectsModel(id), [id]);
    const { formModel } = categoryOfSubjectsModel;
    const [formApi, setFormApi] = useState<FormApi>();

    const updateCard = (): void => {
        categoriesOfSubjectsStore.loadCategoryOfSubjectsDTO(id).then(categoryOfSubjectsModel.loadCard);
    };

    useEffect(() => {
        updateCard();
    }, [intlStore.locale, reloadKey]);

    const onFormReady = useCallback(
        (form: FormApi): void => {
            setFormApi(form);
        },
        [setFormApi],
    );

    const onSubmit = (): void => {
        if (formApi && formApi.validate()) {
            formApi
                .submit(false)
                .then(() => {
                    return categoriesOfSubjectsStore.saveChangeCategoryOfSubjects(
                        id,
                        formApi.getSubmissionWithAdditionalInfo(),
                    );
                })
                .then(() => {
                    reloadIncrement();
                    history.push(generatePath(clientRoute.category, { id }));
                })
                .catch((err: any) => {
                    notificationStore.onError(err.response.data);
                    formApi.setSubmissionFromStartSubmission();
                });
        }
    };

    const deleteCategoryOfSubjects = (): Promise<void> => {
        return categoriesOfSubjectsStore.deleteCategoryOfSubjects(categoryOfSubjectsModel.id).then(() => {
            history.push(generatePath(clientRoute.categories));
        });
    };

    const onNotAllowed = (): void => {
        history.replace(clientRoute.notAllowed);
    };

    const setCommonValidationState = (state: boolean): void => {
        categoriesOfSubjectsStore.showValidation = state;
    };

    const hideReadonlyValidation = useCallback((): void => {
        setCommonValidationState(false);
    }, [setCommonValidationState]);

    const editAuthProps = {
        entityCode: entities.System,
        permCode: permissions.System.Administration,
    };

    return (
        <AuthorizationCheck
            entityCode={entities.System}
            permCode={permissions.System.Administration}
            onNotAllowed={onNotAllowed}
        >
            <Grid container direction="column" wrap="nowrap">
                <Grid item>
                    <CategoryOfSubjectsHeader categoryOfSubjectsModel={categoryOfSubjectsModel} />
                </Grid>
                <TotObjectMain>
                    <Grid container spacing={10}>
                        <Grid item container direction="column" spacing={10}>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                <Grid item>
                                    <ServiceInfoPanel metaInfoModel={categoryOfSubjectsModel.metaInfo} />
                                </Grid>
                            </AuthorizationCheck>
                            <Grid item style={maxWidth100}>
                                <TotObjectForm
                                    reloadKey={reloadKey}
                                    formName="category"
                                    model={formModel}
                                    editPath={clientRoute.categoryEdit}
                                    createPath={clientRoute.categoryCreate}
                                    readPath={clientRoute.category}
                                    onFormReady={onFormReady}
                                    showReadonlyValidation={categoriesOfSubjectsStore.showValidation}
                                    hideReadonlyValidation={hideReadonlyValidation}
                                    editAuthProps={editAuthProps}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectMain>
                <Grid item>
                    <CategoryOfSubjectsControlPanel
                        categoryOfSubjectsTitle={categoryOfSubjectsModel.title}
                        categoryOfSubjectsId={categoryOfSubjectsModel.id}
                        deleteCategoryOfSubjects={deleteCategoryOfSubjects}
                        onSubmit={onSubmit}
                    />
                </Grid>
            </Grid>
        </AuthorizationCheck>
    );
});
