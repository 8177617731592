import { Grid, Link, Tooltip, useTheme } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import clientRoute from '../../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    IconButtonLarge as IconButtonLargeInj,
    RowData as RowDataInj,
} from '../../../../components';
import { ExpertiseModel } from '../../../../models';
import { ReactComponent as EditIcon } from '../../../../resources/images/icons/edit.svg';
import { ExpertisePageRouteParams } from '../ExpertisePage';

export type ExpertiseHeaderRightProps = {
    expertiseCampaign: ExpertiseModel['campaign'];
    curatorName: ExpertiseModel['curator']['name'];
    id: ExpertisePageRouteParams['id'];
    setModalIsOpen: () => void;
};

export const ExpertiseHeaderRight = (props: ExpertiseHeaderRightProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseHeaderRight);
    const [IconButtonLarge] = di([IconButtonLargeInj], ExpertiseHeaderRight);
    const [RowData] = di([RowDataInj], ExpertiseHeaderRight);

    const { expertiseCampaign, curatorName, id, setModalIsOpen } = props;
    const theme = useTheme();
    const intl = useIntl();

    const iconWrapperStyle = { marginTop: `-${theme?.spacing(6)}px` };
    const expertiseCampaignWrapper =
        expertiseCampaign &&
        ((children: JSX.Element): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.campaign, {
                        id: expertiseCampaign.id,
                    })}
                    color="textPrimary"
                >
                    {children}
                </Link>
            );
        });

    const expertiseCampaignValue = expertiseCampaign?.title || '-';

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <RowData
                    label={intl.formatMessage({ id: 'expertise.fields.expertiseCampaign' })}
                    value={expertiseCampaignValue}
                    valueWrapper={expertiseCampaignWrapper}
                />
            </Grid>

            <Grid item>
                <Grid container direction="row" alignItems="baseline" justify="flex-start" spacing={2} wrap="nowrap">
                    <Grid item>
                        <RowData label={intl.formatMessage({ id: 'expertise.fields.curator' })} value={curatorName} />
                    </Grid>
                    <AuthorizationCheck
                        entityCode={entities.Expertise}
                        permCode={permissions.Expertise.UpdateCurator}
                        entityId={id}
                    >
                        <Grid item style={iconWrapperStyle}>
                            <Tooltip title={intl.formatMessage({ id: 'expertise.changeCurator' })}>
                                <div>
                                    <IconButtonLarge onClick={setModalIsOpen}>
                                        <EditIcon />
                                    </IconButtonLarge>
                                </div>
                            </Tooltip>
                        </Grid>
                    </AuthorizationCheck>
                </Grid>
            </Grid>
        </Grid>
    );
};
