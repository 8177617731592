import { Grid } from '@material-ui/core';
import { FormApi } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    TotObjectForm as TotObjectFormInj,
    TotObjectMain as TotObjectMainInj,
} from '../../../components';
import { maxWidth100 } from '../../../constants';
import { useReload, useStore } from '../../../hooks';
import { AuthorizationCheckQuery } from '../../../store';
import { SubjectControlPanel as SubjectControlPanelInj } from './SubjectControlPanel';
import { SubjectHeader as SubjectHeaderInj } from './SubjectHeader/SubjectHeader';
import { SubjectServiceInfo as SubjectServiceInfoInj } from './SubjectServiceInfo';

export type SubjectPageRouteParams = {
    id: string;
};

export const SubjectPage = observer((): JSX.Element => {
    const [TotObjectMain] = di([TotObjectMainInj], SubjectPage);
    const [SubjectHeader] = di([SubjectHeaderInj], SubjectPage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], SubjectPage);
    const [TotObjectForm] = di([TotObjectFormInj], SubjectPage);
    const [SubjectControlPanel] = di([SubjectControlPanelInj], SubjectPage);
    const [SubjectServiceInfo] = di([SubjectServiceInfoInj], SubjectPage);

    const { subjectStore, intlStore, history, notificationStore } = useStore();
    const { id } = useParams<SubjectPageRouteParams>();
    const { reloadKey, reloadIncrement } = useReload();
    const subjectModel = useMemo(() => subjectStore.getSubjectModel(id), [id]);

    const [commonFormApi, setCommonFormApi] = useState<FormApi>();
    const [hiddenFormApi, setHiddenFormApi] = useState<FormApi>();

    const updateCard = (): void => {
        subjectStore.loadSubject(id).then(subjectModel.loadCard);
    };

    useEffect(() => {
        updateCard();
    }, [intlStore.locale, reloadKey]);

    const onCommonFormReady = (form: FormApi): void => {
        setCommonFormApi(form);
    };

    const onHiddenFormReady = (form: FormApi): void => {
        setHiddenFormApi(form);
    };

    const onSaveSuccess = (): void => {
        reloadIncrement();
        history.push(generatePath(clientRoute.subject, { id }));
    };

    const setCommonValidationState = (state: boolean): void => {
        subjectStore.showCommonValidation = state;
    };

    const setHiddenValidationState = (state: boolean): void => {
        subjectStore.showHiddenValidation = state;
    };

    const onSubmit = (): void => {
        const isCommonValid = (commonFormApi && commonFormApi.validate()) || false;
        const isHiddenValid = !hiddenFormApi || hiddenFormApi.validate();
        if (commonFormApi && isCommonValid && isHiddenValid) {
            commonFormApi
                .submit(false)
                .then(() => {
                    return hiddenFormApi?.submit(false);
                })
                .then(() => {
                    return subjectStore.saveForms(
                        id,
                        commonFormApi.getSubmissionWithAdditionalInfo(),
                        hiddenFormApi?.getSubmissionWithAdditionalInfo(),
                    );
                })
                .then(onSaveSuccess)
                .catch((error) => {
                    notificationStore.onError(error.response.data);
                    commonFormApi.setSubmissionFromStartSubmission();
                    hiddenFormApi?.setSubmissionFromStartSubmission();
                });
        }
    };

    const validateForms = (onSuccess?: () => Promise<void>): Promise<void> => {
        const isCommonValid = (commonFormApi && commonFormApi.validate()) || false;
        const isHiddenValid = !hiddenFormApi || hiddenFormApi.validate();
        if (isCommonValid && isHiddenValid) {
            setCommonValidationState(!isCommonValid);
            setHiddenValidationState(!isHiddenValid);
            return onSuccess ? onSuccess() : Promise.resolve();
        } else if (commonFormApi?.readOnly) {
            setCommonValidationState(!isCommonValid);
            setHiddenValidationState(!isHiddenValid);
        }
        return Promise.reject();
    };

    const onLifeCycleTransition = (transitionId: string, subjectId: string, validate?: boolean): Promise<void> => {
        const { lifeCycleTransition } = subjectStore;
        const onSuccess = (): Promise<void> => lifeCycleTransition(transitionId, subjectId, reloadIncrement);
        if (validate) {
            return validateForms(onSuccess);
        } else {
            return onSuccess();
        }
    };

    const editAuthProps: AuthorizationCheckQuery = {
        entityCode: entities.ExpertiseSubject,
        permCode: permissions.ExpertiseSubject.Edit,
        entityId: id,
    };

    const commonHideReadonlyValidation = (): void => {
        setCommonValidationState(false);
    };

    const hiddenHideReadonlyValidation = (): void => {
        setHiddenValidationState(false);
    };

    return (
        <Grid container direction="column" wrap="nowrap">
            <Grid item>
                <SubjectHeader subjectModel={subjectModel} />
            </Grid>
            <TotObjectMain>
                <Grid container spacing={10}>
                    <Grid item container direction="column" spacing={10}>
                        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                            <Grid item>
                                <SubjectServiceInfo subjectModel={subjectModel} />
                            </Grid>
                        </AuthorizationCheck>
                        <Grid item style={maxWidth100}>
                            <TotObjectForm
                                reloadKey={reloadKey}
                                formName="common"
                                model={subjectModel.commonFormModel}
                                editPath={clientRoute.subjectEdit}
                                createPath={clientRoute.subjectCreate}
                                readPath={clientRoute.subject}
                                onFormReady={onCommonFormReady}
                                showReadonlyValidation={subjectStore.showCommonValidation}
                                hideReadonlyValidation={commonHideReadonlyValidation}
                                editAuthProps={editAuthProps}
                            />
                        </Grid>
                        <Grid item style={maxWidth100}>
                            <TotObjectForm
                                reloadKey={reloadKey}
                                formName="hidden"
                                model={subjectModel.hiddenFormModel}
                                editPath={clientRoute.subjectEdit}
                                createPath={clientRoute.subjectCreate}
                                readPath={clientRoute.subject}
                                onFormReady={onHiddenFormReady}
                                showReadonlyValidation={subjectStore.showHiddenValidation}
                                hideReadonlyValidation={hiddenHideReadonlyValidation}
                                editAuthProps={editAuthProps}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectMain>
            <Grid item>
                <SubjectControlPanel
                    key={reloadKey}
                    subjectModel={subjectModel}
                    onSubmit={onSubmit}
                    onLifeCycleTransition={onLifeCycleTransition}
                />
            </Grid>
        </Grid>
    );
});
