import { IconButton, IconButtonProps } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const IconButtonLargeStyled = styled((props) => {
    return <IconButton {...props} />;
})`
    width: 48px !important;
    height: 48px !important;
`;

export type IconButtonLargeProps = IconButtonProps & PropsWithChildren<any>;

export const IconButtonLarge = (props: IconButtonLargeProps): JSX.Element => {
    const { children, ...restProps } = props;
    return <IconButtonLargeStyled {...restProps}>{children}</IconButtonLargeStyled>;
};
