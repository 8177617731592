import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ClearFilterButton, Select, TotObjectFilterPanelDetails, TotObjectFilterPanelSummary } from '../../components';
import { AutocompleteProps, pickerProps, textFieldProps } from '../../constants';
import { useDateBoundaries, useStore } from '../../hooks';
import { ExpertiseTaskListModel } from '../../models';
import { setFiltersFromUrl } from '../../utils';

export type ExpertiseTaskFilterPanelProps = {
    model: ExpertiseTaskListModel;
};

export const ExpertiseTaskListFilterPanel = observer(
    (props: ExpertiseTaskFilterPanelProps): JSX.Element => {
        const { model } = props;
        const { personStore } = useStore();
        const { filtering } = model;
        const leadRole = personStore.isAdmin || personStore.isCurator;
        const cellSize = leadRole ? 4 : 6;
        const location = useLocation();
        const { intlStore: intl } = useStore();

        useEffect(() => {
            setFiltersFromUrl(location, filtering);
        }, [location]);

        const createdDate = {
            dateTo: filtering.created.value.to,
            dateFrom: filtering.created.value.from,
        };

        const deadlineDateTo = filtering.deadline.value.to;
        const deadlineDate = {
            dateTo: deadlineDateTo,
            dateFrom: filtering.deadline.value.from,
        };

        const createdDateBoundaries = useDateBoundaries(createdDate);
        const deadlineDateBoundaries = useDateBoundaries(deadlineDate);

        return (
            <ExpansionPanel>
                <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="common.filters" />
                    </Typography>
                </TotObjectFilterPanelSummary>
                <TotObjectFilterPanelDetails>
                    <Grid container direction="column" spacing={6}>
                        <Grid item container spacing={6}>
                            <Grid item xs={cellSize}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="expertiseTask.fields.number" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    value={filtering.identifier.value}
                                    onChange={filtering.identifier.onChange}
                                />
                            </Grid>
                            <Grid item xs={cellSize}>
                                <Select
                                    values={filtering.taskType.values}
                                    label={<FormattedMessage id="expertiseTask.fields.taskType" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    selectData={model.taskTypeFilterData}
                                    onChange={filtering.taskType.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                            {leadRole && (
                                <Grid item xs={4}>
                                    <Select
                                        values={filtering.expert.values}
                                        label={<FormattedMessage id="expertiseTask.fields.expert" />}
                                        placeholder={intl.formatMessage('common.all')}
                                        selectData={model.expertFilterData}
                                        onChange={filtering.expert.onChange}
                                        textFieldProps={{ ...textFieldProps }}
                                        autoCompleteProps={{ ...AutocompleteProps }}
                                        virtualized={true}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item container spacing={6}>
                            <Grid item xs={4}>
                                <TextField
                                    {...textFieldProps}
                                    label={<FormattedMessage id="expertiseTask.fields.expertiseNumber" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    value={filtering.expertise.value}
                                    onChange={filtering.expertise.onChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    values={filtering.curator.values}
                                    label={<FormattedMessage id="expertiseTask.fields.curator" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    selectData={model.curatorFilterData}
                                    onChange={filtering.curator.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    values={filtering.state.values}
                                    label={<FormattedMessage id="expertiseTask.fields.state" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    selectData={model.stateFilterData}
                                    onChange={filtering.state.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={6} justify="flex-end">
                            <Grid item xs={4}>
                                <Select
                                    values={filtering.campaigns.values}
                                    label={<FormattedMessage id="expertiseTask.fields.campaign" />}
                                    placeholder={intl.formatMessage('common.all')}
                                    selectData={model.campaignFilterData}
                                    onChange={filtering.campaigns.onChange}
                                    textFieldProps={{ ...textFieldProps }}
                                    autoCompleteProps={{ ...AutocompleteProps }}
                                />
                            </Grid>
                            <Grid item xs={4} container justify="space-between">
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        label={<FormattedMessage id="expertiseTask.fields.created" />}
                                        placeholder={intl.formatMessage('common.from')}
                                        value={filtering.created.value.from}
                                        onChange={filtering.created.onFromChange}
                                        maxDate={createdDateBoundaries.maxDate}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={(): void => filtering.created.onFromChange(null)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        placeholder={intl.formatMessage('common.until')}
                                        value={filtering.created.value.to}
                                        onChange={filtering.created.onToChange}
                                        minDate={createdDateBoundaries.minDate}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={(): void => filtering.created.onToChange(null)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4} container justify="space-between">
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        label={<FormattedMessage id="expertiseTask.fields.deadline" />}
                                        placeholder={intl.formatMessage('common.from')}
                                        value={filtering.deadline.value.from}
                                        onChange={filtering.deadline.onFromChange}
                                        {...(deadlineDateTo && { maxDate: deadlineDateTo })}
                                        disableFuture={false}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={(): void => filtering.deadline.onFromChange(null)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker
                                        {...pickerProps}
                                        placeholder={intl.formatMessage('common.until')}
                                        value={filtering.deadline.value.to}
                                        onChange={filtering.deadline.onToChange}
                                        minDate={deadlineDateBoundaries.minDate}
                                        disableFuture={false}
                                        InputProps={{
                                            endAdornment: (
                                                <ClearFilterButton
                                                    clear={(): void => filtering.deadline.onToChange(null)}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TotObjectFilterPanelDetails>
            </ExpansionPanel>
        );
    },
);
