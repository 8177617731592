import { Preset, TTable, TTableRow, TTableVisibilitySettings } from '@platform/ttable';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { useStore } from '../../../hooks';
import { SelectionOfExpertListModel } from '../../../models';
import { RegistryType } from '../../../types';
import { SelectionOfExpertTableActions } from './SelectionOfExpertTableActions';

export type SelectionOfExpertTableProps = {
    tableModel: SelectionOfExpertListModel;
};

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithDownload: false,
    },
    column: {
        isRowActionsFirst: true,
    },
};

const registryCode = RegistryType.experts;
const emptyAsyncFunction = async () => {};

export const SelectionOfExpertTable = observer((props: SelectionOfExpertTableProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], SelectionOfExpertTable);

    const { tableModel } = props;
    const { presetStore, registryStore, catalogStore, userStore } = useStore();
    const { fetchDataWithParams, fetchColumnsWithParams } = registryStore;
    const { id } = tableModel;
    const intl = useIntl();

    const params = {
        taskId: id,
    };

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
        presetStore.saveListPresets(settings);
    const fetchData = fetchDataWithParams(params);
    const fetchColumns = fetchColumnsWithParams(params);

    const setRowActions = (row: TTableRow): JSX.Element => {
        return <SelectionOfExpertTableActions row={row} tableModel={tableModel} />;
    };

    return (
        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
            {(allowed: boolean): JSX.Element => (
                <TTable
                    registryCode={registryCode}
                    fetchData={fetchData}
                    fetchColumns={fetchColumns}
                    fetchCatalog={catalogStore.fetchCatalogForTTable}
                    presetsSetting={presetStore}
                    visibleSettings={visibleSettings}
                    fetchSelectDataByUrl={catalogStore.fetchSelectDataByUrl}
                    upload={emptyAsyncFunction}
                    lang={intl.locale}
                    saveListPresets={saveListPresets}
                    fetchUserRoleList={userStore.userRoleList}
                    isAdmin={allowed}
                    rowActions={setRowActions}
                />
            )}
        </AuthorizationCheck>
    );
});
