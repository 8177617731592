import { AuthorizationCheckQuery } from './store';

export const entities = {
    System: 'System',
    ExpertiseCampaign: 'ExpertiseCampaign',
    TemplateExpertise: 'TemplateExpertise',
    Expertise: 'Expertise',
    ExpertiseSubject: 'ExpertiseSubject',
    ExpertiseTask: 'ExpertiseTask',
    ExpertiseTaskInvite: 'ExpertiseTaskInvite',
    Account: 'Account',
};

export const permissions = {
    System: {
        Administration: 'Administration',

        AccountAdministration: 'AccountAdministration',

        AddExpertiseSubject: 'AddExpertiseSubject',
        ViewExpertiseSubjectList: 'ViewExpertiseSubjectList',

        AddExpertiseCampaign: 'AddExpertiseCampaign',
        ViewCampaignList: 'ViewCampaignList',

        AddTemplateExpertise: 'AddTemplateExpertise',
        ViewTemplateExpertiseList: 'ViewTemplateExpertiseList',

        ViewRegistryTaskList: 'ViewRegistryTaskList',
        ViewExpertiseTaskListLegacy: 'ViewExpertiseTaskListLegacy',

        ViewExpertiseList: 'ViewExpertiseList',
        AddExpertise: 'AddExpertise',

        ViewUserList: 'ViewUserList',

        ViewPfTemplatesList: 'ViewPfTemplatesList',
        CreatePfTemplate: 'CreatePfTemplate',
        EditPfTemplate: 'EditPfTemplate',

        TaskRegistryColumnsManagement: 'TaskRegistryColumnsManagement',

        ViewLogs: 'ViewLogs',
    },

    ExpertiseCampaign: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        AddExpertises: 'AddExpertises',
    },

    ExpertiseSubject: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        CreateExpertise: 'CreateExpertise',
        DownloadCommonFile: 'DownloadCommonFile',
        DownloadHiddenFile: 'DownloadHiddenFile',
    },

    TemplateExpertise: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        ViewExtraInfo: 'ViewExtraInfo',
        EditExpertisePlan: 'EditExpertisePlan',
    },

    Expertise: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        EditPlan: 'EditPlan',
        CreateTask: 'CreateTask',
        UpdateCurator: 'UpdateCurator',
    },

    ExpertiseTask: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        SetExpert: 'SetExpert',
        ViewConclusion: 'ViewConclusion',
        EditConclusion: 'EditConclusion',
        UpdateConclusionPrintForm: 'UpdateConclusionPrintForm',
        UpdateTaskCurator: 'UpdateTaskCurator',
        ViewSubject: 'ViewSubject',
        EditDeadlineAndStartedDates: 'EditDeadlineAndStartedDates',
    },

    ExpertiseTaskInvite: {
        View: 'View',
    },

    User: {
        View: 'View',
        Update: 'Update',
        LoginAs: 'LoginAs',
        UpdateForm: 'UpdateForm',
    },
};

export const permissionsConfig = {
    administration: {
        entityCode: entities.System,
        permCode: permissions.System.Administration,
    },

    accountAdministration: {
        entityCode: entities.System,
        permCode: permissions.System.AccountAdministration,
    },

    updateUser: (userId: string): AuthorizationCheckQuery => ({
        entityCode: entities.Account,
        permCode: permissions.User.Update,
        entityId: userId,
    }),

    deleteExpertiseSubject: (subjectId: string): AuthorizationCheckQuery => ({
        permCode: permissions.ExpertiseSubject.Delete,
        entityCode: entities.ExpertiseSubject,
        entityId: subjectId,
    }),

    createExpertiseForExpertiseSubject: (subjectId: string): AuthorizationCheckQuery => ({
        permCode: permissions.ExpertiseSubject.CreateExpertise,
        entityCode: entities.ExpertiseSubject,
        entityId: subjectId,
    }),

    viewHeaderLinks: (permission: string): AuthorizationCheckQuery => ({
        entityCode: entities.System,
        permCode: permission,
    }),

    updateConclusionPrintForm: (expertiseTaskId: string): AuthorizationCheckQuery => ({
        permCode: permissions.ExpertiseTask.UpdateConclusionPrintForm,
        entityCode: entities.ExpertiseTask,
        entityId: expertiseTaskId,
    }),

    viewConclusion: (subjectId: string): AuthorizationCheckQuery => ({
        permCode: permissions.ExpertiseTask.ViewConclusion,
        entityCode: entities.ExpertiseTask,
        entityId: subjectId,
    }),
    editConclusion: (subjectId: string): AuthorizationCheckQuery => ({
        permCode: permissions.ExpertiseTask.EditConclusion,
        entityCode: entities.ExpertiseTask,
        entityId: subjectId,
    }),
    deleteExpertiseTask: (taskId: string): AuthorizationCheckQuery => ({
        permCode: permissions.ExpertiseTask.Delete,
        entityCode: entities.ExpertiseTask,
        entityId: taskId,
    }),
};
