import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import clientRoute from '../../../../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj, RowData as RowDataInj } from '../../../../components';
import { ExpertiseTaskModel, UserNameModel } from '../../../../models';
import { getOrElse } from '../../../../utils';
import { CuratorInfo as CuratorInfoInj } from '../CuratorInfo';
import { ExpertiseTaskAuthEditField as ExpertiseTaskAuthEditFieldInj } from '../edit';
import { ExpertTooltip as ExpertTooltipInj } from '../ExpertTooltip';
import { ExpertiseLink as ExpertiseLinkInj } from './ExpertiseLink';

export type ExpertiseTaskHeaderDescriptionRightProps = {
    expertId: UserNameModel['userId'];
    expert: ExpertiseTaskModel['expert'];
    expertCandidateId: UserNameModel['userId'];
    expertiseTaskModel: ExpertiseTaskModel;
    expertCandidate: ExpertiseTaskModel['expertCandidate'];
    toggleIsOpen?: () => void;
};

const useStyles = makeStyles(() =>
    createStyles({
        expertWrapper: {
            marginTop: '6px',
        },
        curatorWrapper: {
            marginTop: '9px',
        },
    }),
);

export const ExpertiseTaskHeaderDescriptionRight = (props: ExpertiseTaskHeaderDescriptionRightProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTaskHeaderDescriptionRight);
    const [RowData] = di([RowDataInj], ExpertiseTaskHeaderDescriptionRight);
    const [CuratorInfo] = di([CuratorInfoInj], ExpertiseTaskHeaderDescriptionRight);
    const [ExpertiseTaskAuthEditField] = di([ExpertiseTaskAuthEditFieldInj], ExpertiseTaskHeaderDescriptionRight);
    const [ExpertTooltip] = di([ExpertTooltipInj], ExpertiseTaskHeaderDescriptionRight);
    const [ExpertiseLink] = di([ExpertiseLinkInj], ExpertiseTaskHeaderDescriptionRight);

    const { expertId, expert, expertCandidateId, expertiseTaskModel, expertCandidate, toggleIsOpen } = props;

    const classes = useStyles();
    const intl = useIntl();

    let expertValue = '-';
    let expertWrapper;
    if (expertId) {
        expertValue = expert.name;
        expertWrapper = (children: {} | null): JSX.Element => {
            return (
                <AuthorizationCheck entityCode={entities.Account} permCode={permissions.User.View} entityId={expertId}>
                    {(allowed) =>
                        allowed ? (
                            <ExpertiseLink
                                component={NavLink}
                                to={generatePath(clientRoute.user, {
                                    id: expertId,
                                })}
                                underline="none"
                                color="textPrimary"
                            >
                                {children}
                            </ExpertiseLink>
                        ) : (
                            <React.Fragment>{children}</React.Fragment>
                        )
                    }
                </AuthorizationCheck>
            );
        };
    } else if (expertCandidateId) {
        expertValue = expertCandidate.name;
        expertWrapper = (children: {} | null): JSX.Element => {
            return (
                <AuthorizationCheck
                    entityCode={entities.Account}
                    permCode={permissions.User.View}
                    entityId={expertCandidateId}
                >
                    {(allowed) =>
                        allowed ? (
                            <ExpertiseLink
                                component={NavLink}
                                to={generatePath(clientRoute.user, {
                                    id: expertCandidateId,
                                })}
                                underline="none"
                                color="textPrimary"
                            >
                                <FormattedMessage
                                    id="expertiseTask.expertCandidate"
                                    values={{
                                        expert: children,
                                    }}
                                />
                            </ExpertiseLink>
                        ) : (
                            <React.Fragment>{children}</React.Fragment>
                        )
                    }
                </AuthorizationCheck>
            );
        };
    }

    const curatorValue = getOrElse(expertiseTaskModel.curator.name);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid
                item
                container
                className={classes.expertWrapper}
                direction="row"
                alignItems="center"
                spacing={2}
                wrap="nowrap"
            >
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'expertiseTask.fields.expert' })}
                        value={expertValue}
                        valueWrapper={expertWrapper}
                        valueMaxLength={25}
                    />
                </Grid>
                <Grid item>
                    {(Boolean(expertId) || Boolean(expertCandidateId)) && (
                        <ExpertTooltip fullName={expert.fullName || expertCandidate.fullName} />
                    )}
                </Grid>
            </Grid>
            <Grid item className={classes.curatorWrapper}>
                <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                    <Grid item>
                        <RowData
                            label={intl.formatMessage({ id: 'expertiseTask.fields.curator' })}
                            value={curatorValue}
                            valueMaxLength={25}
                        />
                    </Grid>
                    {expertiseTaskModel.curator.name && (
                        <Grid item>
                            <CuratorInfo
                                name={expertiseTaskModel.curator.fullName}
                                contacts={expertiseTaskModel.curatorContacts}
                            />
                        </Grid>
                    )}
                    <ExpertiseTaskAuthEditField
                        toggleIsOpenDialog={toggleIsOpen}
                        messageId={'expertise.changeCurator'}
                        permCode={permissions.ExpertiseTask.UpdateTaskCurator}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
