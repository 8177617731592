import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import { AuthorizationCheck } from '../../components';
import { SubjectListPage as SubjectListPageInj } from './subject-list/SubjectListPage';
import { SubjectPage as SubjectPageInj } from './subject-page/SubjectPage';

export const SubjectPages = observer((): JSX.Element => {
    const [SubjectPage] = di([SubjectPageInj], SubjectPages);
    const [SubjectListPage] = di([SubjectListPageInj], SubjectPages);
    return (
        <Switch>
            <Route path={clientRoute.subject}>
                <SubjectPage />
            </Route>
            <Route>
                <AuthorizationCheck
                    entityCode={entities.System}
                    permCode={permissions.System.ViewExpertiseSubjectList}
                    isWithRedirect={true}
                >
                    <SubjectListPage />
                </AuthorizationCheck>
            </Route>
        </Switch>
    );
});
