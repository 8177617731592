export enum UserStatus {
    active = 'active',
    inactive = 'inactive',
    blocked = 'blocked',
}

export enum UserStatusAction {
    activate = 'activate',
    unblock = 'unblock',
    block = 'block',
}

export type UserState = {
    code: UserStatus;
    title: string;
};

export type EditStatusDTO = {
    action: UserStatusAction;
    reason?: string;
};

export type UserInfoMessageValues = {
    name: string;
    login: string;
};
