import { Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    IconButtonLarge as IconButtonLargeInj,
} from '../../../components';
import { UserPersonModel } from '../../../models';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';

export type UserControlPanelLeftProps = {
    id: UserPersonModel['id'];
};

export const UserControlPanelLeft = (props: UserControlPanelLeftProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserControlPanelLeft);
    const [IconButtonLarge] = di([IconButtonLargeInj], UserControlPanelLeft);

    const { id } = props;
    return (
        <Grid spacing={3} alignItems="center" justify="center" container>
            <Switch>
                <Route exact path={clientRoute.user}>
                    <Grid item>
                        <AuthorizationCheck
                            entityCode={entities.Account}
                            entityId={id}
                            permCode={permissions.User.UpdateForm}
                        >
                            <Tooltip title={<FormattedMessage id="common.edit" />}>
                                <div>
                                    <IconButtonLarge
                                        component={NavLink}
                                        to={generatePath(clientRoute.userEdit, { id })}
                                    >
                                        <EditIcon />
                                    </IconButtonLarge>
                                </div>
                            </Tooltip>
                        </AuthorizationCheck>
                    </Grid>
                </Route>
            </Switch>
        </Grid>
    );
};
