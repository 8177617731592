import { Box, Chip, Grid } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RowData as RowDataInj, SyslogLink as SyslogLinkInj } from '../../../components';
import { UserPersonModel } from '../../../models';

export type UserPageHeaderLeftProps = {
    userPersonModel: UserPersonModel;
};

const syslogLinkStyle: CSSProperties = { marginBottom: '2px' };

export const UserPageHeaderLeft = (props: UserPageHeaderLeftProps): JSX.Element => {
    const [RowData] = di([RowDataInj], UserPageHeaderLeft);
    const [SyslogLink] = di([SyslogLinkInj], UserPageHeaderLeft);

    const { userPersonModel } = props;
    const intl = useIntl();

    const blockReasonValue = <Box whiteSpace="normal">{userPersonModel.blockReason}</Box>;
    const rolesValue = userPersonModel.roles.map((r) => (
        <Box pr={1} component="span" key={r.code}>
            <Chip label={r.title} />
        </Box>
    ));

    return (
        <React.Fragment>
            <Grid item>
                <Grid item container alignItems="center" spacing={2}>
                    <Grid item>
                        <RowData label={intl.formatMessage({ id: 'users.fields.roles' })} value={rolesValue} />
                    </Grid>
                    <Grid item style={syslogLinkStyle}>
                        <SyslogLink />
                    </Grid>
                </Grid>
                {userPersonModel.blockReason && (
                    <RowData label={intl.formatMessage({ id: 'users.blockReason' })} value={blockReasonValue} />
                )}
            </Grid>
        </React.Fragment>
    );
};
