import { Grid } from '@material-ui/core';
import { FormApi } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { useReload, useStore } from '../../../../hooks';
import { ExpertiseTaskViewModel } from '../../../../models';
import { ExpertiseTaskReport as ExpertiseTaskReportInj } from './ExpertiseTaskReport';
import { ExpertiseTaskViewHeader as ExpertiseTaskViewHeaderInj } from './ExpertiseTaskViewHeader';
import {
    ExpertiseTaskViewPageContainer as ExpertiseTaskViewPageContainerInj,
    TaskViewContainer as TaskViewContainerInj,
    TaskViewReportContainer as TaskViewReportContainerInj,
} from './ExpertiseTaskViewPage.styled';
import { PanelHorizontal as PanelHorizontalInj } from './PanelHorizontal';
import { SwitchViewState } from './SwitchExpertiseTaskView';
import { ExpertiseTaskViewRoutedTabs as ExpertiseTaskViewRoutedTabsInj } from './expertise-task-view-routed-tabs';

const queryString = require('query-string');

export type ExpertiseTaskRouteParams = {
    id: string;
    backUrl: string;
};

export const ExpertiseTaskViewPage = observer(
    (): JSX.Element => {
        const [ExpertiseTaskReport] = di([ExpertiseTaskReportInj], ExpertiseTaskViewPage);
        const [ExpertiseTaskViewHeader] = di([ExpertiseTaskViewHeaderInj], ExpertiseTaskViewPage);
        const [ExpertiseTaskViewPageContainer] = di([ExpertiseTaskViewPageContainerInj], ExpertiseTaskViewPage);
        const [TaskViewContainer] = di([TaskViewContainerInj], ExpertiseTaskViewPage);
        const [TaskViewReportContainer] = di([TaskViewReportContainerInj], ExpertiseTaskViewPage);
        const [PanelHorizontal] = di([PanelHorizontalInj], ExpertiseTaskViewPage);
        const [ExpertiseTaskViewRoutedTabs] = di([ExpertiseTaskViewRoutedTabsInj], ExpertiseTaskViewPage);

        const { reloadKey, reloadIncrement } = useReload();

        const { id } = useParams<ExpertiseTaskRouteParams>();
        const location = useLocation<ExpertiseTaskRouteParams>();
        const { expertiseTaskStore, intlStore, notificationStore } = useStore();

        const [formApi, setFormApi] = useState<FormApi>();
        const expertiseTaskViewModel = useMemo(() => new ExpertiseTaskViewModel(id, notificationStore, intlStore), []);
        const [headerCollapsed, setHeaderCollapsed] = useState<boolean>(false);
        const [switchViewState, setSwitchViewState] = useState<SwitchViewState>({ left: true, right: true });
        let backUrl = '';
        const queries = queryString.parse(location.search);
        backUrl = queries.backUrl || generatePath(clientRoute.expertiseTasks);

        const { taskModel } = expertiseTaskViewModel;

        const reloadTaskModel = (): void => {
            expertiseTaskStore.loadTaskView(id, backUrl).then(expertiseTaskViewModel.load);
            reloadIncrement();
        };

        const onFormReady = useCallback(
            (form: FormApi): void => {
                setFormApi(form);
            },
            [setFormApi],
        );

        const onToggleHeader = (): void => {
            setHeaderCollapsed(!headerCollapsed);
        };

        const onChangeSwitchViewState = (event: React.ChangeEvent<HTMLInputElement>): void => {
            setSwitchViewState({ ...switchViewState, [event.target.name]: event.target.checked });
        };

        useEffect(() => {
            expertiseTaskStore.loadTaskView(id, backUrl).then(expertiseTaskViewModel.load);
        }, [expertiseTaskStore, expertiseTaskViewModel.load, id, intlStore.locale, backUrl]);

        const [formIsChanged, setFormIsChanged] = useState(false);

        const isTaskFullWidth = switchViewState.left && !switchViewState.right;
        const isReportFullWidth = switchViewState.right && !switchViewState.left;

        return (
            <ExpertiseTaskViewPageContainer
                container
                direction="column"
                wrap="nowrap"
                fullScreen={headerCollapsed}
                overflow="hidden"
            >
                <ExpertiseTaskViewHeader
                    taskModel={taskModel}
                    formApi={formApi}
                    backUrl={backUrl}
                    onChangeSwitchViewState={onChangeSwitchViewState}
                    switchViewState={switchViewState}
                    onToggleHeader={onToggleHeader}
                    headerCollapsed={headerCollapsed}
                    reloadKey={reloadKey}
                    reloadTaskModel={reloadTaskModel}
                    setFormIsChanged={(): void => setFormIsChanged(false)}
                />
                <TaskViewContainer overflow="hidden" boxHeight="100%">
                    <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
                    >
                        <ExpertiseTaskViewRoutedTabs
                            expertiseTaskViewModel={expertiseTaskViewModel}
                            id={id}
                            isTaskFullWidth={isTaskFullWidth}
                            isReportFullWidth={isReportFullWidth}
                            reloadKey={reloadKey}
                        />
                        <PanelHorizontal
                            items={[
                                <TaskViewReportContainer key={reloadKey} item style={{ padding: 0 }}>
                                    <ExpertiseTaskReport
                                        setFormIsChanged={setFormIsChanged}
                                        formIsChanged={formIsChanged}
                                        onFormReady={onFormReady}
                                    />
                                </TaskViewReportContainer>,
                            ]}
                            leftSide={false}
                            fullWidth={isReportFullWidth}
                            isOtherFullWidth={isTaskFullWidth}
                        />
                    </Grid>
                </TaskViewContainer>
            </ExpertiseTaskViewPageContainer>
        );
    },
);
