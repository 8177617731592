import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-scala';
import 'prismjs/themes/prism.css';
import React, { ComponentProps, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import Editor from 'react-simple-code-editor';
import { consoleEditorClass, consoleErrorClass, tabSize } from '../../constants';
import { useAntiDoubleClick, useStore } from '../../hooks';
import { ConsoleModel } from '../../models';
import { ResultConsoleControls as ResultConsoleControlsInj } from './components';
import './error.scss';
import { consoleEditorStyles, useConsoleStyles as useConsoleStylesInj } from './useConsoleStyles';

const handleHighlight = (code: string): ReactNode => {
    return highlight(code, languages.scala, 'scala');
};

export const ConsolePage = observer((): JSX.Element => {
    const [ResultConsoleControls] = di([ResultConsoleControlsInj], ConsolePage);
    const [useConsoleStyles] = di([useConsoleStylesInj], ConsolePage);

    const rootStore = useStore();
    const model = useMemo(() => new ConsoleModel(rootStore), [rootStore]);
    const { code, result, error, setCode, runScript } = model;
    const [isSending, endIcon, actionHandler] = useAntiDoubleClick(runScript);

    const classes = useConsoleStyles();

    const generalEditorProps: Omit<ComponentProps<typeof Editor>, 'ignoreTabKey' | 'insertSpaces' | 'value'> = {
        onValueChange: setCode,
        highlight: handleHighlight,
        style: consoleEditorStyles,
        padding: 10,
        tabSize,
    };

    const resultClasses = cn(consoleEditorClass, { [consoleErrorClass]: !!error });
    const resultValue = error || result;

    const isRunBtnDisabled = isSending || !code.replace(/\s/g, '');

    return (
        <Container maxWidth="lg" className={classes.wrapper}>
            <Grid container direction="column" className={classes.inner}>
                <Grid item className={classes.mainBlock}>
                    <Typography variant="h1" component="h1">
                        <FormattedMessage id="console.title" />
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    wrap="nowrap"
                    justify="space-between"
                    className={cn(classes.mainBlock, classes.fullGrow)}
                >
                    <Grid container item direction="column" className={classes.codeWrapperLeft} xs={6}>
                        <Grid item className={classes.columnTitleWrapper}>
                            <Typography className={classes.columnTitle}>
                                <FormattedMessage id="console.script" />:
                            </Typography>
                        </Grid>
                        <Grid item className={classes.editorWrapper}>
                            <Box className={classes.editorInner}>
                                <Editor
                                    {...generalEditorProps}
                                    textareaClassName={consoleEditorClass}
                                    preClassName={consoleEditorClass}
                                    value={code}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container item direction="column" className={classes.codeWrapperRight} xs={6}>
                        <Grid item className={classes.columnTitleWrapper}>
                            <Typography className={classes.columnTitle}>
                                <FormattedMessage id="common.result" />:
                            </Typography>
                        </Grid>
                        <Grid item className={cn(classes.editorWrapper, classes.resultWrapper)}>
                            <Box className={classes.editorInner}>
                                <Box className={classes.resultControlsWrapper}>
                                    <ResultConsoleControls model={model} />
                                </Box>

                                {!!resultValue && (
                                    <Editor
                                        {...generalEditorProps}
                                        textareaClassName={resultClasses}
                                        preClassName={resultClasses}
                                        value={resultValue}
                                        readOnly={true}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item justify="flex-end">
                    <Grid item>
                        <Button
                            onClick={actionHandler}
                            disabled={isRunBtnDisabled}
                            color="primary"
                            variant="contained"
                            endIcon={endIcon}
                        >
                            <FormattedMessage id="console.runScript" />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
});
