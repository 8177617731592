import { AxiosError } from 'axios';
import { Transition } from '../models';

export type TransitionDialogProps = {
    transition: Transition;
    objectId: string;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    lifeCycleTransition: () => Promise<void>;
    handleSuccessTransition: () => void;
    handleErrorTransition: (error: AxiosError) => void;
};

export enum ExpertiseTaskTransitionCode {
    expertiseTaskInviteSentToAccept = 'ExpertiseTaskInviteSentToAccept',
    expertiseTaskInviteToReject = 'ExpertiseTaskInviteToReject',
    taskCheckToTaskRework = 'TaskCheckToTaskRework',
    expertiseTaskConclusionReject = 'ExpertiseTaskConclusionReject',
}
