import { Box, CircularProgress, Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { Redirect, useParams } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { RedirectByRole as RedirectByRoleInj } from '../../components';
import { useStore } from '../../hooks';
import { TotBackground as TotBackgroundInj } from '../../TotBackground';

type RouteParams = {
    token: string | undefined;
};

export const RegistrationConfirmPage = observer((): JSX.Element => {
    const [TotBackground] = di([TotBackgroundInj], RegistrationConfirmPage);
    const [RedirectByRole] = di([RedirectByRoleInj], RegistrationConfirmPage);

    const { authenticationStore } = useStore();
    const { token } = useParams<RouteParams>();
    const registrationConfirmModel = authenticationStore.registrationConfirmModel;

    useEffect(() => {
        registrationConfirmModel.confirmEmail(token);
    }, []);

    const renderByStatus = (): JSX.Element | null => {
        const { status } = registrationConfirmModel;
        switch (status) {
            case 'pending':
                return <CircularProgress />;
            case 'success':
                return <RedirectByRole />;
            case 'expired':
                return <Redirect to={clientRoute.registrationInfoExpired} />;
            case 'not-found':
                return <Redirect to={clientRoute.registrationInfoNotFound} />;
            default:
                return null;
        }
    };

    return (
        <TotBackground withBackdrop={true}>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Paper elevation={24}>
                    <Box>{renderByStatus()}</Box>
                </Paper>
            </Grid>
        </TotBackground>
    );
});
