import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type ExpertiseTaskControlPanelRightProps = {
    goBack: () => void;
    onSubmit: () => void;
};

export const ExpertiseTaskControlPanelRight = (props: ExpertiseTaskControlPanelRightProps): JSX.Element => {
    const { goBack, onSubmit } = props;
    return (
        <Grid spacing={3} alignItems="center" justify="center" container wrap="nowrap">
            <Grid item>
                <Button variant="contained" onClick={goBack}>
                    <FormattedMessage id="common.cancel" />
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={onSubmit}>
                    <FormattedMessage id="common.save" />
                </Button>
            </Grid>
        </Grid>
    );
};
