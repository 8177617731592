import {
    Button,
    Grid,
    Link,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, useRouteMatch } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    ActionsButton as ActionsButtonInj,
    AuthorizationCheck as AuthorizationCheckInj,
    DeleteActionMenuItem as DeleteActionMenuItemInj,
    ErrorDialog as ErrorDialogInj,
    ErrorMessage as ErrorMessageInj,
    MenuButton as MenuButtonInj,
} from '../../../components';
import { useError, useModal, useStore } from '../../../hooks';
import { ExpertiseTasksListByExpertiseModel, ExpertiseTasksRow } from '../../../models';
import { getTaskRoute as getTaskRouteInj } from '../../../utils';
import { CreateTaskDialog as CreateTaskDialogInj } from '../../task';

export type ExpertiseTasksTableProps = {
    model: ExpertiseTasksListByExpertiseModel;
};

export const ExpertiseTasksTable = observer((props: ExpertiseTasksTableProps): JSX.Element => {
    const [ActionsButton] = di([ActionsButtonInj], ExpertiseTasksTable);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTasksTable);
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], ExpertiseTasksTable);
    const [ErrorDialog] = di([ErrorDialogInj], ExpertiseTasksTable);
    const [ErrorMessage] = di([ErrorMessageInj], ExpertiseTasksTable);
    const [MenuButton] = di([MenuButtonInj], ExpertiseTasksTable);
    const [CreateTaskDialog] = di([CreateTaskDialogInj], ExpertiseTasksTable);
    const [getTaskRoute] = di([getTaskRouteInj], ExpertiseTasksTable);

    const { model } = props;

    const { expertiseTaskStore } = useStore();
    const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
    const match = useRouteMatch();

    const createExpertiseTask = (planEntryId: string): Promise<void> => {
        return expertiseTaskStore
            .createExpertiseTask(planEntryId)
            .then(() => {
                model.loadExpertiseTasksList();
            })
            .catch((error) => {
                const errorText = ErrorMessage(error);
                setErrorIsOpen(errorText);
            })
            .finally(() => {
                setModalIsClosed();
            });
    };

    const renderActionItems = (task: ExpertiseTasksRow): (() => ReactNode[]) => {
        const backUrl = match.url;
        const taskId = task.id;
        return (): ReactNode[] => [
            <AuthorizationCheck
                entityCode={entities.ExpertiseTask}
                entityId={taskId}
                permCode={permissions.ExpertiseTask.Edit}
                key="edit"
            >
                <MenuItem
                    dense
                    tabIndex={0}
                    button={true}
                    component={NavLink}
                    to={`${generatePath(clientRoute.expertiseTaskEdit, { id: taskId })}?backUrl=${backUrl}`}
                >
                    <FormattedMessage id="common.edit" />
                </MenuItem>
            </AuthorizationCheck>,
            <AuthorizationCheck
                entityCode={entities.ExpertiseTask}
                entityId={taskId}
                permCode={permissions.ExpertiseTask.Delete}
                key="delete"
            >
                <DeleteActionMenuItem
                    id="delete"
                    wrappedComponentProps={{ tabIndex: 1 }}
                    title={<FormattedMessage id="common.confirmDeletion" />}
                    message={<FormattedMessage id="expertiseTask.listConfirmDeletionInfoText" />}
                    onConfirm={model.deleteTask(taskId)}
                />
            </AuthorizationCheck>,
        ];
    };

    const renderTableBody = (tasks: ExpertiseTasksRow[]) => {
        return tasks.map((task: ExpertiseTasksRow) => {
            const taskRoute = getTaskRoute(task.id, task.taskType?.code);
            return (
                <TableRow key={task.id} hover>
                    <TableCell>
                        <Link component={NavLink} underline="none" to={taskRoute}>
                            {task.identifier}
                        </Link>
                    </TableCell>
                    <TableCell>
                        {task.planEntry.title}{' '}
                        {task.viewPoint && (
                            <Typography>
                                <FormattedMessage id="templatesOfExpertise.plan.fields.viewPoint" />
                                <span>: {task.viewPoint}</span>
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell>{getExpertCell(task)}</TableCell>
                    <TableCell>
                        {(task.acceptance && <FormattedDate value={task.acceptance} />) ||
                            (task.planAcceptanceDays && (
                                <Typography>
                                    {task.planAcceptanceDays}
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.daysPlan" />
                                </Typography>
                            )) ||
                            (task.planAcceptance && (
                                <Typography>
                                    <FormattedDate value={task.planAcceptance} />
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.datePlan" />
                                </Typography>
                            ))}
                    </TableCell>
                    <TableCell>
                        {(task.deadline && <FormattedDate value={task.deadline} />) ||
                            (task.planDeadlineDays && (
                                <Typography>
                                    {task.planDeadlineDays}
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.daysPlan" />
                                </Typography>
                            )) ||
                            (task.planDeadline && (
                                <Typography>
                                    <FormattedDate value={task.planDeadline} />
                                    <FormattedMessage id="expertiseTask.expertiseTasksTable.datePlan" />
                                </Typography>
                            ))}
                    </TableCell>
                    <TableCell>{task.completed && <FormattedDate value={task.completed} />}</TableCell>
                    <TableCell>{task.state}</TableCell>
                    <TableCell>{renderActions(task)}</TableCell>
                </TableRow>
            );
        });
    };

    const renderActions = (task: ExpertiseTasksRow): JSX.Element => {
        return (
            <MenuButton disablePortal={true} renderButton={ActionsButton} renderMenuItems={renderActionItems(task)} />
        );
    };

    const getExpertCell = (row: ExpertiseTasksRow): React.ReactNode => {
        return row.expertName ? (
            row.expertName
        ) : row.expertCandidateName ? (
            <FormattedMessage
                id="expertiseTask.expertCandidate"
                values={{
                    expert: row.expertCandidateName,
                }}
            />
        ) : (
            ''
        );
    };

    return (
        <React.Fragment>
            {isModalOpen && <CreateTaskDialog onCancel={setModalIsClosed} onSubmit={createExpertiseTask} />}
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            <Grid container direction="column" spacing={5}>
                <Grid item container direction="row" justify="space-between">
                    <Grid item>
                        <Typography variant="h3">
                            <FormattedMessage id="expertiseTask.listTitle" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <AuthorizationCheck
                            entityCode={entities.Expertise}
                            permCode={permissions.Expertise.CreateTask}
                            entityId={model.id}
                        >
                            <Button color="primary" variant="contained" onClick={setModalIsOpen}>
                                <FormattedMessage id="expertiseTask.create" />
                            </Button>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.number" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.planEntry" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.expert" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.responseTime" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.deadline" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.actualDateOfTheExecution" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id="expertiseTask.expertiseTasksTable.fields.stateTitle" />
                                        </Typography>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderTableBody(model.expertiseTasks)}</TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    );
});
