import { observer } from 'mobx-react';
import React from 'react';
import { RedirectProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useStore } from '../hooks';

export type RedirectByRoleProps = Partial<RedirectProps>;

export const RedirectByRole = observer((props: RedirectByRoleProps): JSX.Element => {
    const { to, ...otherProps } = props;
    const { personStore, headerLinksStore } = useStore();
    const { redirectPath } = personStore;
    const { firstAvailablePath } = headerLinksStore;

    return (
        <React.Fragment>
            {to || redirectPath ? (
                <Redirect {...otherProps} to={to || redirectPath} />
            ) : (
                <React.Fragment>
                    {firstAvailablePath && <Redirect {...otherProps} to={firstAvailablePath} />}
                </React.Fragment>
            )}
        </React.Fragment>
    );
});
