import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IdTitleNumber } from '../../../../models';
import { getOrElse } from '../../../../utils';

export type ExpertisePageHeaderTitleProps = {
    title?: IdTitleNumber['title'];
};

export const ExpertisePageHeaderTitle = (props: ExpertisePageHeaderTitleProps): JSX.Element => {
    const { title } = props;

    return (
        <Grid item>
            <Typography variant="h1">
                {getOrElse(title, <FormattedMessage id="expertise.expertiseTitle" values={{ title }} />)}
            </Typography>
        </Grid>
    );
};
