import { AppBar, Box, Container, Grid, Link, useMediaQuery } from '@material-ui/core';
import { ModularNavigationWidget } from '@platform/modular-navigation-widget';
import { useFeature } from 'feature-toggle-jsx';
import { observer, useLocalStore } from 'mobx-react';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { useStore } from '../../hooks';
import { StartPageHeaderLinks as StartPageHeaderLinksInj } from '../../pages';
import { AppTheme } from '../../themes';
import { MediaSize } from '../../types';
import { AppHeaderLinks as AppHeaderLinksInj } from './AppHeaderLinks';
import { InstructionsButton as InstructionsButtonInj } from './InstructionsButton';
import { LanguageButton as LanguageButtonInj } from './LanguageButton';
import { LogoConfig, LogoImg as LogoImgInj } from './LogoImg';

export type TotAppBarProps = {
    withInstructions?: boolean;
};

export const TotAppBar = observer((props: TotAppBarProps): JSX.Element => {
    const [StartPageHeaderLinks] = di([StartPageHeaderLinksInj], TotAppBar);
    const [AppHeaderLinks] = di([AppHeaderLinksInj], TotAppBar);
    const [InstructionsButton] = di([InstructionsButtonInj], TotAppBar);
    const [LanguageButton] = di([LanguageButtonInj], TotAppBar);
    const [LogoImg] = di([LogoImgInj], TotAppBar);

    const { withInstructions = true } = props;
    const { intlStore, modulesStore, personStore, headerLinksStore } = useStore();
    const { setMediaSize } = headerLinksStore;
    const { loadModulesList } = modulesStore;

    const [logo, logoConfig] = useFeature('logo');
    const [startPage] = useFeature('startPage');
    const [instructions] = useFeature('instructions');

    const store = useLocalStore(() => ({
        isMenuOpen: false,
        setMenuIsClosed(): void {
            this.isMenuOpen = false;
        },
        handlerBurgerButton(): void {
            this.isMenuOpen = !this.isMenuOpen;
        },
    }));

    const isSmSize = useMediaQuery((theme: AppTheme) => theme.breakpoints.down('sm'));
    const isMdSize =
        (useMediaQuery((theme: AppTheme) => theme.breakpoints.down('md')) && !isSmSize) ||
        (logoConfig.mdLogo && !isSmSize);

    useEffect(() => {
        setMediaSize(isSmSize ? MediaSize.smSize : isMdSize ? MediaSize.mdSize : MediaSize.defaultSize);
    }, [isSmSize, isMdSize]);

    return (
        <AppBar color="default" position="static" elevation={0}>
            <Container maxWidth="lg">
                <Box pt={3.75} pb={3.75}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-between">
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                                <Grid item>
                                    <Link component={RouterLink} to={clientRoute.root} underline="none">
                                        <Grid container direction="row" alignItems="center">
                                            <LogoImg logoConfig={logoConfig as LogoConfig} />
                                        </Grid>
                                    </Link>
                                </Grid>
                                {personStore.user.id && (
                                    <Grid item key={personStore.user.id}>
                                        <ModularNavigationWidget
                                            loadModulesList={loadModulesList}
                                            locale={intlStore.locale}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Box ml={1} mr={1} pt={3.75}>
                                <Switch>
                                    <Route exact path={[clientRoute.root, clientRoute.login]}>
                                        {startPage && <StartPageHeaderLinks />}
                                    </Route>
                                    <Route>
                                        <AppHeaderLinks
                                            isSmSize={isSmSize}
                                            isMdSize={isMdSize}
                                            isOpenMenu={store.isMenuOpen}
                                            handlerBurgerButton={store.handlerBurgerButton}
                                            closeBurgerMenu={store.setMenuIsClosed}
                                        />
                                    </Route>
                                </Switch>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="flex-end" spacing={3}>
                                {instructions && withInstructions && (
                                    <Grid item>
                                        <Box pt={3.75}>
                                            <InstructionsButton />
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Box pt={3.75}>
                                        <LanguageButton />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </AppBar>
    );
});
