import { Box, Button, Container, Grid, SvgIcon, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ExportButton as ExportButtonInj } from '../../../components';
import { useStore } from '../../../hooks';
import { ExpertiseTaskListModel as ExpertiseTaskListModelInj } from '../../../models';
import { ReactComponent as ClearFilter } from '../../../resources/images/icons/clear-filter.svg';
import { ExpertiseTaskListFilterPanel as ExpertiseTaskListFilterPanelInj } from '../ExpertiseTaskListFilterPanel';
import { ExpertiseTaskListOldTable as ExpertiseTaskListOldTableInj } from './ExpertiseTaskListOldTable';

export const ExpertiseTaskListOldPage = observer((): JSX.Element => {
    const [ExportButton] = di([ExportButtonInj], ExpertiseTaskListOldPage);
    const [ExpertiseTaskListFilterPanel] = di([ExpertiseTaskListFilterPanelInj], ExpertiseTaskListOldPage);
    const [ExpertiseTaskListOldTable] = di([ExpertiseTaskListOldTableInj], ExpertiseTaskListOldPage);
    const [ExpertiseTaskListModel] = di([ExpertiseTaskListModelInj], ExpertiseTaskListOldPage);

    const { api, history, expertiseTaskStore, intlStore } = useStore();
    const intl = useIntl();
    const tableModel = useMemo(() => new ExpertiseTaskListModel(api, history), [api, history]);

    useEffect(() => {
        return tableModel.dispose;
    }, [tableModel.dispose]);

    useEffect(() => {
        tableModel.reloadData();
        tableModel.getFilterData();
    }, [tableModel, intlStore.locale]);

    const filename = `${intl.formatMessage({ id: 'expertiseTask.listTitle' })}.xlsx`;

    return (
        <Container maxWidth="lg">
            <Box pt={5} pb={5}>
                <Grid container direction="column" spacing={10}>
                    <Grid item container direction="row" justify="space-between">
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="expertiseTask.listTitle" />
                            </Typography>
                        </Grid>

                        <Grid item xs={6} container spacing={2} justify="flex-end">
                            <Grid item>
                                <Button
                                    variant="text"
                                    color="primary"
                                    startIcon={
                                        <SvgIcon>
                                            <ClearFilter />
                                        </SvgIcon>
                                    }
                                    onClick={tableModel.clearFilters}
                                >
                                    <FormattedMessage id="common.resetFilters" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <ExportButton
                                    queryData={tableModel.queryData}
                                    loadRegisterList={expertiseTaskStore.exportListXls}
                                    filename={filename}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <ExpertiseTaskListFilterPanel model={tableModel} />
                    </Grid>
                    <Grid item>
                        <ExpertiseTaskListOldTable tableModel={tableModel} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
