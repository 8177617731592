import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { permissionsConfig } from '../../../../../../authSchemeConfig';
import { ExpertiseTaskPrintFormsModel, PrintFormFileDTO } from '../../../../../../models';
import { LoaderBox as LoaderBoxInj } from '../ExpertiseTaskPrintForms.styled';
import { ExpertiseTaskPrintFormsTablePfRow as ExpertiseTaskPrintFormsTablePfRowInj } from './ExpertiseTaskPrintFormsTablePfRow';

export type ExpertiseTaskPrintFormsTableProps = {
    downloadPf(file: PrintFormFileDTO): () => void;
    printForms: ExpertiseTaskPrintFormsModel;
};

export type ExpertiseTaskPrintFormsTableRouteParams = {
    id: string;
};

export const ExpertiseTaskPrintFormsTable = observer(
    (props: ExpertiseTaskPrintFormsTableProps): JSX.Element => {
        const [LoaderBox] = di([LoaderBoxInj], ExpertiseTaskPrintFormsTable);
        const [ExpertiseTaskPrintFormsTablePfRow] = di(
            [ExpertiseTaskPrintFormsTablePfRowInj],
            ExpertiseTaskPrintFormsTable,
        );

        const { downloadPf, printForms } = props;
        const { conclusion, loaded } = printForms;
        const { id } = useParams<ExpertiseTaskPrintFormsTableRouteParams>();

        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        {loaded ? (
                            <React.Fragment>
                                {conclusion && (
                                    <ExpertiseTaskPrintFormsTablePfRow
                                        printForm={conclusion}
                                        permission={permissionsConfig.updateConclusionPrintForm(id)}
                                        pfTypeMsgId="expertiseTask.printFormsTable.conclusion"
                                        downloadPf={downloadPf}
                                    />
                                )}
                            </React.Fragment>
                        ) : (
                            <TableRow key="loader">
                                <TableCell>
                                    <LoaderBox>
                                        <CircularProgress />
                                    </LoaderBox>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    },
);
