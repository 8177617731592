export default {
    startPage: false,
    startPageDefaultText: false,
    startPageWithLogin: true,
    logo: {
        isEnabled: true,
        locales: {
            ru: {
                src: require(`../resources/sstp-logo.svg`),
                alt: 'SSTP logo',
            },
            en: {
                src: require(`../resources/sstp-logo.svg`),
                alt: 'SSTP logo',
            },
        },
        style: { margin: '9px 0' },
        withText: false,
        mdLogo: false,
    },
    customBackground: false,
    phoneNumber: true,
    instructions: false,
    esiaLogin: false,

    yaMetrika: false,

    userAdding: true,
    userAssignPassword: true,

    withSubjectFilesTab: false,
};
