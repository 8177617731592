import { Toolbar, Tooltip } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import styled from 'styled-components';
import { withConfirmDialog } from '../../hocs';
import { ReactComponent as DeleteIcon } from '../../resources/images/icons/delete.svg';
import { IconButtonLarge as IconButtonLargeInj } from '../buttons';

const TotObjectActionsBarStyled = withTheme(styled(Toolbar)`
    background-color: ${({ theme }): string => theme.palette.primary.contrastText};
    color: ${({ theme }): string => theme.palette.secondary.contrastText};
    border-top: ${({ theme }): string => `1px solid ${theme.variables.palette.hoverInLists}`};
`);

type TotObjectActionsBarProps = {
    style?: object;
};

export const TotObjectActionsBar = withTheme(
    (props: PropsWithChildren<ThemedComponentProps & TotObjectActionsBarProps>) => {
        return (
            <TotObjectActionsBarStyled
                style={{
                    ...props.style,
                }}
            >
                {props.children}
            </TotObjectActionsBarStyled>
        );
    },
);

type TotObjectDeleteButtonProps = {
    tooltipTitleId: string;
};

export const TotObjectDeleteButton = withConfirmDialog<TotObjectDeleteButtonProps>((props) => {
    const [IconButtonLarge] = di([IconButtonLargeInj], TotObjectDeleteButton);
    return (
        <Tooltip title={<FormattedMessage id={props.tooltipTitleId} />}>
            <div>
                <IconButtonLarge onClick={props.openConfirmDialog}>
                    <DeleteIcon />
                </IconButtonLarge>
            </div>
        </Tooltip>
    );
});
