import { Grid } from '@material-ui/core';
import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RowData as RowDataInj, SyslogLink as SyslogLinkInj } from '../../../components';
import { TemplatesOfExpertiseModel } from '../../../models';
import { getOrElse } from '../../../utils';

export type TemplateOfExpertiseHeaderLeftProps = {
    created: TemplatesOfExpertiseModel['metaInfo']['created'];
    stateTitle: TemplatesOfExpertiseModel['metaInfo']['stateTitle'];
};

export const TemplateOfExpertiseHeaderLeft = (props: TemplateOfExpertiseHeaderLeftProps): JSX.Element => {
    const [RowData] = di([RowDataInj], TemplateOfExpertiseHeaderLeft);
    const [SyslogLink] = di([SyslogLinkInj], TemplateOfExpertiseHeaderLeft);

    const { created, stateTitle } = props;
    const intl = useIntl();

    return (
        <React.Fragment>
            <Grid item>
                <RowData
                    label={intl.formatMessage({ id: 'templatesOfExpertise.fields.created' })}
                    value={getOrElse(created, <FormattedDate value={created} />)}
                />
            </Grid>
            <Grid item>
                <RowData
                    label={intl.formatMessage({ id: 'templatesOfExpertise.fields.state' })}
                    value={getOrElse(stateTitle, stateTitle, '-')}
                />
            </Grid>
            <Grid item>
                <SyslogLink />
            </Grid>
        </React.Fragment>
    );
};
