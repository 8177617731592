import { Box, Grid, makeStyles, Paper, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectHeaderLabel as TotObjectHeaderLabelInj,
    TotObjectHeaderValue as TotObjectHeaderValueInj,
} from '../../../components';

export type ExpertTooltipProps = {
    fullName: string;
};

const useStyles = makeStyles({
    tooltip: {
        padding: 0,
        maxWidth: 'none',
    },
});

export const ExpertTooltip = (props: ExpertTooltipProps): JSX.Element => {
    const [TotObjectHeaderLabel] = di([TotObjectHeaderLabelInj], ExpertTooltip);
    const [TotObjectHeaderValue] = di([TotObjectHeaderValueInj], ExpertTooltip);

    const classes = useStyles();
    const { fullName } = props;
    return (
        <Tooltip
            classes={classes}
            title={
                <Paper>
                    <Box padding={3}>
                        <Grid container direction="row" alignItems="baseline" spacing={2} wrap="nowrap">
                            <Grid item>
                                <TotObjectHeaderLabel>
                                    <FormattedMessage id="expertiseTask.curator.name" />
                                </TotObjectHeaderLabel>
                            </Grid>
                            <Grid item>
                                <TotObjectHeaderValue>{fullName || '-'}</TotObjectHeaderValue>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            }
        >
            <InfoOutlinedIcon />
        </Tooltip>
    );
};
