import { Box, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import React, { PropsWithChildren } from 'react';

export const TotObjectPanelLabel = withTheme(({ theme, children }: PropsWithChildren<ThemedComponentProps>) => {
    return (
        <Box color={theme?.palette.primary.light}>
            <Typography variant="body1" component="span">
                {children}
            </Typography>
        </Box>
    );
});

export const TotObjectPanelValue = ({ children }: PropsWithChildren<{}>) => {
    return (
        <Typography variant="body2" component="span">
            {children}
        </Typography>
    );
};
