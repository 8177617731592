import { AppFonts, AppIcons, AppPalette, ThemeOverrides } from '../../../themes';
import fonts from './fonts';

type ColorName =
    | 'blackLight'
    | 'black'
    | 'white'
    | 'greyMain'
    | 'greyLight'
    | 'greyDark'
    | 'greyWhite'
    | 'greyLighter'
    | 'greyMiddleLight'
    | 'green'
    | 'red'
    | 'greyTable'
    | 'deepBlue'
    | 'blue'
    | 'blueDark'
    | 'blueMiddle'
    | 'blueLight'
    | 'blueLighter'
    | 'blueWhite';

const sstpColors: Record<ColorName, string> = {
    blackLight: '#1c1d1f',
    black: '#000000',
    white: '#ffffff',
    greyMain: '#63666a',
    greyLight: '#85898f',
    greyDark: '#2c302e',
    greyWhite: '#f1f1f1',
    greyLighter: '#fafafa',
    greyMiddleLight: '#ccd3db',
    green: '#03cea4',
    red: '#fb4d3d',
    greyTable: '#ececec',
    deepBlue: '#0000FF',
    blue: '#1B6195',
    blueDark: '#124265',
    blueMiddle: '#D6E3EC',
    blueLight: '#5C8EB3',
    blueLighter: '#AEC7D9',
    blueWhite: '#E7EFF4',
};

const sstpFonts: AppFonts = {
    ...fonts,
    mainFontAttrs: {
        fontSize: '13px',
        lineHeight: 1.69,
        letterSpacing: '0.2px',
        fontWeight: 400,
    },
};

const sstpIcons: AppIcons = {
    fontFamily: fonts.iconsFont,
    arrowIcon: {
        fontSize: 11,
        content: '"\\e90f"',
        width: 12,
        height: 7,
    },
    doneIcon: {
        fontSize: 14,
        content: '"\\e906"',
        width: 14,
        height: 11,
    },
    calendarIcon: {
        fontSize: 20,
        content: '"\\e905"',
        width: 18,
        height: 20,
    },
};

const accentBackgroundGradient = `linear-gradient(-30deg, ${sstpColors.blue} 0%, ${sstpColors.blue} 100%)`;

export const sstpPalette: AppPalette = {
    main: sstpColors.blueDark,
    textMain: sstpColors.greyMain,
    textDark: sstpColors.black,
    mainMiddleLight: sstpColors.greyMiddleLight,
    mainLight: sstpColors.blueLighter,
    mainDark: sstpColors.greyDark,
    mainContrast: sstpColors.white,
    mainContrastDarker: sstpColors.greyLighter,
    accent: sstpColors.blue,
    accentDark: sstpColors.blueDark,
    accentContrast: sstpColors.black,
    accentBackgroundGradient: accentBackgroundGradient,
    accentContrastBackgroundGradient: sstpColors.blueWhite,
    hover: sstpColors.blueWhite,
    hoverInLists: sstpColors.greyWhite,
    green: sstpColors.green,
    red: sstpColors.red,
    radio: {
        color: sstpColors.greyMiddleLight,
        hoverColor: sstpColors.green,
        activeColor: sstpColors.green,
    },

    table: {
        row: {
            even: sstpColors.white,
            odd: sstpColors.greyLighter,
            checked: sstpColors.greyTable,
        },
    },
    card: {
        header: {
            main: '#252629',
        },
    },
    appBar: {
        mainContrast: sstpColors.blue,
    },
    tooltip: {
        backgroundColor: sstpColors.blackLight,
    },
    buttonPrimary: {
        color: sstpColors.greyMain,
        hoverColor: sstpColors.blueDark,
    },
    iconButton: {
        color: sstpColors.blueDark,
        hoverColor: sstpColors.white,
        hoverBackgroundColor: sstpColors.blueDark,
    },
    footer: {
        textMain: sstpColors.blueLighter,
    },
    panel: {
        markerBackgroundColor: accentBackgroundGradient,
        markerColor: sstpColors.white,
    },
    campaignRequest: {
        icon: sstpColors.black,
    },
    startLink: {
        color: sstpColors.blueLighter,
        hoverColor: sstpColors.white,
    },
    productText: {
        color: sstpColors.white,
    },
    linkDefault: {
        color: sstpColors.deepBlue,
    },
};

export default {
    fonts: sstpFonts,
    palette: sstpPalette,
    icons: sstpIcons,
} as ThemeOverrides;
