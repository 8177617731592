import { FullSubmission } from '@platform/formiojs-react';
import { AxiosPromise } from 'axios';
import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import clientRoute from '../clientRoute';
import {
    CodeTitle, EntityCreatedDTO, ExpertiseCreateModel, ExpertiseModel, IdTitleNumber, MetaInfoDTO,
    TableQueryData, TransitionsDTO, UserPersonDTO
} from '../models';
import { IdTitle } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { ExpertisePlanStore, PlanEntryDTO } from './ExpertisePlanStore';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

export interface ExpertiseDTO {
    id: string;
    identifier: string;
    subject: IdTitleNumber;
    campaignTitle: IdTitle;
    template?: IdTitle;
    curator: UserPersonDTO;
    metaInfo: MetaInfoDTO;
}

export type ExpertiseCreateDTO = {
    subjectId: string;
    templateId?: string;
    processCode?: string;
};

export type ExpertiseByCampaignDTO = {
    campaignId: string;
    templateId: string;
};

export class ExpertiseStore extends ExpertisePlanStore {
    @observable rootStore: RootStore;
    @observable api: Api;
    @observable intl: IntlStore;

    constructor(rootStore: RootStore) {
        super();
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intl = rootStore.intlStore;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    getExpertiseModel(id: string): ExpertiseModel {
        const model = new ExpertiseModel(id);
        this.loadExpertise(id).then(model.load);
        return model;
    }

    @action.bound
    loadExpertise(id: string): Promise<ExpertiseDTO> {
        return this.api
            .client(apiConfigs.loadExpertise(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    403: () => this.history.replace(clientRoute.notAllowed),
                    404: () => this.history.replace(clientRoute.notFound),
                }),
            );
    }

    @action.bound
    deleteExpertise(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteExpertise(id)).then((r) => r.data);
    }

    @action.bound
    lifeCycleTransition(transitionId: string, expertiseId: string): Promise<void> {
        return this.api.client(apiConfigs.expertiseLifeCycleTransition(transitionId, expertiseId)).then((r) => r.data);
    }

    @action.bound
    getLifeCycleTransitions(expertiseId: string): Promise<TransitionsDTO> {
        return this.api.client(apiConfigs.getExpertiseLifeCycleTransitions(expertiseId)).then((r) => r.data);
    }

    @action.bound
    getExpertiseCreateModel(): ExpertiseCreateModel {
        const model = new ExpertiseCreateModel(this.rootStore);
        this.rootStore.templatesOfExpertiseStore.loadTemplatesSelectList().then(model.loadTemplates);
        this.loadLifeCyclesSelectList().then(model.loadLifeCycles);
        return observable(model);
    }

    @action.bound
    loadLifeCyclesSelectList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.lifeCyclesExpertiseList).then((r) => r.data);
    }

    @action.bound
    createExpertise(data: ExpertiseCreateDTO): Promise<string> {
        return this.api.client(apiConfigs.createExpertise(data)).then((r) => r.data);
    }

    @action.bound
    createExpertiseByCampaign(data: ExpertiseByCampaignDTO): Promise<string> {
        return this.api.client(apiConfigs.createExpertiseByCampaign(data)).then((r) => r.data);
    }

    @action.bound
    loadPlanEntryList(expertiseId: string): Promise<PlanEntryDTO[]> {
        return this.api.client(apiConfigs.loadExpertisePlanEntries(expertiseId)).then((r) => r.data);
    }

    @action.bound
    createPlanEntry(expertiseId: string): Promise<EntityCreatedDTO> {
        return this.api.client(apiConfigs.createExpertisePlanEntry(expertiseId)).then((r) => r.data);
    }

    @action.bound
    updatePlanEntry(
        id: string,
        submission: FullSubmission,
        taskFormCode?: string,
        taskSubmission?: FullSubmission,
    ): Promise<void> {
        return this.api
            .client(apiConfigs.updateExpertisePlanEntry(id, submission, taskFormCode, taskSubmission))
            .then((r) => r.data);
    }

    @action.bound
    loadPlanEntry(id: string): Promise<PlanEntryDTO> {
        return this.api.client(apiConfigs.loadExpertisePlanEntry(id)).then((r) => r.data);
    }

    @action.bound
    deletePlanEntry(id: string): Promise<void> {
        return this.api.client(apiConfigs.deleteExpertisePlanEntry(id)).then((r) => r.data);
    }

    @action.bound
    exportListXls(queryData: TableQueryData): AxiosPromise<Blob> {
        return this.api.client(apiConfigs.expertiseListXls(queryData));
    }

    @action.bound
    loadCuratorSelectOptions(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.expertiseCuratorSelectOptions).then((r) => r.data);
    }

    @action.bound
    editCurator(expertiseId: string, curatorUserId: string): Promise<void> {
        return this.api.client(apiConfigs.editExpertiseCurator(expertiseId, curatorUserId)).then((r) => r.data);
    }
}

export const getExpertiseStore = (): any => {
    const [_ExpertiseStore] = di([ExpertiseStore], getExpertiseStore);
    return _ExpertiseStore;
};
