import { useFeature } from 'feature-toggle-jsx';
import React, { useMemo } from 'react';
import { defaultLocale } from '../store';
import { useStore } from './useStore';

export const useLogo = (): JSX.Element => {
    const [logo, logoConfig] = useFeature('logo');
    const { intlStore } = useStore();
    const { locale } = intlStore;

    return useMemo((): JSX.Element => {
        const { alt, src } = logoConfig.locales[locale] || logoConfig.locales[defaultLocale];
        return <img src={src} alt={alt} style={logoConfig.style} />;
    }, [logoConfig, locale]);
};
