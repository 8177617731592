import { recordToLang } from '../../../../../resources';
import en from '../../../../../resources/locales/en';
import startPage from './startPage';

const lang: Record<string, string> = {
    ...en,
    ...recordToLang({ startPage }),
};

export default lang;
