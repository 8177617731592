import { Grid, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    ServiceInfoPanel as ServiceInfoPanelInj,
    TotObjectMain as TotObjectMainInj,
} from '../../../components';
import { useReload, useStore } from '../../../hooks';
import { ExpertiseModel, ExpertiseTasksListByExpertiseModel, PlanEntryListModel } from '../../../models';
import { ExpertisePlan as ExpertisePlanInj } from '../../templates-of-expertise/ExpertisePlan';
import { ExpertiseControlPanel as ExpertiseControlPanelInj } from './ExpertiseControlPanel';
import { ExpertiseHeader as ExpertiseHeaderInj } from './ExpertiseHeader';
import { ExpertiseTasksTable as ExpertiseTasksTableInj } from './ExpertiseTasksTable';

export type ExpertisePageRouteParams = {
    id: string;
};

export const ExpertisePage = observer((): JSX.Element => {
    const [TotObjectMain] = di([TotObjectMainInj], ExpertisePage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertisePage);
    const [ServiceInfoPanel] = di([ServiceInfoPanelInj], ExpertisePage);
    const [ExpertisePlan] = di([ExpertisePlanInj], ExpertisePage);
    const [ExpertiseControlPanel] = di([ExpertiseControlPanelInj], ExpertisePage);
    const [ExpertiseHeader] = di([ExpertiseHeaderInj], ExpertisePage);
    const [ExpertiseTasksTable] = di([ExpertiseTasksTableInj], ExpertisePage);

    const { expertiseStore, expertiseTaskStore, intlStore, history } = useStore();

    const { id } = useParams<ExpertisePageRouteParams>();

    const [expertiseModel, setExpertiseModel] = useState<ExpertiseModel>(expertiseStore.getExpertiseModel(id));
    const [planEntryListModel, setPlanEntryListModel] = useState<PlanEntryListModel>(
        new PlanEntryListModel(id, expertiseStore),
    );
    const [expertiseTasksListByExpertiseModel, setExpertiseTasksListByExpertiseModel] =
        useState<ExpertiseTasksListByExpertiseModel>(new ExpertiseTasksListByExpertiseModel(id, expertiseTaskStore));

    const { reloadKey, reloadIncrement } = useReload();

    useEffect(() => {
        expertiseTasksListByExpertiseModel.loadExpertiseTasksList();
    }, [planEntryListModel.entries]);

    const updateModel = (): void => {
        expertiseStore.loadExpertise(id).then(expertiseModel.load);
    };

    useEffect(() => {
        updateModel();
    }, [intlStore.locale]);

    const reloadModel = (): void => {
        setExpertiseModel(expertiseStore.getExpertiseModel(id));
        reloadIncrement();
    };

    const deleteExpertise = (): Promise<void> => {
        return expertiseStore.deleteExpertise(expertiseModel.id).then(() => {
            history.push(generatePath(clientRoute.expertiseList));
        });
    };

    const onLifeCycleTransition = (transitionId: string, taskId: string): Promise<void> => {
        return expertiseStore.lifeCycleTransition(transitionId, taskId);
    };

    return (
        <Grid container direction="column" wrap="nowrap">
            <Snackbar
                open={planEntryListModel.isAlertOpen}
                autoHideDuration={6000}
                onClose={planEntryListModel.setAlertIsClosed}
            >
                <Alert severity="success">
                    <FormattedMessage id="expertise.updatedTasks" />
                </Alert>
            </Snackbar>
            <Grid item>
                <ExpertiseHeader expertiseModel={expertiseModel} reloadModel={reloadModel} />
            </Grid>
            <TotObjectMain>
                <Grid container spacing={10}>
                    <Grid item container direction="column" spacing={10}>
                        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                            <Grid item>
                                <ServiceInfoPanel
                                    metaInfoModel={expertiseModel.metaInfo}
                                    withForm={false}
                                    elevation={1}
                                />
                            </Grid>
                        </AuthorizationCheck>
                        <Grid item>
                            <ExpertisePlan
                                key={reloadKey}
                                createPath={clientRoute.expertisePlanEntryCreate}
                                editPath={clientRoute.expertisePlanEntryEdit}
                                ownerPagePath={clientRoute.expertise}
                                ownerEntityCode={entities.Expertise}
                                editPermission={permissions.Expertise.EditPlan}
                                model={planEntryListModel}
                            />
                        </Grid>
                        <Grid item>
                            <ExpertiseTasksTable key={reloadKey} model={expertiseTasksListByExpertiseModel} />
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectMain>
            <Grid item>
                <ExpertiseControlPanel
                    key={reloadKey}
                    expertiseId={expertiseModel.id}
                    expertiseTitle={expertiseModel.identifier}
                    deleteExpertise={deleteExpertise}
                    reloadModel={reloadModel}
                    expertiseStore={expertiseStore}
                    onLifeCycleTransition={onLifeCycleTransition}
                />
            </Grid>
        </Grid>
    );
});
