import { Tab, useTheme } from '@material-ui/core';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import clientRoute from '../../../../../clientRoute';
import { RoutedTabs as RoutedTabsInj, RouteSetting } from '../../../../../components';

export const getAdditionalTaskViewTabsInj = (allowed: boolean, id: string): JSX.Element[] => [];
export const getAdditionalTaskViewTabSettingsInj = (): RouteSetting[] => [];

export const defaultTabsSettings: RouteSetting[] = [
    { tab: 'task', path: clientRoute.expertiseTask, exact: true },
    {
        tab: 'subject',
        path: clientRoute.expertiseTaskSubject,
        exact: true,
    },
];

export const renderTabsInj = (
    id: string,
    allowed: boolean,
    additionalTabs: JSX.Element[],
    withSubjectFilesTab: boolean,
    intl: IntlShape,
): JSX.Element[] => {
    return [
        <Tab
            key="task"
            value="task"
            label={intl.formatMessage({ id: 'expertiseTask.tabs.task' })}
            component={NavLink}
            to={generatePath(clientRoute.expertiseTask, { id })}
        />,
        allowed ? (
            <Tab
                key="subject"
                value="subject"
                label={intl.formatMessage({ id: 'expertiseTask.tabs.subject' })}
                component={NavLink}
                to={generatePath(clientRoute.expertiseTaskSubject, { id })}
            />
        ) : (
            <React.Fragment key="subject" />
        ),
        withSubjectFilesTab && allowed ? (
            <Tab
                key="file"
                value="file"
                label={intl.formatMessage({ id: 'expertiseTask.tabs.files' })}
                component={NavLink}
                to={generatePath(clientRoute.expertiseTaskFiles, { id })}
            />
        ) : (
            <React.Fragment key="file" />
        ),
        ...additionalTabs,
    ];
};

export type ExpertiseTaskViewTabButtonsType = {
    allowed: boolean;
    id: string;
};

export const ExpertiseTaskViewTabButtons = observer(
    (props: ExpertiseTaskViewTabButtonsType): JSX.Element => {
        const [RoutedTabs] = di([RoutedTabsInj], ExpertiseTaskViewTabButtons);
        const [getAdditionalTaskViewTabs] = di([getAdditionalTaskViewTabsInj], ExpertiseTaskViewTabButtons);
        const [renderTabs] = di([renderTabsInj], ExpertiseTaskViewTabButtons);
        const [getAdditionalTaskViewTabSettings] = di(
            [getAdditionalTaskViewTabSettingsInj],
            ExpertiseTaskViewTabButtons,
        );

        const [withSubjectFilesTab] = useFeature('withSubjectFilesTab');

        const { allowed, id } = props;
        const theme = useTheme();
        const intl = useIntl();

        const additionalSetting: RouteSetting[] = getAdditionalTaskViewTabSettings();
        const settings: RouteSetting[] = withSubjectFilesTab
            ? defaultTabsSettings.concat(
                  [
                      {
                          tab: 'file',
                          path: clientRoute.expertiseTaskFiles,
                          exact: true,
                      },
                  ],
                  additionalSetting,
              )
            : defaultTabsSettings.concat(additionalSetting);

        const tabs: JSX.Element[] = renderTabs(
            id,
            allowed,
            getAdditionalTaskViewTabs(allowed, id),
            withSubjectFilesTab,
            intl,
        );

        const getTabIndicatorStyle = (): React.CSSProperties => {
            return theme ? { backgroundColor: theme.palette.secondary.dark } : {};
        };

        return (
            <RoutedTabs
                tabsProps={{
                    indicatorColor: 'secondary',
                    textColor: 'secondary',
                    TabIndicatorProps: { style: getTabIndicatorStyle() },
                }}
                settings={settings}
            >
                {tabs}
            </RoutedTabs>
        );
    },
);
