import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { SubjectModel } from '../../../../models';

export type SubjectHeaderTitleProps = {
    title: SubjectModel['title'];
};

export const SubjectHeaderTitle = (props: SubjectHeaderTitleProps): JSX.Element => {
    const { title } = props;
    return (
        <Grid item>
            <Typography variant="h1">{title}</Typography>
        </Grid>
    );
};
