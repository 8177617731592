import { Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../../../../hooks';
import { ErrorIcon as ErrorIconInj } from '../ExpertiseTaskPrintForms.styled';

export type ExpertiseTaskPrintFormsTableErrorTooltip = {
    errorCode: string;
};

export const ExpertiseTaskPrintFormsTableErrorTooltip = observer(
    (props: ExpertiseTaskPrintFormsTableErrorTooltip): JSX.Element => {
        const [ErrorIcon] = di([ErrorIconInj], ExpertiseTaskPrintFormsTableErrorTooltip);

        const { errorCode } = props;
        const { intlStore: intl } = useStore();

        return (
            <Tooltip title={intl.formatMessage(errorCode)}>
                <div>
                    <ErrorIcon>!</ErrorIcon>
                </div>
            </Tooltip>
        );
    },
);
