import { Box, Button, Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { AutocompleteField as AutocompleteFieldInj, ErrorMessage as ErrorMessageInj } from '../../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../../hooks';
import { CreateSubjectModel } from '../../../models';
import { IdTitle, NewSubject, NewSubjectDTO } from '../../../types';

export type CreateSubjectDialogProps = {
    onCancel: () => void;
    onSubmit: (dto: NewSubjectDTO) => Promise<void>;
};

const bannedSymbols = /[\/\\:*?<>|]/g;

export const CreateSubjectDialog = observer((props: CreateSubjectDialogProps): JSX.Element => {
    const [ErrorMessage] = di([ErrorMessageInj], CreateSubjectDialog);
    const [AutocompleteField] = di([AutocompleteFieldInj], CreateSubjectDialog);

    const { onCancel, onSubmit } = props;
    const rootStore = useStore();
    const { subjectStore } = rootStore;
    const intl = useIntl();

    const model = useMemo(() => new CreateSubjectModel(rootStore), [rootStore]);
    const {
        categoryOptions,
        campaignOptions,
        identifier: initialIdentifier,
        error: errorText,
        loadCampaignOptions,
        setError,
    } = model;

    const { Yup } = useYup();
    const schema = Yup.object().shape({
        category: Yup.object()
            .shape({
                title: Yup.string().required(),
            })
            .nullable(),
        campaign: Yup.object().nullable(),
        identifier: Yup.string()
            .required()
            .test(
                '',
                intl.formatMessage({ id: 'validation.bannedSymbols' }, { symbols: '/ \\ : * ? < > |' }),
                (value) => {
                    const clearValue = value ? value.replace(bannedSymbols, '') : '';
                    return value === clearValue;
                },
            ),
    });

    const initialValues: NewSubject = {
        category: {
            id: '',
            title: '',
        },
        campaign: null,
        identifier: initialIdentifier,
    };

    const onCampaignChangeCreator =
        (setFieldValue: FormikHelpers<NewSubject>['setFieldValue']): ((entity: IdTitle) => void) =>
        (entity): void => {
            subjectStore.getSubjectNumber(entity && entity.id).then((identifier) => {
                setFieldValue('identifier', identifier);
            });
        };

    const onSubmitCallback = (values: NewSubject): Promise<void> => {
        const { category, campaign, identifier } = values;
        const dto: NewSubjectDTO = {
            categoryId: category.id,
            identifier,
        };

        if (campaign) {
            dto.campaignId = campaign.id;
        }

        return onSubmit(dto).catch((error) => {
            const errorText = ErrorMessage(error);
            setError(errorText);
        });
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmitCallback);

    return (
        <Dialog maxWidth="xs" fullWidth open={true} scroll="body">
            <Box pt={4} pr={4}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <IconButton onClick={onCancel}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pl={12} pr={12} pb={12}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="subject.newSubject" />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Box pt={8}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue }): JSX.Element => {
                            const onCampaignChange = onCampaignChangeCreator(setFieldValue);
                            return (
                                <Form>
                                    <Grid container spacing={6} direction="column" justify="center">
                                        <Grid item>
                                            <AutocompleteField
                                                fieldName="category"
                                                required={true}
                                                disableClearable={true}
                                                options={categoryOptions}
                                                label={intl.formatMessage({ id: 'subject.fields.category' })}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <AutocompleteField
                                                fieldName="campaign"
                                                options={campaignOptions}
                                                onChange={onCampaignChange}
                                                onOpen={loadCampaignOptions}
                                                label={intl.formatMessage({ id: 'subject.fields.campaign' })}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                disabled={false}
                                                name="identifier"
                                                fullWidth={true}
                                                variant="outlined"
                                                label={intl.formatMessage({ id: 'subject.fields.identifier' })}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="p" color="error" align="center">
                                                {errorText}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                disabled={isSending}
                                                endIcon={endIcon}
                                            >
                                                <FormattedMessage id="subject.createSubject" />
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={onCancel} fullWidth size="large" variant="contained">
                                                <FormattedMessage id="common.cancel" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
            </Box>
        </Dialog>
    );
});
