import { Box, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const TotObjectHeader = withTheme(
    styled(Box)`
        color: ${({ theme }) => theme.palette.secondary.contrastText};
        border-bottom: ${({ theme }) => `1px solid ${theme.variables.palette.hoverInLists}`};
        background-color: ${({ theme }) => theme.palette.primary.contrastText};
        padding: ${({ theme }): string => `${theme.spacing(5)}px 0 ${theme.spacing(5)}px 0`};
    `,
);

export const TotObjectHeaderLabel = withTheme(({ theme, children }: PropsWithChildren<ThemedComponentProps>) => {
    return (
        <Box color={theme?.palette.primary.light}>
            <Typography variant="body1" component="span">
                {children}
            </Typography>
        </Box>
    );
});

export const TotObjectHeaderValue = ({ children }: PropsWithChildren<{}>) => {
    return (
        <Typography variant="body2" component="span" style={{ fontWeight: 'bold' }} noWrap>
            {children}
        </Typography>
    );
};
