import { action, observable } from 'mobx';
import { PfTemplateDTO } from '../store';

export class PfTemplateListModel {
    @observable pfTemplateList: PfTemplateDTO[];

    constructor() {
        this.pfTemplateList = [];
    }

    @action.bound
    load(list: PfTemplateDTO[]): void {
        this.pfTemplateList = list;
    }
}
