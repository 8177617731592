import { Box, Grid, Link } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TotObjectPanelLabel, TotObjectPanelValue } from '../../../components';
import { MetaInfoModel } from '../../../models';
import { getOrElse } from '../../../utils';
import { ExpertiseTaskServiceInfoWrapper } from './ExpertiseTaskServiceInfoWrapper';

export type ExpertiseTaskServiceInfoProps = {
    metaInfoModel: MetaInfoModel;
    withExpansionPanel?: boolean;
};

export const ExpertiseTaskServiceInfo = observer((props: ExpertiseTaskServiceInfoProps): JSX.Element => {
    const { metaInfoModel, withExpansionPanel = true } = props;
    const { formLinks, processLink, modified, author, created, stateTitle } = metaInfoModel;

    return (
        <ExpertiseTaskServiceInfoWrapper withExpansionPanel={withExpansionPanel}>
            <Grid container direction="column">
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <TotObjectPanelLabel>
                                <FormattedMessage id="common.serviceInfoFields.author" />
                            </TotObjectPanelLabel>
                        </Grid>
                        <Grid item>
                            <TotObjectPanelValue>{metaInfoModel.author.name}</TotObjectPanelValue>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box pt={8}>
                        <Grid item container direction="row">
                            <Grid item xs={6}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="common.serviceInfoFields.created" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(created, <FormattedDate value={created} />)}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="common.serviceInfoFields.lastModified" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(modified, <FormattedDate value={modified} />)}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item>
                    <Box pt={8}>
                        <Grid container direction="column">
                            <Grid item>
                                <TotObjectPanelLabel>
                                    <FormattedMessage id="common.serviceInfoFields.lifeCycle" />
                                </TotObjectPanelLabel>
                            </Grid>
                            <Grid item>
                                <TotObjectPanelValue>
                                    {getOrElse(
                                        processLink,
                                        <Link target="_blank" href={processLink?.url}>
                                            {processLink?.label}
                                        </Link>,
                                    )}
                                </TotObjectPanelValue>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item>
                    <Box pt={8}>
                        <Grid container direction="column">
                            <Grid item>
                                <TotObjectPanelLabel>
                                    <FormattedMessage id="common.serviceInfoFields.form" />
                                </TotObjectPanelLabel>
                            </Grid>
                            <Grid item>
                                <TotObjectPanelValue>
                                    {getOrElse(
                                        formLinks,
                                        formLinks.map((item) => (
                                            <Link key={item.url} target="_blank" href={item.url}>
                                                {item.label}
                                            </Link>
                                        )),
                                    )}
                                </TotObjectPanelValue>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </ExpertiseTaskServiceInfoWrapper>
    );
});
