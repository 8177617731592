import {
    Box,
    Button,
    Container,
    createStyles,
    Grid,
    LabelDisplayedRowsArgs,
    LinearProgress,
    Link,
    makeStyles,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { CSSProperties, ReactNode, useEffect, useMemo } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import {
    ActionsButton as ActionsButtonInj,
    AuthorizationCheck as AuthorizationCheckInj,
    DeleteActionMenuItem as DeleteActionMenuItemInj,
    ExportButton as ExportButtonInj,
    MenuButton as MenuButtonInj,
    TotTablePagination as TotTablePaginationInj,
} from '../../components';
import { WithConfirmDialogProps } from '../../hocs';
import { useModal, useStore } from '../../hooks';
import { ExpertiseListModel as ExpertiseListModelInj, ExpertiseRow } from '../../models';
import { ReactComponent as ClearFilter } from '../../resources/images/icons/clear-filter.svg';
import { ExpertiseCreateDialog as ExpertiseCreateDialogInj } from './ExpertiseCreateDialog';
import { ExpertiseListFilterPanel as ExpertiseListFilterPanelInj } from './ExpertiseListFilterPanel';

const tableCellsStyle: CSSProperties = { width: '12.5%' };
const wrappedComponentProps: WithConfirmDialogProps<{ tabIndex: number }>['wrappedComponentProps'] = { tabIndex: 0 };
const clearFilterIcon: JSX.Element = (
    <SvgIcon>
        <ClearFilter />
    </SvgIcon>
);

export const renderExpertiseListHeadInj = (): JSX.Element => {
    return (
        <TableRow>
            <TableCell style={tableCellsStyle}>
                <Typography>
                    <FormattedMessage id="expertise.fields.identifier" />
                </Typography>
            </TableCell>
            <TableCell style={tableCellsStyle}>
                <Typography>
                    <FormattedMessage id="expertise.fields.expertiseSubject" />
                </Typography>
            </TableCell>
            <TableCell style={tableCellsStyle}>
                <Typography>
                    <FormattedMessage id="expertise.fields.expertiseSubjectIdentifier" />
                </Typography>
            </TableCell>
            <TableCell style={tableCellsStyle}>
                <Typography>
                    <FormattedMessage id="expertise.fields.curator" />
                </Typography>
            </TableCell>
            <TableCell style={tableCellsStyle}>
                <Typography>
                    <FormattedMessage id="expertise.fields.campaign" />
                </Typography>
            </TableCell>
            <TableCell style={tableCellsStyle}>
                <Typography>
                    <FormattedMessage id="expertise.fields.deadline" />
                </Typography>
            </TableCell>
            <TableCell style={tableCellsStyle}>
                <Typography>
                    <FormattedMessage id="expertise.fields.state" />
                </Typography>
            </TableCell>
            <TableCell style={tableCellsStyle}>
                <Typography>
                    <FormattedMessage id="expertise.fields.template" />
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export const renderExpertiseListBodyInj = (
    tableModel: ExpertiseListModelInj,
    renderActions: (categoryRow: ExpertiseRow) => JSX.Element,
): JSX.Element[] => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseListPage);

    return tableModel.rows.map((expertise) => {
        const { id, campaign } = expertise;
        return (
            <TableRow key={id} hover>
                <TableCell>
                    <Link component={NavLink} underline="none" to={generatePath(clientRoute.expertise, { id })}>
                        {expertise.identifier}
                    </Link>
                </TableCell>
                <TableCell>
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.subject, { id: expertise.subject.id })}
                    >
                        {expertise.subject.title}
                    </Link>
                </TableCell>
                <TableCell>
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.subject, { id: expertise.subject.id })}
                    >
                        {expertise.subject.number}
                    </Link>
                </TableCell>
                <TableCell>{expertise.curator?.name || ''}</TableCell>
                <TableCell>
                    {campaign && (
                        <Link
                            component={NavLink}
                            underline="none"
                            to={generatePath(clientRoute.campaign, {
                                id: campaign.id,
                            })}
                        >
                            {campaign.title}
                        </Link>
                    )}
                </TableCell>
                <TableCell>{expertise.deadline && <FormattedDate value={expertise.deadline} />}</TableCell>
                <TableCell>{expertise.state}</TableCell>
                <TableCell>
                    {expertise.template && (
                        <Link
                            component={NavLink}
                            underline="none"
                            to={generatePath(clientRoute.templateOfExpertise, { id: expertise.template.id })}
                        >
                            {expertise.template?.title}
                        </Link>
                    )}
                </TableCell>
                <TableCell>
                    <AuthorizationCheck
                        entityCode={entities.Expertise}
                        permCode={permissions.Expertise.Delete}
                        entityId={id}
                    >
                        {renderActions(expertise)}
                    </AuthorizationCheck>
                </TableCell>
            </TableRow>
        );
    });
};

export const ExpertiseListPage = observer((): JSX.Element => {
    const [ExpertiseListModel] = di([ExpertiseListModelInj], ExpertiseListPage);
    const [ExpertiseListFilterPanel] = di([ExpertiseListFilterPanelInj], ExpertiseListPage);
    const [renderExpertiseListHead] = di([renderExpertiseListHeadInj], ExpertiseListPage);
    const [ActionsButton] = di([ActionsButtonInj], ExpertiseListPage);
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], ExpertiseListPage);
    const [ExportButton] = di([ExportButtonInj], ExpertiseListPage);
    const [MenuButton] = di([MenuButtonInj], ExpertiseListPage);
    const [TotTablePagination] = di([TotTablePaginationInj], ExpertiseListPage);
    const [ExpertiseCreateDialog] = di([ExpertiseCreateDialogInj], ExpertiseListPage);
    const [renderExpertiseListBody] = di([renderExpertiseListBodyInj], ExpertiseListPage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseListPage);

    const { api, history, expertiseStore, intlStore } = useStore();
    const intl = useIntl();
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

    const tableModel = useMemo(() => new ExpertiseListModel(api, history), [api, history]);

    useEffect(() => {
        return tableModel.dispose;
    }, [tableModel.dispose]);

    useEffect(() => {
        tableModel.reloadData();
        tableModel.getFilterData();
    }, [tableModel, intlStore.locale]);

    const deleteExpertise = (expertiseRow: ExpertiseRow): (() => Promise<void>) => {
        return (): Promise<void> => {
            return expertiseStore.deleteExpertise(expertiseRow.id).then(tableModel.reloadData);
        };
    };

    const renderActionItems = (expertiseRow: ExpertiseRow): (() => ReactNode[]) => {
        const deleteActionMenuItemTitle = intl.formatMessage({ id: 'common.confirmDeletion' });
        const deleteActionMenuMessage = intl.formatMessage(
            { id: 'expertise.confirmDeletionInfoText' },
            { title: expertiseRow.identifier },
        );

        return (): ReactNode[] => [
            <DeleteActionMenuItem
                id="delete"
                key="delete"
                wrappedComponentProps={wrappedComponentProps}
                title={deleteActionMenuItemTitle}
                message={deleteActionMenuMessage}
                onConfirm={deleteExpertise(expertiseRow)}
            />,
        ];
    };

    const renderActions = (categoryRow: ExpertiseRow): JSX.Element => {
        return (
            <MenuButton
                disablePortal={true}
                renderButton={ActionsButton}
                renderMenuItems={renderActionItems(categoryRow)}
            />
        );
    };

    const filename = `${intl.formatMessage({ id: 'expertise.expertiseListTitle' })}.xlsx`;

    const labelRowsPerPage = intl.formatMessage({ id: 'common.rowsPerPage' });
    const labelDisplayedRows = (displayedRow: LabelDisplayedRowsArgs): string => {
        const paginationInfo = intl.formatMessage(
            { id: 'expertise.pagingInfo' },
            { to: displayedRow.to, from: displayedRow.from, count: displayedRow.count },
        );
        return paginationInfo;
    };

    return (
        <React.Fragment>
            <ExpertiseCreateDialog open={isModalOpen} onClose={setModalIsClosed} />
            <Container maxWidth="lg">
                <Box pt={5} pb={5} width="100%">
                    <Grid container direction="column" spacing={10}>
                        <Grid item container direction="row" justify="space-between">
                            <Grid item>
                                <Typography variant="h1">
                                    <FormattedMessage id="expertise.expertiseListTitle" />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={clearFilterIcon}
                                        onClick={tableModel.clearFilters}
                                    >
                                        <FormattedMessage id="common.resetFilters" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <ExportButton
                                        queryData={tableModel.queryData}
                                        loadRegisterList={expertiseStore.exportListXls}
                                        filename={filename}
                                    />
                                </Grid>
                                <AuthorizationCheck
                                    entityCode={entities.System}
                                    permCode={permissions.System.AddExpertise}
                                >
                                    <Grid item>
                                        <Button color="primary" variant="contained" onClick={setModalIsOpen}>
                                            <FormattedMessage id="expertise.createExpertise" />
                                        </Button>
                                    </Grid>
                                </AuthorizationCheck>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <ExpertiseListFilterPanel model={tableModel} />
                        </Grid>
                        <Grid item xs>
                            <TableContainer component={Paper}>
                                {tableModel.isLoading && <LinearProgress />}
                                <Table>
                                    <TableHead>{renderExpertiseListHead()}</TableHead>
                                    <TableBody>{renderExpertiseListBody(tableModel, renderActions)}</TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TotTablePagination
                                                count={tableModel.rowsCount}
                                                page={tableModel.pageNumber}
                                                onChangePage={tableModel.onChangePage}
                                                onChangeRowsPerPage={tableModel.onChangePageSize}
                                                rowsPerPage={tableModel.pageSize}
                                                rowsPerPageOptions={tableModel.pageSizeOptions}
                                                labelRowsPerPage={labelRowsPerPage}
                                                labelDisplayedRows={labelDisplayedRows}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
});
