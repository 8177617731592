import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useYup = (): { Yup: typeof Yup } => {
    const intl = useIntl();
    const required = intl.formatMessage({ id: 'validation.required' });
    const validEmail = intl.formatMessage({ id: 'validation.validEmail' });
    Yup.setLocale({
        mixed: {
            required: required,
        },
        array: {
            min: required,
        },
        string: {
            email: validEmail,
            required: required,
        },
    });

    return { Yup };
};
