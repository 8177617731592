import { action } from 'mobx';
import { ChangeEvent } from 'react';
import { Filter } from '../Filter';

export type CheckFilterValue = boolean;

export class CheckFilter extends Filter<CheckFilterValue> {
    constructor(defaultValue = false) {
        super(defaultValue);
    }

    checkActive(value: CheckFilterValue): boolean {
        return value;
    }

    @action.bound
    onChange(e: ChangeEvent<HTMLInputElement>): void {
        this.value = e.target.checked;
    }

    @action.bound
    clear(): void {
        this.value = false;
    }

    get asJson(): boolean {
        return this.value;
    }
}
