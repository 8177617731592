import { ExpansionPanel, Grid, Link, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelLabel as TotObjectPanelLabelInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
    TotObjectPanelValue as TotObjectPanelValueInj,
} from '../../../components';
import { SubjectModel } from '../../../models';
import { getOrElse } from '../../../utils';

export type SubjectServiceInfoProps = {
    subjectModel: SubjectModel;
};

export const SubjectServiceInfo = observer((props: SubjectServiceInfoProps): JSX.Element => {
    const [TotObjectPanelValue] = di([TotObjectPanelValueInj], SubjectServiceInfo);
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], SubjectServiceInfo);
    const [TotObjectPanelLabel] = di([TotObjectPanelLabelInj], SubjectServiceInfo);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], SubjectServiceInfo);

    const { subjectModel } = props;
    const { metaInfo, categoryTitle } = subjectModel;
    const { processLink, formLinks, modified, author } = metaInfo;

    return (
        <ExpansionPanel defaultExpanded elevation={0}>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="subject.blocks.serviceInfo" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container direction="row">
                        <Grid item xs={8}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.author" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>{author.name}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.lastModified" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(modified, <FormattedDate value={modified} />)}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.category" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>{getOrElse(categoryTitle)}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.lifecycle" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            processLink,
                                            <Link target="_blank" href={processLink?.url}>
                                                {processLink?.label}
                                            </Link>,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row">
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="subject.fields.form" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            formLinks,
                                            formLinks.map((item) => {
                                                return (
                                                    <Link key={item.url} target="_blank" href={item.url}>
                                                        {item.label}
                                                    </Link>
                                                );
                                            }),
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
});
