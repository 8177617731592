import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { action, observable } from 'mobx';
import { Filter } from '../Filter';

export type DateRangeFilterValue = {
    from: Date | null;
    to: Date | null;
};

export type DateRangeJsonFilterValue = { from?: string; to?: string };

export class DateRangeFilter extends Filter<DateRangeFilterValue> {
    constructor(defaultValue: DateRangeFilterValue = { from: null, to: null }) {
        super(defaultValue);
    }

    checkActive(value: DateRangeFilterValue): boolean {
        return !!value.from || !!value.to;
    }

    get asJson(): DateRangeJsonFilterValue {
        return {
            from: this.value.from ? formatISO(startOfDay(this.value.from)) : undefined,
            to: this.value.to ? formatISO(endOfDay(this.value.to)) : undefined,
        };
    }

    @action.bound
    onFromChange(date: Date | null): void {
        this.value.from = date;
    }

    @action.bound
    onToChange(date: Date | null): void {
        this.value.to = date;
    }

    @action.bound
    onChange(date: DateRangeFilterValue): void {
        if (date.to) {
            this.value.to = new Date(date.to);
        }

        if (date.from) {
            this.value.from = new Date(date.from);
        }
    }

    @action.bound
    clear(): void {
        this.value = observable({ from: null, to: null });
    }
}
