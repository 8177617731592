import React from 'react';
import { di } from 'react-magnetic-di';
import { Route } from 'react-router-dom';
import { getExpertiseTaskViewRoutes as getExpertiseTaskViewRoutesInj } from '../../utils';
import { ExpertiseTaskViewPage as ExpertiseTaskViewPageInj } from './expertise-task-page';

export const ExpertiseTaskViewRoutesPages = (withSubjectFilesTab: boolean): JSX.Element => {
    const [ExpertiseTaskViewPage] = di([ExpertiseTaskViewPageInj], ExpertiseTaskViewRoutesPages);
    const [getExpertiseTaskViewRoutes] = di([getExpertiseTaskViewRoutesInj], ExpertiseTaskViewRoutesPages);

    const taskViewRoutes = getExpertiseTaskViewRoutes(withSubjectFilesTab);

    return (
        <Route path={taskViewRoutes} key={taskViewRoutes[0]} exact>
            <ExpertiseTaskViewPage />
        </Route>
    );
};
