import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    FormApi,
    FormioSidebarStore,
    FormModel,
    FormView,
    MultiLangFormEdit,
    MultiLangFormEditProps,
    validateEditPage,
} from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useStore } from '../../hooks';
import { AuthorizationCheckQuery } from '../../store';
import { AuthorizedRoute as AuthorizedRouteInj } from '../AuthorizedRoute';
import { AlertLink as AlertLinkInj } from '../links';

type TotObjectFormRouteParams = {
    id: string;
};

export type TotObjectFormProps = {
    formName: string;
    model: FormModel;
    formioSidebarStore?: FormioSidebarStore;

    editPath: string;
    readPath: string;
    createPath: string;
    editAuthProps: AuthorizationCheckQuery;

    showReadonlyValidation: boolean;

    onFormReady(form: FormApi): void;
    hideReadonlyValidation(): void;

    reloadKey: number;
};

export const TotObjectForm = observer(
    (props: TotObjectFormProps): JSX.Element => {
        const [AlertLink] = di([AlertLinkInj], TotObjectForm);
        const [AuthorizedRoute] = di([AuthorizedRouteInj], TotObjectForm);

        const {
            editPath,
            createPath,
            readPath,
            showReadonlyValidation,
            model,
            editAuthProps,
            reloadKey,
            formName,
            onFormReady,
            hideReadonlyValidation,
        } = props;
        const { formioSidebarStore, intlStore } = useStore();
        const { locale } = intlStore;
        const [formApi, setFormApi] = useState<FormApi>();
        const history = useHistory();
        const { id } = useParams<TotObjectFormRouteParams>();

        const onFormReadyCallback = (form: FormApi): void => {
            setFormApi(form);
            formioSidebarStore && formioSidebarStore.initSidebarItems(formName, form.form);
            validateAfterReadonly();

            onFormReady(form);
        };

        const onFormChange: MultiLangFormEditProps['onFormChange'] = (form): void => {
            formioSidebarStore && formioSidebarStore.updateItemsVisibility(formName, form);
        };

        const goToEditPageAndValidate = (): void => {
            history.push(generatePath(editPath, { id }));
        };

        const renderLink = (...chunks: string[]): JSX.Element => {
            return (
                <AlertLink onClick={goToEditPageAndValidate} href="#" style={{ display: 'inline' }}>
                    {chunks}
                </AlertLink>
            );
        };

        const renderAlert = (): JSX.Element => {
            return (
                <Box mb={5}>
                    <Alert elevation={0} variant="filled" severity="error">
                        <FormattedMessage
                            id="common.readonlyValidationMsg"
                            values={{
                                a: renderLink,
                            }}
                        />
                    </Alert>
                </Box>
            );
        };

        const validateAfterReadonly = (): void => {
            // Этот метод вызывается, когда после валидации в текстовом виде переходишь в режим редактирования.
            // Это улучшение по сравнению с демо стендом, т.к. в редактирование в данном случае переходят как раз для того,
            // чтобы исправить ошибки
            if (showReadonlyValidation) {
                validateEditPage(formApi, formioSidebarStore, formName).finally(() => {
                    hideReadonlyValidation();
                });
            }
        };

        return (
            <Switch>
                <AuthorizedRoute path={[editPath, createPath]} key={editPath} exact {...editAuthProps}>
                    <MultiLangFormEdit
                        intlStore={intlStore}
                        form={model}
                        onFormReady={onFormReadyCallback}
                        onFormChange={onFormChange}
                    />
                </AuthorizedRoute>
                <Route path={readPath} key={`${readPath}_${reloadKey}`}>
                    {showReadonlyValidation && renderAlert()}
                    <FormView locale={locale} form={model} onFormReady={onFormReadyCallback} multiLang={true} />
                </Route>
            </Switch>
        );
    },
);
