import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField as MUITextField,
} from '@material-ui/core';
import { Field, Form, FormikProps, FormikValues } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RequiredLabel as RequiredLabelInj } from '../../../../components';
import { CodeTitle } from '../../../../models';

const roles = 'roles';

const getAutocompleteOptionLabel = (option: CodeTitle): string => {
    return option.title;
};

const getAutocompleteOptionSelected = (option: CodeTitle, value: CodeTitle): boolean => {
    return option.code === value.code;
};

const renderInput = (formik: FormikProps<FormikValues>) => {
    return function AutocompleteInput(params: AutocompleteRenderInputParams): JSX.Element {
        const intl = useIntl();
        const [RequiredLabel] = di([RequiredLabelInj], AutocompleteInput);

        const touchedRoles = formik.touched[roles];
        const errorsRoles = formik.errors[roles];

        const error = touchedRoles && !!errorsRoles;
        const helperText = touchedRoles && errorsRoles;

        const textFieldLabel = <RequiredLabel text={intl.formatMessage({ id: 'users.fields.roles' })} />;

        return (
            <MUITextField
                {...params}
                name={roles}
                error={error}
                helperText={helperText}
                label={textFieldLabel}
                variant="outlined"
            />
        );
    };
};

export const userAddForm = (open: boolean, userRoleList: CodeTitle[], setModalIsClosed: () => void) => {
    return function UserAddFormikForm(formik: FormikProps<FormikValues>): JSX.Element {
        const intl = useIntl();
        const [RequiredLabel] = di([RequiredLabelInj], UserAddFormikForm);

        const checkBoxLabel = { label: intl.formatMessage({ id: 'users.fields.lang' }) };

        const autoCompleteInput = renderInput(formik);

        const emailLabel = <RequiredLabel text={intl.formatMessage({ id: 'authentication.email' })} />;
        const lastNameLabel = <RequiredLabel text={intl.formatMessage({ id: 'authentication.lastName' })} />;
        const firstNameLabel = <RequiredLabel text={intl.formatMessage({ id: 'authentication.firstName' })} />;

        return (
            <Dialog open={open} fullWidth>
                <DialogTitle>
                    <FormattedMessage id="users.addUser" />
                </DialogTitle>
                <Form>
                    <DialogContent>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Field
                                    fullWidth
                                    name="email"
                                    component={TextField}
                                    variant="outlined"
                                    label={emailLabel}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    multiple
                                    name="roles"
                                    component={Autocomplete}
                                    options={userRoleList}
                                    getOptionSelected={getAutocompleteOptionSelected}
                                    getOptionLabel={getAutocompleteOptionLabel}
                                    renderInput={autoCompleteInput}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    fullWidth
                                    name="lastName"
                                    component={TextField}
                                    variant="outlined"
                                    label={lastNameLabel}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    fullWidth
                                    name="firstName"
                                    component={TextField}
                                    variant="outlined"
                                    label={firstNameLabel}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    fullWidth
                                    name="middleName"
                                    component={TextField}
                                    variant="outlined"
                                    label={intl.formatMessage({ id: 'authentication.middleName' })}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="englishUser"
                                    Label={checkBoxLabel}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    fullWidth
                                    name="enLastName"
                                    component={TextField}
                                    variant="outlined"
                                    label={intl.formatMessage({ id: 'authentication.lastName' })}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    fullWidth
                                    name="enFirstName"
                                    component={TextField}
                                    variant="outlined"
                                    label={intl.formatMessage({ id: 'authentication.firstName' })}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    fullWidth
                                    name="enMiddleName"
                                    component={TextField}
                                    variant="outlined"
                                    label={intl.formatMessage({ id: 'authentication.middleName' })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={setModalIsClosed}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                        <Button color="primary" variant="contained" type="submit">
                            <FormattedMessage id="users.register" />
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        );
    };
};
