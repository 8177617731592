import { Box, Container, Grid, withTheme } from '@material-ui/core';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import React, { PropsWithChildren } from 'react';

export const TotObjectMain = withTheme((props: PropsWithChildren<ThemedComponentProps>) => {
    return (
        <Grid item style={{ flexGrow: 1, width: '100%' }}>
            <Box bgcolor={props.theme?.variables.palette.hoverInLists} height="100%">
                <Container maxWidth="lg">
                    <Box pt={10} pb={10}>
                        {props.children}
                    </Box>
                </Container>
            </Box>
        </Grid>
    );
});
