import { TableCell, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExpertiseTaskListModel } from '../../../models';
import { expertiseTaskNumberWidthCell, expertiseTaskWidthCell } from './ExpertiseTaskListOldTable';

export type ExpertiseTaskListOldHeaderTableProps = {
    tableModel: ExpertiseTaskListModel;
};

export const ExpertiseTaskListOldHeaderTable = observer((props: ExpertiseTaskListOldHeaderTableProps): JSX.Element => {
    const { tableModel } = props;
    const { sorting } = tableModel;
    return (
        <TableRow>
            <TableCell style={expertiseTaskNumberWidthCell}>
                <TableSortLabel
                    active={sorting.identifier.isActive}
                    direction={sorting.identifier.direction}
                    onClick={tableModel.changeSorting(sorting.identifier)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="expertiseTask.fields.number" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell style={expertiseTaskWidthCell}>
                <Typography>
                    <FormattedMessage id="expertiseTask.fields.taskType" />
                </Typography>
            </TableCell>
            <TableCell style={expertiseTaskWidthCell}>
                <Typography>
                    <FormattedMessage id="expertiseTask.fields.expert" />
                </Typography>
            </TableCell>
            <TableCell style={expertiseTaskWidthCell}>
                <TableSortLabel
                    active={sorting.expertise.isActive}
                    direction={sorting.expertise.direction}
                    onClick={tableModel.changeSorting(sorting.expertise)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="expertiseTask.fields.expertiseNumber" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell style={expertiseTaskWidthCell}>
                <Typography>
                    <FormattedMessage id="expertiseTask.fields.curator" />
                </Typography>
            </TableCell>

            <TableCell style={expertiseTaskWidthCell}>
                <TableSortLabel
                    active={sorting.created.isActive}
                    direction={sorting.created.direction}
                    onClick={tableModel.changeSorting(sorting.created)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="expertiseTask.fields.created" />
                    </Typography>
                </TableSortLabel>
            </TableCell>

            <TableCell style={expertiseTaskWidthCell}>
                <TableSortLabel
                    active={sorting.deadline.isActive}
                    direction={sorting.deadline.direction}
                    onClick={tableModel.changeSorting(sorting.deadline)}
                    IconComponent={ExpandMoreIcon}
                >
                    <Typography>
                        <FormattedMessage id="expertiseTask.fields.deadline" />
                    </Typography>
                </TableSortLabel>
            </TableCell>
            <TableCell style={expertiseTaskWidthCell}>
                <Typography>
                    <FormattedMessage id="expertiseTask.fields.state" />
                </Typography>
            </TableCell>
        </TableRow>
    );
});
