import { Grid } from '@material-ui/core';
import { FormApi } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    ErrorDialog as ErrorDialogInj,
    ErrorMessage as ErrorMessageInj,
    ServiceInfoPanel as ServiceInfoPanelInj,
    TotObjectForm as TotObjectFormInj,
    TotObjectMain as TotObjectMainInj,
} from '../../components';
import { maxWidth100, pageGridContainerStyle } from '../../constants';
import { useError, useReload, useStore } from '../../hooks';
import { AuthorizationCheckQuery } from '../../store';
import { CampaignControlPanel as CampaignControlPanelInj } from './CampaignControlPanel';
import { CampaignExpertisesPanel as CampaignExpertisesPanelInj } from './CampaignExpertisesPanel';
import { CampaignHeader as CampaignHeaderInj } from './CampaignHeader';
import { CampaignSubjectsPanel as CampaignSubjectsPanelInj } from './CampaignSubjectsPanel';
import { CampaignTasksPanel as CampaignTasksPanelInj } from './CampaignTasksPanel';

export type CampaignPageRouteParams = {
    id: string;
};

export const CampaignPage = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], CampaignPage);
    const [ErrorDialog] = di([ErrorDialogInj], CampaignPage);
    const [ErrorMessage] = di([ErrorMessageInj], CampaignPage);
    const [ServiceInfoPanel] = di([ServiceInfoPanelInj], CampaignPage);
    const [TotObjectForm] = di([TotObjectFormInj], CampaignPage);
    const [TotObjectMain] = di([TotObjectMainInj], CampaignPage);
    const [CampaignControlPanel] = di([CampaignControlPanelInj], CampaignPage);
    const [CampaignExpertisesPanel] = di([CampaignExpertisesPanelInj], CampaignPage);
    const [CampaignHeader] = di([CampaignHeaderInj], CampaignPage);
    const [CampaignSubjectsPanel] = di([CampaignSubjectsPanelInj], CampaignPage);
    const [CampaignTasksPanel] = di([CampaignTasksPanelInj], CampaignPage);

    const { id } = useParams<CampaignPageRouteParams>();
    const { reloadKey, reloadIncrement } = useReload();
    const history = useHistory();
    const location = useLocation();

    const { campaignStore, intlStore } = useStore();
    const campaignModel = useMemo(() => campaignStore.getCampaignModel(id), [id]);
    const campaignStatisticModel = useMemo(() => campaignStore.getCampaignStatisticModel(id), [id]);
    const campaignLinksModel = useMemo(() => campaignStore.getCampaignLinksModel(id), [id]);
    const [formApi, setFormApi] = useState<FormApi>();
    const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();

    const { subjects, expertises, tasks } = campaignStatisticModel;
    const { lifeCycleTransition } = campaignStore;

    const updateCard = (): void => {
        campaignStore.loadCampaign(id).then(campaignModel.loadCard);
    };

    const updateCampaignStatistic = (): void => {
        campaignStore.getCampaignStatistic(id).then(campaignStatisticModel.load);
    };

    useEffect(() => {
        updateCard();
    }, [intlStore.locale, reloadKey]);

    useEffect(() => {
        if (formApi) {
            formApi.setSubmissionFromStartSubmission();
        }
    }, [location.pathname]);

    const deleteCampaign = useCallback((): Promise<void> => {
        return campaignStore.deleteCampaign(campaignModel.id).then(() => {
            history.push(generatePath(clientRoute.campaigns));
        });
    }, [campaignStore.deleteCampaign, campaignModel.id]);

    const validateForm = (onSuccess?: () => Promise<void>): Promise<void> => {
        const isFormValid = (formApi && formApi.validate()) || false;
        if (isFormValid) {
            return onSuccess ? onSuccess() : Promise.resolve();
        }
        return Promise.reject();
    };

    const onLifeCycleTransition = useCallback(
        (transitionId: string, campaignId: string, validate?: boolean): Promise<void> => {
            const onSuccess = () => lifeCycleTransition(transitionId, campaignId, reloadIncrement);
            if (validate) {
                return validateForm(onSuccess);
            } else {
                return onSuccess();
            }
        },
        [lifeCycleTransition, validateForm, reloadIncrement],
    );

    const onFormReady = useCallback(
        (form: FormApi): void => {
            setFormApi(form);
        },
        [setFormApi],
    );

    const setCommonValidationState = useCallback(
        (state: boolean): void => {
            campaignStore.showValidation = state;
        },
        [campaignStore.showValidation],
    );

    const onSubmit = useCallback((): void => {
        if (formApi && formApi.validate()) {
            formApi
                .submit(false)
                .then(() => {
                    return campaignStore.editCampaign(id, formApi.getSubmissionWithAdditionalInfo());
                })
                .then(() => {
                    formApi.setStartSubmission();
                    reloadIncrement();
                    history.push(generatePath(clientRoute.campaign, { id }));
                })
                .catch((error) => {
                    const errorText = ErrorMessage(error);
                    setErrorIsOpen(errorText);
                });
        }
    }, [formApi, campaignStore.editCampaign, id, history, generatePath]);

    const handleHideReadonlyValidation = (): void => {
        setCommonValidationState(false);
    };

    const editAuthProps: AuthorizationCheckQuery = {
        entityCode: entities.ExpertiseCampaign,
        permCode: permissions.ExpertiseCampaign.Edit,
        entityId: id,
    };

    return (
        <React.Fragment>
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />

            <Grid container direction="column" wrap="nowrap" style={pageGridContainerStyle}>
                <Grid item>
                    <CampaignHeader campaignModel={campaignModel} />
                </Grid>
                <TotObjectMain>
                    <Grid item container direction="column" spacing={6}>
                        <Grid item>
                            <AuthorizationCheck
                                entityCode={entities.System}
                                permCode={permissions.System.Administration}
                            >
                                <ServiceInfoPanel
                                    metaInfoModel={campaignModel.metaInfo}
                                    withForm={false}
                                    elevation={1}
                                />
                            </AuthorizationCheck>
                        </Grid>

                        <Grid item style={maxWidth100}>
                            <TotObjectForm
                                reloadKey={reloadKey}
                                formName="template"
                                model={campaignModel.formModel}
                                editPath={clientRoute.campaignEdit}
                                createPath={clientRoute.campaignCreate}
                                readPath={clientRoute.campaign}
                                onFormReady={onFormReady}
                                showReadonlyValidation={campaignStore.showValidation}
                                hideReadonlyValidation={handleHideReadonlyValidation}
                                editAuthProps={editAuthProps}
                            />
                        </Grid>

                        <Route path={clientRoute.campaign} exact>
                            <Grid item>
                                <CampaignSubjectsPanel subjects={subjects} links={campaignLinksModel.subjects} />
                            </Grid>
                            <Grid item>
                                <CampaignExpertisesPanel
                                    expertises={expertises}
                                    links={campaignLinksModel.expertises}
                                />
                            </Grid>
                            <Grid item>
                                <CampaignTasksPanel tasks={tasks} links={campaignLinksModel.tasks} />
                            </Grid>
                        </Route>
                    </Grid>
                </TotObjectMain>

                <CampaignControlPanel
                    key={reloadKey}
                    updateCampaignStatistic={updateCampaignStatistic}
                    campaignTitle={campaignModel.title}
                    campaignIdentifier={campaignModel.identifier}
                    deleteCampaign={deleteCampaign}
                    getCampaignTransitions={campaignStore.getLifeCycleTransitions}
                    lifeCycleTransition={onLifeCycleTransition}
                    onSubmit={onSubmit}
                />
            </Grid>
        </React.Fragment>
    );
});
