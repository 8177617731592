import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Redirect, Route, Switch } from 'react-router-dom';
import { entities, permissions } from './authSchemeConfig';
import clientRoute from './clientRoute';
import {
    AppContentContainer as AppContentContainerInj,
    AppContentScreenSizeContainer as AppContentScreenSizeContainerInj,
    Authentication as AuthenticationInj,
    AuthorizationCheck as AuthorizationCheckInj,
    Footer as FooterInj,
    NotificationComponent as NotificationComponentInj,
    RedirectByRole as RedirectByRoleInj,
    TotAppBar as TotAppBarInj,
} from './components';
import {
    AuthenticationInfoPage as AuthenticationInfoPageInj,
    CampaignPages as CampaignPagesInj,
    CategoriesOfSubjectsPages as CategoriesOfSubjectsPagesInj,
    ConsolePage as ConsolePageInj,
    ErrorPage as ErrorPageInj,
    EsiaPages as EsiaPagesInj,
    ExpertisePages as ExpertisePagesInj,
    ExpertiseTaskListOldPage as ExpertiseTaskListOldPageInj,
    ExpertiseTasksPages as ExpertiseTasksPagesInj,
    ExpertiseTaskViewRoutesPages as ExpertiseTaskViewRoutesPagesInj,
    HomePage as HomePageInj,
    LoginPage as LoginPageInj,
    NewPasswordPage as NewPasswordPageInj,
    PfTemplateListPage as PfTemplateListPageInj,
    RecoveryPasswordPage as RecoveryPasswordPageInj,
    RegistrationConfirmPage as RegistrationConfirmPageInj,
    RegistrationPage as RegistrationPageInj,
    StartPage as StartPageInj,
    SubjectPages as SubjectPagesInj,
    SyslogPage as SyslogPageInj,
    TemplatesOfExpertisePages as TemplatesOfExpertisePagesInj,
    UserPages as UserPagesInj,
} from './pages';
import { getExpertiseTaskViewRoutes as getExpertiseTaskViewRoutesInj } from './utils';

export const LeadExpertExpertiseTaskViewPageInj = (): JSX.Element => <React.Fragment />;

export const getLeadExpertExpertiseTaskRoutesInj = (): string[] => [];

export const App = observer((): JSX.Element => {
    const [ExpertiseTasksPages] = di([ExpertiseTasksPagesInj], App);
    const [getExpertiseTaskViewRoutes] = di([getExpertiseTaskViewRoutesInj], App);
    const [CampaignPages] = di([CampaignPagesInj], App);
    const [ErrorPage] = di([ErrorPageInj], App);
    const [ExpertisePages] = di([ExpertisePagesInj], App);
    const [ExpertiseTaskListOldPage] = di([ExpertiseTaskListOldPageInj], App);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], App);
    const [ConsolePage] = di([ConsolePageInj], App);
    const [HomePage] = di([HomePageInj], App);
    const [LoginPage] = di([LoginPageInj], App);
    const [NewPasswordPage] = di([NewPasswordPageInj], App);
    const [PfTemplateListPage] = di([PfTemplateListPageInj], App);
    const [RegistrationPage] = di([RegistrationPageInj], App);
    const [StartPage] = di([StartPageInj], App);
    const [SubjectPages] = di([SubjectPagesInj], App);
    const [ExpertiseTaskViewRoutesPages] = di([ExpertiseTaskViewRoutesPagesInj], App);
    const [AuthenticationInfoPage] = di([AuthenticationInfoPageInj], App);
    const [EsiaPages] = di([EsiaPagesInj], App);
    const [TemplatesOfExpertisePages] = di([TemplatesOfExpertisePagesInj], App);
    const [UserPages] = di([UserPagesInj], App);
    const [CategoriesOfSubjectsPages] = di([CategoriesOfSubjectsPagesInj], App);
    const [NotificationComponent] = di([NotificationComponentInj], App);
    const [RegistrationConfirmPage] = di([RegistrationConfirmPageInj], App);
    const [RecoveryPasswordPage] = di([RecoveryPasswordPageInj], App);
    const [AppContentContainer] = di([AppContentContainerInj], App);
    const [AppContentScreenSizeContainer] = di([AppContentScreenSizeContainerInj], App);
    const [SyslogPage] = di([SyslogPageInj], App);
    const [Authentication] = di([AuthenticationInj], App);
    const [Footer] = di([FooterInj], App);
    const [TotAppBar] = di([TotAppBarInj], App);
    const [RedirectByRole] = di([RedirectByRoleInj], App);

    const [startPage] = useFeature('startPage');
    const [startPageWithLogin] = useFeature('startPageWithLogin');
    const [withSubjectFilesTab] = useFeature('withSubjectFilesTab');

    const taskViewRoutes = getExpertiseTaskViewRoutes(withSubjectFilesTab);

    return (
        <Switch>
            <Route path={taskViewRoutes} key={taskViewRoutes[0]} exact>
                <AppContentScreenSizeContainer>
                    <TotAppBar />
                    <NotificationComponent />
                    <Authentication>{ExpertiseTaskViewRoutesPages(withSubjectFilesTab)}</Authentication>
                </AppContentScreenSizeContainer>
            </Route>
            {startPageWithLogin && (
                <Route path={clientRoute.login} exact>
                    <AppContentContainer>
                        <HomePage />
                    </AppContentContainer>
                </Route>
            )}
            <Route path={[clientRoute.notFound, clientRoute.notAllowed]}>
                <Authentication>
                    <TotAppBar withInstructions={false} />
                    <AppContentContainer>
                        <Switch>
                            <Route path={clientRoute.notFound} exact>
                                <ErrorPage
                                    errorCode="404"
                                    errorText={<FormattedMessage id="notFoundPage.notExist" />}
                                />
                            </Route>
                            <Route path={clientRoute.notAllowed} exact>
                                <ErrorPage
                                    errorCode="403"
                                    errorText={<FormattedMessage id="common.actionNotAllowed" />}
                                />
                            </Route>
                        </Switch>
                    </AppContentContainer>
                    <Footer />
                </Authentication>
            </Route>
            <Route>
                <TotAppBar />
                <NotificationComponent />
                <AppContentContainer>
                    <Switch>
                        <Route path={clientRoute.esia}>
                            <EsiaPages />
                        </Route>
                        <Route path={clientRoute.newPassword}>
                            <NewPasswordPage />
                        </Route>
                        <Route path={clientRoute.recoveryPassword}>
                            <RecoveryPasswordPage />
                        </Route>
                        <Route path={clientRoute.authenticationInfo}>
                            <AuthenticationInfoPage />
                        </Route>
                        <Route path={clientRoute.registrationConfirm}>
                            <RegistrationConfirmPage />
                        </Route>
                        <Route path={clientRoute.registration}>
                            <RegistrationPage />
                        </Route>
                        {!startPage && (
                            <Route path={clientRoute.root} exact>
                                <Authentication>
                                    <RedirectByRole />
                                </Authentication>
                            </Route>
                        )}
                        <Route path={[clientRoute.root, clientRoute.login]} exact>
                            {startPage && <StartPage />}
                            {!startPageWithLogin && (
                                <Route path={clientRoute.login} exact>
                                    <LoginPage />
                                </Route>
                            )}
                        </Route>
                        <Authentication>
                            <Switch>
                                <Route path={clientRoute.campaigns}>
                                    <CampaignPages />
                                </Route>
                                <Route path={clientRoute.subjects}>
                                    <SubjectPages />
                                </Route>
                                <Route path={clientRoute.categories}>
                                    <CategoriesOfSubjectsPages />
                                </Route>
                                <Route path={clientRoute.templatesOfExpertise}>
                                    <TemplatesOfExpertisePages />
                                </Route>
                                <Route path={clientRoute.expertiseTasksOld} exact>
                                    <ExpertiseTaskListOldPage />
                                </Route>
                                <Route path={clientRoute.expertiseTasks}>
                                    <ExpertiseTasksPages />
                                </Route>
                                <Route path={clientRoute.expertiseList}>
                                    <ExpertisePages />
                                </Route>
                                <Route path={clientRoute.users}>
                                    <UserPages />
                                </Route>

                                <Route path={clientRoute.console}>
                                    <AuthorizationCheck
                                        entityCode={entities.System}
                                        permCode={permissions.System.Administration}
                                        isWithRedirect={true}
                                    >
                                        <ConsolePage />
                                    </AuthorizationCheck>
                                </Route>
                                <Route path={clientRoute.pfTemplateList}>
                                    <PfTemplateListPage />
                                </Route>
                                <Route path={clientRoute.syslog}>
                                    <SyslogPage />
                                </Route>
                                <Route path={clientRoute.expertiseTasksForCurator}>
                                    <Redirect to={clientRoute.expertiseTasks} />
                                </Route>
                                <Route>
                                    <Redirect to={clientRoute.notFound} />
                                </Route>
                            </Switch>
                        </Authentication>
                        <Route>
                            <Redirect to={clientRoute.notFound} />
                        </Route>
                    </Switch>
                </AppContentContainer>
                <Footer />
            </Route>
        </Switch>
    );
});
