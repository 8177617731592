import { FormDTO, FormModel } from '@platform/formiojs-react';
import { action, observable } from 'mobx';
import { IdTitle } from '../../types';
import { MetaInfoDTO, MetaInfoModel } from '../MetaInfo';

export interface SubjectDTO {
    id: string;
    title: string;
    identifier: string;
    externalId?: string;
    category: IdTitle;
    metaInfo: MetaInfoDTO;
    commonFormInfo: FormDTO;
    hiddenFormInfo?: FormDTO;
    campaign?: IdTitle;
    expertise?: IdTitle;
}

export class SubjectModel {
    @observable id = '';
    @observable title = '';
    @observable identifier = '';
    @observable categoryTitle = '';
    @observable campaign?: IdTitle;
    @observable expertise?: IdTitle;
    @observable commonFormModel: FormModel;
    @observable hiddenFormModel: FormModel;
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();

    constructor(id: string) {
        this.id = id;
        this.commonFormModel = new FormModel(id);
        this.hiddenFormModel = new FormModel(id);
    }

    @action.bound
    load(dto: SubjectDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.categoryTitle = dto.category?.title;
        dto.commonFormInfo && this.commonFormModel.load(dto.commonFormInfo);
        dto.metaInfo && this.metaInfo.load(dto.metaInfo);

        const { campaign, expertise, hiddenFormInfo } = dto;
        campaign && (this.campaign = campaign);
        expertise && (this.expertise = expertise);
        hiddenFormInfo && this.hiddenFormModel.load(hiddenFormInfo);
    }

    @action.bound
    loadCard(dto: SubjectDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.identifier = dto.identifier;
        this.categoryTitle = dto.category?.title;
        this.metaInfo.load(dto.metaInfo);

        const { campaign, expertise } = dto;
        campaign && (this.campaign = campaign);
        expertise && (this.expertise = expertise);
    }
}
