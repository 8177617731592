import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useLocation } from 'react-router-dom';
import {
    ClearFilterButton as ClearFilterButtonInj,
    Select as SelectInj,
    TotObjectFilterPanelDetails as TotObjectFilterPanelDetailsInj,
    TotObjectFilterPanelSummary as TotObjectFilterPanelSummaryInj,
} from '../../components';
import { AutocompleteProps, pickerProps, textFieldProps } from '../../constants';
import { useDateBoundaries } from '../../hooks';
import { ExpertiseListModel } from '../../models';
import { setFiltersFromUrl } from '../../utils';

export type ExpertiseListFilterPanelProps = {
    model: ExpertiseListModel;
};

export const ExpertiseListFilterPanel = observer((props: ExpertiseListFilterPanelProps): JSX.Element => {
    const [ClearFilterButton] = di([ClearFilterButtonInj], ExpertiseListFilterPanel);
    const [Select] = di([SelectInj], ExpertiseListFilterPanel);
    const [TotObjectFilterPanelDetails] = di([TotObjectFilterPanelDetailsInj], ExpertiseListFilterPanel);
    const [TotObjectFilterPanelSummary] = di([TotObjectFilterPanelSummaryInj], ExpertiseListFilterPanel);

    const { model } = props;
    const { filtering } = model;
    const intl = useIntl();
    const location = useLocation();

    useEffect(() => {
        setFiltersFromUrl(location, filtering);
    }, [location]);

    const deadlineDate = {
        dateTo: filtering.deadline.value.to,
        dateFrom: filtering.deadline.value.from,
    };

    const { dateTo } = deadlineDate;
    const deadlineDateBoundaries = useDateBoundaries(deadlineDate);

    return (
        <ExpansionPanel>
            <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="common.filters" />
                </Typography>
            </TotObjectFilterPanelSummary>
            <TotObjectFilterPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="expertise.fields.identifier" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.identifier.value}
                                onChange={filtering.identifier.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="expertise.fields.expertiseSubject" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.subjectTitle.value}
                                onChange={filtering.subjectTitle.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="expertise.fields.expertiseSubjectIdentifier" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.subjectIdentifier.value}
                                onChange={filtering.subjectIdentifier.onChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.curator.values}
                                label={<FormattedMessage id="expertise.fields.curator" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.curatorFilterData}
                                onChange={filtering.curator.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.campaigns.values}
                                label={<FormattedMessage id="expertiseTask.fields.campaign" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.campaignFilterData}
                                onChange={filtering.campaigns.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                        <Grid item xs={4} container justify="space-between">
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    label={<FormattedMessage id="expertise.fields.deadline" />}
                                    placeholder={intl.formatMessage({ id: 'common.from' })}
                                    value={filtering.deadline.value.from}
                                    onChange={filtering.deadline.onFromChange}
                                    {...(dateTo && { maxDate: dateTo })}
                                    disableFuture={false}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={(): void => filtering.deadline.onFromChange(null)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    placeholder={intl.formatMessage({ id: 'common.until' })}
                                    value={filtering.deadline.value.to}
                                    onChange={filtering.deadline.onToChange}
                                    minDate={deadlineDateBoundaries.minDate}
                                    disableFuture={false}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={(): void => filtering.deadline.onToChange(null)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={6} justify="flex-end">
                        <Grid item xs={4}>
                            <Select
                                values={filtering.state.values}
                                label={<FormattedMessage id="expertise.fields.state" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.stateFilterData}
                                onChange={filtering.state.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.template.values}
                                label={<FormattedMessage id="expertise.fields.template" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.templateFilterData}
                                onChange={filtering.template.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectFilterPanelDetails>
        </ExpansionPanel>
    );
});
