const validation: Record<string, string> = {
    required: 'Обязательное поле',
    minLength: 'Минимальная длина {length, number} {length, plural, one {символ} few {символа} other {символов}}',
    validEmail: 'Введите валидный email',
    maxDate: 'Дата начала работ не может быть позже даты, указанной в поле "Срок выполнения"',
    minDate: 'Дата, указанная в поле "Срок выполнения" не может быть раньше даты начала работ',
    bannedSymbols: 'Нельзя использовать символы: « {symbols} »',
};

export default validation;
