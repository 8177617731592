import { Container, Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { di } from 'react-magnetic-di';
import { StickyWrapper as StickyWrapperInj } from './sticky';
import { TotObjectActionsBar as TotObjectActionsBarInj } from './tot-object';

export type BottomControlPanelProps = {
    left?: ReactNode;
    right: ReactNode;
};

export const BottomControlPanel = (props: BottomControlPanelProps): JSX.Element => {
    const [TotObjectActionsBar] = di([TotObjectActionsBarInj], BottomControlPanel);
    const [StickyWrapper] = di([StickyWrapperInj], BottomControlPanel);

    const { left, right } = props;

    return (
        <StickyWrapper mode="bottom" positionRecheckInterval={150}>
            <TotObjectActionsBar>
                <Container maxWidth="lg">
                    <Grid alignItems="center" justify="space-between" container style={{ height: '65px' }}>
                        <Grid item>{left}</Grid>
                        <Grid item>{right}</Grid>
                    </Grid>
                </Container>
            </TotObjectActionsBar>
        </StickyWrapper>
    );
};
