import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { ActionMenuItem as ActionMenuItemInj, UserStatusModal as UserStatusModalInj } from '../../../../components';
import { useFlag } from '../../../../hooks';
import { ActionItemProps, UserTableRow } from '../../../../types';
import { actionCodeByUserStatus } from '../../../../utils';

export type UserStatusButtonProps = ActionItemProps & {
    userTableRow: UserTableRow;
    reloadData: () => void;
};

export const UserStatusButton = observer((props: UserStatusButtonProps): JSX.Element => {
    const [ActionMenuItem] = di([ActionMenuItemInj], UserStatusButton);
    const [UserStatusModal] = di([UserStatusModalInj], UserStatusButton);

    const { userTableRow, reloadData, hideMenu } = props;
    const [isConfirmationDialogOpen, openDialog, closeDialog] = useFlag();

    const { customData, id } = userTableRow;
    const { name, login, state } = customData;
    const code = state.code;

    const closeDialogAndMenu = (): void => {
        closeDialog();
        hideMenu();
    };

    return (
        <React.Fragment>
            <ActionMenuItem messageId={`users.action.${actionCodeByUserStatus[code]}`} onClick={openDialog} />
            <UserStatusModal
                id={id}
                name={name.title}
                login={login.title}
                userStatusCode={code}
                isOpen={isConfirmationDialogOpen}
                reloadData={reloadData}
                setIsClosed={closeDialogAndMenu}
            />
        </React.Fragment>
    );
});
