import { Button, Container, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { Form, FormApi, MultiLangFormEdit } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../components';
import { useLoading, useStore } from '../../hooks';
import { CodeTitle, CodeTitleNull, PlanEntryListModel, PlanEntryModel } from '../../models';

export type PlanEntryFormDialogProps = {
    getEntryModel: () => PlanEntryModel;
    listModel: PlanEntryListModel;
    ownerPagePath: string;
};

export type PlanEntryFormDialogRouteParams = {
    id: string;
};

export const PlanEntryFormDialog = observer((props: PlanEntryFormDialogProps): JSX.Element => {
    const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], PlanEntryFormDialog);

    const { getEntryModel, listModel, ownerPagePath } = props;
    const { id } = useParams<PlanEntryFormDialogRouteParams>();
    const history = useHistory();
    const { intlStore: intl } = useStore();
    const { locale } = intl;
    const { isLoading, disableLoading, enableLoading } = useLoading();

    const [formApi, setFormApi] = useState<FormApi>();
    const [taskFormApi, setTaskFormApi] = useState<FormApi>();
    const entryModel = useMemo(() => getEntryModel(), [getEntryModel]);

    const getFormSelectDefaultValue = (): CodeTitleNull => {
        const { taskFormCode } = entryModel;
        if (taskFormCode) {
            const value = entryModel.taskFormSelectData.find((val: CodeTitle) => val.code === taskFormCode);
            if (value) {
                return value;
            }
        }
        return null;
    };

    useEffect((): void => {
        entryModel.setTaskForm(getFormSelectDefaultValue());
    }, [entryModel.id, entryModel.taskFormCode]);

    const onFormReady = useCallback(
        (form: FormApi): void => {
            setFormApi(form);
        },
        [setFormApi],
    );

    const onTaskFormReady = useCallback(
        (form: FormApi): void => {
            setTaskFormApi(form);
        },
        [setTaskFormApi],
    );

    const handleSave = async (): Promise<void> => {
        const { isValid } = entryModel;
        entryModel.validationStarted = true;
        if (formApi && formApi.validate() && isValid && taskFormApi?.validate()) {
            try {
                enableLoading();
                await formApi.submit(false);
                await taskFormApi?.submit(false);
                await listModel.saveEntry(entryModel, formApi.getSubmission(), taskFormApi?.getSubmission());
                history.push(generatePath(ownerPagePath, { id }));
            } catch (error) {
                disableLoading();
            }
        }
    };

    const handleCancel = (): void => {
        history.push(generatePath(ownerPagePath, { id }));
    };

    const getAutocompleteOptionSelected = (option: CodeTitle, value: CodeTitle): boolean => {
        return option.code === value.code;
    };

    const getAutocompleteOptionLabel = (option: CodeTitle): string => {
        return option.title;
    };

    return (
        <Dialog maxWidth="md" fullScreen open={true} scroll="paper">
            <DialogTitle>
                <Container maxWidth="lg">
                    <FormattedMessage id="templatesOfExpertise.plan.entryTitle" />
                </Container>
            </DialogTitle>
            <DialogContent dividers>
                <Container maxWidth="lg">
                    <Form locale={locale} onFormReady={onFormReady} form={entryModel.formModel} />
                    {formApi && (
                        <Autocomplete
                            value={entryModel.taskForm}
                            onChange={entryModel.onChangeSelect}
                            getOptionSelected={getAutocompleteOptionSelected}
                            getOptionLabel={getAutocompleteOptionLabel}
                            options={entryModel.taskFormSelectData.slice()}
                            renderInput={renderAutoCompleteInput(
                                intl.formatMessage('templatesOfExpertise.plan.fields.taskForm'),
                                true,
                                entryModel.errorTaskForm,
                            )}
                        />
                    )}
                    {entryModel.taskFormCode && entryModel.taskFormModel && (
                        <MultiLangFormEdit
                            intlStore={intl}
                            onFormReady={onTaskFormReady}
                            form={entryModel.taskFormModel}
                        />
                    )}
                </Container>
            </DialogContent>
            <Container maxWidth="lg">
                <DialogActions>
                    <Button size="large" fullWidth variant="contained" onClick={handleCancel}>
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        disabled={isLoading}
                        size="large"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        <FormattedMessage id="common.save" />
                    </Button>
                </DialogActions>
            </Container>
        </Dialog>
    );
});
