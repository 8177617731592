import { TTableRow } from '@platform/ttable/lib/data/TTableRow';
import { History } from 'history';
import fileDownload from 'js-file-download';
import { action, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import clientRoute from '../clientRoute';
import { RegistryFetchColumns, RegistryFetchData, RegistryUpload } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export class RegistryStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;
    @observable protected history: History;
    @observable fetchColumns: RegistryFetchColumns = this.fetchColumnsCreator();
    @observable fetchData: RegistryFetchData = this.fetchDataCreator();

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.history = rootStore.history;
    }

    @action.bound
    fetchDataCreator<RowType extends TTableRow>(params?: Record<string, string>): RegistryFetchData {
        return (registryCode, query) => {
            return this.api
                .client(apiConfigs.registryFetchData(registryCode, query, params))
                .then((r) => r.data)
                .catch(
                    handleAxiosErrorByResponseStatus({
                        403: () => this.history.replace(clientRoute.notAllowed),
                    }),
                );
        };
    }

    @action.bound
    fetchDataWithParams<RowType extends TTableRow>(params: Record<string, string>): RegistryFetchData {
        return this.fetchDataCreator(params);
    }

    @action.bound
    fetchColumnsCreator<RowType extends TTableRow>(params?: Record<string, string>): RegistryFetchColumns {
        return (registryCode) => {
            return this.api
                .client(apiConfigs.registryFetchColumns(registryCode, params))
                .then((r) => r.data)
                .catch(
                    handleAxiosErrorByResponseStatus({
                        403: () => this.history.replace(clientRoute.notAllowed),
                    }),
                );
        };
    }

    @action.bound
    fetchColumnsWithParams<RowType extends TTableRow>(params: Record<string, string>): RegistryFetchColumns {
        return this.fetchColumnsCreator(params);
    }

    @action.bound
    downloadCreator<RowType extends TTableRow>(
        filename: string,
        registryCode: string,
        params?: Record<string, string>,
    ): RegistryUpload {
        return (query) => {
            return this.api
                .client(apiConfigs.registryDownload(registryCode, query, params))
                .then((r) => r.data)
                .then((file) => fileDownload(file, `${filename}.xlsx`));
        };
    }
}

export const getRegistryStore = (): any => {
    const [_RegistryStore] = di([RegistryStore], getRegistryStore);
    return _RegistryStore;
};
