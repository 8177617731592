const campaign: Record<string, string | Record<string, string>> = {
    campaignListTitle: 'Кампании',
    createCampaign: 'Создать кампанию',
    newCampaign: 'Новая кампания',
    editCampaign: 'Редактировать кампанию',
    fields: {
        title: 'Наименование',
        identifier: 'Номер',
        created: 'Дата создания',
        state: 'Состояние',
        responsible: 'Ответственный',
        author: 'Автор',
        lastModified: 'Последнее изменение',
        lifecycle: 'Жизненный цикл',
    },
    subjects: {
        title: 'Объекты экспертизы',
        total: 'Всего',
        withoutExpertise: 'Без экспертизы',
        active: 'Действительных',
        withExpertise: 'С экспертизой',
    },
    expertises: {
        title: 'Экспертизы',
        total: 'Всего',
        active: 'В процессе',
        done: 'Завершены',
        canceled: 'Отменены',
    },
    tasks: {
        title: 'Задания на экспертизу',
        total: 'Всего',
        withoutExpert: 'Без эксперта',
        responseWaiting: 'Ожидают ответа',
        startWaiting: 'Ожидают старта',
        active: 'В процессе',
        overdue: 'Просрочены',
        done: 'Завершены',
        notCompleted: 'Не выполнены',
    },
    registryPagingInfo:
        'Всего {count, number} кампан{count, plural, one {ия} few {ии} other {ий}}. ' +
        'Отображены c {from, number} по {to, number}',
    blocks: {
        serviceInfo: 'Служебная информация',
        expertiseObjects: 'Объекты экспертизы',
        expertise: 'Экспертизы',
        tasks: 'Задания на экспертизу',
    },
    actions: {
        deleteCampaign: 'Удалить кампанию',
    },
    confirmDeletionInfoText: 'Вы действительно хотите удалить кампанию "{title}"?',
};

export default campaign;
