import * as History from 'history';
import queryString from 'querystring';
import { ChangeEvent } from 'react';
import { DateRangeFilter } from '../models';
import { CheckFilter } from '../models/list/filter/CheckFilter';
import { InSetFilter } from '../models/list/filter/InSetFilter';
import { LikeFilter } from '../models/list/filter/LikeFilter';

export const setFiltersFromUrl = <F>(location: History.Location<History.History.PoorMansUnknown>, filtering: F) => {
    const filterParams = location.search.substr(1);
    const filtersFromParams = queryString.parse(filterParams);
    const filtersKeys = Object.keys(filtersFromParams);

    filtersKeys.forEach((filter) => {
        let value = filtersFromParams[filter].toString();
        try {
            value = JSON.parse(value);
        } catch {}

        const targetFilter = filtering[filter as keyof F] as unknown;

        if (targetFilter) {
            switch (typeof value) {
                case 'object': {
                    if (Array.isArray(value)) {
                        (targetFilter as InSetFilter).onChange({} as ChangeEvent<{}>, value);
                    } else {
                        (targetFilter as DateRangeFilter).onChange(value);
                    }
                    break;
                }
                case 'string': {
                    (targetFilter as LikeFilter).onChange({ target: { value } } as ChangeEvent<HTMLInputElement>);
                    break;
                }
                case 'boolean': {
                    (targetFilter as CheckFilter).onChange({
                        target: { checked: !!value },
                    } as ChangeEvent<HTMLInputElement>);
                    break;
                }
            }
        }
    });
};
