import { Box, Container, Grid, Typography } from '@material-ui/core';
import { Preset, TTable, TTableRow, TTableVisibilitySettings } from '@platform/ttable';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import { AuthorizationCheck as AuthorizationCheckInj, TableWrapper as TableWrapperInj } from '../../components';
import { useStore } from '../../hooks';
import { RegistryType } from '../../types';

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithPresets: false,
        isWithAddCustomColumns: false,
    },
    column: {
        isWithDraggableColumns: false,
    },
};

type RouteParams = {
    id: string;
};

const registryCode = RegistryType.logging;

export const SyslogPage = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], SyslogPage);
    const [TableWrapper] = di([TableWrapperInj], SyslogPage);

    const { presetStore, registryStore, catalogStore, userStore } = useStore();
    const { fetchDataWithParams, fetchColumnsWithParams, downloadCreator } = registryStore;
    const { id } = useParams<RouteParams>();
    const intl = useIntl();

    const registryTitle = intl.formatMessage({ id: 'syslog.title' });
    const params = {
        entityId: id,
    };

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> =>
        presetStore.saveListPresets(settings);

    const fetchData = fetchDataWithParams(params);
    const fetchColumns = fetchColumnsWithParams(params);
    const download = downloadCreator(registryTitle, registryCode, params);

    return (
        <Container maxWidth="lg">
            <Box pt={5} pb={5}>
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <Typography variant="h1">{registryTitle}</Typography>
                    </Grid>

                    <TableWrapper item>
                        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                            {(allowed: boolean): JSX.Element => (
                                <TTable
                                    registryCode={registryCode}
                                    fetchData={fetchData}
                                    fetchColumns={fetchColumns}
                                    fetchCatalog={catalogStore.fetchCatalogForTTable}
                                    presetsSetting={presetStore}
                                    visibleSettings={visibleSettings}
                                    fetchSelectDataByUrl={catalogStore.fetchSelectDataByUrl}
                                    upload={download}
                                    lang={intl.locale}
                                    saveListPresets={saveListPresets}
                                    fetchUserRoleList={userStore.userRoleList}
                                    isAdmin={allowed}
                                />
                            )}
                        </AuthorizationCheck>
                    </TableWrapper>
                </Grid>
            </Box>
        </Container>
    );
});
