import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { CampaignListPage as CampaignListPageInj } from './CampaignListPage';
import { CampaignPage as CampaignPageInj } from './CampaignPage';

export const CampaignPages = (): JSX.Element => {
    const [CampaignPage] = di([CampaignPageInj], CampaignPages);
    const [CampaignListPage] = di([CampaignListPageInj], CampaignPages);

    return (
        <Switch>
            <Route path={[clientRoute.campaign, clientRoute.campaignEdit, clientRoute.campaignCreate]}>
                <CampaignPage />
            </Route>
            <Route>
                <CampaignListPage />
            </Route>
        </Switch>
    );
};
