import { action, observable } from 'mobx';
import apiConfigs from '../../apiConfigs';
import { Api, RootStore } from '../../store';
import { IdTitle } from '../../types';
import { DateUtils } from '../../utils';
import { GroupedIdTitle } from '../GroupedIdTitle';
import {
    CheckFilter, DateRangeFilter, InSetFilter, LikeFilter, RowsData, Sort, TableModel,
    TableQueryData
} from '../list';

export interface SubjectRowDTO {
    id: string;
    identifier: string;
    title: string;
    created: string; //date
    state: string;
    category: IdTitle;
    campaign?: IdTitle;
    expertise?: IdTitle;
}

export interface SubjectRow {
    id: string;
    identifier: string;
    title: string;
    created?: Date; //date
    state: string;
    category: IdTitle;
    campaign?: IdTitle;
    expertise?: IdTitle;
}

export type SubjectsFilter = {
    identifier: LikeFilter;
    title: LikeFilter;
    created: DateRangeFilter;
    campaigns: InSetFilter;
    category: LikeFilter;
    expertise: LikeFilter;
    withoutExpertise: CheckFilter;
    state: InSetFilter;
};

export type SubjectsSort = {
    identifier: Sort;
    title: Sort;
    created: Sort;
    category: Sort;
    campaign: Sort;
    expertise: Sort;
};

export class SubjectListModel extends TableModel<SubjectRow, SubjectsFilter, SubjectsSort> {
    @observable protected api: Api;
    @observable stateFilterData: GroupedIdTitle;
    @observable campaignFilterData: IdTitle[];

    constructor(rootStore: RootStore) {
        const filter = {
            identifier: new LikeFilter(),
            title: new LikeFilter(),
            created: new DateRangeFilter(),
            category: new LikeFilter(),
            expertise: new LikeFilter(),
            withoutExpertise: new CheckFilter(),
            state: new InSetFilter(),
            campaigns: new InSetFilter(),
        };

        const sort = {
            identifier: new Sort(),
            title: new Sort(),
            created: new Sort('desc'),
            category: new Sort(),
            campaign: new Sort(),
            expertise: new Sort(),
        };
        super(filter, sort);
        this.api = rootStore.api;
        this.stateFilterData = {};
        this.campaignFilterData = [];

        this.getStateFilterData();
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<SubjectRow>> {
        return this.api
            .client(apiConfigs.subjects(queryData))
            .then((r) => r.data)
            .then(({ rows, count }) => ({ rows: rows.map(this.mapDtoToRow), count }));
    }

    @action.bound
    getStateFilterData(): void {
        this.api
            .client(apiConfigs.loadStateFilterData)
            .then((r) => r.data)
            .then(
                action((data) => {
                    this.stateFilterData = data.statesByProcess;
                    this.campaignFilterData = data.campaigns.map((campaign: IdTitle) => ({
                        id: campaign.id,
                        title: campaign.title,
                    }));
                }),
            );
    }

    @action.bound
    mapDtoToRow(dto: SubjectRowDTO): SubjectRow {
        const created = new Date(dto.created);
        const parsedFields = {
            ...(DateUtils.isValidDate(created) && { created }),
        };

        return {
            id: dto.id,
            identifier: dto.identifier,
            title: dto.title,
            state: dto.state,
            category: dto.category,
            campaign: dto.campaign,
            expertise: dto.expertise,
            ...parsedFields,
        };
    }
}
