import { Grid, Link } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import clientRoute from '../../../../clientRoute';
import { RowData as RowDataInj, SyslogLink as SyslogLinkInj } from '../../../../components';
import { ExpertiseModel } from '../../../../models';
import { getOrElse } from '../../../../utils';

export type ExpertiseHeaderLeftProps = {
    identifier: ExpertiseModel['identifier'];
    stateTitle: ExpertiseModel['metaInfo']['stateTitle'];
    created: ExpertiseModel['metaInfo']['created'];
    template?: ExpertiseModel['template'];
    subjectOfExpertise?: ExpertiseModel['subject'];
};

export const ExpertiseHeaderLeft = (props: ExpertiseHeaderLeftProps): JSX.Element => {
    const [RowData] = di([RowDataInj], ExpertiseHeaderLeft);
    const [SyslogLink] = di([SyslogLinkInj], ExpertiseHeaderLeft);

    const { identifier, stateTitle, created, template, subjectOfExpertise } = props;
    const intl = useIntl();

    const templateValue = template?.title || '-';
    const templateWrapper =
        template &&
        ((children: JSX.Element): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.templateOfExpertise, {
                        id: template.id,
                    })}
                    color="textPrimary"
                >
                    {children}
                </Link>
            );
        });

    const identifierValue = getOrElse(identifier);
    const stateValue = getOrElse(stateTitle);
    const createdValue = getOrElse(created, intl.formatDate(created), '-');
    const expertiseSubjectValue = subjectOfExpertise ? (
        <Link
            component={NavLink}
            underline="none"
            to={generatePath(clientRoute.subject, {
                id: subjectOfExpertise.id,
            })}
            color="textPrimary"
        >
            {subjectOfExpertise.number}
        </Link>
    ) : (
        '-'
    );

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item container spacing={7} direction="row">
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'expertise.fields.identifier' })}
                        value={identifierValue}
                    />
                </Grid>
                <Grid item>
                    <RowData label={intl.formatMessage({ id: 'expertise.fields.state' })} value={stateValue} />
                </Grid>
                <Grid item>
                    <SyslogLink />
                </Grid>
            </Grid>
            <Grid item container spacing={7} direction="row" wrap="nowrap">
                <Grid item>
                    <RowData label={intl.formatMessage({ id: 'expertise.fields.created' })} value={createdValue} />
                </Grid>
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'expertise.fields.template' })}
                        value={templateValue}
                        valueWrapper={templateWrapper}
                    />
                </Grid>
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'expertise.fields.expertiseSubject' })}
                        value={expertiseSubjectValue}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
