import { Grid } from '@material-ui/core';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { TotBackground } from '../../../TotBackground';
import { EsiaGetAuthUrlPage as EsiaGetAuthUrlPageInj } from './EsiaGetAuthUrlPage';
import { EsiaLoginPage as EsiaLoginPageInj } from './EsiaLoginPage';

export const EsiaPages = (): JSX.Element => {
    const [EsiaGetAuthUrlPage] = di([EsiaGetAuthUrlPageInj], EsiaPages);
    const [EsiaLoginPage] = di([EsiaLoginPageInj], EsiaPages);

    return (
        <TotBackground>
            <Grid container item direction="column" alignItems="center" justify="center">
                <Switch>
                    <Route path={clientRoute.esiaGetAuthUrl}>
                        <EsiaGetAuthUrlPage />
                    </Route>
                    <Route path={clientRoute.esiaLogin}>
                        <EsiaLoginPage />
                    </Route>
                </Switch>
            </Grid>
        </TotBackground>
    );
};
