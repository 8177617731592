import { action, observable } from 'mobx';
import { File } from '../File';

export interface ExpertiseTaskPrintFormDTO {
    id: string;
    title: string;
    code: string;
    taskId: string;
    file?: PrintFormFileDTO;
}

export interface PrintFormFileDTO extends File {
    pfId: string;
}

export class ExpertiseTaskPrintFormModel {
    @observable id = '';
    @observable title = '';
    @observable code = '';
    @observable taskId = '';
    @observable file?: PrintFormFileDTO;

    @observable isGenerated = false;
    @observable errorCode = '';

    @action.bound
    load(dto: ExpertiseTaskPrintFormDTO): ExpertiseTaskPrintFormModel {
        Object.assign(this, dto);
        return this;
    }
}
