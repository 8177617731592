import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../components';
import { CategoryOfSubjectsControlPanelLeft as CategoryOfSubjectsControlPanelLeftInj } from './CategoryOfSubjectsControlPanelLeft';
import { CategoryOfSubjectsControlPanelRight as CategoryOfSubjectsControlPanelRightInj } from './CategoryOfSubjectsControlPanelRight';

export type CategoryOfSubjectsControlPanelProps = {
    categoryOfSubjectsTitle: string;
    categoryOfSubjectsId: string;
    deleteCategoryOfSubjects: () => Promise<void>;
    onSubmit: () => void;
};

export const CategoryOfSubjectsControlPanel = observer((props: CategoryOfSubjectsControlPanelProps): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], CategoryOfSubjectsControlPanel);
    const [CategoryOfSubjectsControlPanelLeft] = di(
        [CategoryOfSubjectsControlPanelLeftInj],
        CategoryOfSubjectsControlPanel,
    );
    const [CategoryOfSubjectsControlPanelRight] = di(
        [CategoryOfSubjectsControlPanelRightInj],
        CategoryOfSubjectsControlPanel,
    );

    const { categoryOfSubjectsId, categoryOfSubjectsTitle, deleteCategoryOfSubjects, onSubmit } = props;
    return (
        <BottomControlPanel
            left={
                <CategoryOfSubjectsControlPanelLeft
                    categoryOfSubjectsTitle={categoryOfSubjectsTitle}
                    deleteCategoryOfSubjects={deleteCategoryOfSubjects}
                    categoryOfSubjectsId={categoryOfSubjectsId}
                />
            }
            right={
                <CategoryOfSubjectsControlPanelRight categoryOfSubjectsId={categoryOfSubjectsId} onSubmit={onSubmit} />
            }
        />
    );
});
