import { recordToLang } from '../../../../../resources';
import ru from '../../../../../resources/locales/ru';
import startPage from './startPage';

const lang: Record<string, string> = {
    ...ru,
    ...recordToLang({ startPage }),
};

export default lang;


