import {
    Box,
    Button,
    Checkbox,
    createStyles,
    FormControlLabel,
    Grid,
    Link,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import {
    EmailField as EmailFieldInj,
    PasswordField as PasswordFieldInj,
    RedirectByRole as RedirectByRoleInj,
} from '../../components';
import { useLoginRedirect, useStore } from '../../hooks';

const useStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            height: '100%',
        },
    }),
);

export const HomePageRightSide = observer((): JSX.Element => {
    const [EmailField] = di([EmailFieldInj], HomePageRightSide);
    const [PasswordField] = di([PasswordFieldInj], HomePageRightSide);
    const [RedirectByRole] = di([RedirectByRoleInj], HomePageRightSide);

    const classes = useStyles();
    const { authenticationStore } = useStore();
    const { loginModel } = authenticationStore;

    const onRememberMeChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        loginModel.rememberMe = checked;
    };

    const [isRedirect, backUrl] = useLoginRedirect();

    return (
        <Grid container justify="center" alignItems="center" className={classes.mainContainer}>
            <Grid item xs={8}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="h5" className="t-login-title">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="authentication.loginTitle" />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Box pt={8}>
                    <form noValidate onSubmit={loginModel.login}>
                        <Grid item container spacing={6} direction="column">
                            {loginModel.loginFailedMessage && (
                                <Grid item>
                                    <Typography className="t-login-failed-message" color="error">
                                        {loginModel.loginFailedMessage}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <EmailField model={loginModel} />
                            </Grid>
                            <Grid item>
                                <PasswordField model={loginModel} newPassword={false} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    value="top"
                                    control={
                                        <Checkbox
                                            className="t-remember-me"
                                            color="primary"
                                            onChange={onRememberMeChange}
                                        />
                                    }
                                    label={<FormattedMessage id="authentication.rememberMe" />}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    className="t-login"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    <FormattedMessage id="authentication.login" />
                                </Button>
                            </Grid>
                            <Grid item container justify="space-between">
                                <Grid item>
                                    <Link
                                        className="t-forgot-password"
                                        component={RouterLink}
                                        to={clientRoute.recoveryPassword}
                                        underline="none"
                                    >
                                        <FormattedMessage id="authentication.forgotPassword" />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        className="t-registration"
                                        component={RouterLink}
                                        to={clientRoute.registration}
                                        underline="none"
                                    >
                                        <FormattedMessage id="authentication.register" />
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                {isRedirect && <RedirectByRole push to={backUrl} />}
            </Grid>
        </Grid>
    );
});
