import { AxiosResponse } from 'axios';
import { action, computed, observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import clientRoute from '../clientRoute';
import { PersonDTO, PersonModel, UserModel } from '../models';
import { Api } from './Api';
import { AuthorizationStore } from './AuthorizationStore';
import { HeaderLinksStore } from './HeaderLinksStore';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

export interface PersonInfo {
    user: UserModel;
    person: PersonDTO;
    roles: string[];
}

const loginRedirectByRole: { [role: string]: string } = {
    Expert: clientRoute.expertiseTasks,
    Curator: clientRoute.campaigns,
    User: clientRoute.campaigns,
};

const rolePriorities: { [role: string]: number } = {
    Curator: 2,
    Expert: 1,
    User: 0,
};

export const getRouteByRoles = (
    roles: string[],
    loginRedirectByRole: { [role: string]: string },
    rolePriorities: { [role: string]: number },
): string => {
    const rolesLength = roles.length;
    let redirect = '';
    let priority = 0;
    for (let index = 0; index < rolesLength; index++) {
        const role = roles[index];
        const rolePriority = rolePriorities[role];
        if ((rolePriority || rolePriority === 0) && rolePriority >= priority) {
            priority = rolePriority;
            redirect = loginRedirectByRole[role];
        }
    }

    if (redirect) {
        return redirect;
    }

    return '';
};

export class PersonStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;
    @observable private intl: IntlStore;
    @observable private authorizationStore: AuthorizationStore;
    @observable private headerLinksStore: HeaderLinksStore;

    @observable user: UserModel = new UserModel();
    @observable person: PersonModel = new PersonModel();
    @observable roles: string[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intl = rootStore.intlStore;
        this.authorizationStore = rootStore.authorizationStore;
        this.headerLinksStore = rootStore.headerLinksStore;

        this.getInfo().then((r) => r);
    }

    @asyncAction
    @action.bound
    async getInfo(): Promise<void> {
        this.authorizationStore.updateDataLoader();
        const response: AxiosResponse<PersonInfo> = await this.api.client(apiConfigs.person(this.intl.locale));
        const { user, person, roles } = response.data;
        this.user = user;
        this.person.load(person);
        this.roles = roles;
        this.headerLinksStore.checkLinksPermissions();
    }

    @computed
    get redirectPath(): string {
        return getRouteByRoles(this.roles, loginRedirectByRole, rolePriorities);
    }

    @computed
    get isExpert() {
        return this.roles.findIndex((role) => role === 'Expert') !== -1;
    }

    @computed
    get isAdmin() {
        return this.roles.findIndex((role) => role === 'Admin') !== -1;
    }

    @computed
    get isCurator() {
        return this.roles.findIndex((role) => role === 'Curator') !== -1;
    }

    @action.bound
    clearPerson() {
        this.user = new UserModel();
        this.person = new PersonModel();
        this.roles = [];
    }
}

export const getPersonStore = (): any => {
    const [_PersonStore] = di([PersonStore], getPersonStore);
    return _PersonStore;
};
