import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Portal,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAntiDoubleClick, useYup } from '../../../hooks';
import { TransitionDialogProps } from '../../../types';
import { RequiredLabel } from '../../RequiredLabel';

const useStyles = makeStyles(() =>
    createStyles({
        formWrapper: {
            '&:first-child': {
                paddingTop: '10px',
            },
        },
    }),
);

export type RejectDialogProps = TransitionDialogProps & {
    expertiseTaskFormatMessage: ReactNode;
    sendMethod: (id: string, objectId: string, message: string) => Promise<void>;
};

type FormikValues = {
    message: string;
};

const initialValues: FormikValues = {
    message: '',
};

export const ExpertiseTaskRejectWithReasonDialog = observer((props: RejectDialogProps): JSX.Element => {
    const {
        objectId,
        transition,
        isModalOpen,
        setModalIsClosed,
        handleSuccessTransition,
        handleErrorTransition,
        expertiseTaskFormatMessage,
        sendMethod,
    } = props;
    const { formWrapper } = useStyles();
    const intl = useIntl();
    const { Yup } = useYup();
    const { toStateTitle, id } = transition;
    const labelField = <RequiredLabel text={intl.formatMessage({ id: 'expertiseTask.invites.rejectReason' })} />;

    const schema = Yup.object().shape({
        message: Yup.string().required(),
    });

    const handleConfirm = (values: FormikValues): Promise<void> => {
        return sendMethod(id, objectId, values.message)
            .then(handleSuccessTransition)
            .catch(handleErrorTransition)
            .finally(setModalIsClosed);
    };

    const [isLoading, startIcon, onSubmitHandler] = useAntiDoubleClick(handleConfirm);

    const dialogContentValues = { toState: toStateTitle };

    return (
        <Portal>
            <Dialog fullWidth maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={isModalOpen}>
                <DialogTitle id="confirmation-dialog-title">
                    <FormattedMessage id="expertiseTask.invites.rejectReason" />
                </DialogTitle>
                <DialogContent>
                    <FormattedMessage id="common.lifeCycleConfirmText" values={dialogContentValues} />
                </DialogContent>
                <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmitHandler}>
                    <Form>
                        <DialogContent className={formWrapper}>
                            <Field
                                component={TextField}
                                label={labelField}
                                rows={3}
                                name="message"
                                variant="outlined"
                                size="small"
                                multiline
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus color="primary" onClick={setModalIsClosed}>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                disabled={isLoading}
                                startIcon={startIcon}
                            >
                                {expertiseTaskFormatMessage}
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </Dialog>
        </Portal>
    );
});
