import { createStyles, makeStyles, Portal } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { RequiredLabel as RequiredLabelInj } from '../../../../components';
import { useYup } from '../../../../hooks';
import { ModalProps } from '../../../../types';
import { stopPropagation } from '../../../../utils';

const useStyles = makeStyles(() =>
    createStyles({
        formWrapper: {
            '&:first-child': {
                paddingTop: '10px',
            },
        },
    }),
);

export type UserBlockDialogProps = ModalProps & {
    onConfirm: (message?: string) => Promise<void>;
    message: string;
    title: string;
};

type FormikValues = {
    message: string;
};

const initialValues: FormikValues = {
    message: '',
};

export const UserBlockDialog = observer((props: UserBlockDialogProps): JSX.Element => {
    const [RequiredLabel] = di([RequiredLabelInj], UserBlockDialog);

    const { isOpen, message, title, setIsClosed, onConfirm } = props;
    const classes = useStyles();
    const intl = useIntl();
    const { Yup } = useYup();

    const schema = Yup.object().shape({
        message: Yup.string().required(),
    });

    const handleConfirm = useCallback(
        (formData: FormikValues) => {
            onConfirm(formData.message).finally(setIsClosed);
        },
        [onConfirm],
    );

    const fieldLabel = <RequiredLabel text={intl.formatMessage({ id: 'users.blockReason' })} />;

    return (
        <Portal>
            <Dialog id="block-action" fullWidth={true} maxWidth="xs" open={isOpen} onKeyDown={stopPropagation}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{message}</DialogContent>
                <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleConfirm}>
                    <Form>
                        <DialogContent className={classes.formWrapper}>
                            <Field
                                component={TextField}
                                name="message"
                                label={fieldLabel}
                                variant="outlined"
                                size="small"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={setIsClosed} color="primary">
                                <FormattedMessage id="common.cancel" />
                            </Button>
                            <Button type="submit" color="secondary" variant="contained">
                                <FormattedMessage id="users.actions.block" />
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </Dialog>
        </Portal>
    );
});
