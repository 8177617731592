import { FormDTO, FullSubmission } from '@platform/formiojs-react';
import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import clientRoute from '../clientRoute';
import { CodeTitle, PersonDTO, UserPersonModel } from '../models';
import { EditStatusDTO } from '../types';
import { handleAxiosErrorByResponseStatus } from '../utils';
import { Api } from './Api';
import { RootStore } from './RootStore';

export interface UserDTO {
    userId: string;
    lang: string;
    login: string;
    roles: CodeTitle[];
    formInfo: FormDTO;
    blockInfo?: BlockInfoDTO;
}

export type UserUpdateBody = {
    email: string;
    lang: string;
    roles: string[];
};

type LangUserDTO = Omit<PersonDTO, 'uuid'>;

export type UserForCreate = {
    email: string;
    names: [LangUserDTO, LangUserDTO];
    roles: string[];
} & Omit<PersonDTO, 'uuid'>;

export type BlockInfoDTO = {
    author: PersonDTO;
    blockReason: string;
    created: Date;
};

export class UserStore {
    @observable private rootStore: RootStore;
    @observable protected api: Api;
    @observable roleListWithoutAdministration: CodeTitle[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
    }

    @computed
    get history(): History {
        return this.rootStore.history;
    }

    @action.bound
    loginAsUser(id: string): Promise<void> {
        return this.api.client(apiConfigs.loginAsUser(id)).then((r) => r.data);
    }

    @action.bound
    loadUser(id: string): Promise<UserDTO> {
        return this.api
            .client(apiConfigs.loadUser(id))
            .then((r) => r.data)
            .catch(
                handleAxiosErrorByResponseStatus({
                    404: () => this.history.replace(clientRoute.notFound),
                    403: () => this.history.replace(clientRoute.notAllowed),
                }),
            );
    }

    @action.bound
    getUserPersonModel(id: string): UserPersonModel {
        const model = new UserPersonModel(id);
        this.loadUser(id).then(model.load);
        return model;
    }

    @action.bound
    saveUser(id: string, submission: FullSubmission): Promise<void> {
        return this.api.client(apiConfigs.saveUser(id, submission)).then((r) => r.data);
    }

    @action.bound
    saveUserHead(user: UserPersonModel): Promise<void> {
        const { id, roles, login, lang } = user;
        return this.api
            .client(
                apiConfigs.saveUserHead(id, {
                    email: login,
                    lang,
                    roles: roles.map((_) => _.code),
                }),
            )
            .then((r) => r.data);
    }

    @action.bound
    userRoleList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.userRoleList).then((r) => r.data);
    }

    @action.bound
    editUserStatus(id: string, data: EditStatusDTO): Promise<void> {
        return this.api.client(apiConfigs.editUserStatus(id, data)).then((r) => r.data);
    }

    @action.bound
    assignUserPassword(id: string, password: string): Promise<void> {
        return this.api.client(apiConfigs.assignUserPassword(id, password)).then((r) => r.data);
    }

    @action.bound
    async createUser(user: UserForCreate): Promise<string> {
        const { data } = await this.api.client(apiConfigs.createUser(user));
        return data;
    }

    @action.bound
    async getRoleListWithoutAdministration(): Promise<void> {
        const { data } = await this.api.client(apiConfigs.getRoleListWithoutAdministration);
        this.roleListWithoutAdministration = data;
    }
}

export const getUserStore = (): any => {
    const [_UserStore] = di([UserStore], getUserStore);
    return _UserStore;
};
