import { Button, TableCell, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../../../../components';
import { useStore } from '../../../../../../hooks';
import { ExpertiseTaskPrintFormDTO, ExpertiseTaskPrintFormModel, PrintFormFileDTO } from '../../../../../../models';
import { AuthorizationCheckQuery } from '../../../../../../store';
import { StyledCircularProgress as StyledCircularProgressInj } from '../ExpertiseTaskPrintForms.styled';
import { ExpertiseTaskPrintFormsTableErrorTooltip as ExpertiseTaskPrintFormsTableErrorTooltipInj } from './ExpertiseTaskPrintFormsTableErrorTooltip';
import { ExpertiseTaskPrintFormsTableTooltipFile as ExpertiseTaskPrintFormsTableTooltipFileInj } from './ExpertiseTaskPrintFormsTableTooltipFile';

export type ExpertiseTaskPrintFormsTablePfRowProps = {
    printForm: ExpertiseTaskPrintFormModel;
    permission: AuthorizationCheckQuery;
    pfTypeMsgId: string;
    downloadPf(file: PrintFormFileDTO): () => void;
};

type ButtonStyle = {
    float: 'right';
};

export const ExpertiseTaskPrintFormsTablePfRow = observer(
    (props: ExpertiseTaskPrintFormsTablePfRowProps): JSX.Element => {
        const [StyledCircularProgress] = di([StyledCircularProgressInj], ExpertiseTaskPrintFormsTablePfRow);
        const [ExpertiseTaskPrintFormsTableTooltipFile] = di(
            [ExpertiseTaskPrintFormsTableTooltipFileInj],
            ExpertiseTaskPrintFormsTablePfRow,
        );
        const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTaskPrintFormsTablePfRow);
        const [ExpertiseTaskPrintFormsTableErrorTooltip] = di(
            [ExpertiseTaskPrintFormsTableErrorTooltipInj],
            ExpertiseTaskPrintFormsTablePfRow,
        );

        const { permission, pfTypeMsgId, printForm, downloadPf } = props;
        const { id, title, file, errorCode, isGenerated } = printForm;
        const { expertiseTaskStore } = useStore();

        const styledButton: ButtonStyle = { float: 'right' };
        const startIcon = isGenerated ? (
            <StyledCircularProgress />
        ) : (
            !!errorCode && <ExpertiseTaskPrintFormsTableErrorTooltip errorCode={errorCode} />
        );
        const generateMsgId = file
            ? 'expertiseTask.printFormsTable.updateAction'
            : 'expertiseTask.printFormsTable.createAction';

        const handleUpdateAction = (printForm: ExpertiseTaskPrintFormModel) => () => {
            const { id: taskPrintFormId, taskId, code: pfCode } = printForm;
            printForm.isGenerated = true;
            return (printForm.file
                ? expertiseTaskStore.updatePrintForm(taskPrintFormId)
                : expertiseTaskStore.createPrintForm(taskId, pfCode)
            )
                .then((dto: ExpertiseTaskPrintFormDTO) => {
                    printForm.load(dto);
                    printForm.errorCode = '';
                })
                .catch(() => {
                    printForm.errorCode = 'expertiseTask.printFormsTable.generationError';
                })
                .finally(() => {
                    printForm.isGenerated = false;
                });
        };

        return (
            <TableRow key={id}>
                <TableCell>
                    {file ? (
                        <ExpertiseTaskPrintFormsTableTooltipFile
                            file={file}
                            downloadPf={downloadPf}
                            templateTitle={title}
                        />
                    ) : (
                        <FormattedMessage id={pfTypeMsgId} />
                    )}
                </TableCell>
                <TableCell>
                    <AuthorizationCheck {...permission}>
                        <Button
                            disabled={isGenerated}
                            onClick={handleUpdateAction(printForm)}
                            color="secondary"
                            variant="contained"
                            startIcon={startIcon}
                            style={styledButton}
                        >
                            <FormattedMessage id={generateMsgId} />
                        </Button>
                    </AuthorizationCheck>
                </TableCell>
            </TableRow>
        );
    },
);
