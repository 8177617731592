import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Portal,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAntiDoubleClick, useToggle } from '../../../hooks';
import { TransitionDialogProps } from '../../../types';

export const ExpertiseTaskInviteSentToAcceptDialog = observer((props: TransitionDialogProps): JSX.Element => {
    const { setModalIsClosed, lifeCycleTransition, handleErrorTransition, handleSuccessTransition, isModalOpen } =
        props;

    const intl = useIntl();
    const [isAgree, toggleIsAgree] = useToggle();

    const handleConfirm = async (): Promise<void> => {
        lifeCycleTransition().then(handleSuccessTransition).catch(handleErrorTransition).finally(setModalIsClosed);
    };

    const [isLoading, startIcon, onSubmitHandler] = useAntiDoubleClick(handleConfirm);

    const formControlLabelCheckbox = <Checkbox color="primary" checked={isAgree} onClick={toggleIsAgree} />;

    return (
        <Portal>
            <Dialog fullWidth maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={isModalOpen}>
                <DialogTitle id="confirmation-dialog-title">
                    <FormattedMessage id="common.lifeCycleConfirmTitle" />
                </DialogTitle>
                <DialogContent dividers>
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                        <FormattedMessage id="expertiseTask.invites.agreement" />
                    </Typography>
                    <FormControlLabel
                        value="top"
                        control={formControlLabelCheckbox}
                        label={intl.formatMessage({ id: 'expertiseTask.invites.agree' })}
                        labelPlacement="end"
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={setModalIsClosed} color="primary">
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        disabled={isLoading || !isAgree}
                        startIcon={startIcon}
                        onClick={onSubmitHandler}
                        color="secondary"
                        variant="contained"
                    >
                        <FormattedMessage id="expertiseTask.invites.accept" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
