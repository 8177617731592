import { CampaignLinkQueries } from '../models/campaign/CampaignLinksModel';

export const createLinkWithQueries = (urn: string, queries: CampaignLinkQueries): string => {
    if (!urn.length) {
        return '';
    }

    const queriesKeys = Object.keys(queries);
    let queriesString = '';

    queriesKeys.forEach((queryKey) => {
        queriesString += `${queryKey}=${JSON.stringify(queries[queryKey])}&`;
    });

    return `/${urn}?${queriesString}`;
};
