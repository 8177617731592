import { ExpansionPanel, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { NavLink } from 'react-router-dom';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelLabel as TotObjectPanelLabelInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
} from '../../components';
import { CampaignSubjectsLinks } from '../../models';
import { CampaignSubjects } from '../../store';
import { createLinkWithQueries } from '../../utils';
import { StyledButton as StyledButtonInj, StyledTypography as StyledTypographyInj } from './StyledCampaignComponents';

export type CampaignSubjectsPanelProps = {
    subjects: CampaignSubjects;
    links: CampaignSubjectsLinks;
};

export const CampaignSubjectsPanel = (props: CampaignSubjectsPanelProps): JSX.Element => {
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], CampaignSubjectsPanel);
    const [TotObjectPanelLabel] = di([TotObjectPanelLabelInj], CampaignSubjectsPanel);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], CampaignSubjectsPanel);
    const [StyledButton] = di([StyledButtonInj], CampaignSubjectsPanel);
    const [StyledTypography] = di([StyledTypographyInj], CampaignSubjectsPanel);

    const { subjects, links } = props;
    const { active, withExpertise, withoutExpertise, total } = links;

    return (
        <ExpansionPanel defaultExpanded>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="campaign.subjects.title" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item container direction="row">
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.subjects.total" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(total.urn, total.queries)}
                                            variant="text"
                                        >
                                            {subjects.total}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.subjects.active" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(active.urn, active.queries)}
                                            variant="text"
                                        >
                                            {subjects.active}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.subjects.withExpertise" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(withExpertise.urn, withExpertise.queries)}
                                            variant="text"
                                        >
                                            {subjects.withExpertise}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.subjects.withoutExpertise" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(withoutExpertise.urn, withoutExpertise.queries)}
                                            variant="text"
                                        >
                                            {subjects.withoutExpertise}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
};
