import { useState } from 'react';

export type UseLoading = {
    isLoading: boolean;
    enableLoading: () => void;
    disableLoading: () => void;
};

export const useLoading = (): UseLoading => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const enableLoading = (): void => setIsLoading(true);
    const disableLoading = (): void => setIsLoading(false);

    return { isLoading, enableLoading, disableLoading };
};
