import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import clientRoute from '../../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    RowData as RowDataInj,
    SyslogLink,
    SyslogLink as SyslogLinkInj,
} from '../../../../components';
import { ExpertiseTaskModel } from '../../../../models';
import { getOrElse } from '../../../../utils';
import { ExpertiseTaskAuthEditField as ExpertiseTaskAuthEditFieldInj } from '../edit';
import { ExpertiseLink as ExpertiseLinkInj } from './ExpertiseLink';

export type ExpertiseTaskHeaderDescriptionLeftProps = {
    deadline: ExpertiseTaskModel['deadline'];
    stateTitle: ExpertiseTaskModel['metaInfo']['stateTitle'];
    expertise: ExpertiseTaskModel['expertise'];
    viewPoint: ExpertiseTaskModel['viewPoint'];
    started: ExpertiseTaskModel['started'];
    toggleIsOpenStartDateDialog?: () => void;
    toggleIsOpenDueDateDialog?: () => void;
    entityId: string;
};

export const ExpertiseTaskHeaderDescriptionLeft = (props: ExpertiseTaskHeaderDescriptionLeftProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTaskHeaderDescriptionLeft);
    const [RowData] = di([RowDataInj], ExpertiseTaskHeaderDescriptionLeft);
    const [ExpertiseTaskAuthEditField] = di([ExpertiseTaskAuthEditFieldInj], ExpertiseTaskHeaderDescriptionLeft);
    const [ExpertiseLink] = di([ExpertiseLinkInj], ExpertiseTaskHeaderDescriptionLeft);
    const [SyslogLink] = di([SyslogLinkInj], ExpertiseTaskHeaderDescriptionLeft);

    const {
        deadline,
        stateTitle,
        expertise,
        viewPoint,
        started,
        toggleIsOpenStartDateDialog,
        toggleIsOpenDueDateDialog,
        entityId,
    } = props;
    const intl = useIntl();

    const expertiseValue = expertise?.title || '-';
    const expertiseWrapper = expertise?.id
        ? (children: {} | null): JSX.Element => {
              return (
                  <ExpertiseLink
                      component={NavLink}
                      to={generatePath(clientRoute.expertise, {
                          id: expertise.id,
                      })}
                      underline="none"
                      color="textPrimary"
                  >
                      {children}
                  </ExpertiseLink>
              );
          }
        : undefined;

    const deadlineValue = getOrElse(deadline, intl.formatDate(deadline), '-');
    const stateValue = getOrElse(stateTitle);
    const startedValue = getOrElse(started, intl.formatDate(started), '-');
    const viewPointValue = getOrElse(viewPoint?.title);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item container direction="row" spacing={7} wrap="nowrap" alignItems="center">
                <Grid item>
                    <Grid container alignItems="center" wrap="nowrap">
                        <AuthorizationCheck
                            entityCode={entities.ExpertiseTask}
                            permCode={permissions.ExpertiseTask.View}
                        >
                            <React.Fragment>
                                <Grid item>
                                    <RowData
                                        label={intl.formatMessage({ id: 'expertiseTask.fields.deadline' })}
                                        value={deadlineValue}
                                    />
                                </Grid>
                                <ExpertiseTaskAuthEditField
                                    toggleIsOpenDialog={toggleIsOpenDueDateDialog}
                                    messageId={'expertiseTask.editDeadline'}
                                    permCode={permissions.ExpertiseTask.EditDeadlineAndStartedDates}
                                    entityId={entityId}
                                />
                            </React.Fragment>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
                <Grid item>
                    <RowData label={intl.formatMessage({ id: 'expertiseTask.fields.state' })} value={stateValue} />
                </Grid>
                <Grid item>
                    <SyslogLink />
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={7} wrap="nowrap" alignItems="center">
                <Grid item>
                    <Grid container alignItems="center" wrap="nowrap">
                        <AuthorizationCheck
                            entityCode={entities.ExpertiseTask}
                            permCode={permissions.ExpertiseTask.View}
                        >
                            <React.Fragment>
                                <Grid item>
                                    <RowData
                                        label={intl.formatMessage({ id: 'expertiseTask.fields.started' })}
                                        value={startedValue}
                                    />
                                </Grid>
                                <ExpertiseTaskAuthEditField
                                    toggleIsOpenDialog={toggleIsOpenStartDateDialog}
                                    messageId={'expertiseTask.editStarted'}
                                    permCode={permissions.ExpertiseTask.EditDeadlineAndStartedDates}
                                    entityId={entityId}
                                />
                            </React.Fragment>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'expertiseTask.fields.expertise' })}
                        value={expertiseValue}
                        valueWrapper={expertiseWrapper}
                    />
                </Grid>
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'expertiseTask.fields.viewPoint' })}
                        value={viewPointValue}
                        valueMaxLength={25}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
