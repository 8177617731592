import { Button } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { useModal, useStore } from '../../../hooks';
import { NewSubjectDTO } from '../../../types';
import { CreateSubjectDialog as CreateSubjectDialogInj } from './CreateSubjectDialog';

export const CreateSubjectButton = (): JSX.Element => {
    const [CreateSubjectDialog] = di([CreateSubjectDialogInj], CreateSubjectButton);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], CreateSubjectButton);

    const rootStore = useStore();
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
    const { subjectStore, history } = rootStore;

    const createSubject = (dto: NewSubjectDTO): Promise<void> => {
        return subjectStore.createSubject(dto).then((id) => {
            setModalIsClosed();
            history.push(generatePath(clientRoute.subjectCreate, { id }));
        });
    };

    return (
        <React.Fragment>
            {isModalOpen && <CreateSubjectDialog onCancel={setModalIsClosed} onSubmit={createSubject} />}
            <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.AddExpertiseSubject}>
                <Button color="primary" variant="contained" onClick={setModalIsOpen}>
                    <FormattedMessage id="subject.createSubject" />
                </Button>
            </AuthorizationCheck>
        </React.Fragment>
    );
};
