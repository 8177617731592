import { action, observable } from 'mobx';
import apiConfigs from '../../apiConfigs';
import { Api } from '../../store';
import { RowsData, TableModel, TableQueryData } from '../list';

export interface CategoriesOfSubjectsRowDTO {
    id: string;
    title: string;
    created: string; //date
}

export class CategoriesOfSubjectsListModel extends TableModel<CategoriesOfSubjectsRowDTO, {}, {}> {
    @observable protected api: Api;

    constructor(api: Api) {
        super({}, {});

        this.api = api;
    }

    @action.bound
    getRowsData(queryData: TableQueryData): Promise<RowsData<CategoriesOfSubjectsRowDTO>> {
        return this.api.client(apiConfigs.categoriesOfSubjectsListData(queryData)).then((r) => r.data);
    }
}
