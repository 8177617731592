import { Box, ExpansionPanel, Grid, Link, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { MetaInfoModel } from '../models';
import { getOrElse } from '../utils';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelLabel as TotObjectPanelLabelInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
    TotObjectPanelValue as TotObjectPanelValueInj,
} from './tot-object';

export type ServiceInfoPanelProps = {
    metaInfoModel: MetaInfoModel;
    withForm?: boolean;
    elevation?: number;
};

export const ServiceInfoPanel = observer((props: ServiceInfoPanelProps): JSX.Element => {
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], ServiceInfoPanel);
    const [TotObjectPanelLabel] = di([TotObjectPanelLabelInj], ServiceInfoPanel);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], ServiceInfoPanel);
    const [TotObjectPanelValue] = di([TotObjectPanelValueInj], ServiceInfoPanel);

    const { withForm = true, elevation = 0, metaInfoModel } = props;
    return (
        <ExpansionPanel defaultExpanded elevation={elevation}>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="common.serviceInfo" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item container direction="row">
                        <Grid item xs={6}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.author" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>{metaInfoModel.author.name}</TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.lastModified" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            metaInfoModel.modified,
                                            <FormattedDate value={metaInfoModel.modified} />,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Box pt={8}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="common.serviceInfoFields.lifeCycle" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            metaInfoModel.processLink,
                                            <Link target="_blank" href={metaInfoModel.processLink?.url}>
                                                {metaInfoModel.processLink?.label}
                                            </Link>,
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {withForm && (
                        <Grid item>
                            <Box pt={8}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TotObjectPanelLabel>
                                            <FormattedMessage id="common.serviceInfoFields.form" />
                                        </TotObjectPanelLabel>
                                    </Grid>
                                    <Grid item>
                                        <TotObjectPanelValue>
                                            {getOrElse(
                                                metaInfoModel.formLinks,
                                                metaInfoModel.formLinks.map((item) => {
                                                    return (
                                                        <Link target="_blank" key={item.url} href={item.url}>
                                                            {item.label}
                                                        </Link>
                                                    );
                                                }),
                                            )}
                                        </TotObjectPanelValue>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
});
