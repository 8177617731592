const expertise: Record<string, string | Record<string, string | Record<string, string>>> = {
    expertiseTitle: 'Экспертиза: "{title}"',
    expertiseListTitle: 'Экспертизы',
    createExpertise: 'Создать экспертизу',
    createExpertises: 'Создать экспертизы',
    expertiseSubjectsOfCampaign: 'Объекты экспертизы в кампании "{title}" ({number})',
    newExpertise: 'Новая экспертиза',
    newExpertises: 'Новые экспертизы',
    updatedTasks: 'Список задач обновлен',
    fields: {
        identifier: 'Номер',
        expertiseSubject: 'Объект экспертизы',
        expertiseCampaign: 'Кампания',
        expertiseSubjectIdentifier: 'Номер объекта',
        curator: 'Куратор',
        created: 'Дата создания',
        deadline: 'Срок',
        state: 'Состояние',
        template: 'Шаблон',
        campaign: 'Кампания',
    },
    createDialogFields: {
        expertiseSubject: 'Объект экспертизы',
        templateExpertise: 'Шаблон экспертизы',
        lifeCycle: 'Жизненный цикл экспертизы',
    },
    plan: {
        listTitle: 'План экспертизы',
        fields: {
            numberOfConclusions: 'Количество заключений',
            responseTimeForAJob: 'Срок ответа на задание',
            deadline: 'Срок выполнения',
            numberOfConclusionsText: 'От {min} до {max} заданий',
            responseTimeForAJobText: '{count, number} {count, plural, one {день} few {дня} other {дней}}',
            deadlineText: '{count, number} {count, plural, one {день} few {дня} other {дней}}',
        },
    },
    actions: {
        deleteExpertise: 'Удалить экспертизу',
        editExpertise: 'Редактировать экспертизу',
    },
    pagingInfo:
        'Всего {count, number} экспертиз{count, plural, one {а} few {ы} other {}}. ' +
        'Отображены c {from, number} по {to, number}',
    confirmDeletionInfoText: 'Вы действительно хотите удалить экспертизу "{title}"?',
    changeCurator: 'Сменить куратора',
};

export default expertise;
