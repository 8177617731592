import { Box, Button, Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { AutocompleteField as AutocompleteFieldInj } from '../../components';
import { useAntiDoubleClick, useStore, useYup } from '../../hooks';
import { IdTitle, NewTask } from '../../types';

export type CreateTaskDialogProps = {
    onCancel: () => void;
    onSubmit: (planEntryId: string) => Promise<void>;
};

export type CreateTaskDialogRouteParams = {
    id: string;
};

const initialValues: NewTask = {
    expertisePlanEntry: null,
};

export const CreateTaskDialog = observer((props: CreateTaskDialogProps): JSX.Element => {
    const [AutocompleteField] = di([AutocompleteFieldInj], CreateTaskDialog);

    const { onCancel, onSubmit } = props;
    const [expertisePlanEntriesOptions, setExpertisePlanEntriesOptions] = useState<IdTitle[]>([]);

    const { expertiseTaskStore } = useStore();
    const { id } = useParams<CreateTaskDialogRouteParams>();
    const intl = useIntl();

    useEffect(() => {
        expertiseTaskStore.loadExpertisePlanEntriesSelectOptions(id).then((options: IdTitle[]) => {
            setExpertisePlanEntriesOptions(options);
        });
    }, []);

    const { Yup } = useYup();
    const schema = Yup.object().shape({
        expertisePlanEntry: Yup.object().nullable().required(),
    });

    const onSubmitCallback = (values: NewTask): Promise<void> => {
        return onSubmit((values.expertisePlanEntry as IdTitle).id);
    };

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmitCallback);

    return (
        <Dialog maxWidth="xs" fullWidth open={true} scroll="body">
            <Box pt={4} pr={4}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <IconButton onClick={onCancel}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pl={12} pr={12} pb={12}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="expertiseTask.new" />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Box pt={8}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <Grid container spacing={6} direction="column" justify="center">
                                <Grid item>
                                    <AutocompleteField
                                        fieldName="expertisePlanEntry"
                                        required={true}
                                        disableClearable={true}
                                        options={expertisePlanEntriesOptions}
                                        label={intl.formatMessage({ id: 'expertiseTask.fields.expertisePlanEntry' })}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSending}
                                        endIcon={endIcon}
                                    >
                                        <FormattedMessage id="expertiseTask.create" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={onCancel} fullWidth size="large" variant="contained">
                                        <FormattedMessage id="common.cancel" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </Box>
            </Box>
        </Dialog>
    );
});
