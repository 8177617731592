import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../hooks';
import { UserBlockDialog as UserBlockDialogInj } from '../pages';
import { EditStatusDTO, ModalProps, UserStatus, UserStatusAction } from '../types';
import { actionCodeByUserStatus } from '../utils';
import { ConfirmationDialog as ConfirmationDialogInj, ConfirmationDialog } from './ConfirmationDialog';

export type UserStatusModalProps = ModalProps & {
    id: string;
    login: string;
    name: string;
    userStatusCode: UserStatus;
    reloadData: () => void;
};

export const UserStatusModal = observer((props: UserStatusModalProps): JSX.Element => {
    const [ConfirmationDialog] = di([ConfirmationDialogInj], UserStatusModal);
    const [UserBlockDialog] = di([UserBlockDialogInj], UserStatusModal);

    const { id, login, name, userStatusCode, isOpen, reloadData, setIsClosed } = props;
    const { userStore } = useStore();
    const intl = useIntl();
    const userStatus = actionCodeByUserStatus[userStatusCode];

    const onChangeStatusConfirm = useCallback(
        (reason?: string): Promise<void> => {
            setIsClosed();
            const data: EditStatusDTO = {
                action: userStatus,
                reason,
            };
            return userStore.editUserStatus(id, data).then(reloadData);
        },
        [id, userStatus],
    );

    const message = intl.formatMessage(
        { id: `users.confirmStatusText.${userStatus}` },
        {
            login,
            name,
        },
    );

    const title = intl.formatMessage({ id: `users.confirmStatus.${userStatus}` });

    return (
        <React.Fragment>
            {userStatus === UserStatusAction.block ? (
                <UserBlockDialog
                    title={title}
                    message={message}
                    isOpen={isOpen}
                    onConfirm={onChangeStatusConfirm}
                    setIsClosed={setIsClosed}
                />
            ) : (
                <ConfirmationDialog
                    id="confirm-action"
                    keepMounted
                    open={isOpen}
                    onConfirm={onChangeStatusConfirm}
                    onCancel={setIsClosed}
                    title={title}
                    message={message}
                />
            )}
        </React.Fragment>
    );
});
