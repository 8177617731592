import { FormioSidebarStore } from '@platform/formiojs-react';
import { History } from 'history';
import { action, observable } from 'mobx';
import en from '../resources/locales/en';
import ru from '../resources/locales/ru';
import { AppTheme, ThemeOverrides } from '../themes';
import { RootStoreProps } from '../types';
import { Api, getApi } from './Api';
import { AuthenticationStore, getAuthenticationStore } from './AuthenticationStore';
import { AuthorizationStore, getAuthorizationStore } from './AuthorizationStore';
import { CampaignStore, getCampaignStore } from './CampaignStore';
import { CatalogStore, getCatalogStore } from './CatalogStore';
import { CategoriesOfSubjectsStore, getCategoriesOfSubjectsStore } from './CategoriesOfSubjectsStore';
import { ConsoleStore, getConsoleStore } from './ConsoleStore';
import { ExpertiseStore, getExpertiseStore } from './ExpertiseStore';
import { ExpertiseTaskStore, getExpertiseTaskStore } from './ExpertiseTaskStore';
import { FormioStore, getFormioStore } from './FormioStore';
import { getHeaderLinksStore, HeaderLinksStore } from './HeaderLinksStore';
import { getIntlStore, IntlStore, Locales } from './IntlStore';
import { getModulesStore, ModulesStore } from './ModulesStore';
import { getNotificationStore, NotificationStore } from './NotificationStore';
import { getPersonStore, PersonStore } from './PersonStore';
import { getPfTemplateStore, PfTemplateStore } from './PfTemplateStore';
import { getPresetStore, PresetStore } from './PresetStore';
import { getRegistryStore, RegistryStore } from './RegistryStore';
import { getSubjectStore, SubjectStore } from './SubjectStore';
import { getTemplatesOfExpertiseStore, TemplatesOfExpertiseStore } from './TemplatesOfExpertiseStore';
import { getUserStore, UserStore } from './UserStore';

type Env = {
    apiUrl?: string;
    totCopyrightRu?: string;
    totCopyrightEn?: string;
    totTel?: string;
    totMail?: string;
    appThemeName?: string;
    termsOfServiceLinkRu?: string;
    termsOfServiceLinkEn?: string;
    yaMetrikaId?: number;
};

export class RootStore {
    @observable api: Api;
    @observable authenticationStore: AuthenticationStore;
    @observable authorizationStore: AuthorizationStore;
    @observable intlStore: IntlStore;
    @observable history: History;
    @observable headerLinksStore: HeaderLinksStore;
    @observable personStore: PersonStore;
    @observable formioStore: FormioStore;
    @observable campaignStore: CampaignStore;
    @observable formioSidebarStore: FormioSidebarStore;
    @observable subjectStore: SubjectStore;
    @observable categoriesOfSubjectsStore: CategoriesOfSubjectsStore;
    @observable templatesOfExpertiseStore: TemplatesOfExpertiseStore;
    @observable expertiseStore: ExpertiseStore;
    @observable expertiseTaskStore: ExpertiseTaskStore;
    @observable userStore: UserStore;
    @observable catalogStore: CatalogStore;
    @observable pfTemplateStore: PfTemplateStore;
    @observable presetStore: PresetStore;
    @observable modulesStore: ModulesStore;
    @observable notificationStore: NotificationStore;
    @observable registryStore: RegistryStore;
    @observable features: RootStoreProps['features'] = {};
    @observable env: Env = {} as Env;
    @observable themeOverrides: ThemeOverrides = {} as ThemeOverrides;
    @observable appTheme?: AppTheme;
    @observable consoleStore: ConsoleStore;

    constructor(props: RootStoreProps) {
        this.setObservables(props);

        const { locales, history } = props;
        const messages: Locales = {
            ru: { ...ru, ...locales.ru },
            en: { ...en, ...locales.en },
        };

        const IntlStore = getIntlStore();
        const AuthenticationStore = getAuthenticationStore();
        const HeaderLinksStore = getHeaderLinksStore();
        const Api = getApi();
        const PersonStore = getPersonStore();
        const FormioStore = getFormioStore();
        const CampaignStore = getCampaignStore();
        const AuthorizationStore = getAuthorizationStore();
        const SubjectStore = getSubjectStore();
        const CategoriesOfSubjectsStore = getCategoriesOfSubjectsStore();
        const TemplatesOfExpertiseStore = getTemplatesOfExpertiseStore();
        const ExpertiseStore = getExpertiseStore();
        const ExpertiseTaskStore = getExpertiseTaskStore();
        const UserStore = getUserStore();
        const CatalogStore = getCatalogStore();
        const PfTemplateStore = getPfTemplateStore();
        const PresetStore = getPresetStore();
        const ModulesStore = getModulesStore();
        const NotificationStore = getNotificationStore();
        const RegistryStore = getRegistryStore();
        const ConsoleStore = getConsoleStore();

        this.api = new Api(this);
        this.authenticationStore = new AuthenticationStore(this);
        this.authorizationStore = new AuthorizationStore(this);
        this.intlStore = new IntlStore(this, messages);
        this.history = history;
        this.headerLinksStore = new HeaderLinksStore(this);
        this.notificationStore = new NotificationStore();
        this.registryStore = new RegistryStore(this);
        this.personStore = new PersonStore(this);
        this.formioStore = new FormioStore(this);
        this.campaignStore = new CampaignStore(this);
        this.formioSidebarStore = new FormioSidebarStore();
        this.subjectStore = new SubjectStore(this);
        this.categoriesOfSubjectsStore = new CategoriesOfSubjectsStore(this);
        this.templatesOfExpertiseStore = new TemplatesOfExpertiseStore(this);
        this.expertiseStore = new ExpertiseStore(this);
        this.expertiseTaskStore = new ExpertiseTaskStore(this);
        this.userStore = new UserStore(this);
        this.catalogStore = new CatalogStore(this);
        this.pfTemplateStore = new PfTemplateStore(this);
        this.presetStore = new PresetStore(this);
        this.modulesStore = new ModulesStore(this);
        this.consoleStore = new ConsoleStore(this);
    }

    @action.bound
    setObservables(props: RootStoreProps) {
        const { env, features, themeOverrides } = props;
        this.env = env;
        this.features = features;
        this.themeOverrides = themeOverrides;
    }

    @action.bound
    setAppTheme(appTheme: AppTheme) {
        this.appTheme = appTheme;
    }
}
