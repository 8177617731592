import DataLoader from 'dataloader';
import { action, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import { Api } from './Api';
import { RootStore } from './RootStore';

export type AuthorizationCheckQuery = {
    entityCode: string;
    permCode: string;
    entityId?: string;
};

export type AuthorizationCheckResult = {
    entityCode: string;
    permCode: string;
    entityId?: string;
    allowed: boolean;
};

export class AuthorizationStore {
    @observable protected api: Api;
    private dataLoader = {} as DataLoader<AuthorizationCheckQuery, boolean>;

    constructor(rootStore: RootStore) {
        this.api = rootStore.api;
    }

    @action.bound
    check(query: AuthorizationCheckQuery): Promise<boolean> {
        return this.dataLoader.load(query);
    }

    @action.bound
    checkAll(queries: AuthorizationCheckQuery[]): Promise<boolean[]> {
        return Promise.all(queries.map((query) => this.dataLoader.load(query)));
    }

    @action.bound
    updateDataLoader(): void {
        this.dataLoader = new DataLoader(this.loadGrants);
    }

    @action.bound
    private loadGrants(keys: Readonly<AuthorizationCheckQuery[]>): Promise<boolean[]> {
        return this.api
            .client(apiConfigs.authorizationCheck(keys))
            .then((r) => r.data)
            .then((checkResults: AuthorizationCheckResult[]) => checkResults.map((r) => r.allowed));
    }
}

export const getAuthorizationStore = (): any => {
    const [_AuthorizationStore] = di([AuthorizationStore], getAuthorizationStore);
    return _AuthorizationStore;
};
