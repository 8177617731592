import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const AppContentScreenSizeContainer = withTheme(
    styled.main.attrs(() => ({
        className: 'app-content-screen-size-container',
    }))`
        display: flex;
        height: 100vh;
        overflow: hidden;
        flex: 1;
        flex-direction: column;
    `,
);
