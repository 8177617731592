import { Box, Button, CircularProgress, Dialog, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { observer } from 'mobx-react';
import React, { ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../hooks';
import { IdTitle, IdTitleNull } from '../../../types';

type ExpertiseEditCuratorProps = {
    open: boolean;
    curatorUserId: string;
    onCancel: () => void;
    onSubmit(currentCurator: IdTitle): void;
    isLoading: boolean;
};

const renderInput = (
    label: string,
    required: boolean,
    curatorOptionsLoading: boolean,
    errorText?: string,
): ((params: AutocompleteRenderInputParams) => ReactNode) => {
    return (params: AutocompleteRenderInputParams): ReactNode => (
        <TextField
            {...params}
            required={required}
            label={label}
            variant="outlined"
            error={!!errorText}
            helperText={errorText}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <React.Fragment>
                        {curatorOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
        />
    );
};

export const ExpertiseEditCurator = observer((props: ExpertiseEditCuratorProps) => {
    const { open, onCancel, onSubmit: handlerSubmit, isLoading, curatorUserId } = props;

    const [currentCurator, setCurrentCurator] = useState<IdTitleNull>(null);
    const [validationStarted, setValidationStarted] = useState<boolean>(false);
    const [curatorOptions, setCuratorOptions] = useState<IdTitle[]>([]);
    const [curatorOptionsLoading, setCuratorOptionsLoading] = useState<boolean>(false);
    const [curatorOptionsLoaded, setCuratorOptionsLoaded] = useState<boolean>(false);

    const { expertiseStore, intlStore: intl } = useStore();

    const loadCuratorOptions = useCallback((): void => {
        if (!curatorOptionsLoaded && !curatorOptionsLoading) {
            setCuratorOptionsLoading(true);
            expertiseStore
                .loadCuratorSelectOptions()
                .then((options: IdTitle[]) => {
                    setCuratorOptions(options);
                    setCuratorOptionsLoaded(true);
                })
                .finally(() => {
                    setCuratorOptionsLoading(false);
                });
        }
    }, [curatorOptionsLoaded, curatorOptionsLoading]);

    useEffect(() => {
        if (!curatorOptions.length && open) {
            loadCuratorOptions();
        }
    }, [curatorOptions, open, loadCuratorOptions]);

    useEffect((): void => {
        if (curatorOptions.length !== 0 && open) {
            const currentCurator = curatorOptions.find((curator) => curator.id === curatorUserId);
            setCurrentCurator(currentCurator || null);
        }
    }, [curatorUserId, curatorOptions, open]);

    const onChangeCurator = useCallback(
        (event: ChangeEvent<{}>, value: IdTitleNull): void => {
            setValidationStarted(true);
            setCurrentCurator(value);
        },
        [setValidationStarted, setCurrentCurator],
    );

    const errorCurator = useMemo((): string => {
        if (validationStarted && currentCurator === null) {
            return intl.formatMessage('validation.required');
        }
        return '';
    }, [validationStarted, currentCurator]);

    const handleCloseModal = (): void => {
        onCancel();
        setValidationStarted(false);
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setValidationStarted(true);
        if (currentCurator) {
            handleCloseModal();
            handlerSubmit(currentCurator);
        }
    };

    const autocompleteInput = useMemo(
        () => renderInput(intl.formatMessage('expertise.fields.curator'), true, curatorOptionsLoading, errorCurator),
        [curatorOptionsLoading, errorCurator],
    );

    const getOptionSelectedAutocomplete = (option: IdTitle, value: IdTitle): boolean => {
        return option.id === value.id;
    };

    const getOptionalLabel = (option: IdTitle) => {
        return option.title;
    };

    return (
        <Dialog maxWidth="xs" fullWidth open={open} scroll="body">
            <Box pt={4} pr={4}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pl={12} pr={12} pb={12}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                <FormattedMessage id="expertise.changeCurator" />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Box pt={8}>
                    <form noValidate onSubmit={onSubmit}>
                        <Grid container spacing={6} direction="column" justify="center">
                            <Grid item>
                                <Autocomplete
                                    value={currentCurator}
                                    onChange={onChangeCurator}
                                    getOptionSelected={getOptionSelectedAutocomplete}
                                    getOptionLabel={getOptionalLabel}
                                    options={curatorOptions.slice()}
                                    loading={curatorOptionsLoading}
                                    renderInput={autocompleteInput}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={isLoading}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                >
                                    <FormattedMessage id="common.save" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleCloseModal} fullWidth size="large" variant="contained">
                                    <FormattedMessage id="common.cancel" />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
        </Dialog>
    );
});
