import React from 'react';
import { observer } from 'mobx-react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../../components';
import { ExpertiseStore } from '../../../../store';
import { ExpertiseControlPanelLeft as ExpertiseControlPanelLeftInj } from './ExpertiseControlPanelLeft';
import { ExpertiseControlPanelRight as ExpertiseControlPanelRightInj } from './ExpertiseControlPanelRight';

export type ExpertiseControlPanelProps = {
    expertiseId: string;
    expertiseTitle: string;
    deleteExpertise: () => Promise<void>;
    reloadModel: () => void;
    expertiseStore: ExpertiseStore;
    onLifeCycleTransition: (transitionId: string, taskId: string) => Promise<void>;
};

export const ExpertiseControlPanel = observer((props: ExpertiseControlPanelProps) => {
    const [BottomControlPanel] = di([BottomControlPanelInj], ExpertiseControlPanel);
    const [ExpertiseControlPanelLeft] = di([ExpertiseControlPanelLeftInj], ExpertiseControlPanel);
    const [ExpertiseControlPanelRight] = di([ExpertiseControlPanelRightInj], ExpertiseControlPanel);

    const { expertiseId, expertiseTitle, deleteExpertise, expertiseStore, reloadModel, onLifeCycleTransition } = props;

    return (
        <BottomControlPanel
            left={
                <ExpertiseControlPanelLeft
                    expertiseId={expertiseId}
                    deleteExpertise={deleteExpertise}
                    title={expertiseTitle}
                />
            }
            right={
                <ExpertiseControlPanelRight
                    expertiseId={expertiseId}
                    reloadModel={reloadModel}
                    getLifeCycleTransitions={expertiseStore.getLifeCycleTransitions}
                    onLifeCycleTransition={onLifeCycleTransition}
                />
            }
        />
    );
});
