import { ExpansionPanel, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { NavLink } from 'react-router-dom';
import {
    TotObjectPanelDetails as TotObjectPanelDetailsInj,
    TotObjectPanelLabel as TotObjectPanelLabelInj,
    TotObjectPanelSummary as TotObjectPanelSummaryInj,
} from '../../components';
import { CampaignExpertiseLinks } from '../../models';
import { CampaignExpertise } from '../../store';
import { createLinkWithQueries } from '../../utils';
import { StyledButton as StyledButtonInj, StyledTypography as StyledTypographyInj } from './StyledCampaignComponents';

export type CampaignExpertisesPanelProps = {
    expertises: CampaignExpertise;
    links: CampaignExpertiseLinks;
};

export const CampaignExpertisesPanel = (props: CampaignExpertisesPanelProps): JSX.Element => {
    const [TotObjectPanelDetails] = di([TotObjectPanelDetailsInj], CampaignExpertisesPanel);
    const [TotObjectPanelLabel] = di([TotObjectPanelLabelInj], CampaignExpertisesPanel);
    const [TotObjectPanelSummary] = di([TotObjectPanelSummaryInj], CampaignExpertisesPanel);
    const [StyledButton] = di([StyledButtonInj], CampaignExpertisesPanel);
    const [StyledTypography] = di([StyledTypographyInj], CampaignExpertisesPanel);

    const { expertises, links } = props;
    const { total, active, done, canceled } = links;

    return (
        <ExpansionPanel defaultExpanded>
            <TotObjectPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">
                    <FormattedMessage id="campaign.expertises.title" />
                </Typography>
            </TotObjectPanelSummary>
            <TotObjectPanelDetails>
                <Grid container direction="column">
                    <Grid item container direction="row">
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.expertises.total" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(total.urn, total.queries)}
                                            variant="text"
                                        >
                                            {expertises.total}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.expertises.active" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(active.urn, active.queries)}
                                            variant="text"
                                        >
                                            {expertises.active}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.expertises.done" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(done.urn, done.queries)}
                                            variant="text"
                                        >
                                            {expertises.done}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="campaign.expertises.canceled" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <StyledTypography>
                                        <StyledButton
                                            component={NavLink}
                                            to={createLinkWithQueries(canceled.urn, canceled.queries)}
                                            variant="text"
                                        >
                                            {expertises.canceled}
                                        </StyledButton>
                                    </StyledTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectPanelDetails>
        </ExpansionPanel>
    );
};
