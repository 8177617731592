import { Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    IconButtonLarge as IconButtonLargeInj,
    TotObjectDeleteButton as TotObjectDeleteButtonInj,
} from '../../../components';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';
import { CampaignPageRouteParams } from '../CampaignPage';

interface Props {
    id: CampaignPageRouteParams['id'];
    deleteCampaign: () => Promise<void>;
    title: string;
}

export const ControlPanelLeft = ({ id, deleteCampaign, title }: Props): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ControlPanelLeft);
    const [IconButtonLarge] = di([IconButtonLargeInj], ControlPanelLeft);
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], ControlPanelLeft);

    return (
        <Grid spacing={3} alignItems="center" justify="center" container>
            <Route path={clientRoute.campaign} exact>
                <AuthorizationCheck
                    entityCode={entities.ExpertiseCampaign}
                    permCode={permissions.ExpertiseCampaign.Delete}
                    entityId={id}
                >
                    <TotObjectDeleteButton
                        id="delete-campaign"
                        title={<FormattedMessage id="common.confirmDeletion" />}
                        message={<FormattedMessage id="campaign.confirmDeletionInfoText" values={{ title }} />}
                        onConfirm={deleteCampaign}
                        wrappedComponentProps={{
                            tooltipTitleId: 'campaign.actions.deleteCampaign',
                        }}
                    />
                </AuthorizationCheck>
                <AuthorizationCheck
                    entityCode={entities.ExpertiseCampaign}
                    permCode={permissions.ExpertiseCampaign.Edit}
                    entityId={id}
                >
                    <Grid item>
                        <Tooltip title={<FormattedMessage id="common.edit" />}>
                            <div>
                                <IconButtonLarge
                                    component={NavLink}
                                    to={generatePath(clientRoute.campaignEdit, {
                                        id,
                                    })}
                                >
                                    <EditIcon />
                                </IconButtonLarge>
                            </div>
                        </Tooltip>
                    </Grid>
                </AuthorizationCheck>
            </Route>
        </Grid>
    );
};
