const pfTemplate: Record<string, string | Record<string, string>> = {
    listTitle: 'Templates of printed forms',
    create: 'Create template',
    edit: 'Edit template',
    fields: {
        title: 'Title',
        created: 'Created',
        file: 'File',
    },
    actions: {
        download: 'Download template',
    },
};

export default pfTemplate;
