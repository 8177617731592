import { Button, Grid } from '@material-ui/core';
import { AxiosPromise } from 'axios';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { entities, permissions } from '../../../../authSchemeConfig';
import clientRoute from '../../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    FilesDownload as FilesDownloadInj,
    TotObjectTransitions as TotObjectTransitionsInj,
} from '../../../../components';
import { useStore } from '../../../../hooks';
import { SubjectModel } from '../../../../models';
import { SubjectStore } from '../../../../store';
import { ExpertiseCreateDialog as ExpertiseCreateDialogInj } from '../../../expertise';
import { SubjectPageRouteParams } from '../SubjectPage';

export type SubjectControlPanelRightProps = {
    onSubmit: () => void;
    setModalIsOpen: () => void;
    id: SubjectPageRouteParams['id'];
    isModalOpen: boolean;
    subjectModel: SubjectModel;
    setModalIsClosed: () => void;
    goToSubjectReadPage: () => void;
    subjectStore: SubjectStore;
    onLifeCycleTransition: (transitionId: string, requestId: string, validate?: boolean | undefined) => Promise<void>;
};

const ButtonNoWrap = styled(Button)`
    white-space: nowrap;
`;

export const SubjectControlPanelRight = (props: SubjectControlPanelRightProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], SubjectControlPanelRight);
    const [FilesDownload] = di([FilesDownloadInj], SubjectControlPanelRight);
    const [TotObjectTransitions] = di([TotObjectTransitionsInj], SubjectControlPanelRight);
    const [ExpertiseCreateDialog] = di([ExpertiseCreateDialogInj], SubjectControlPanelRight);

    const {
        onSubmit,
        id,
        setModalIsOpen,
        isModalOpen,
        subjectModel,
        setModalIsClosed,
        goToSubjectReadPage,
        subjectStore,
        onLifeCycleTransition,
    } = props;

    const { intlStore } = useStore();
    const filename = `${intlStore.formatMessage('subject.allFiles', { title: subjectModel.identifier })}.zip`;

    const downloadAllFiles = (): AxiosPromise<Blob> => {
        return subjectStore.downloadSubjectFiles(subjectModel.id);
    };

    const subjectIdTitle = {
        id,
        title: `${subjectModel.identifier} ${subjectModel.title}`,
    };

    return (
        <Grid item container alignItems="baseline" spacing={3} wrap="nowrap">
            <Switch>
                <Route path={clientRoute.subjectCreate} exact>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.subjects)}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.subjectEdit} exact>
                    <Grid item>
                        <Button variant="contained" component={NavLink} to={generatePath(clientRoute.subject, { id })}>
                            <FormattedMessage id="common.cancel" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSubmit}>
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Grid>
                </Route>
                <Route path={clientRoute.subject} exact>
                    <AuthorizationCheck
                        entityCode={entities.ExpertiseSubject}
                        entityId={id}
                        permCode={permissions.ExpertiseSubject.DownloadCommonFile}
                    >
                        <Grid item>
                            <FilesDownload
                                onDownloadClick={downloadAllFiles}
                                filename={filename}
                                buttonText={<FormattedMessage id="subject.downloadAllFiles" />}
                            />
                        </Grid>
                    </AuthorizationCheck>

                    <AuthorizationCheck
                        entityCode={entities.ExpertiseSubject}
                        permCode={permissions.ExpertiseSubject.CreateExpertise}
                        entityId={id}
                    >
                        <Grid item>
                            <ButtonNoWrap variant="contained" onClick={setModalIsOpen}>
                                <FormattedMessage id="expertise.createExpertise" />
                            </ButtonNoWrap>
                            <ExpertiseCreateDialog
                                subject={subjectIdTitle}
                                onClose={setModalIsClosed}
                                open={isModalOpen}
                            />
                        </Grid>
                    </AuthorizationCheck>

                    <TotObjectTransitions
                        objectId={id}
                        updateObjectPage={goToSubjectReadPage}
                        getTransitions={subjectStore.getLifeCycleTransitions}
                        lifeCycleTransition={onLifeCycleTransition}
                    />
                </Route>
            </Switch>
        </Grid>
    );
};
