import { action, observable } from 'mobx';
import { SeverityType } from '../../store';
import { MessageType, NotificationModel } from './NotificationModel';

export class NotificationListModel {
    @observable notifications: Array<NotificationModel> = [];

    @action.bound
    pushNotification(title: string, severity: SeverityType): void {
        const notification = new NotificationModel();
        const messageDetails: MessageType = { title, severity };
        if (this.notifications.length >= 3) {
            this.clearNotifications();
            setTimeout(() => {
                this.notifications = [];
                notification.load(messageDetails);
                this.notifications.unshift(notification);
            }, 300);
        } else {
            notification.load(messageDetails);
            this.notifications.unshift(notification);
        }
        this.removeAfterTimeout(notification);
    }

    @action.bound
    removeAfterTimeout(notification: NotificationModel): void {
        setTimeout(() => {
            notification.closeNotification();
        }, 3000);
    }

    @action.bound
    clearNotifications(): void {
        this.notifications.forEach((m) => {
            m.closeNotification();
        });
    }

    @action.bound
    deleteNotification(id: string): void {
        const notification = this.notifications.find((n) => n.id === id);
        const indexNotification = this.notifications.findIndex((m) => m.id === id);
        notification?.closeNotification();
        setTimeout(() => {
            this.notifications.splice(indexNotification, 1);
        }, 300);
    }
}
