import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { PageHeader as PageHeaderInj, TotObjectHeader as TotObjectHeaderInj } from '../../../components';
import { useModal } from '../../../hooks';
import { UserPersonModel } from '../../../models';
import { UserPageHeaderLeft as UserPageHeaderLeftInj } from './UserPageHeaderLeft';
import { UserPageHeaderTitle as UserPageHeaderTitleInj } from './UserPageHeaderTitle';

export type UserHeaderProps = {
    userPersonModel: UserPersonModel;
    loginAsUser: () => void;
};

export const UserPageHeader = observer((props: UserHeaderProps): JSX.Element => {
    const [UserPageHeaderTitle] = di([UserPageHeaderTitleInj], UserPageHeader);
    const [UserPageHeaderLeft] = di([UserPageHeaderLeftInj], UserPageHeader);
    const [PageHeader] = di([PageHeaderInj], UserPageHeader);
    const [TotObjectHeader] = di([TotObjectHeaderInj], UserPageHeader);

    const { userPersonModel, loginAsUser } = props;
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

    return (
        <TotObjectHeader>
            <PageHeader
                title={
                    <UserPageHeaderTitle
                        userPersonModel={userPersonModel}
                        login={userPersonModel.login}
                        setModalIsOpen={setModalIsOpen}
                        isModalOpen={isModalOpen}
                        setModalIsClosed={setModalIsClosed}
                        loginAsUser={loginAsUser}
                    />
                }
                left={<UserPageHeaderLeft userPersonModel={userPersonModel} />}
            />
        </TotObjectHeader>
    );
});
