import { action, observable } from 'mobx';
import { LinkDTO } from '../types';
import { DateUtils } from '../utils';
import { UserNameModel, UserPersonDTO } from './person';

export interface MetaInfoDTO {
    author: UserPersonDTO;
    created: string; //date
    modified?: string; //date
    stateTitle?: string;
    processLink?: LinkDTO;
    formLinks: LinkDTO[];
}

export class MetaInfoModel {
    @observable author: UserNameModel;
    @observable created?: Date;
    @observable modified?: Date;
    @observable stateTitle?: string;
    @observable processLink?: LinkDTO;
    @observable formLinks: LinkDTO[] = [];

    constructor() {
        this.author = new UserNameModel();
    }

    @action.bound
    load(dto: MetaInfoDTO): MetaInfoModel {
        this.author.load(dto.author);

        const created = new Date(dto.created || '');
        if (DateUtils.isValidDate(created)) {
            this.created = created;
        }

        const modified = new Date(dto.modified || '');
        if (DateUtils.isValidDate(modified)) {
            this.modified = modified;
        }
        this.stateTitle = dto.stateTitle;
        this.processLink = dto.processLink;
        this.formLinks = dto.formLinks;
        return this;
    }
}
