import { Box, Button, Checkbox, FormControlLabel, Grid, Link, Typography } from '@material-ui/core';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import {
    EmailField as EmailFieldInj,
    PasswordField as PasswordFieldInj,
    RedirectByRole as RedirectByRoleInj,
} from '../../../components';
import { useLoginRedirect, useStore } from '../../../hooks';
import { LoginWrapper as LoginWrapperInj } from './LoginWrapper';

// todo: Этот компонент отображается в одном единственном проекте - на стенде детей.
//  Необходимо будет удалить вместе с остальными файлами tszd (и удалить полностью фичу startPageWithLogin)
//  а также удалить StartPage и фичу startPage
export const LoginPage = observer(() => {
    const [LoginWrapper] = di([LoginWrapperInj], LoginPage);
    const [EmailField] = di([EmailFieldInj], LoginPage);
    const [PasswordField] = di([PasswordFieldInj], LoginPage);
    const [RedirectByRole] = di([RedirectByRoleInj], LoginPage);

    const [esiaLogin] = useFeature('esiaLogin');

    const { authenticationStore, personStore } = useStore();
    const { loginModel } = authenticationStore;

    const [isRedirect, backUrl] = useLoginRedirect();

    return (
        <LoginWrapper>
            <Grid container justify="center">
                <Grid item>
                    <Typography variant="h5" className="t-login-title">
                        <Box fontWeight="fontWeightBold">
                            <FormattedMessage id="authentication.loginTitle" />
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
            <Box pt={8}>
                <form noValidate onSubmit={loginModel.login}>
                    <Grid container spacing={6} direction="column">
                        {loginModel.loginFailedMessage && (
                            <Grid item>
                                <Typography className="t-login-failed-message" color="error">
                                    {loginModel.loginFailedMessage}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <EmailField model={loginModel} />
                        </Grid>
                        <Grid item>
                            <PasswordField model={loginModel} newPassword={false} />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                value="top"
                                control={
                                    <Checkbox
                                        className="t-remember-me"
                                        color="primary"
                                        onChange={(event, checked): boolean => (loginModel.rememberMe = checked)}
                                    />
                                }
                                label={<FormattedMessage id="authentication.rememberMe" />}
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                className="t-login"
                                fullWidth
                                size="large"
                                variant="contained"
                                color="secondary"
                                type="submit"
                            >
                                <FormattedMessage id="authentication.login" />
                            </Button>
                        </Grid>
                        {esiaLogin && (
                            <Grid item>
                                <Link
                                    className="t-esia-login"
                                    component={RouterLink}
                                    to={clientRoute.esiaGetAuthUrl}
                                    underline="none"
                                >
                                    <FormattedMessage id="authentication.loginEsia" />
                                </Link>
                            </Grid>
                        )}
                        <Grid item container justify="space-between">
                            <Grid item>
                                <Link
                                    className="t-forgot-password"
                                    component={RouterLink}
                                    to={clientRoute.recoveryPassword}
                                    underline="none"
                                >
                                    <FormattedMessage id="authentication.forgotPassword" />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    className="t-registration"
                                    component={RouterLink}
                                    to={clientRoute.registration}
                                    underline="none"
                                >
                                    <FormattedMessage id="authentication.register" />
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {isRedirect && <RedirectByRole push to={backUrl} />}
        </LoginWrapper>
    );
});
