import { Box, Button, Tooltip, Typography, withTheme } from '@material-ui/core';
import { ButtonTypeMap } from '@material-ui/core/Button/Button';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import styled from 'styled-components';
import { ProgressButton as ProgressButtonInj } from '../../../../components';
import { SaveStatus } from '../../../../types';

const SuccessButton = withTheme(styled(Button)`
    background-image: none;
    background-color: ${({ theme }): string => theme.variables.palette.green} !important;
    color: ${({ theme }): string => theme.variables.palette.mainContrast} !important;
`);

const FailedButton = withTheme(styled(Button)`
    background-image: none;
    background-color: ${({ theme }): string => theme.variables.palette.red} !important;
    color: ${({ theme }): string => theme.variables.palette.mainContrast} !important;
`);

export type SaveButtonProps = {
    status: SaveStatus;
    onSubmit: () => void;
    saveProgress: boolean;
    noOverflow?: boolean;
};

const buttonProps: ButtonTypeMap<{}>['props'] = {
    color: 'primary',
    variant: 'contained',
};

const noOverflowSx: CSSProperties = {
    display: 'flex',
    overflow: 'hidden',
};

export const SaveButton = observer((props: SaveButtonProps): JSX.Element => {
    const [ProgressButton] = di([ProgressButtonInj], SaveButton);

    const { status, onSubmit, saveProgress, noOverflow } = props;
    const [button, setButton] = useState<JSX.Element>(<React.Fragment />);
    const intl = useIntl();
    const saveText = intl.formatMessage({ id: 'common.save' });

    const buttonTextEllipsis = noOverflow ? (
        <Typography variant="inherit" noWrap>
            {saveText}
        </Typography>
    ) : (
        saveText
    );

    useEffect(() => {
        switch (status) {
            case SaveStatus.success:
                setButton(
                    <SuccessButton variant="contained" disabled={true}>
                        {buttonTextEllipsis}
                    </SuccessButton>,
                );
                break;
            case SaveStatus.failed:
                setButton(
                    <Tooltip open={true} title={intl.formatMessage({ id: 'common.errorText' })}>
                        <FailedButton variant="contained" disabled={true}>
                            <Tooltip title={saveText}>
                                <React.Fragment>{buttonTextEllipsis}</React.Fragment>
                            </Tooltip>
                        </FailedButton>
                    </Tooltip>,
                );
                break;
            case SaveStatus.default:
                setButton(
                    <ProgressButton
                        onClick={onSubmit}
                        buttonText={buttonTextEllipsis}
                        isInProgress={saveProgress}
                        buttonProps={buttonProps}
                    />,
                );
        }
    }, [status, saveProgress, noOverflow, onSubmit]);

    return (
        <Tooltip title={saveText}>
            <Box style={noOverflowSx}>{button}</Box>
        </Tooltip>
    );
});
