import { FormatListNumbered } from '@material-ui/icons';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import PeopleIcon from '@material-ui/icons/People';
import SubjectIcon from '@material-ui/icons/Subject';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import { action, computed, observable } from 'mobx';
import React, { CSSProperties } from 'react';
import { di } from 'react-magnetic-di';
import { permissions, permissionsConfig } from '../authSchemeConfig';
import clientRoute from '../clientRoute';
import { ReactComponent as ExpertiseIcon } from '../resources/images/icons/file-document-box-search.svg';
import { ReactComponent as FolderIcon } from '../resources/images/icons/folder.svg';
import { ReactComponent as ShapeIcon } from '../resources/images/icons/shape.svg';
import { ReactComponent as TemplateIcon } from '../resources/images/icons/template-icon.svg';
import {
    AllPermissionHeaderLinks,
    AppHeaderLinkData,
    HeaderLinksConfigs,
    HeaderLinkType,
    LinkPermissions,
    LinksKeysTypeBySize,
    MediaSize,
} from '../types';
import { AuthorizationCheckQuery, AuthorizationStore } from './AuthorizationStore';
import { RootStore } from './RootStore';

export const allHeaderPermissions: AllPermissionHeaderLinks = {
    campaigns: permissionsConfig.viewHeaderLinks(permissions.System.ViewCampaignList),
    subjectsOfExpertise: permissionsConfig.viewHeaderLinks(permissions.System.ViewExpertiseSubjectList),
    categories: permissionsConfig.viewHeaderLinks(permissions.System.Administration),
    expertises: permissionsConfig.viewHeaderLinks(permissions.System.ViewExpertiseList),
    pfTemplates: permissionsConfig.viewHeaderLinks(permissions.System.ViewPfTemplatesList),
    templatesOfExpertise: permissionsConfig.viewHeaderLinks(permissions.System.ViewTemplateExpertiseList),
    expertiseTasks: permissionsConfig.viewHeaderLinks(permissions.System.ViewRegistryTaskList),
    users: permissionsConfig.viewHeaderLinks(permissions.System.ViewUserList),
    console: permissionsConfig.viewHeaderLinks(permissions.System.Administration),
};

export const fontSizeHeaderLinksIcons: CSSProperties = { fontSize: '24px' };
export const allHeaderLinks: HeaderLinksConfigs = {
    campaigns: {
        key: HeaderLinkType.campaigns,
        to: clientRoute.campaigns,
        messageId: 'headerLinks.campaigns',
        startIcon: <WorkOutlineOutlinedIcon style={fontSizeHeaderLinksIcons} />,
    },
    subjectsOfExpertise: {
        key: HeaderLinkType.subjectsOfExpertise,
        to: clientRoute.subjects,
        messageId: 'headerLinks.subjects',
        startIcon: <ShapeIcon />,
    },
    categories: {
        key: HeaderLinkType.categories,
        to: clientRoute.categories,
        messageId: 'headerLinks.categories',
        startIcon: <FolderIcon />,
    },
    expertises: {
        key: HeaderLinkType.expertises,
        to: clientRoute.expertiseList,
        messageId: 'headerLinks.expertiseList',
        startIcon: <ExpertiseIcon />,
    },
    pfTemplates: {
        key: HeaderLinkType.pfTemplates,
        to: clientRoute.pfTemplateList,
        messageId: 'headerLinks.pfTemplates',
        startIcon: <SubjectIcon style={fontSizeHeaderLinksIcons} />,
    },
    templatesOfExpertise: {
        key: HeaderLinkType.templatesOfExpertise,
        to: clientRoute.templatesOfExpertise,
        messageId: 'headerLinks.templatesOfExpertise',
        startIcon: <TemplateIcon />,
    },
    expertiseTasks: {
        key: HeaderLinkType.expertiseTasks,
        to: clientRoute.expertiseTasks,
        exact: true,
        messageId: 'headerLinks.expertiseTasks',
        startIcon: <FormatListNumbered style={fontSizeHeaderLinksIcons} />,
    },
    users: {
        key: HeaderLinkType.users,
        to: clientRoute.users,
        messageId: 'headerLinks.users',
        startIcon: <PeopleIcon style={fontSizeHeaderLinksIcons} />,
    },
    console: {
        key: HeaderLinkType.console,
        to: clientRoute.console,
        messageId: 'headerLinks.console',
        startIcon: <CodeOutlinedIcon />,
    },
};

export const burgerLinks: LinksKeysTypeBySize = {
    defaultSize: [
        HeaderLinkType.categories,
        HeaderLinkType.templatesOfExpertise,
        HeaderLinkType.pfTemplates,
        HeaderLinkType.console,
    ],
    mdSize: [
        HeaderLinkType.users,
        HeaderLinkType.categories,
        HeaderLinkType.templatesOfExpertise,
        HeaderLinkType.pfTemplates,
    ],
    smSize: (Object.keys(HeaderLinkType) as HeaderLinkType[]).filter((key) => key !== HeaderLinkType.expertiseTasks),
};

export class HeaderLinksStore<Link extends string = HeaderLinkType> {
    @observable rootStore: RootStore;
    @observable authorizationStore: AuthorizationStore;

    @observable mediaSize: MediaSize = MediaSize.defaultSize;
    @observable linksPermissions: LinkPermissions<Link> = {};
    @observable firstAvailablePath = '';

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authorizationStore = rootStore.authorizationStore;
    }

    @computed
    get allHeaderPermissions(): AllPermissionHeaderLinks<Link> {
        return allHeaderPermissions as AllPermissionHeaderLinks<Link>;
    }

    @computed
    get allHeaderLinksConfigs(): HeaderLinksConfigs<Link> {
        return allHeaderLinks as unknown as HeaderLinksConfigs<Link>;
    }

    @computed
    get allHeaderLinksKeys(): Link[] {
        return Object.keys(this.allHeaderLinksConfigs) as Link[];
    }

    @computed
    get burgerLinks(): Link[] {
        return (burgerLinks as LinksKeysTypeBySize<Link>)[this.mediaSize];
    }

    @computed
    get burgerLinksConfigs(): AppHeaderLinkData<Link>[] {
        return this.mapLinks(this.burgerLinks);
    }

    @computed
    get mainLinks(): Link[] {
        return this.allHeaderLinksKeys.filter((link) => {
            return this.burgerLinks.indexOf(link) === -1;
        });
    }

    @computed
    get mainLinksConfigs(): AppHeaderLinkData<Link>[] {
        return this.mapLinks(this.mainLinks);
    }

    @computed
    get burgerLinksIsAllowed(): boolean {
        return this.burgerLinks.some((link) => {
            return this.linksPermissions[link];
        });
    }

    @action.bound
    getMainLinks(links: LinksKeysTypeBySize<Link>): AppHeaderLinkData<Link>[] {
        return links[this.mediaSize].map((link) => {
            return this.allHeaderLinksConfigs[link];
        });
    }

    @action.bound
    mapLinks(links: Link[]): AppHeaderLinkData<Link>[] {
        return links.map((link) => {
            return this.allHeaderLinksConfigs[link];
        });
    }

    @action.bound
    checkLinksPermissions(): Promise<void> {
        this.dropFirstAvailablePath();

        const permissionsValues: AuthorizationCheckQuery[] = Object.values(this.allHeaderPermissions);
        const permissionsKeys = Object.keys(this.allHeaderPermissions) as Link[];

        return this.authorizationStore.checkAll([...permissionsValues]).then((permissions) => {
            const linksPermissions: LinkPermissions<Link> = {};

            permissionsKeys.forEach((headerLink, index) => {
                linksPermissions[headerLink] = permissions[index];
            });

            this.setLinksPermissions(linksPermissions);
            this.setFirstAvailablePath();
        });
    }

    @action.bound
    dropFirstAvailablePath(): void {
        this.firstAvailablePath = '';
    }

    @action.bound
    setFirstAvailablePath(): void {
        const mainAvailableLink: Link | undefined = this.mainLinks.find((key) => {
            return this.linksPermissions[key];
        });

        if (mainAvailableLink) {
            this.firstAvailablePath = this.allHeaderLinksConfigs[mainAvailableLink!].to;
            return;
        }

        const burgerAvailableLink: Link | undefined = this.burgerLinks.find((key) => {
            return this.linksPermissions[key];
        });

        this.firstAvailablePath = burgerAvailableLink
            ? this.allHeaderLinksConfigs[burgerAvailableLink!].to
            : clientRoute.notAllowed;
    }

    @action.bound
    setMediaSize(mediaSize: MediaSize): void {
        this.mediaSize = mediaSize;
    }

    @action.bound
    setLinksPermissions(linksPermissions: LinkPermissions<Link>): void {
        this.linksPermissions = linksPermissions;
    }
}

export const getHeaderLinksStore = (): any => {
    const [_HeaderLinksStore] = di([HeaderLinksStore], getHeaderLinksStore);
    return _HeaderLinksStore;
};
