import { Form, FormApi, FormModel, FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { permissionsConfig } from '../../../../authSchemeConfig';
import { AuthorizationCheckAll as AuthorizationCheckAllInj, CheckResult } from '../../../../components';
import { useStore } from '../../../../hooks';
import { ExpertiseTaskReportEditWrapper as ExpertiseTaskReportEditWrapperInj } from './ExpertiseTaskReportEditWrapper';
import { ExpertiseTaskRouteParams } from './ExpertiseTaskViewPage';
import { getFormTitle } from './formTitle';
import { FormTitleInfo as FormTitleInfoInj } from './FormTitleInfo';

export type ExpertiseTaskReportProps = {
    onFormReady(form: FormApi): void;
    formIsChanged?: boolean;
    setFormIsChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    withTitle?: boolean;
};

export const ExpertiseTaskReport = observer(
    (props: ExpertiseTaskReportProps): JSX.Element => {
        const [AuthorizationCheckAll] = di([AuthorizationCheckAllInj], ExpertiseTaskReport);
        const [ExpertiseTaskReportEditWrapper] = di([ExpertiseTaskReportEditWrapperInj], ExpertiseTaskReport);
        const [FormTitleInfo] = di([FormTitleInfoInj], ExpertiseTaskReport);

        const { setFormIsChanged, formIsChanged, onFormReady, withTitle = true } = props;

        const { id } = useParams<ExpertiseTaskRouteParams>();
        const [formModel] = useState<FormModel>(new FormModel(id));
        const { expertiseTaskStore, intlStore } = useStore();
        const { locale } = intlStore;

        let panelTitleText: HTMLElement | null;
        let scrollableElement: HTMLElement | null;
        let isScrolled = false;

        const checkScroll = (e: Event): void => {
            const isScrolledNow = (e.currentTarget as HTMLElement).scrollTop !== 0;
            if (isScrolledNow !== isScrolled) {
                isScrolled = !isScrolled;
                panelTitleText?.classList.toggle('hide-report-title', isScrolledNow);
            }
        };

        useEffect(() => {
            expertiseTaskStore.loadTaskReportForm(id).then(formModel.load);
            return (): void => {
                scrollableElement?.removeEventListener('scroll', (e: Event) => checkScroll(e), true);
            };
        }, []);

        const handleScroll = (): void => {
            panelTitleText = document.querySelector<HTMLElement>('.expertise-task-report-edit-form .panel-title-text');
            scrollableElement = document.querySelector<HTMLElement>('.expertise-task-report-edit-form .card-body');

            scrollableElement?.addEventListener('scroll', (e: Event) => checkScroll(e), true);
        };

        const onFormReadyCallback = useCallback(
            (form: FormApi): void => {
                onFormReady(form);
                handleScroll();
            },
            [onFormReady],
        );

        let formTitleInfo: React.ReactNode | null = null;
        let formClassName = '';
        if (withTitle) {
            const formTitle = getFormTitle(formModel.form);
            formTitleInfo = <FormTitleInfo title={formTitle} />;
            formClassName = 'expertise-task-report-edit-form';
        }

        const queries = useMemo(() => {
            return {
                viewConclusion: permissionsConfig.viewConclusion(id),
                editConclusion: permissionsConfig.editConclusion(id),
            };
        }, [id]);

        return (
            <AuthorizationCheckAll queries={queries} or={true}>
                {(result: CheckResult): JSX.Element => {
                    return result.editConclusion ? (
                        <ExpertiseTaskReportEditWrapper formIsChanged={formIsChanged}>
                            {formTitleInfo}
                            <Form
                                locale={locale}
                                setFormIsChanged={setFormIsChanged}
                                form={formModel}
                                onFormReady={onFormReadyCallback}
                                className={formClassName}
                            />
                        </ExpertiseTaskReportEditWrapper>
                    ) : (
                        <React.Fragment>
                            {formTitleInfo}
                            <FormView
                                locale={locale}
                                onFormReady={handleScroll}
                                form={formModel}
                                className={formClassName}
                            />
                        </React.Fragment>
                    );
                }}
            </AuthorizationCheckAll>
        );
    },
);
