import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj } from '../../../components';
import { UserPersonModel } from '../../../models';

export type UserControlPanelRightProps = {
    id: UserPersonModel['id'];
    saveForm: () => Promise<void>;
};

export const UserControlPanelRight = (props: UserControlPanelRightProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserControlPanelRight);

    const { id, saveForm } = props;

    return (
        <Grid spacing={3} alignItems="center" justify="center" container wrap="nowrap">
            <Switch>
                <AuthorizationCheck entityCode={entities.Account} entityId={id} permCode={permissions.User.UpdateForm}>
                    <Route exact path={clientRoute.userEdit}>
                        <Grid item>
                            <Button variant="contained" component={NavLink} to={generatePath(clientRoute.user, { id })}>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={saveForm}>
                                <FormattedMessage id="common.save" />
                            </Button>
                        </Grid>
                    </Route>
                </AuthorizationCheck>
            </Switch>
        </Grid>
    );
};
