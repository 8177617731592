import { action, observable } from 'mobx';
import { ReactNode } from 'react';
import { RootStore, SubjectStore } from '../../store';
import { IdTitle } from '../../types';

export class CreateSubjectModel {
    @observable private rootStore: RootStore;
    @observable private subjectStore: SubjectStore;

    @observable categoryOptions: IdTitle[] = [];
    @observable campaignOptions: IdTitle[] = [];
    @observable campaignOptionsLoaded = false;
    @observable error: ReactNode = '';
    @observable identifier: string = '';

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.subjectStore = rootStore.subjectStore;
        this.loadCategorySelectOptions();
        this.getSubjectNumber();
    }

    @action.bound
    loadCategorySelectOptions(): void {
        this.subjectStore.loadCategorySelectOptions().then(this.setCategoryOptions);
    }

    @action.bound
    loadCampaignOptions(): void {
        if (!this.campaignOptionsLoaded) {
            this.subjectStore.loadCampaignSelectOptions().then((options) => {
                this.setCampaignOptions(options);
                this.setCampaignOptionsLoaded();
            });
        }
    }

    @action.bound
    getSubjectNumber(): void {
        this.subjectStore.getSubjectNumber().then(this.setIdentifier);
    }

    @action.bound
    setCategoryOptions(categoryOptions: IdTitle[]): void {
        this.categoryOptions = categoryOptions;
    }

    @action.bound
    setCampaignOptions(campaignOptions: IdTitle[]): void {
        this.campaignOptions = campaignOptions;
    }

    @action.bound
    setCampaignOptionsLoaded(): void {
        this.campaignOptionsLoaded = true;
    }

    @action.bound
    setIdentifier(identifier: string): void {
        this.identifier = identifier;
    }

    @action.bound
    setError(error: ReactNode): void {
        this.error = error;
    }
}
