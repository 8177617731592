import { SelectType } from '../../../../types/themeTypes';

export default {
    selectItem: {
        fontSize: 13,
        padding: '9px 0 9px 18px !important',
    },
    selectDropdown: {
        borderRadius: 4,
        boxShadow: '0px 6px 32px 0 rgba(0, 0, 0, 0.09)',
    },
    selectChipMultiple: {
        height: 24,
        fontSize: 11,
        lineHeight: 22,
        borderRadius: 4,
        transition:
            'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    selectChoicesList: {
        paddingTop: 6,
    },
} as SelectType;
