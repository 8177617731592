import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ExpandIcon } from '../../../../resources/images/icons/angle-down.svg';

type ToggleDescriptionButtonProps = {
    collapsed: boolean;
    onClick: MouseEventHandler;
};

export const ToggleDescriptionButton = (props: ToggleDescriptionButtonProps) => {
    const transform = props.collapsed ? 'rotate(-90deg)' : '';
    const transition = 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';
    const tooltip = props.collapsed
        ? 'expertiseTask.headerActions.expandDescriptionTooltip'
        : 'expertiseTask.headerActions.collapseDescriptionTooltip';
    return (
        <Tooltip title={<FormattedMessage id={tooltip} />} placement="bottom-start">
            <div>
                <Button onClick={props.onClick} startIcon={<ExpandIcon style={{ transform, transition }} />}>
                    <FormattedMessage id="expertiseTask.headerActions.toggleDescription" />
                </Button>
            </div>
        </Tooltip>
    );
};
