import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { ConsoleModel } from '../../../models';
import { transitionDefault } from '../../../themes';

export type ResultConsoleControlsProps = {
    model: ConsoleModel;
};

const useStyles = makeStyles(() => ({
    buttonWrapper: {
        transition: transitionDefault,
        opacity: 0.5,

        '&:hover ': {
            opacity: 1,
        },
    },
}));

export const ResultConsoleControls = observer((props: ResultConsoleControlsProps): JSX.Element => {
    const { model } = props;
    const { clearResult, copyResult } = model;
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item className={classes.buttonWrapper}>
                <Tooltip title={intl.formatMessage({ id: 'common.clear' })}>
                    <IconButton onClick={clearResult}>
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item className={classes.buttonWrapper}>
                <Tooltip title={intl.formatMessage({ id: 'common.copy' })}>
                    <IconButton onClick={copyResult}>
                        <FileCopyOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
});
