import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { BottomControlPanel as BottomControlPanelInj } from '../../../components';
import { UserPersonModel } from '../../../models';
import { UserControlPanelLeft as UserControlPanelLeftInj } from './UserControlPanelLeft';
import { UserControlPanelRight as UserControlPanelRightInj } from './UserControlPanelRight';

export type UserControlPanelProps = {
    userPersonModel: UserPersonModel;
    saveForm: () => Promise<void>;
};

export const UserControlPanel = observer((props: UserControlPanelProps): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], UserControlPanel);
    const [UserControlPanelLeft] = di([UserControlPanelLeftInj], UserControlPanel);
    const [UserControlPanelRight] = di([UserControlPanelRightInj], UserControlPanel);

    const { saveForm, userPersonModel } = props;
    const { id } = userPersonModel;
    return (
        <BottomControlPanel
            left={<UserControlPanelLeft id={id} />}
            right={<UserControlPanelRight id={id} saveForm={saveForm} />}
        />
    );
});
