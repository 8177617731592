import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { BottomControlPanel as BottomControlPanelInj } from '../../../components';
import { useModal, useReload, useStore } from '../../../hooks';
import { TransitionsDTO } from '../../../models';
import { ControlPanelLeft as ControlPanelLeftInj } from './ControlPanelLeft';
import { ControlPanelRight as ControlPanelRightInj } from './ControlPanelRight';

export type CampaignControlPanelRouteParams = {
    id: string;
};

export type CampaignControlPanelProps = {
    campaignTitle: string;
    campaignIdentifier: string;
    deleteCampaign: () => Promise<void>;
    onSubmit: () => void;
    getCampaignTransitions: (campaignId: string) => Promise<TransitionsDTO>;
    lifeCycleTransition(transitionId: string, campaignId: string): Promise<void>;
    updateCampaignStatistic: () => void;
};

export const CampaignControlPanel = observer((props: CampaignControlPanelProps): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], CampaignControlPanel);
    const [ControlPanelLeft] = di([ControlPanelLeftInj], CampaignControlPanel);
    const [ControlPanelRight] = di([ControlPanelRightInj], CampaignControlPanel);

    const {
        campaignTitle,
        campaignIdentifier,
        deleteCampaign,
        lifeCycleTransition,
        onSubmit,
        updateCampaignStatistic,
    } = props;

    const { setModalIsOpen, isModalOpen, setModalIsClosed } = useModal();
    const { id } = useParams<CampaignControlPanelRouteParams>();
    const { reloadKey, reloadIncrement } = useReload();
    const { campaignStore } = useStore();
    const history = useHistory();

    const goToReadPage = useCallback((): void => {
        history.push(generatePath(clientRoute.campaign, { id }));
    }, [history, generatePath]);

    return (
        <BottomControlPanel
            left={<ControlPanelLeft id={id} deleteCampaign={deleteCampaign} title={campaignTitle} />}
            right={
                <ControlPanelRight
                    id={id}
                    reloadKey={reloadKey}
                    reloadIncrement={reloadIncrement}
                    updateCampaignStatistic={updateCampaignStatistic}
                    setModalIsOpen={setModalIsOpen}
                    isModalOpen={isModalOpen}
                    campaignTitle={campaignTitle}
                    campaignIdentifier={campaignIdentifier}
                    setModalIsClosed={setModalIsClosed}
                    goToReadPage={goToReadPage}
                    campaignStore={campaignStore}
                    lifeCycleTransition={lifeCycleTransition}
                    onSubmit={onSubmit}
                />
            }
        />
    );
});
