import { FormDTO, FormModel } from '@platform/formiojs-react';
import { action, observable } from 'mobx';

export type ExpertiseTaskSubjectDTO = {
    subjectId: string;
    subjectIdentifier: string;
    commonFormInfo: FormDTO;
    hiddenFormInfo?: FormDTO;
};

export class ExpertiseTaskSubjectModel {
    @observable taskId: string;
    @observable subjectId = '';
    @observable subjectIdentifier = '';
    @observable commonFormModel: FormModel;
    @observable hiddenFormModel?: FormModel;

    constructor(id: string) {
        this.taskId = id;
        this.commonFormModel = new FormModel(id);
    }

    @action.bound
    load(dto: ExpertiseTaskSubjectDTO): void {
        const { commonFormInfo, hiddenFormInfo } = dto;
        this.subjectId = dto.subjectId;
        this.subjectIdentifier = dto.subjectIdentifier;
        this.commonFormModel.load(commonFormInfo);
        if (hiddenFormInfo) {
            this.hiddenFormModel = new FormModel(this.taskId);
            this.hiddenFormModel.load(hiddenFormInfo);
        }
    }
}
