import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../../hooks';
import { ExpertiseTaskTransitionCode, TransitionDialogProps } from '../../types';
import {
    ExpertiseTaskInviteSentToAcceptDialog as ExpertiseTaskInviteSentToAcceptDialogInj,
    ExpertiseTaskInviteToRejectDialog as ExpertiseTaskInviteToRejectDialogInj,
} from './invite';
import { LifeCycleTransitionConfirmationDialog as LifeCycleTransitionConfirmationDialogInj } from './LifeCycleTransitionConfirmationDialog';
import { ExpertiseTaskRejectWithReasonDialog as ExpertiseTaskRejectWithReasonDialogInj } from './reject';

export const TransitionDialog = (props: TransitionDialogProps): JSX.Element => {
    const [ExpertiseTaskInviteSentToAcceptDialog] = di([ExpertiseTaskInviteSentToAcceptDialogInj], TransitionDialog);
    const [ExpertiseTaskInviteToRejectDialog] = di([ExpertiseTaskInviteToRejectDialogInj], TransitionDialog);
    const [LifeCycleTransitionConfirmationDialog] = di([LifeCycleTransitionConfirmationDialogInj], TransitionDialog);
    const [ExpertiseTaskRejectWithReasonDialog] = di([ExpertiseTaskRejectWithReasonDialogInj], TransitionDialog);

    const intl = useIntl();

    const { expertiseTaskStore } = useStore();
    const { sendToReject, sendToRework } = expertiseTaskStore;

    const { dialogComponentCode } = props.transition.params;
    switch (dialogComponentCode) {
        //<editor-fold desk="ExpertiseTaskInvite">
        case ExpertiseTaskTransitionCode.expertiseTaskInviteSentToAccept:
            return <ExpertiseTaskInviteSentToAcceptDialog {...props} />;
        case ExpertiseTaskTransitionCode.expertiseTaskInviteToReject:
            return <ExpertiseTaskInviteToRejectDialog {...props} />;
        case ExpertiseTaskTransitionCode.taskCheckToTaskRework:
            return (
                <ExpertiseTaskRejectWithReasonDialog
                    expertiseTaskFormatMessage={intl.formatMessage({ id: 'expertiseTask.actions.returnForRevision' })}
                    sendMethod={sendToRework}
                    {...props}
                />
            );
        case ExpertiseTaskTransitionCode.expertiseTaskConclusionReject:
            return (
                <ExpertiseTaskRejectWithReasonDialog
                    expertiseTaskFormatMessage={intl.formatMessage({ id: 'expertiseTask.invites.reject' })}
                    sendMethod={sendToReject}
                    {...props}
                />
            );
        //</editor-fold>
        default:
            return <LifeCycleTransitionConfirmationDialog {...props} />;
    }
};
