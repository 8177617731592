import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { ComponentClass, ReactNode } from 'react';
import { ConfirmationDialog, ConfirmationDialogProps } from '../components';

export type WrappedComponentProps<P> = {
    openConfirmDialog: () => void;
} & P;

type DialogProps = Omit<ConfirmationDialogProps, 'open' | 'onConfirm' | 'onCancel' | 'keepMounted'>;
export type WithConfirmDialogProps<P> = DialogProps & {
    onConfirm: () => Promise<void>;
    onOpen?: () => void;
    onClose?: () => void;
    wrappedComponentProps?: P;
};

export function withConfirmDialog<P>(
    WrappedComponent: React.ComponentType<WrappedComponentProps<P>>,
): ComponentClass<WithConfirmDialogProps<P>> {
    class ComponentWithConfirmDialog extends React.Component<WithConfirmDialogProps<P>, {}> {
        static displayName = `WithConfirmDialog(${WrappedComponent.displayName || WrappedComponent.name})`;

        @observable isOpen = false;

        render(): ReactNode {
            // не пускаем onConfirm в ConfirmationDialog
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { onConfirm, onOpen, onClose, wrappedComponentProps, ...dialogProps } = this
                .props as WithConfirmDialogProps<P>;
            return (
                <React.Fragment>
                    <WrappedComponent openConfirmDialog={this.openConfirmDialog} {...(wrappedComponentProps as P)} />
                    <ConfirmationDialog
                        open={this.isOpen}
                        onConfirm={this.handleConfirm}
                        onCancel={this.handleCancel}
                        keepMounted
                        {...dialogProps}
                    />
                </React.Fragment>
            );
        }

        @action.bound
        openConfirmDialog(): void {
            const { onOpen } = this.props;
            this.isOpen = true;
            onOpen && onOpen();
        }

        @action.bound
        handleConfirm(): Promise<void> {
            const { onClose } = this.props;
            return this.props.onConfirm().finally(() => {
                this.isOpen = false;
                onClose && onClose();
            });
        }

        @action.bound
        handleCancel(): void {
            const { onClose } = this.props;
            this.isOpen = false;
            onClose && onClose();
        }
    }

    return observer(ComponentWithConfirmDialog);
}
