import { createStyles, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import clientRoute from '../../../../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj, RowData as RowDataInj } from '../../../../components';
import { SubjectModel } from '../../../../models';

export type SubjectHeaderRightProps = {
    campaign: SubjectModel['campaign'];
    expertise: SubjectModel['expertise'];
};

const useStyles = makeStyles((theme) => {
    return createStyles({
        linkAlike: {
            fontWeight: 400,
            lineHeight: '1.69',
            letterSpacing: '0.2px',
            color: theme.palette.primary.main,
        },
    });
});

export const SubjectHeaderRight = (props: SubjectHeaderRightProps): JSX.Element => {
    const [RowData] = di([RowDataInj], SubjectHeaderRight);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], SubjectHeaderRight);

    const { campaign, expertise } = props;
    const classes = useStyles();
    const intl = useIntl();

    const campaignValue = campaign?.title || '-';
    const campaignWrapper =
        campaign &&
        ((children: JSX.Element): JSX.Element => {
            return (
                <AuthorizationCheck
                    entityCode={entities.ExpertiseCampaign}
                    permCode={permissions.ExpertiseCampaign.View}
                    entityId={campaign.id}
                >
                    {(allowed) => {
                        return allowed ? (
                            <Link
                                component={NavLink}
                                underline="none"
                                to={generatePath(clientRoute.campaign, {
                                    id: campaign.id,
                                })}
                                color="textPrimary"
                            >
                                {children}
                            </Link>
                        ) : (
                            <Typography className={classes.linkAlike}>{children}</Typography>
                        );
                    }}
                </AuthorizationCheck>
            );
        });

    const expertiseValue = expertise?.title || '-';
    const expertiseWrapper =
        expertise &&
        ((children: JSX.Element): JSX.Element => {
            return (
                <AuthorizationCheck
                    entityCode={entities.Expertise}
                    permCode={permissions.Expertise.View}
                    entityId={expertise.id}
                >
                    {(allowed) => {
                        return allowed ? (
                            <Link
                                component={NavLink}
                                underline="none"
                                to={generatePath(clientRoute.expertise, {
                                    id: expertise.id,
                                })}
                                color="textPrimary"
                            >
                                {children}
                            </Link>
                        ) : (
                            <Typography className={classes.linkAlike}>{children}</Typography>
                        );
                    }}
                </AuthorizationCheck>
            );
        });

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'subject.fields.campaign' })}
                        value={campaignValue}
                        valueWrapper={campaignWrapper}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Grid item>
                    <RowData
                        label={intl.formatMessage({ id: 'subject.fields.expertise' })}
                        value={expertiseValue}
                        valueWrapper={expertiseWrapper}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
