import { Button, Checkbox, Container, Dialog, FormControlLabel, Grid, Portal, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { createViewModel, IViewModel } from 'mobx-utils';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import {
    ErrorDialog as ErrorDialogInj,
    ErrorMessage as ErrorMessageInj,
    renderAutoCompleteInput as renderAutoCompleteInputInj,
} from '../../components';
import { checkboxProps } from '../../constants';
import { useError, useStore } from '../../hooks';
import { CodeTitle, UserPersonModel } from '../../models';
import { en, ru } from '../../store';

export type UserEditDialogProps = {
    userPersonModel: UserPersonModel;
    isOpen: boolean;
    close: () => void;
};

export const UserEditDialog = observer((props: UserEditDialogProps): JSX.Element => {
    const { userPersonModel, isOpen, close } = props;

    const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], UserEditDialog);
    const [ErrorDialog] = di([ErrorDialogInj], UserEditDialog);
    const [ErrorMessage] = di([ErrorMessageInj], UserEditDialog);

    const [userRolesSelectData, setUserRolesSelectData] = useState<CodeTitle[]>([]);
    const [viewModel] = useState<UserPersonModel & IViewModel<UserPersonModel>>(createViewModel(userPersonModel));
    const { login, roles } = viewModel;
    const [validationStarted, setValidationStarted] = useState<boolean>(false);

    const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();
    const { userStore } = useStore();
    const intl = useIntl();

    useEffect(() => {
        userStore.userRoleList().then((roles) => {
            setUserRolesSelectData(roles);
        });
    }, [userStore, setUserRolesSelectData]);

    const handleCancel = (): void => {
        viewModel.reset();
        close();
    };

    const errorRoles = useMemo((): string => {
        if (validationStarted && !roles.length) {
            return intl.formatMessage({ id: 'validation.required' });
        }
        return '';
    }, [validationStarted, roles, intl]);

    const errorLogin = useMemo((): string => {
        if (validationStarted && !login) {
            return intl.formatMessage({ id: 'validation.required' });
        }
        return '';
    }, [validationStarted, login, intl]);

    const handleSave = async (): Promise<void> => {
        const { submit } = viewModel;
        setValidationStarted(true);

        if (login && roles.length) {
            try {
                await userStore.saveUserHead(viewModel);
                submit();
            } catch (error) {
                const errorText = ErrorMessage(error);
                setErrorIsOpen(errorText);
            }
            close();
            setValidationStarted(false);
        }
    };

    const onChangeRoles = (event: ChangeEvent<{}>, value: CodeTitle[] | null): void => {
        if (value) {
            viewModel.roles = observable.array(value);
        }
    };

    const onChangeLogin = (event: ChangeEvent<HTMLInputElement>): void => {
        viewModel.login = event.target.value;
    };

    const onChangeLang = (event: ChangeEvent<HTMLInputElement>): void => {
        viewModel.lang = event.target.checked ? en : ru;
    };

    return (
        <React.Fragment>
            <Portal>
                <Dialog maxWidth="sm" fullWidth open={isOpen} scroll="paper">
                    <DialogTitle>
                        <Container maxWidth="lg">
                            <FormattedMessage id="users.editUser" />
                        </Container>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Container maxWidth="lg">
                            <Grid container spacing={6} direction="column" justify="center">
                                <Grid item>
                                    <TextField
                                        required
                                        onChange={onChangeLogin}
                                        fullWidth
                                        label={<FormattedMessage id="users.fields.login" />}
                                        variant="outlined"
                                        error={!!errorLogin}
                                        helperText={errorLogin}
                                        defaultValue={login}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        onChange={onChangeRoles}
                                        getOptionSelected={(option, value) => option.code === value.code}
                                        getOptionLabel={(option) => option.title}
                                        options={userRolesSelectData.slice()}
                                        value={roles.slice()}
                                        multiple
                                        renderInput={renderAutoCompleteInput(
                                            intl.formatMessage({
                                                id: 'users.fields.roles',
                                            }),
                                            true,
                                            errorRoles,
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        value="top"
                                        control={
                                            <Checkbox
                                                {...checkboxProps}
                                                checked={viewModel.lang === en}
                                                onChange={onChangeLang}
                                            />
                                        }
                                        label={<FormattedMessage id="users.fields.lang" />}
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContent>
                    <Container maxWidth="lg">
                        <DialogActions>
                            <Button size="large" fullWidth variant="contained" onClick={handleCancel}>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                            <Button size="large" fullWidth variant="contained" color="primary" onClick={handleSave}>
                                <FormattedMessage id="common.save" />
                            </Button>
                        </DialogActions>
                    </Container>
                </Dialog>
            </Portal>
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
        </React.Fragment>
    );
});
