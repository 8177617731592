import { MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useModal } from '../../../hooks';
import { IdTitle } from '../../../types';
import { ExpertiseCreateDialog as ExpertiseCreateDialogInj } from '../../expertise';

export type CreateExpertiseButtonProps = {
    tabIndex: number;
    subject: IdTitle;
};

export const CreateExpertiseButton = observer((props: CreateExpertiseButtonProps): JSX.Element => {
    const [ExpertiseCreateDialog] = di([ExpertiseCreateDialogInj], CreateExpertiseButton);

    const { tabIndex, subject } = props;
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

    const subjectIdTitle = { id: subject.id, title: subject.title };

    return (
        <React.Fragment>
            <MenuItem key="create-expertise" dense button={true} tabIndex={tabIndex} onClick={setModalIsOpen}>
                <FormattedMessage id="expertise.createExpertise" />
            </MenuItem>
            <ExpertiseCreateDialog subject={subjectIdTitle} onClose={setModalIsClosed} open={isModalOpen} />
        </React.Fragment>
    );
});
