import { Grid } from '@material-ui/core';
import { FormApi } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Route, useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    ServiceInfoPanel as ServiceInfoPanelInj,
    TotObjectForm as TotObjectFormInj,
    TotObjectMain as TotObjectMainInj,
} from '../../components';
import { useReload, useStore } from '../../hooks';
import { PlanEntryListModel } from '../../models';
import { ExpertisePlan as ExpertisePlanInj } from './ExpertisePlan';
import { TemplateOfExpertiseControlPanel as TemplateOfExpertiseControlPanelInj } from './TemplateOfExpertiseControlPanel';
import { TemplateOfExpertiseHeader as TemplateOfExpertiseHeaderInj } from './TemplateOfExpertiseHeader';
import { AuthorizationCheckQuery } from '../../store';
import { maxWidth100 } from '../../constants';

type TemplateOfExpertisePageRouteParams = {
    id: string;
};

const templateOfExpertiseRoutePath = [
    clientRoute.templateOfExpertise,
    clientRoute.templateOfExpertisePlanEntryCreate,
    clientRoute.templateOfExpertisePlanEntryEdit,
];

export const TemplateOfExpertisePage = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], TemplateOfExpertisePage);
    const [ServiceInfoPanel] = di([ServiceInfoPanelInj], TemplateOfExpertisePage);
    const [TotObjectForm] = di([TotObjectFormInj], TemplateOfExpertisePage);
    const [ExpertisePlan] = di([ExpertisePlanInj], TemplateOfExpertisePage);
    const [TemplateOfExpertiseControlPanel] = di([TemplateOfExpertiseControlPanelInj], TemplateOfExpertisePage);
    const [TemplateOfExpertiseHeader] = di([TemplateOfExpertiseHeaderInj], TemplateOfExpertisePage);
    const [TotObjectMain] = di([TotObjectMainInj], TemplateOfExpertisePage);

    const { templatesOfExpertiseStore, intlStore, history } = useStore();
    const { reloadKey, reloadIncrement } = useReload();
    const { id } = useParams<TemplateOfExpertisePageRouteParams>();
    const [formApi, setFormApi] = useState<FormApi>();
    const planEntryListModel = useMemo(() => {
        return new PlanEntryListModel(id, templatesOfExpertiseStore);
    }, [id, templatesOfExpertiseStore]);

    const templatesOfExpertiseModel = useMemo(() => templatesOfExpertiseStore.getTemplatesOfExpertiseModel(id), [id]);

    const updateCard = (): void => {
        templatesOfExpertiseStore.loadTemplateOfExpertiseDTO(id).then(templatesOfExpertiseModel.loadCard);
    };

    useEffect(() => {
        updateCard();
    }, [intlStore.locale, reloadKey]);

    const onFormReady = (form: FormApi): void => {
        setFormApi(form);
    };

    const onSubmit = (): void => {
        if (formApi && formApi.validate()) {
            formApi
                .submit(false)
                .then(() => {
                    return templatesOfExpertiseStore.saveChangeTemplateOfExpertise(
                        id,
                        formApi.getSubmissionWithAdditionalInfo(),
                    );
                })
                .then(() => {
                    reloadIncrement();
                    history.push(generatePath(clientRoute.templateOfExpertise, { id }));
                });
        }
    };

    const deleteTemplateOfExpertise = (): Promise<void> => {
        return templatesOfExpertiseStore.delete(templatesOfExpertiseModel.id).then(() => {
            history.push(generatePath(clientRoute.templatesOfExpertise));
        });
    };

    const validateForm = (onSuccess?: () => Promise<void>): Promise<void> => {
        const isFormValid = (formApi && formApi.validate()) || false;
        if (isFormValid) {
            return onSuccess ? onSuccess() : Promise.resolve();
        }
        return Promise.reject();
    };

    const onLifeCycleTransition = (transitionId: string, taskId: string, validate?: boolean): Promise<void> => {
        const { lifeCycleTransition } = templatesOfExpertiseStore;
        const onSuccess = (): Promise<void> => lifeCycleTransition(transitionId, taskId, reloadIncrement);
        if (validate) {
            return validateForm(onSuccess);
        } else {
            return onSuccess();
        }
    };

    const editAuthProps: AuthorizationCheckQuery = {
        entityCode: entities.TemplateExpertise,
        permCode: permissions.TemplateExpertise.Edit,
        entityId: id,
    };

    const handleHideReadonlyValidation = (): void => {
        templatesOfExpertiseStore.setShowValidation(false);
    };

    const { formModel } = templatesOfExpertiseModel;
    return (
        <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
            <Grid item>
                <TemplateOfExpertiseHeader templatesOfExpertiseModel={templatesOfExpertiseModel} />
            </Grid>
            <TotObjectMain>
                <Grid container spacing={10}>
                    <Grid item container direction="column" spacing={10}>
                        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                            <Grid item>
                                <ServiceInfoPanel metaInfoModel={templatesOfExpertiseModel.metaInfo} />
                            </Grid>
                        </AuthorizationCheck>
                        <Grid item style={maxWidth100}>
                            <TotObjectForm
                                reloadKey={reloadKey}
                                formName="template"
                                model={formModel}
                                editPath={clientRoute.templateOfExpertiseEdit}
                                createPath={clientRoute.templateOfExpertiseCreate}
                                readPath={clientRoute.templateOfExpertise}
                                onFormReady={onFormReady}
                                showReadonlyValidation={templatesOfExpertiseStore.showValidation}
                                hideReadonlyValidation={handleHideReadonlyValidation}
                                editAuthProps={editAuthProps}
                            />
                        </Grid>
                        <Route exact path={templateOfExpertiseRoutePath}>
                            <Grid item>
                                <ExpertisePlan
                                    key={reloadKey}
                                    createPath={clientRoute.templateOfExpertisePlanEntryCreate}
                                    editPath={clientRoute.templateOfExpertisePlanEntryEdit}
                                    ownerPagePath={clientRoute.templateOfExpertise}
                                    ownerEntityCode={entities.TemplateExpertise}
                                    editPermission={permissions.TemplateExpertise.EditExpertisePlan}
                                    model={planEntryListModel}
                                />
                            </Grid>
                        </Route>
                    </Grid>
                </Grid>
            </TotObjectMain>
            <Grid item>
                <TemplateOfExpertiseControlPanel
                    key={reloadKey}
                    templateOfExpertiseTitle={templatesOfExpertiseModel.title}
                    deleteTemplateOfExpertise={deleteTemplateOfExpertise}
                    onSubmit={onSubmit}
                    onLifeCycleTransition={onLifeCycleTransition}
                />
            </Grid>
        </Grid>
    );
});
