import { observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React, { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import { AuthenticationError as AuthenticationErrorInj } from './AuthenticationError';
import { PendingHandler as PendingHandlerInj } from './PendingHandler';

export const EsiaGetAuthUrlPage = observer((): JSX.Element => {
    const [AuthenticationError] = di([AuthenticationErrorInj], EsiaGetAuthUrlPage);
    const [PendingHandler] = di([PendingHandlerInj], EsiaGetAuthUrlPage);

    const { authenticationStore } = useStore();
    const [getAuthUrl, setGetAuthUrl] = useState<Promise<string>>(authenticationStore.esiaGetAuthUrl());

    useEffect(() => {
        setGetAuthUrl(authenticationStore.esiaGetAuthUrl());
    }, [authenticationStore.esiaGetAuthUrl, setGetAuthUrl]);

    const redirectToAuthUrl = (authUrl: string): JSX.Element => {
        window.location.href = authUrl;
        return <React.Fragment />;
    };

    return fromPromise(getAuthUrl).case({
        pending: PendingHandler,
        fulfilled: redirectToAuthUrl,
        rejected: AuthenticationError,
    });
});
