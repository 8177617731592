import { Box, Button, Dialog, Grid, IconButton, MenuItem, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { PasswordField as PasswordFieldInj } from '../../../../components';
import { useLoading, useModal, useStore } from '../../../../hooks';
import { AssignUserPasswordModel } from '../../../../models';
import { UserInfoMessageValues } from '../../../../types';

export type UserAssignPasswordDialogProps = {
    tabIndex: number;
    id: string;
    userInfoMessageValues: UserInfoMessageValues;
    hideMenu: () => void;
};

export const UserAssignPasswordDialog = observer((props: UserAssignPasswordDialogProps): JSX.Element => {
    const [PasswordField] = di([PasswordFieldInj], UserAssignPasswordDialog);

    const { id, tabIndex, userInfoMessageValues, hideMenu } = props;
    const rootStore = useStore();
    const { userStore } = rootStore;
    const [model] = useState<AssignUserPasswordModel>(new AssignUserPasswordModel(rootStore));
    const intl = useIntl();
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
    const { isLoading, enableLoading, disableLoading } = useLoading();

    const closeAssignPasswordDialog = (): void => {
        setModalIsClosed();
        hideMenu();
        model.disableValidationStartedNewPassword();
        model.disableValidationStartedRepeatPassword();
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        enableLoading();

        model.enableValidationStartedNewPassword();
        model.enableValidationStartedRepeatPassword();

        if (model.isAllowedSubmit) {
            userStore
                .assignUserPassword(id, model.newPassword)
                .then(() => {
                    closeAssignPasswordDialog();
                })
                .catch((e: AxiosError) => {
                    model.requestStatus = 'failed';
                    model.failedMessage = e.message || intl.formatMessage({ id: 'common.errorText' });
                })
                .finally(() => disableLoading());
        } else {
            disableLoading();
        }
    };

    return (
        <React.Fragment>
            <MenuItem dense button={true} tabIndex={tabIndex} onClick={setModalIsOpen}>
                <FormattedMessage id="users.actions.passwordAssignment" />
            </MenuItem>

            <Dialog maxWidth="xs" fullWidth open={isModalOpen} scroll="body">
                <Box pt={4} pr={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton onClick={closeAssignPasswordDialog}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={12} pr={12} pb={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h5">
                                <Box fontWeight="fontWeightBold">
                                    <FormattedMessage id="users.editPasswordDialogTitle" />
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid item>
                            <FormattedMessage id="users.editPasswordDialogInfo" values={userInfoMessageValues} />
                        </Grid>
                    </Grid>
                    <Box pt={8}>
                        <form noValidate onSubmit={onSubmit}>
                            <Grid container spacing={6} direction="column" justify="center">
                                {model.requestStatus === 'failed' && (
                                    <Grid item>
                                        <Typography className="t-login-failed-message" color="error">
                                            {model.failedMessage}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item>
                                    <PasswordField model={model.newPasswordModel} />
                                </Grid>
                                <Grid item>
                                    <PasswordField
                                        model={model.repeatPasswordModel}
                                        label={<FormattedMessage id="authentication.repeatPassword" />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={isLoading}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                    >
                                        <FormattedMessage id="common.save" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={closeAssignPasswordDialog}
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                    >
                                        <FormattedMessage id="common.cancel" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
});
