import { Grid } from '@material-ui/core';
import React from 'react';
import { di } from 'react-magnetic-di';
import { TotObjectTransitions as TotObjectTransitionsInj } from '../../../../components';
import { ExpertiseStore } from '../../../../store';

export type ExpertiseControlPanelRightProps = {
    expertiseId: string;
    reloadModel: () => void;
    getLifeCycleTransitions: ExpertiseStore['getLifeCycleTransitions'];
    onLifeCycleTransition: (transitionId: string, taskId: string) => Promise<void>;
};

export const ExpertiseControlPanelRight = (props: ExpertiseControlPanelRightProps): JSX.Element => {
    const [TotObjectTransitions] = di([TotObjectTransitionsInj], ExpertiseControlPanelRight);

    const { expertiseId, reloadModel, getLifeCycleTransitions, onLifeCycleTransition } = props;

    return (
        <Grid item container spacing={3} wrap="nowrap">
            <TotObjectTransitions
                objectId={expertiseId}
                updateObjectPage={reloadModel}
                getTransitions={getLifeCycleTransitions}
                lifeCycleTransition={onLifeCycleTransition}
            />
        </Grid>
    );
};
