import { ThemeOptions } from '@material-ui/core';
import { ThemeOverrides } from '../../types/themeTypes';

const mainFont = '"Roboto", sans-serif';

const createLandingThemeOptions = (overrides: ThemeOverrides): ThemeOptions => {
    const { palette, fonts } = overrides;
    return {
        typography: {
            fontFamily: mainFont,
            fontSize: 18,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
        },
        overrides: {
            MuiLink: {
                root: {
                    color: `${palette.startLink.color} !important`,

                    '&:hover': {
                        color: `${palette.startLink.hoverColor} !important`,
                    },
                },
            },
            MuiTypography: {
                colorSecondary: {
                    color: `${palette.mainContrast} !important`,

                    '&:hover': {
                        color: `${palette.hover} !important`,
                    },
                },
            },
        },
        variables: {
            palette: palette,
            fonts: {
                ...fonts,
                mainFont,
            },
        },
    };
};

export default createLandingThemeOptions;
