const authentication: Record<string, string> = {
    loginTitle: 'Sign in',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember me',
    login: 'Sign in',
    forgotPassword: 'Forgot password?',
    register: 'Sign up',
    loginFailed: 'Incorrect email or password',
    registrationTitle: 'Registration',
    firstName: 'First name',
    middleName: 'Middle name',
    lastName: 'Last name',
    termsIsAccepted: 'I accept the terms of {link}',
    termsLinkTitle: 'the Data Usage Policy',
    emailAlreadyExists: 'Email is already registered',
    confirmingEmailReminder:
        'We already sent confirm link to your email. Please check your email (link expires in 15 minutes) or try to register again later',
    confirmingEmailSent: 'A confirmation email was sent',
    confirmingEmailExpired: 'Sorry, your link has expired. We have already sent you a new one.',
    confirmingEmailNotFound: 'The link is incorrect or has already been used to navigate earlie.',
    recoveryTitle: 'Recovery password',
    sendToken: 'Send',
    recoveryInfo:
        'Please enter your login email address. We will send an email to address used for registration explaining how to reset your password.',
    recoveryLinkSent: 'We sent you an email with the link',
    recoveryPasswordFailed: 'This email is not registered or recovery attempts were too frequent',
    newPasswordTitle: 'Change password',
    newPassword: 'New password',
    repeatPassword: 'Repeat password',
    confirm: 'Confirm',
    passwordsMustMatch: 'Passwords must match',
    linkInvalidOrExpired: 'This link is invalid or expired',
    logout: 'Sign out',
    loginEsia: 'Log in with Gosuslugi',
    error: 'An error has occurred',
};

export default authentication;
