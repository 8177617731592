import { makeStyles } from '@material-ui/core';
import { CSSProperties } from 'react';

export const useConsoleStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        paddingTop: theme.spacing(5.5),
        paddingBottom: theme.spacing(15),
    },
    inner: {
        height: '100%',
    },
    mainBlock: {
        marginBottom: theme.spacing(5),
        maxWidth: '100% !important',
    },
    fullGrow: {
        flexGrow: 1,
    },
    editorWrapper: {
        flexGrow: 1,
        maxWidth: '100% !important',
    },
    editorInner: {
        maxHeight: '60vh',
        height: '100%',
        width: '100%',
        border: `1px solid ${theme.palette.grey[300]}`,
        overflowY: 'auto',
    },
    columnTitleWrapper: {
        marginBottom: theme.spacing(2),
    },
    columnTitle: {
        fontSize: '14px',
        fontWeight: 600,
    },
    resultWrapper: {
        position: 'relative',
    },
    codeWrapperLeft: {
        paddingRight: '8px',
    },
    codeWrapperRight: {
        paddingLeft: '8px',
    },
    resultControlsWrapper: {
        position: 'absolute',
        top: 5,
        right: 5,
        zIndex: 10,
    },
}));

export const consoleEditorStyles: CSSProperties = {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 12,
    minHeight: '100%',
};
