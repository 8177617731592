import { Link, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../../../../hooks';
import { PrintFormFileDTO } from '../../../../../../models';

export type ExpertiseTaskPrintFormsTableTooltipFileProps = {
    file: PrintFormFileDTO;
    templateTitle: string;
    downloadPf(file: PrintFormFileDTO): () => void;
};

export const ExpertiseTaskPrintFormsTableTooltipFile = observer(
    (props: ExpertiseTaskPrintFormsTableTooltipFileProps) => {
        const { file, templateTitle, downloadPf } = props;
        const { intlStore: intl } = useStore();
        const onClickHandler = downloadPf(file);
        return (
            <Tooltip title={intl.formatMessage('common.downloadFile', { fileName: file.filename })}>
                <div>
                    <Link component="button" underline="none" onClick={onClickHandler}>
                        {templateTitle}
                    </Link>
                </div>
            </Tooltip>
        );
    },
);
