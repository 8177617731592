import { Grid } from '@material-ui/core';
import React from 'react';
import { di } from 'react-magnetic-di';
import { EllipsisBox as EllipsisBoxInj } from '../../pages';
import {
    TotObjectHeaderLabel as TotObjectHeaderLabelInj,
    TotObjectHeaderValue as TotObjectHeaderValueInj,
} from '../tot-object';

export type RowDataProps = {
    label: string;
    value: string | JSX.Element | React.ReactNode;
    valueWrapper?: (children: {} | null) => JSX.Element;
    withEllipsis?: boolean;
    valueMaxLength?: number;
};

export const RowData = (props: RowDataProps): JSX.Element => {
    const [EllipsisBox] = di([EllipsisBoxInj], RowData);
    const [TotObjectHeaderLabel] = di([TotObjectHeaderLabelInj], RowData);
    const [TotObjectHeaderValue] = di([TotObjectHeaderValueInj], RowData);

    const { label, value = '', valueMaxLength, withEllipsis = true, valueWrapper } = props;
    const resultValue = withEllipsis ? <EllipsisBox text={value as string} correctLength={valueMaxLength} /> : value;
    return (
        <Grid container alignItems="baseline" spacing={2} style={{ width: 'calc(100% + 10px)' }} wrap="nowrap">
            <Grid item>
                <TotObjectHeaderLabel>{label}</TotObjectHeaderLabel>
            </Grid>
            <Grid item>
                <TotObjectHeaderValue>{valueWrapper ? valueWrapper(resultValue) : resultValue}</TotObjectHeaderValue>
            </Grid>
        </Grid>
    );
};
