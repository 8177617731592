import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { CategoryOfSubjectsModel } from '../../../models';

export type CategoryOfSubjectsPageHeaderTitleProps = {
    title: CategoryOfSubjectsModel['title'];
};

export const CategoryOfSubjectsPageHeaderTitle = (props: CategoryOfSubjectsPageHeaderTitleProps): JSX.Element => {
    const { title } = props;
    return (
        <Grid item>
            <Typography variant="h1">{title}</Typography>
        </Grid>
    );
};
