import { Container, Grid, Typography } from '@material-ui/core';
import { FormApi, validateEditPage } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useStore } from '../../../../hooks';
import { ExpertiseTaskModel } from '../../../../models';
import { SaveStatus } from '../../../../types';
import { scrollToElement } from '../../../../utils';
import { ExpertiseTaskEditCurator as ExpertiseTaskEditCuratorInj } from '../ExpertiseTaskEditCurator';
import { ExpertiseTaskEditDate as ExpertiseTaskEditDateInj } from '../ExpertiseTaskEditDate';
import { ExpertiseTaskViewControlPanel as ExpertiseTaskViewControlPanelInj } from './ExpertiseTaskViewControlPanel';
import { ExpertiseTaskViewHeaderDescription as ExpertiseTaskViewHeaderDescriptionInj } from './ExpertiseTaskViewHeaderDescription';
import {
    ExpertiseTaskHeaderActionsDelimiter as ExpertiseTaskHeaderActionsDelimiterInj,
    TaskViewHeaderActionsContainer as TaskViewHeaderActionsContainerInj,
    TaskViewHeaderContainer as TaskViewHeaderContainerInj,
} from './ExpertiseTaskViewPage.styled';
import { SwitchExpertiseTaskView as SwitchExpertiseTaskViewInj, SwitchViewState } from './SwitchExpertiseTaskView';
import { ToggleDescriptionButton as ToggleDescriptionButtonInj } from './ToggleDescriptionButton';
import { ToggleHeaderButton as ToggleHeaderButtonInj } from './ToggleHeaderButton';

export type ExpertiseTaskViewHeader = {
    taskModel: ExpertiseTaskModel;
    formApi?: FormApi;
    backUrl: string;
    onToggleHeader(): void;
    headerCollapsed: boolean;
    reloadKey: number;
    reloadTaskModel(): void;
    onChangeSwitchViewState?(event: React.ChangeEvent<HTMLInputElement>): void;
    switchViewState?: SwitchViewState;
    setFormIsChanged?: () => void;
    withPaddingRight?: boolean;
};

type routeMatch = {
    params: {
        id: string;
    };
};

export const ExpertiseTaskViewHeader = observer((props: ExpertiseTaskViewHeader): JSX.Element => {
    const [ExpertiseTaskEditCurator] = di([ExpertiseTaskEditCuratorInj], ExpertiseTaskViewHeader);
    const [ExpertiseTaskEditDate] = di([ExpertiseTaskEditDateInj], ExpertiseTaskViewHeader);
    const [ExpertiseTaskHeaderActionsDelimiter] = di([ExpertiseTaskHeaderActionsDelimiterInj], ExpertiseTaskViewHeader);
    const [ExpertiseTaskViewControlPanel] = di([ExpertiseTaskViewControlPanelInj], ExpertiseTaskViewHeader);
    const [ExpertiseTaskViewHeaderDescription] = di([ExpertiseTaskViewHeaderDescriptionInj], ExpertiseTaskViewHeader);
    const [TaskViewHeaderActionsContainer] = di([TaskViewHeaderActionsContainerInj], ExpertiseTaskViewHeader);
    const [TaskViewHeaderContainer] = di([TaskViewHeaderContainerInj], ExpertiseTaskViewHeader);
    const [SwitchExpertiseTaskView] = di([SwitchExpertiseTaskViewInj], ExpertiseTaskViewHeader);
    const [ToggleDescriptionButton] = di([ToggleDescriptionButtonInj], ExpertiseTaskViewHeader);
    const [ToggleHeaderButton] = di([ToggleHeaderButtonInj], ExpertiseTaskViewHeader);

    const intl = useIntl();

    const { expertiseTaskStore } = useStore();

    const [isLoadingSaved, setIsLoadingSaved] = useState<boolean>(false);
    const [status, setStatus] = useState<SaveStatus>(SaveStatus.default);
    const [descriptionCollapsed, setDescriptionCollapsed] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenStartDateDialog, setIsOpenStartDateDialog] = useState<boolean>(false);
    const [isOpenDueDateDialog, setIsOpenDueDateDialog] = useState<boolean>(false);

    const {
        formApi,
        backUrl,
        reloadKey,
        onChangeSwitchViewState,
        switchViewState,
        onToggleHeader,
        headerCollapsed,
        taskModel,
        reloadTaskModel,
        setFormIsChanged,
        withPaddingRight,
    } = props;

    const history = useHistory();
    const match: routeMatch = useRouteMatch();
    const { id } = match.params;

    const toggleIsOpen = (): void => {
        setIsOpen(!isOpen);
    };
    const toggleIsOpenStartDateDialog = (): void => {
        setIsOpenStartDateDialog(!isOpenStartDateDialog);
    };
    const toggleIsOpenDueDateDialog = (): void => {
        setIsOpenDueDateDialog(!isOpenDueDateDialog);
    };

    const changeExpertiseTaskCurator = (curatorUserId: string): Promise<void> => {
        return expertiseTaskStore.changeCurator(id, curatorUserId).then(() => {
            reloadTaskModel();
            toggleIsOpen();
        });
    };

    const changeStartedDate = (started: Date): void => {
        expertiseTaskStore.changeStartedDate(id, started).then(() => {
            reloadTaskModel();
        });
        toggleIsOpenStartDateDialog();
    };

    const changeDueDate = (deadline: Date): void => {
        expertiseTaskStore.changeDueDate(id, deadline).then(() => {
            reloadTaskModel();
        });
        toggleIsOpenDueDateDialog();
    };

    const onSubmit = (needValidate: boolean): Promise<void> => {
        if (formApi) {
            const { submission } = formApi.getSubmissionWithAdditionalInfo();
            const expertiseInfo = formApi.getExpertiseInfo();
            
            if (needValidate && !formApi.validate()) {
                return Promise.resolve();
            }
            
            setIsLoadingSaved(true);
            return formApi
                .submit(false)
                .then(() => {
                    return expertiseTaskStore.updateTaskReportForm(id, submission, expertiseInfo);
                })

                .then(() => {
                    setStatus(SaveStatus.success);
                    setFormIsChanged && setFormIsChanged();
                })
                .catch(() => {
                    setStatus(SaveStatus.failed);
                })
                .finally(() => {
                    setIsLoadingSaved(false);
                    setTimeout(() => setStatus(SaveStatus.default), 3000);
                });
        }
        return Promise.resolve();
    };

    const onValidateSubmit = (): Promise<void> => {
        return onSubmit(true);
    };

    const onNoValidateSubmit = (): Promise<void> => {
        return onSubmit(false);
    };

    const deleteExpertiseTask = (): Promise<void> => {
        return expertiseTaskStore.delete(id).then(() => {
            history.push(backUrl);
        });
    };

    const validateForm = async (onSuccess?: () => Promise<void>): Promise<void> => {
        try {
            const isFormValid = (formApi && formApi.validate()) || false;
            await validateEditPage(formApi);
            if (isFormValid) {
                return onSuccess ? onSuccess() : Promise.resolve();
            }
        } catch (errors) {
            const errorsIsArray = Array.isArray(errors);
            if (errorsIsArray) {
                const elementId = errors[0].component.id;
                scrollToElement(elementId);

                const isContainsRequiredError = errors.some(
                    (error: { message?: string }) =>
                        error.message === 'Это поле обязательное' || error.message?.includes('is required'),
                );
                if (isContainsRequiredError) {
                    return Promise.reject({ messageId: 'expertiseTask.requiredFieldsAreNotFilled' });
                }
            }
            return Promise.reject(errors);
        }
    };

    const onLifeCycleTransition = async (transitionId: string, taskId: string, validate?: boolean): Promise<void> => {
        const { lifeCycleTransition } = expertiseTaskStore;
        const onSuccess = (): Promise<void> => {
            return lifeCycleTransition(transitionId, taskId, backUrl).finally(() => {
                onValidateSubmit();
            });
        };
        if (validate) {
            return validateForm(onSuccess);
        } else {
            return onSuccess();
        }
    };

    const onToggleDescription = (): void => {
        setDescriptionCollapsed(!descriptionCollapsed);
    };

    return (
        <React.Fragment>
            <ExpertiseTaskEditCurator
                open={isOpen}
                onCancel={toggleIsOpen}
                onSubmit={changeExpertiseTaskCurator}
                taskModel={taskModel}
            />
            {isOpenStartDateDialog && (
                <ExpertiseTaskEditDate
                    open={isOpenStartDateDialog}
                    onCancel={toggleIsOpenStartDateDialog}
                    onSubmit={changeStartedDate}
                    currentDate={new Date(taskModel.started ? taskModel.started : Date.now())}
                    label={intl.formatMessage({ id: 'expertiseTask.fields.started' }) + '*'}
                    title={<FormattedMessage id="expertiseTask.changeStartDate" />}
                    maxValue={taskModel.deadline ? new Date(taskModel.deadline) : undefined}
                />
            )}
            {isOpenDueDateDialog && (
                <ExpertiseTaskEditDate
                    open={isOpenDueDateDialog}
                    onCancel={toggleIsOpenDueDateDialog}
                    onSubmit={changeDueDate}
                    currentDate={new Date(taskModel.deadline ? taskModel.deadline : Date.now())}
                    label={intl.formatMessage({ id: 'expertiseTask.fields.deadline' }) + '*'}
                    title={<FormattedMessage id="expertiseTask.changeOfDueDate" />}
                    minValue={taskModel.started ? new Date(taskModel.started) : undefined}
                />
            )}
            <TaskViewHeaderContainer withPaddingRight={withPaddingRight} item>
                <Container maxWidth="lg">
                    <Grid container spacing={3} direction="column" style={{ padding: '8px 0' }}>
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="expertiseTask.title" values={{ number: taskModel.identifier }} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                <TaskViewHeaderActionsContainer item>
                    <Container maxWidth="lg">
                        <Grid container item direction="row" wrap="nowrap">
                            <Grid item style={{ flex: 1, padding: '6px 0' }}>
                                <ToggleDescriptionButton
                                    onClick={onToggleDescription}
                                    collapsed={descriptionCollapsed}
                                />
                            </Grid>
                            <Grid item>
                                <ExpertiseTaskViewControlPanel
                                    key={reloadKey}
                                    expertiseTaskModel={taskModel}
                                    saveProgress={isLoadingSaved}
                                    status={status}
                                    deleteExpertiseTask={deleteExpertiseTask}
                                    onSubmit={onNoValidateSubmit}
                                    onLifeCycleTransition={onLifeCycleTransition}
                                    updateTaskViewPage={reloadTaskModel}
                                />
                            </Grid>
                            {switchViewState && onChangeSwitchViewState && (
                                <React.Fragment>
                                    <ExpertiseTaskHeaderActionsDelimiter item />
                                    <Grid item>
                                        <SwitchExpertiseTaskView
                                            viewState={switchViewState}
                                            labels={{
                                                left: <FormattedMessage id="expertiseTask.switchView.task" />,
                                                right: <FormattedMessage id="expertiseTask.switchView.report" />,
                                            }}
                                            handleChange={onChangeSwitchViewState}
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                            <ExpertiseTaskHeaderActionsDelimiter
                                item
                                style={{ marginLeft: '16px', marginRight: '16px' }}
                            />
                            <Grid item style={{ padding: '6px 0' }}>
                                <ToggleHeaderButton onClick={onToggleHeader} collapsed={headerCollapsed} />
                            </Grid>
                        </Grid>
                    </Container>
                </TaskViewHeaderActionsContainer>
                <ExpertiseTaskViewHeaderDescription
                    key={reloadKey}
                    toggleIsOpen={toggleIsOpen}
                    toggleIsOpenStartDateDialog={toggleIsOpenStartDateDialog}
                    toggleIsOpenDueDateDialog={toggleIsOpenDueDateDialog}
                    expertiseTaskModel={taskModel}
                    collapsed={descriptionCollapsed}
                />
            </TaskViewHeaderContainer>
        </React.Fragment>
    );
});
