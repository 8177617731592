import { Button, CircularProgress, Grid, useTheme } from '@material-ui/core';
import { ButtonTypeMap } from '@material-ui/core/Button/Button';
import React from 'react';

export type ProgressButtonProps = {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    buttonText: React.ReactNode;
    isInProgress: boolean;
    buttonProps?: ButtonTypeMap<{}>['props'];
};

export const ProgressButton = (props: ProgressButtonProps): JSX.Element => {
    const { onClick, buttonText, isInProgress, buttonProps } = props;
    const theme = useTheme();

    return (
        <Button onClick={onClick} disabled={isInProgress} {...buttonProps}>
            {buttonText}
            {isInProgress && (
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{ position: 'absolute', top: 1, left: 0, height: '100%' }}
                >
                    <Grid item>
                        <CircularProgress
                            size={24}
                            style={{
                                color: theme?.variables.palette.main,
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </Button>
    );
};
