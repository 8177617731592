const expertiseTask: Record<string, string | Record<string, string | Record<string, string>>> = {
    listTitle: 'Expertise tasks',
    create: 'Create task',
    new: 'New task',
    title: 'Expertise task № {number}',
    saveWarning: 'Don’t forget to press the “Save” button!',
    fields: {
        number: 'Number',
        taskType: 'Type of task',
        expert: 'Expert',
        expertiseNumber: 'Expertise',
        curator: 'Task manager',
        created: 'Created',
        deadline: 'Due date',
        state: 'State',
        expertisePlanEntry: 'Expertise plan entry',
        expertise: 'Expertise',
        viewPoint: 'Point of view',
        campaign: 'Campaign',
        started: 'Start date',
        withoutExpert: 'Tasks without expert',
    },
    editDeadline: 'Change due date',
    editStarted: 'Change start date',
    curator: {
        name: 'Name',
        phone: 'Phone',
        email: 'Email',
    },
    expertCandidate: '{expert} (picked)',
    expertiseTasksTable: {
        fields: {
            number: 'Task number',
            planEntry: 'Plan entry',
            expert: 'Expert',
            deadline: 'Deadline',
            responseTime: 'The response time',
            actualDateOfTheExecution: 'The actual date of the execution',
            stateTitle: 'State',
        },
        daysPlan: 'd (plan)',
        datePlan: ' (plan)',
    },
    selectExpertButton: 'Select an expert',
    selectionOfExpert: {
        title: 'Selection of expert',
        buttonLabel: 'Select',
    },
    actions: {
        delete: 'Delete task',
        edit: 'Edit task',
        returnForRevision: 'Return for revision',
    },
    headerActions: {
        collapseHeader: 'Collapse header',
        expandHeader: 'Expand header',
        toggleDescription: 'Description',
        collapseDescriptionTooltip: 'Collapse description',
        expandDescriptionTooltip: 'Expand description',
    },

    registryPagingInfo:
        'Total {count, number} task{count, plural, one {} other {s}}. Displayed from {from, number} to {to, number}',
    listConfirmDeletionInfoText: 'Are you sure you want to delete the expertise task?',
    confirmDeletionInfoText: 'Are you sure you want to delete the expertise task № {number}?',
    errorText: 'To continue, select an expert from the list',
    prohibitedErrorText: 'Changing the expert is prohibited',
    tabs: {
        task: 'Task',
        subject: 'Object',
    },
    localConclusions: {
        title: 'Expert reports',
        fields: {
            report: 'Report',
            deadline: 'Deadline',
            completed: 'Completed',
        },
    },
    invites: {
        agree: 'I agree to terms conducting expertise',
        agreement:
            'When evaluating grant proposal, expert should be guided by the following conditions:\n' +
            '• Competence\n' +
            'By agreeing to conduct an evaluation, the expert confirms that he/she has the necessary knowledge and experience to conduct a quality evaluation in the subject area.\n' +
            '• No conflicts of interests\n' +
            'If the expert is affiliated with the applicant, then he/she must immediately notify the examination curator about it.\n' +
            '• Confidentiality\n' +
            'The expert must ensure the confidentiality of any information contained in the documents submitted for evaluation. The expert is not entitled to disclose any information to third parties.\n' +
            '• Independence\n' +
            'The expert is not entitled to discuss the examination process with any third parties (including applicant), except for the examination curator.\n' +
            'The expert declares no conflict of interest.\n' +
            '\n' +
            'Conflict of interests occurs in cases when the expert:\n' +
            '• participated in the preparation of the research proposal,\n' +
            '• is in a labor relationship with the applicant,\n' +
            '• performed work or services for the applicant (within last 5 years),\n' +
            '• stands to gain or lose financially if the proposal approved,\n' +
            '• someone with whom the expert has a close relationship is in a labor relationship with the applicant,\n' +
            '• someone with whom the expert has a close relationship performed work or services for the applicant (within last 5 years),\n' +
            '• someone with whom the expert has a close relationship stands to gain or lose financially if the proposal approved,\n' +
            '• is influenced by other circumstances that hinder the objective evaluation of the proposal.',
        accept: 'Accept task',
        reject: 'Reject task',
        rejectReason: 'Reject reason',
        otherReason: 'Other',
    },
    printFormsTable: {
        title: 'Printed forms',
        updateAction: 'Update file',
        createAction: 'Generate file',
        conclusion: 'Expert evaluation report',
        generationError: 'An error occurred while generating the print form',
        noPrintForms: 'No printed forms',
    },
    switchView: {
        task: 'Task',
        report: 'Report',
    },
    changeStartDate: 'Change start date',
    changeOfDueDate: 'Change of due date',
    requiredFieldsAreNotFilled: 'Please fill in all required fields',
};

export default expertiseTask;
