import { Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import {
    ErrorDialog as ErrorDialogInj,
    ErrorMessage as ErrorMessageInj,
    IconButtonLarge as IconButtonLargeInj,
    TotObjectDeleteButton as TotObjectDeleteButtonInj,
} from '../../../components';
import { useError } from '../../../hooks';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';

export type CategoryOfSubjectsControlPanelLeftProps = {
    categoryOfSubjectsTitle: string;
    deleteCategoryOfSubjects: () => Promise<void>;
    categoryOfSubjectsId: string;
};

export const CategoryOfSubjectsControlPanelLeft = (props: CategoryOfSubjectsControlPanelLeftProps): JSX.Element => {
    const [ErrorDialog] = di([ErrorDialogInj], CategoryOfSubjectsControlPanelLeft);
    const [ErrorMessage] = di([ErrorMessageInj], CategoryOfSubjectsControlPanelLeft);
    const [IconButtonLarge] = di([IconButtonLargeInj], CategoryOfSubjectsControlPanelLeft);
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], CategoryOfSubjectsControlPanelLeft);

    const { categoryOfSubjectsTitle, deleteCategoryOfSubjects, categoryOfSubjectsId } = props;
    const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();

    const handleDeleteCategory = (): Promise<void> => {
        return deleteCategoryOfSubjects().catch((error) => {
            const errorText = ErrorMessage(error);
            setErrorIsOpen(errorText);
        });
    };

    return (
        <React.Fragment>
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            <Grid spacing={3} alignItems="center" justify="center" container>
                <Switch>
                    <Route exact path={clientRoute.category}>
                        <Grid item>
                            <TotObjectDeleteButton
                                id="delete-category"
                                title={<FormattedMessage id="common.confirmDeletion" />}
                                message={
                                    <FormattedMessage
                                        id="category.confirmDeletionInfoText"
                                        values={{ title: categoryOfSubjectsTitle }}
                                    />
                                }
                                onConfirm={handleDeleteCategory}
                                wrappedComponentProps={{
                                    tooltipTitleId: 'category.actions.deleteCategory',
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title={<FormattedMessage id="category.actions.editCategory" />}>
                                <div>
                                    <IconButtonLarge
                                        component={NavLink}
                                        to={generatePath(clientRoute.categoryEdit, {
                                            id: categoryOfSubjectsId,
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButtonLarge>
                                </div>
                            </Tooltip>
                        </Grid>
                    </Route>
                </Switch>
            </Grid>
        </React.Fragment>
    );
};
