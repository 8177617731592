const subject: Record<string, string | Record<string, string>> = {
    subjectListTitle: 'Объекты экспертизы',
    createSubject: 'Создать объект',
    newSubject: 'Новый объект',
    allFiles: 'Все документы по {title}',
    downloadAllFiles: 'Скачать все файлы',
    fields: {
        title: 'Наименование',
        identifier: 'Номер',
        created: 'Дата создания',
        state: 'Состояние',
        campaign: 'Кампания',
        expertise: 'Экспертиза',
        withoutExpertise: 'Объекты без экспертизы',

        author: 'Автор',
        lastModified: 'Последнее изменение',
        category: 'Категория',
        lifecycle: 'Жизненный цикл',
        form: 'Форма',
    },
    actions: {
        deleteSubject: 'Удалить объект',
        editSubject: 'Редактировать объект',
    },
    registryPagingInfo:
        'Всего {count, number} объект{count, plural, one {} few {а} other {ов}}. ' +
        'Отображены c {from, number} по {to, number}',
    blocks: {
        serviceInfo: 'Служебная информация',
    },
    confirmDeletionInfoText: 'Вы действительно хотите удалить объект экспертизы "{title}"?',
};

export default subject;
