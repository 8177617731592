import { ReactNode, useState } from 'react';

export type UseError = {
    isError: boolean;
    errorText: string | ReactNode;
    setErrorIsClosed: () => void;
    setErrorIsOpen: (text: string | ReactNode) => void;
};

export const useError = (): UseError => {
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string | ReactNode>();
    const setErrorIsClosed = (): void => setIsError(false);
    const setErrorIsOpen = (text: string | ReactNode): void => {
        setErrorText(text);
        setIsError(true);
    };

    return { isError, errorText, setErrorIsClosed, setErrorIsOpen };
};
