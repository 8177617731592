import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Portal, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useAntiDoubleClick, useFlag, useStore } from '../../../hooks';
import { CodeTitleNull } from '../../../models';
import { CatalogCodes, CatalogItemCodes } from '../../../store';
import { TransitionDialogProps } from '../../../types';
import { renderAutoCompleteInput as renderAutoCompleteInputInj } from '../../renderAutoCompleteInput';

export const ExpertiseTaskInviteToRejectDialog = observer((props: TransitionDialogProps): JSX.Element => {
    const [renderAutoCompleteInput] = di([renderAutoCompleteInputInj], ExpertiseTaskInviteToRejectDialog);

    const { transition, objectId, handleErrorTransition, handleSuccessTransition, setModalIsClosed, isModalOpen } =
        props;
    const { expertiseTaskStore, catalogStore } = useStore();
    const intl = useIntl();
    const reasonsCatalog = useMemo(() => catalogStore.catalogItems(CatalogCodes.ReasonsOfRejectInvite), []);
    const [reason, setReason] = useState<CodeTitleNull>(null);
    const [otherReason, setOtherReason] = useState<string>('');
    const [validationStarted, setValidationStarted] = useFlag();

    const isOtherReason = useMemo<boolean>((): boolean => {
        return !!reason && reason.code === CatalogItemCodes.ReasonsOfRejectInvite.Other;
    }, [reason]);

    const reasonText = useMemo<string>((): string => {
        return reason && !isOtherReason ? reason.title : otherReason;
    }, [reason, isOtherReason, otherReason]);

    const isValid = useMemo<boolean>((): boolean => !!reasonText, [reasonText]);

    const handleConfirm = async (): Promise<void> => {
        setValidationStarted();
        if (isValid) {
            expertiseTaskStore
                .rejectTaskInvite(transition.id, objectId, reasonText)
                .then(handleSuccessTransition)
                .catch(handleErrorTransition)
                .finally(setModalIsClosed);
        }
    };

    const [isLoading, startIcon, onSubmitHandler] = useAntiDoubleClick(handleConfirm);

    const onChangeReason = (event: ChangeEvent<{}>, value: CodeTitleNull): void => {
        setReason(value);
        setOtherReason('');
    };

    const onChangeOtherReason = (event: ChangeEvent<HTMLInputElement>): void => {
        setOtherReason(event.target.value);
    };

    const reasonError = useMemo<string>((): string => {
        const isRequiredValidation: boolean = validationStarted && !reason;

        return isRequiredValidation ? intl.formatMessage({ id: 'validation.required' }) : '';
    }, [validationStarted, reason, intl]);

    const otherReasonError = useMemo<string>((): string => {
        const isRequiredValidation: boolean = validationStarted && isOtherReason && !otherReason;

        return isRequiredValidation ? intl.formatMessage({ id: 'validation.required' }) : '';
    }, [validationStarted, isOtherReason, otherReason]);

    const renderInput = renderAutoCompleteInput(
        intl.formatMessage({ id: 'expertiseTask.invites.rejectReason' }),
        true,
        reasonError,
    );

    return (
        <Portal>
            <Dialog fullWidth maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={isModalOpen}>
                <DialogTitle id="confirmation-dialog-title">
                    <FormattedMessage id="common.lifeCycleConfirmTitle" />
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Autocomplete
                                onChange={onChangeReason}
                                getOptionSelected={(option, value) => option.code === value.code}
                                getOptionLabel={(option) => option.title}
                                options={reasonsCatalog.slice()}
                                value={reason}
                                renderInput={renderInput}
                            />
                        </Grid>
                        <Grid item>
                            {isOtherReason && (
                                <TextField
                                    label={intl.formatMessage({ id: 'expertiseTask.invites.otherReason' })}
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    required
                                    onChange={onChangeOtherReason}
                                    value={otherReason}
                                    error={!!otherReasonError}
                                    helperText={otherReasonError}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={setModalIsClosed} color="primary">
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        disabled={isLoading}
                        startIcon={startIcon}
                        onClick={onSubmitHandler}
                        color="secondary"
                        variant="contained"
                    >
                        <FormattedMessage id="expertiseTask.invites.reject" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Portal>
    );
});
