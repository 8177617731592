import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EmailModel } from '../../models';

export type EmailFieldProps = {
    model: EmailModel;
};

export const EmailField = observer(
    (props: EmailFieldProps): JSX.Element => {
        const { model } = props;

        return (
            <TextField
                inputProps={{
                    className: 't-email-field',
                }}
                FormHelperTextProps={{
                    className: 't-email-field-help-text',
                }}
                required
                type="email"
                onChange={model.onChangeEmail}
                fullWidth
                label={<FormattedMessage id="authentication.email" />}
                variant="outlined"
                error={!!model.errorEmail}
                helperText={model.errorEmail}
            />
        );
    },
);
