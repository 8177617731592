export type TimeBoundaries = {
    minDate: Date;
    maxDate: Date;
};

export type useDateBoundariesProps = {
    dateTo: Date | null;
    dateFrom: Date | null;
};

export const useDateBoundaries = (props: useDateBoundariesProps): TimeBoundaries => {
    const { dateTo, dateFrom } = props;

    const todayDate = new Date();
    const oldDate = new Date(2000);
    const minDate = dateFrom ? dateFrom : oldDate;
    const maxDate = dateTo ? dateTo : todayDate;

    return { minDate, maxDate };
};
