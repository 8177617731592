import { Box, Grid, Typography, withTheme } from '@material-ui/core';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import styled from 'styled-components';
import {
    TotObjectPanelLabel as TotObjectPanelLabelInj,
    TotObjectPanelValue as TotObjectPanelValueInj,
} from '../../components';
import { getOrElse } from '../../utils';

const StyledBox = withTheme(
    styled(Box)`
        background: ${({ theme }): string => theme.variables.palette.accentBackgroundGradient};
        color: ${({ theme }): string => theme.variables.palette.accentContrastBackgroundGradient};
        min-width: 75px;
        box-sizing: border-box;
        text-align: center;
    `,
);

export type PlanEntryExpertiseProps = {
    index: number;
    taskTypeTitle: string;
    taskMin: number;
    taskMax: number;
    acceptanceDays?: number;
    acceptance?: Date;
    deadlineDays?: number;
    deadline?: Date;
};

export const PlanEntryExpertise = (props: PlanEntryExpertiseProps) => {
    const [TotObjectPanelLabel] = di([TotObjectPanelLabelInj], PlanEntryExpertise);
    const [TotObjectPanelValue] = di([TotObjectPanelValueInj], PlanEntryExpertise);

    const { index, taskTypeTitle, taskMin, taskMax, acceptanceDays, acceptance, deadlineDays, deadline } = props;
    return (
        <React.Fragment>
            <Box pt={12}>
                <Grid container alignItems="baseline">
                    <Grid item>
                        <StyledBox pt={2.5} pb={2.5}>
                            <Typography variant="body2" className="t-new-expertise-title">
                                <Box component="span" fontWeight="fontWeightBold">
                                    {index}
                                </Box>
                            </Typography>
                        </StyledBox>
                    </Grid>
                    <Grid item>
                        <Box pl={12}>
                            <Typography variant="body2" className="t-new-expertise-title">
                                <Box component="span" fontWeight="fontWeightBold">
                                    {taskTypeTitle || '-'}
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box pt={4} pl={30.75}>
                <Grid container alignItems="baseline">
                    <Grid item>
                        <Box pr={12}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="expertise.plan.fields.numberOfConclusions" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {getOrElse(
                                            taskMin !== undefined &&
                                                taskMax !== undefined &&
                                                taskMin !== null &&
                                                taskMax !== null,
                                            <FormattedMessage
                                                id="expertise.plan.fields.numberOfConclusionsText"
                                                values={{ min: taskMin, max: taskMax }}
                                            />,
                                            '-',
                                        )}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pr={12}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="expertise.plan.fields.responseTimeForAJob" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {(acceptanceDays && (
                                            <FormattedMessage
                                                id="expertise.plan.fields.responseTimeForAJobText"
                                                values={{ count: acceptanceDays }}
                                            />
                                        )) ||
                                            (acceptance && <FormattedDate value={acceptance} />) ||
                                            '-'}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pr={12}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <TotObjectPanelLabel>
                                        <FormattedMessage id="expertise.plan.fields.deadline" />
                                    </TotObjectPanelLabel>
                                </Grid>
                                <Grid item>
                                    <TotObjectPanelValue>
                                        {(deadlineDays && (
                                            <FormattedMessage
                                                id="expertise.plan.fields.deadlineText"
                                                values={{ count: deadlineDays }}
                                            />
                                        )) ||
                                            (deadline && <FormattedDate value={deadline} />) ||
                                            '-'}
                                    </TotObjectPanelValue>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};
