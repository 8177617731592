import { Grid, Tooltip, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useError, useModal } from '../../hooks';
import { Transition, TransitionsDTO } from '../../models';
import { ErrorDialog as ErrorDialogInj } from '../ErrorDialog';
import { ErrorMessage as ErrorMessageInj } from '../ErrorMessage';
import { GridNoOverflow as GridNoOverflowInj } from '../styled/GridNoOverflow';
import { TransitionDialog as TransitionDialogInj } from '../transition-dialogs';
import { TransitionButton as TransitionButtonInj } from './TransitionButton';

export type TotObjectTransitionsProps = {
    objectId: string;
    updateObjectPage: () => void;
    getTransitions: (objectId: string) => Promise<TransitionsDTO>;
    lifeCycleTransition: (transitionId: string, objectId: string, validate?: boolean) => Promise<void>;
    noOverflow?: boolean;
};

export const TotObjectTransitions = observer((props: TotObjectTransitionsProps): JSX.Element => {
    const [ErrorDialog] = di([ErrorDialogInj], TotObjectTransitions);
    const [ErrorMessage] = di([ErrorMessageInj], TotObjectTransitions);
    const [GridNoOverflow] = di([GridNoOverflowInj], TotObjectTransitions);
    const [TransitionDialog] = di([TransitionDialogInj], TotObjectTransitions);
    const [TransitionButton] = di([TransitionButtonInj], TotObjectTransitions);

    const intl = useIntl();
    const { objectId, getTransitions, updateObjectPage, lifeCycleTransition, noOverflow } = props;

    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
    const { isError, errorText, setErrorIsClosed, setErrorIsOpen } = useError();

    const [transition, setTransition] = useState<Transition>();
    const [data, setData] = useState<TransitionsDTO>([]);

    const updateTransitions = (): void => {
        getTransitions(objectId).then((data) => setData(data));
    };

    useEffect(() => {
        updateTransitions();
    }, [intl.locale]);

    const handleLifeCycleTransition = (): Promise<void> => {
        return lifeCycleTransition(transition?.id || '', objectId, transition?.params.validate || false).finally(
            setModalIsClosed,
        );
    };

    const handleErrorTransition = (error: AxiosError): void => {
        const errorText = ErrorMessage(error);
        setErrorIsOpen(errorText);
    };

    const onClickModalOpen = (transition: Transition): void => {
        setTransition(transition);
        setModalIsOpen();
    };

    const closeModal = (): void => {
        setModalIsClosed();
        setTransition(undefined);
    };

    const renderTransitions = (noOverflow: boolean | undefined): Array<JSX.Element> => {
        return data.map((transition: Transition) => {
            return noOverflow ? (
                <GridNoOverflow key={transition.id}>
                    <Tooltip title={transition.title}>
                        <TransitionButton
                            onClick={(): void => onClickModalOpen(transition)}
                            color={transition.params.btnType}
                            size="medium"
                            variant="contained"
                            type="button"
                        >
                            <Typography variant="inherit" noWrap>
                                {transition.title}
                            </Typography>
                        </TransitionButton>
                    </Tooltip>
                </GridNoOverflow>
            ) : (
                <Grid item key={transition.id}>
                    <TransitionButton
                        onClick={(): void => onClickModalOpen(transition)}
                        color={transition.params.btnType}
                        size="medium"
                        variant="contained"
                        type="button"
                    >
                        <span>{transition.title}</span>
                    </TransitionButton>
                </Grid>
            );
        });
    };

    return (
        <React.Fragment>
            {transition && (
                <TransitionDialog
                    objectId={objectId}
                    transition={transition}
                    isModalOpen={isModalOpen}
                    setModalIsClosed={closeModal}
                    lifeCycleTransition={handleLifeCycleTransition}
                    handleSuccessTransition={updateObjectPage}
                    handleErrorTransition={handleErrorTransition}
                />
            )}
            <ErrorDialog message={errorText} open={isError} onClose={setErrorIsClosed} />
            {data && renderTransitions(noOverflow)}
        </React.Fragment>
    );
});
