const validation: Record<string, string> = {
    required: 'Required field',
    minLength: 'Minimal length {length, number} {length, plural, one {symbol} other {symbols}}',
    validEmail: 'Enter a valid email',
    maxDate: 'The start date cannot be later than the date set in the "Due date" field',
    minDate: 'The date specified in the "Due date" field cannot be earlier than the work start date',
    bannedSymbols: 'Symbols cannot be used in the field: « {symbols} »',
};

export default validation;
