import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { PageHeader as PageHeaderInj, TotObjectHeader as TotObjectHeaderInj } from '../../../components';
import { CampaignModel } from '../../../models';
import { CampaignHeaderLeft as CampaignHeaderLeftInj } from './CampaignHeaderLeft';
import { PageHeaderTitle as PageHeaderTitleInj } from './PageHeaderTitle';

export type CampaignHeaderProps = {
    campaignModel: CampaignModel;
};

export const CampaignHeader = observer((props: CampaignHeaderProps): JSX.Element => {
    const [PageHeaderTitle] = di([PageHeaderTitleInj], CampaignHeader);
    const [PageHeader] = di([PageHeaderInj], CampaignHeader);
    const [TotObjectHeader] = di([TotObjectHeaderInj], CampaignHeader);
    const [CampaignHeaderLeft] = di([CampaignHeaderLeftInj], CampaignHeader);

    const { campaignModel } = props;
    const { title, identifier, metaInfo, manager } = campaignModel;
    const { created, stateTitle } = metaInfo;
    const { name } = manager;

    return (
        <TotObjectHeader>
            <PageHeader
                title={<PageHeaderTitle title={title} />}
                left={
                    <CampaignHeaderLeft
                        identifier={identifier}
                        created={created}
                        stateTitle={stateTitle}
                        managerName={name}
                    />
                }
            />
        </TotObjectHeader>
    );
});
