import { Link, TableCell, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { ExpertiseTaskRow } from '../../../models';
import { getTaskRoute as getTaskRouteInj } from '../../../utils';

export type ExpertiseTaskListTableRowProps = {
    row: ExpertiseTaskRow;
};

export const ExpertiseTaskListOldTableRow = observer((props: ExpertiseTaskListTableRowProps): JSX.Element => {
    const [getTaskRoute] = di([getTaskRouteInj], ExpertiseTaskListOldTableRow);
    const { row } = props;
    const { id, identifier, curator, expert, expertCandidate, expertise, created, taskType, state, deadline } = row;
    const intl = useIntl();

    const expertCandidateTitle = expertCandidate
        ? intl.formatMessage(
              { id: 'expertiseTask.expertCandidate' },
              {
                  expert: expertCandidate.name,
              },
          )
        : '';

    const expertCell = expert ? (
        <Link component={NavLink} underline="none" to={generatePath(clientRoute.user, { id: expert.userId })}>
            {expert.name}
        </Link>
    ) : expertCandidate ? (
        <Link component={NavLink} underline="none" to={generatePath(clientRoute.user, { id: expertCandidate.userId })}>
            <FormattedMessage
                id="expertiseTask.expertCandidate"
                values={{
                    expert: expertCandidate.name,
                }}
            />
        </Link>
    ) : (
        ''
    );

    const taskRoute = getTaskRoute(id, row.taskType.code);

    return (
        <TableRow hover>
            <TableCell>
                <Link component={NavLink} underline="none" to={taskRoute}>
                    {identifier}
                </Link>
            </TableCell>
            <TableCell>{taskType.title}</TableCell>
            <TableCell>{expertCell}</TableCell>
            <TableCell>
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.expertise, { id: expertise.id })}
                >
                    {expertise.title}
                </Link>
            </TableCell>
            <TableCell>
                {curator && (
                    <Link
                        component={NavLink}
                        underline="none"
                        to={generatePath(clientRoute.user, { id: curator.userId })}
                    >
                        {curator.name}
                    </Link>
                )}
            </TableCell>
            <TableCell>{created && <FormattedDate value={created} />}</TableCell>
            <TableCell>{deadline && <FormattedDate value={deadline} />}</TableCell>
            <TableCell>{state}</TableCell>
        </TableRow>
    );
});
