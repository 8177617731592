import { action, observable } from 'mobx';

export type CampaignLinkQueries = { [key: string]: string | string[] | boolean };

export type CampaignLink = {
    urn: string;
    queries: CampaignLinkQueries;
};

export interface CampaignSubjectsLinks {
    total: CampaignLink;
    active: CampaignLink;
    withExpertise: CampaignLink;
    withoutExpertise: CampaignLink;
}
export interface CampaignExpertiseLinks {
    total: CampaignLink;
    active: CampaignLink;
    done: CampaignLink;
    canceled: CampaignLink;
}
export interface CampaignTasksLinks {
    total: CampaignLink;
    withoutExpert: CampaignLink;
    responseWaiting: CampaignLink;
    active: CampaignLink;
    overdue: CampaignLink;
    done: CampaignLink;
    notCompleted: CampaignLink;
}

export interface CampaignLinksModelDTO {
    subjects: CampaignSubjectsLinks;
    expertise: CampaignExpertiseLinks;
    tasks: CampaignTasksLinks;
}

const defaultLink = {
    urn: '',
    queries: {},
};

export class CampaignLinksModel {
    @observable subjects: CampaignSubjectsLinks = {
        total: defaultLink,
        active: defaultLink,
        withoutExpertise: defaultLink,
        withExpertise: defaultLink,
    };
    @observable expertises: CampaignExpertiseLinks = {
        total: defaultLink,
        active: defaultLink,
        done: defaultLink,
        canceled: defaultLink,
    };
    @observable tasks: CampaignTasksLinks = {
        total: defaultLink,
        active: defaultLink,
        overdue: defaultLink,
        done: defaultLink,
        notCompleted: defaultLink,
        responseWaiting: defaultLink,
        withoutExpert: defaultLink,
    };

    @action.bound
    load(dto: CampaignLinksModelDTO): void {
        this.subjects = dto.subjects;
        this.expertises = dto.expertise;
        this.tasks = dto.tasks;
    }
}
