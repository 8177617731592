import { AuthorizationCheckQuery } from '../store';

export enum MediaSize {
    defaultSize = 'defaultSize',
    mdSize = 'mdSize',
    smSize = 'smSize',
}

export enum HeaderLinkType {
    campaigns = 'campaigns',
    subjectsOfExpertise = 'subjectsOfExpertise',
    categories = 'categories',
    expertises = 'expertises',
    pfTemplates = 'pfTemplates',
    templatesOfExpertise = 'templatesOfExpertise',
    expertiseTasks = 'expertiseTasks',
    users = 'users',
    console = 'console',
}

export type AppHeaderLinkData<Link extends string = HeaderLinkType> = {
    key: Link;
    to: string;
    messageId: string;
    startIcon: JSX.Element;
    exact?: boolean;
};

export type HeaderLinksConfigs<Link extends string = HeaderLinkType> = Record<Link, AppHeaderLinkData<Link>>;
export type LinksKeysTypeBySize<Link extends string = HeaderLinkType> = Record<MediaSize, Link[]>;

export type AllPermissionHeaderLinks<Link extends string = HeaderLinkType> = Record<Link, AuthorizationCheckQuery>;
export type LinkPermissions<Link extends string = HeaderLinkType> = Partial<Record<Link, boolean>>;
