import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { useFlag } from 'hooks/useFlag';
import { title } from 'process';
import React, { MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';

type ToggleHeaderButtonProps = {
    collapsed: boolean;
    onClick: MouseEventHandler;
};

export const ToggleHeaderButton = (props: ToggleHeaderButtonProps) => {
    const { collapsed, onClick } = props;

    const intl = useIntl();

    const tooltip = collapsed
        ? 'expertiseTask.headerActions.expandHeader'
        : 'expertiseTask.headerActions.collapseHeader';

    const [open, tooltipOpen, tooltipClose] = useFlag(false);

    return (
        <Tooltip
            title={intl.formatMessage({ id: tooltip })}
            open={open}
            onClose={tooltipClose}
            onOpen={tooltipOpen}
            onClick={tooltipClose}
        >
            <div>
                <IconButton onClick={onClick}>
                    {props.collapsed ? <VerticalAlignBottomIcon /> : <VerticalAlignTopIcon />}
                </IconButton>
            </div>
        </Tooltip>
    );
};
