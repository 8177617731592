import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { Preset, TTable, TTableRow, TTableVisibilitySettings } from '@platform/ttable';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink } from 'react-router-dom';
import { Row } from 'react-table';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj, TableWrapper as TableWrapperInj } from '../../components';
import { useStore } from '../../hooks';
import { ExpertiseTaskRegistryRow } from '../../store';
import { RegistryType } from '../../types';
import { getTaskRoute as getTaskRouteInj } from '../../utils';
import { ExpertiseTaskRegistryActionsBtns as ExpertiseTaskRegistryActionsBtnsInj } from './ExpertiseTaskRegistryActionsBtns';

export type ExpertiseTaskCellRendererProps = {
    row: Row<ExpertiseTaskRegistryRow>;
};

export const cellFormattersExpertiseTaskListInj = (): Record<
    string,
    (p: ExpertiseTaskCellRendererProps) => JSX.Element | null
> => {
    return {
        'customData.number': function CellFormatterLink(p: ExpertiseTaskCellRendererProps): JSX.Element {
            const [getTaskRoute] = di([getTaskRouteInj], CellFormatterLink);
            const taskRoutes = getTaskRoute(p.row.original.id, p.row.original.customData.taskType.code);
            return (
                <Link component={NavLink} underline="none" to={taskRoutes}>
                    {p.row.original.customData.number}
                </Link>
            );
        },
        'customData.subject.title': (p: ExpertiseTaskCellRendererProps): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.subject, { id: p.row.original.customData.subject.id })}
                >
                    {p.row.original.customData.subject.title}
                </Link>
            );
        },
        'customData.expertise.title': (p: ExpertiseTaskCellRendererProps): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.expertise, { id: p.row.original.customData.expertise.id })}
                >
                    {p.row.original.customData.expertise.title}
                </Link>
            );
        },
        'customData.curator.title': (p: ExpertiseTaskCellRendererProps): JSX.Element => {
            return (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.user, { id: p.row.original.customData.curator.id })}
                >
                    {p.row.original.customData.curator.title}
                </Link>
            );
        },
        'customData.expert.title': (p: ExpertiseTaskCellRendererProps): JSX.Element | null => {
            const row = p.row.original.customData;
            return row.expert ? (
                <Link component={NavLink} underline="none" to={generatePath(clientRoute.user, { id: row.expert.id })}>
                    {row.expert.title}
                </Link>
            ) : row.expertCandidate ? (
                <Link
                    component={NavLink}
                    underline="none"
                    to={generatePath(clientRoute.user, { id: row.expertCandidate.id })}
                >
                    <FormattedMessage
                        id="expertiseTask.expertCandidate"
                        values={{
                            expert: row.expertCandidate.title,
                        }}
                    />
                </Link>
            ) : null;
        },
    };
};

export const ExpertiseTaskListPage = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTaskListPage);
    const [cellFormattersExpertiseTaskList] = di([cellFormattersExpertiseTaskListInj], ExpertiseTaskListPage);
    const [ExpertiseTaskRegistryActionsBtns] = di([ExpertiseTaskRegistryActionsBtnsInj], ExpertiseTaskListPage);
    const [TableWrapper] = di([TableWrapperInj], ExpertiseTaskListPage);

    const { expertiseTaskStore, presetStore, catalogStore, intlStore, userStore, authorizationStore } = useStore();
    const [isWithAddCustomColumns, setIsWithAddCustomColumns] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);

    const saveListPresets = <RowType extends TTableRow>(settings: Preset<RowType>[]): Promise<void> => {
        return presetStore.saveListPresets(settings);
    };

    const cellFormattersItems = cellFormattersExpertiseTaskList();

    useEffect((): void => {
        authorizationStore
            .check({
                entityCode: entities.System,
                permCode: permissions.System.TaskRegistryColumnsManagement,
            })
            .then(setIsWithAddCustomColumns);
    }, [authorizationStore]);

    const visibleSettings: TTableVisibilitySettings = {
        toolbar: {
            isWithAddCustomColumns,
        },
    };

    const setRowActions = useMemo(
        () =>
            (row: ExpertiseTaskRegistryRow, reloadData: () => void): JSX.Element => {
                return (
                    <ExpertiseTaskRegistryActionsBtns
                        expertiseTaskRow={row}
                        reloadData={reloadData}
                        isConfirm={isConfirm}
                        setIsConfirm={setIsConfirm}
                    />
                );
            },
        [isConfirm],
    );

    return (
        <Container maxWidth="lg">
            <Box pt={5} pb={5}>
                <Grid container direction="column" spacing={8}>
                    <Grid item container direction="row" justify="space-between">
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="expertiseTask.listTitle" />
                            </Typography>
                        </Grid>
                    </Grid>

                    <TableWrapper item>
                        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.Administration}>
                            {(allowed: boolean): JSX.Element => (
                                <TTable<ExpertiseTaskRegistryRow>
                                    registryCode={RegistryType.task}
                                    fetchData={expertiseTaskStore.registry}
                                    fetchColumns={expertiseTaskStore.registryColumns}
                                    presetsSetting={presetStore}
                                    visibleSettings={visibleSettings}
                                    fetchCatalog={catalogStore.fetchCatalogForTTable}
                                    fetchSelectDataByUrl={catalogStore.fetchSelectDataByUrl}
                                    lang={intlStore.locale}
                                    cellFormatters={cellFormattersItems}
                                    saveListPresets={saveListPresets}
                                    fetchUserRoleList={userStore.userRoleList}
                                    upload={expertiseTaskStore.upload}
                                    isAdmin={allowed}
                                    rowActions={setRowActions}
                                />
                            )}
                        </AuthorizationCheck>
                    </TableWrapper>
                </Grid>
            </Box>
        </Container>
    );
});
