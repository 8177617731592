import { Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, Route, Switch } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    IconButtonLarge as IconButtonLargeInj,
    TotObjectDeleteButton as TotObjectDeleteButtonInj,
} from '../../../components';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';

export type TemplateOfExpertiseControlPanelLeftProps = {
    id: string;
    title: string;
    deleteTemplateOfExpertise: () => Promise<void>;
};

export const TemplateOfExpertiseControlPanelLeft = (props: TemplateOfExpertiseControlPanelLeftProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], TemplateOfExpertiseControlPanelLeft);
    const [IconButtonLarge] = di([IconButtonLargeInj], TemplateOfExpertiseControlPanelLeft);
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], TemplateOfExpertiseControlPanelLeft);

    const { id, title, deleteTemplateOfExpertise } = props;

    return (
        <Grid spacing={3} alignItems="center" justify="center" container>
            <Switch>
                <Route exact path={clientRoute.templateOfExpertise}>
                    <AuthorizationCheck
                        entityCode={entities.TemplateExpertise}
                        entityId={id}
                        permCode={permissions.TemplateExpertise.Delete}
                    >
                        <Grid item>
                            <TotObjectDeleteButton
                                id="delete-category"
                                title={<FormattedMessage id="common.confirmDeletion" />}
                                message={
                                    <FormattedMessage
                                        id="templatesOfExpertise.confirmDeletionInfoText"
                                        values={{ title }}
                                    />
                                }
                                onConfirm={deleteTemplateOfExpertise}
                                wrappedComponentProps={{
                                    tooltipTitleId: 'templatesOfExpertise.actions.delete',
                                }}
                            />
                        </Grid>
                    </AuthorizationCheck>
                    <AuthorizationCheck
                        entityCode={entities.TemplateExpertise}
                        entityId={id}
                        permCode={permissions.TemplateExpertise.Edit}
                    >
                        <Grid item>
                            <Tooltip title={<FormattedMessage id="templatesOfExpertise.actions.edit" />}>
                                <div>
                                    <IconButtonLarge
                                        component={NavLink}
                                        to={generatePath(clientRoute.templateOfExpertiseEdit, {
                                            id,
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButtonLarge>
                                </div>
                            </Tooltip>
                        </Grid>
                    </AuthorizationCheck>
                </Route>
            </Switch>
        </Grid>
    );
};
