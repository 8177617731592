import { Backdrop, createStyles, Grid, makeStyles } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { useFeature } from 'feature-toggle-jsx';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import totBackgroundLayer2 from 'tot-background-layer-2.png';
import totBackgroundLayer3 from 'tot-background-layer-3.png';

type CustomBackgroundProps = {
    backgroundImage: string;
};

const CustomBackground = withTheme(
    styled.div<CustomBackgroundProps>`
        height: 100%;
        background: ${(props) => {
            const { backgroundImage } = props;

            return `url(${backgroundImage}) center`;
        }};
        background-size: cover;
        background-blend-mode: soft-light, overlay, normal;
        display: flex;
    `,
);

const useStyles = makeStyles(() =>
    createStyles({
        contentWrapper: {
            height: '100%',
            zIndex: 2,
        },
        defaultBackgroundChildrenWrapper: {
            height: '100%',
            width: '100%',
            display: 'flex',
            zIndex: 2,
        },
    }),
);

export type TotBackgroundProps = PropsWithChildren<{ withBackdrop?: boolean }>;

export const TotBackground = (props: TotBackgroundProps): JSX.Element => {
    const { withBackdrop = false, children } = props;
    const [customBackground, config] = useFeature('customBackground');
    const classes = useStyles();

    return (
        <CustomBackground backgroundImage={config.src}>
            <Grid container className={classes.contentWrapper}>
                {children}
            </Grid>
            <Backdrop style={{ zIndex: 1 }} open={withBackdrop} />
        </CustomBackground>
    );
};
