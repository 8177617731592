export enum ExpertiseTaskConfirmationButtons {
    delete = 'delete',
}

export type ExpertiseTaskSettingsConfirmDialogType = {
    id: ExpertiseTaskConfirmationButtons;
    title: string;
    message: string;
    onConfirm: () => Promise<void>;
};

export type OnExpertiseTaskConfirmCallback = () => Promise<void>;

export type ExpertiseTaskSettingsPropsType = Record<ExpertiseTaskConfirmationButtons, OnExpertiseTaskConfirmCallback>;
export type ExpertiseTaskSettings = Record<ExpertiseTaskConfirmationButtons, ExpertiseTaskSettingsConfirmDialogType>;

export function expertiseTaskSettingsConfirmDialog(props: ExpertiseTaskSettingsPropsType): ExpertiseTaskSettings {
    return {
        delete: {
            id: ExpertiseTaskConfirmationButtons.delete,
            title: 'common.confirmDeletion',
            message: 'expertiseTask.confirmDeletionInfoText',
            onConfirm: props[ExpertiseTaskConfirmationButtons.delete],
        },
    };
}
