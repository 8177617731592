import { Link, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { entities, permissions } from '../authSchemeConfig';
import clientRoute from '../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj } from './AuthorizationCheck';

const useStyles = makeStyles(() => ({
    link: {
        fontSize: '15px',
        lineHeight: '1.47',
        fontWeight: 600,
    },
}));

type RouteParams = {
    id: string;
};

export const SyslogLink = (): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], SyslogLink);

    const { id } = useParams<RouteParams>();
    const classes = useStyles();

    return (
        <AuthorizationCheck entityCode={entities.System} permCode={permissions.System.ViewLogs}>
            <Link
                component={NavLink}
                className={classes.link}
                to={generatePath(clientRoute.syslog, {
                    id,
                })}
                underline="none"
                color="textPrimary"
            >
                <FormattedMessage id="syslog.title" />
            </Link>
        </AuthorizationCheck>
    );
};
