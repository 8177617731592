import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { UserListPage as UserListPageInj } from './user-list';
import { UserPage as UserPageInj } from './UserPage';

export const UserPages = (): JSX.Element => {
    const [UserListPage] = di([UserListPageInj], UserPages);
    const [UserPage] = di([UserPageInj], UserPages);

    return (
        <Switch>
            <Route path={clientRoute.user}>
                <UserPage />
            </Route>
            <Route path={clientRoute.users}>
                <UserListPage />
            </Route>
        </Switch>
    );
};
