import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PasswordModel } from '../../models';

export type PasswordFieldProps = {
    model: PasswordModel;
    label?: React.ReactNode;
    newPassword?: boolean;
};

export const PasswordField = observer(
    (props: PasswordFieldProps): JSX.Element => {
        const { model, label, newPassword = true } = props;
        const [showPassword, setShowPassword] = useState<boolean>(false);
        const fieldType = showPassword ? 'text' : 'password';

        const toggleVisibilityPassword = (): void => {
            setShowPassword(!showPassword);
        };

        return (
            <TextField
                inputProps={{
                    className: 't-password-field',
                    ...(newPassword && { autocomplete: 'new-password' }),
                }}
                FormHelperTextProps={{
                    className: 't-password-field-help-text',
                }}
                required
                onChange={model.onPasswordChange}
                fullWidth
                label={label || <FormattedMessage id="authentication.password" />}
                variant="outlined"
                type={fieldType}
                error={!!model.errorPassword}
                helperText={model.errorPassword}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton className="t-visibility-password" onClick={toggleVisibilityPassword}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    },
);
