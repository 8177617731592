import { multilevelCatalog_ru } from '@platform/multilevel-catalog';
import { ttable_ru } from '@platform/ttable';
import { recordToLang } from '../utils';
import authentication from './authentication';
import campaign from './campaign';
import category from './category';
import common from './common';
import console from './console';
import expertise from './expertise';
import expertiseTask from './expertiseTask';
import headerLinks from './headerLinks';
import notFoundPage from './notFoundPage';
import pfTemplate from './pfTemplate';
import sidebar from './sidebar';
import startPage from './startPage';
import subject from './subject';
import syslog from './syslog';
import templatesOfExpertise from './templatesOfExpertise';
import users from './users';
import validation from './validation';

const lang: Record<string, string> = recordToLang({
    productTitle: 'TOT EXP',
    authentication,
    validation,
    startPage,
    headerLinks,
    campaign,
    common,
    sidebar,
    subject,
    category,
    templatesOfExpertise,
    expertiseTask,
    expertise,
    users,
    pfTemplate,
    syslog,
    console,
    ttable: ttable_ru,
    notFoundPage,
    multilevelCatalog: multilevelCatalog_ru,
});

export default lang;
