import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { PageHeader as PageHeaderInj, TotObjectHeader as TotObjectHeaderInj } from '../../../../components';
import { useLoading, useModal, useStore } from '../../../../hooks';
import { ExpertiseModel } from '../../../../models';
import { IdTitle } from '../../../../types';
import { ExpertiseEditCurator as ExpertiseEditCuratorInj } from '../ExpertiseEditCurator';
import { ExpertisePageRouteParams } from '../ExpertisePage';
import { ExpertiseHeaderLeft as ExpertiseHeaderLeftInj } from './ExpertiseHeaderLeft';
import { ExpertiseHeaderRight as ExpertiseHeaderRightInj } from './ExpertiseHeaderRight';
import { ExpertisePageHeaderTitle as ExpertisePageHeaderTitleInj } from './ExpertisePageHeaderTitle';

export type ExpertiseHeaderProps = {
    expertiseModel: ExpertiseModel;
    reloadModel: () => void;
};

export const ExpertiseHeader = observer((props: ExpertiseHeaderProps) => {
    const [PageHeader] = di([PageHeaderInj], ExpertiseHeader);
    const [TotObjectHeader] = di([TotObjectHeaderInj], ExpertiseHeader);
    const [ExpertiseEditCurator] = di([ExpertiseEditCuratorInj], ExpertiseHeader);
    const [ExpertiseHeaderLeft] = di([ExpertiseHeaderLeftInj], ExpertiseHeader);
    const [ExpertiseHeaderRight] = di([ExpertiseHeaderRightInj], ExpertiseHeader);
    const [ExpertisePageHeaderTitle] = di([ExpertisePageHeaderTitleInj], ExpertiseHeader);

    const { reloadModel, expertiseModel } = props;
    const { identifier, metaInfo, template, campaign: expertiseCampaign, subject: subjectOfExpertise } = expertiseModel;
    const { stateTitle, created } = metaInfo;
    const { expertiseStore } = useStore();
    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();
    const { isLoading, enableLoading, disableLoading } = useLoading();
    const { id } = useParams<ExpertisePageRouteParams>();

    const editExpertiseCurator = (currentCurator: IdTitle): void => {
        enableLoading();
        expertiseStore
            .editCurator(expertiseModel.id, currentCurator.id)
            .then(() => {
                reloadModel();
            })
            .finally(() => {
                disableLoading();
            });
    };

    return (
        <TotObjectHeader>
            <ExpertiseEditCurator
                open={isModalOpen}
                onCancel={setModalIsClosed}
                onSubmit={editExpertiseCurator}
                curatorUserId={expertiseModel.curator.userId}
                isLoading={isLoading}
            />
            <PageHeader
                title={<ExpertisePageHeaderTitle title={subjectOfExpertise?.title} />}
                left={
                    <ExpertiseHeaderLeft
                        identifier={identifier}
                        stateTitle={stateTitle}
                        created={created}
                        template={template}
                        subjectOfExpertise={subjectOfExpertise}
                    />
                }
                right={
                    <ExpertiseHeaderRight
                        expertiseCampaign={expertiseCampaign}
                        curatorName={expertiseModel.curator.name}
                        id={id}
                        setModalIsOpen={setModalIsOpen}
                    />
                }
            />
        </TotObjectHeader>
    );
});
