import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Route } from 'react-router-dom';
import { entities, permissions } from 'authSchemeConfig';
import clientRoute from '../../../clientRoute';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    IconButtonLarge as IconButtonLargeInj,
} from '../../../components';
import { UserPersonModel } from '../../../models';
import { ReactComponent as EditIcon } from '../../../resources/images/icons/edit.svg';
import { getOrElse } from '../../../utils';
import { UserEditDialog as UserEditDialogInj } from '../UserEditDialog';

export type UserPageHeaderTitleProps = {
    userPersonModel: UserPersonModel;
    setModalIsOpen: () => void;
    isModalOpen: boolean;
    setModalIsClosed: () => void;
    loginAsUser: () => void;
    login: UserPersonModel['login'];
};

export const UserPageHeaderTitle = observer((props: UserPageHeaderTitleProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserPageHeaderTitle);
    const [IconButtonLarge] = di([IconButtonLargeInj], UserPageHeaderTitle);
    const [UserEditDialog] = di([UserEditDialogInj], UserPageHeaderTitle);

    const { userPersonModel, setModalIsOpen, isModalOpen, setModalIsClosed, loginAsUser, login } = props;
    const { id } = userPersonModel;

    return (
        <Grid item container justify="space-between">
            <Grid item>
                <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Typography variant="h1">{getOrElse(login, login, '-')}</Typography>
                    </Grid>
                    <Route path={clientRoute.user} exact>
                        <AuthorizationCheck
                            entityCode={entities.Account}
                            permCode={permissions.User.Update}
                            entityId={id}
                            key={id}
                        >
                            <Grid item>
                                <Tooltip title={<FormattedMessage id="users.editUser" />}>
                                    <div>
                                        <IconButtonLarge onClick={setModalIsOpen}>
                                            <EditIcon />
                                        </IconButtonLarge>
                                    </div>
                                </Tooltip>
                                <UserEditDialog
                                    userPersonModel={userPersonModel}
                                    isOpen={isModalOpen}
                                    close={setModalIsClosed}
                                />
                            </Grid>
                        </AuthorizationCheck>
                    </Route>
                </Grid>
            </Grid>
            <Route path={clientRoute.user} exact>
                <Grid item>
                    <AuthorizationCheck
                        entityCode={entities.Account}
                        permCode={permissions.User.LoginAs}
                        entityId={id}
                        key={id}
                    >
                        <Button onClick={loginAsUser} variant="contained" color="primary">
                            <FormattedMessage id="users.loginAsUser" />
                        </Button>
                    </AuthorizationCheck>
                </Grid>
            </Route>
        </Grid>
    );
});
