import { FormModel } from '@platform/formiojs-react';
import { action, observable } from 'mobx';
import { Contacts, ExpertiseTaskDTO } from '../../store';
import { IdTitle } from '../../types';
import { DateUtils } from '../../utils';
import { CodeTitle } from '../CodeTitle';
import { MetaInfoModel } from '../MetaInfo';
import { UserNameModel } from '../person';

export class ExpertiseTaskModel {
    @observable id: string;
    @observable identifier = '';
    @observable expertise: IdTitle = { id: '', title: '' };
    @observable expert: UserNameModel = new UserNameModel();
    @observable curator: UserNameModel = new UserNameModel();
    @observable curatorContacts: Contacts = {};
    @observable expertCandidate: UserNameModel = new UserNameModel();
    @observable metaInfo: MetaInfoModel = new MetaInfoModel();
    @observable formModel: FormModel;
    @observable deadline?: Date;
    @observable viewPoint?: CodeTitle;
    @observable activeInviteId?: string;
    @observable started?: Date;

    constructor(id: string) {
        this.id = id;
        this.formModel = new FormModel(id);
    }

    @action.bound
    load(dto: ExpertiseTaskDTO): void {
        const { expert, curator, expertCandidate, metaInfo, formInfo, deadline, ...other } = dto;
        if (expert) {
            this.expert.load(expert);
        } else {
            this.expert.clear();
        }

        if (curator) {
            this.curator.load(curator);
            this.curatorContacts = curator.contacts;
        }

        if (expertCandidate) {
            this.expertCandidate.load(expertCandidate);
        } else {
            this.expertCandidate.clear();
        }
        this.metaInfo.load(metaInfo);
        formInfo && this.formModel.load(formInfo);
        const deadlineDate = new Date(deadline || '');
        if (DateUtils.isValidDate(deadlineDate)) {
            this.deadline = deadlineDate;
        } else {
            this.deadline = undefined;
        }
        Object.assign(this, other);
    }

    @action.bound
    loadCard(dto: ExpertiseTaskDTO): void {
        const { expert, curator, expertCandidate, metaInfo, deadline, ...other } = dto;
        expert && this.expert.load(expert);
        if (curator) {
            this.curator.load(curator);
            this.curatorContacts = curator.contacts;
        }
        expertCandidate && this.expertCandidate.load(expertCandidate);
        this.metaInfo.load(metaInfo);
        const deadlineDate = new Date(deadline || '');
        DateUtils.isValidDate(deadlineDate) && (this.deadline = deadlineDate);
        Object.assign(this, other);
    }
}
