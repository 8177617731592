import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import clientRoute from '../../../clientRoute';
import { BottomControlPanel as BottomControlPanelInj } from '../../../components';
import { useStore } from '../../../hooks';
import { TemplateOfExpertiseControlPanelLeft as TemplateOfExpertiseControlPanelLeftInj } from './TemplateOfExpertiseControlPanelLeft';
import { TemplateOfExpertiseControlPanelRight as TemplateOfExpertiseControlPanelRightInj } from './TemplateOfExpertiseControlPanelRight';

export type TemplateOfExpertiseControlPanelProps = {
    templateOfExpertiseTitle: string;
    deleteTemplateOfExpertise: () => Promise<void>;
    onSubmit: () => void;
    onLifeCycleTransition(transitionId: string, taskId: string, validate?: boolean): Promise<void>;
};

type RouteParams = {
    id: string;
};

export const TemplateOfExpertiseControlPanel = observer((props: TemplateOfExpertiseControlPanelProps): JSX.Element => {
    const [BottomControlPanel] = di([BottomControlPanelInj], TemplateOfExpertiseControlPanel);
    const [TemplateOfExpertiseControlPanelLeft] = di(
        [TemplateOfExpertiseControlPanelLeftInj],
        TemplateOfExpertiseControlPanel,
    );
    const [TemplateOfExpertiseControlPanelRight] = di(
        [TemplateOfExpertiseControlPanelRightInj],
        TemplateOfExpertiseControlPanel,
    );

    const { templateOfExpertiseTitle, deleteTemplateOfExpertise, onSubmit, onLifeCycleTransition } = props;
    const { templatesOfExpertiseStore } = useStore();
    const history = useHistory();
    const { id } = useParams<RouteParams>();

    const goToReadPage = (): void => {
        history.push(generatePath(clientRoute.templateOfExpertise, { id }));
    };

    return (
        <BottomControlPanel
            left={
                <TemplateOfExpertiseControlPanelLeft
                    id={id}
                    title={templateOfExpertiseTitle}
                    deleteTemplateOfExpertise={deleteTemplateOfExpertise}
                />
            }
            right={
                <TemplateOfExpertiseControlPanelRight
                    onSubmit={onSubmit}
                    id={id}
                    goToReadPage={goToReadPage}
                    getTransitionButtons={templatesOfExpertiseStore.getTransitionButtons}
                    onLifeCycleTransition={onLifeCycleTransition}
                />
            }
        />
    );
});
