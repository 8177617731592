import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { TotObjectHeader as TotObjectHeaderInj } from '../../../../components';
import { ExpertiseTaskModel } from '../../../../models';
import { ExpertiseTaskHeaderDescription as ExpertiseTaskHeaderDescriptionInj } from '../ExpertiseTaskHeaderDescription';

export type ExpertiseTaskEditHeaderProps = {
    expertiseTaskModel: ExpertiseTaskModel;
};

export const ExpertiseTaskEditHeader = (props: ExpertiseTaskEditHeaderProps): JSX.Element => {
    const [TotObjectHeader] = di([TotObjectHeaderInj], ExpertiseTaskEditHeader);
    const [ExpertiseTaskHeaderDescription] = di([ExpertiseTaskHeaderDescriptionInj], ExpertiseTaskEditHeader);

    const { expertiseTaskModel } = props;

    return (
        <TotObjectHeader>
            <Container maxWidth="lg">
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Typography variant="h1">
                            <FormattedMessage
                                id="expertiseTask.title"
                                values={{ number: expertiseTaskModel.identifier }}
                            />
                        </Typography>
                    </Grid>
                    <ExpertiseTaskHeaderDescription expertiseTaskModel={expertiseTaskModel} />
                </Grid>
            </Container>
        </TotObjectHeader>
    );
};
