import { action, observable } from 'mobx';
import { ExpertiseTaskPrintFormDTO, ExpertiseTaskPrintFormModel } from './ExpertiseTaskPrintFormModel';

export interface ExpertiseTaskPrintFormsDTO {
    conclusion?: ExpertiseTaskPrintFormDTO;
}

export class ExpertiseTaskPrintFormsModel {
    @observable conclusion?: ExpertiseTaskPrintFormModel;
    @observable loaded = false;

    @action.bound
    load(dto: ExpertiseTaskPrintFormsDTO): ExpertiseTaskPrintFormsModel {
        const { conclusion } = dto;
        conclusion && (this.conclusion = new ExpertiseTaskPrintFormModel().load(conclusion));
        this.loaded = true;
        return this;
    }
}
