import { FormView } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../hooks';
import { ExpertiseTaskSubjectModel } from '../../../../models';

type RouteParams = {
    id: string;
};

export type ExpertiseTaskSubjectWithoutFiles = {
    taskId?: string;
};

export const ExpertiseTaskSubjectWithoutFiles = observer(
    (props: ExpertiseTaskSubjectWithoutFiles): JSX.Element => {
        const { taskId } = props;
        const { expertiseTaskStore, intlStore } = useStore();
        const { locale } = intlStore;
        const { id } = useParams<RouteParams>();
        const subjectModelId = taskId || id;
        const [subjectModel] = useState<ExpertiseTaskSubjectModel>(new ExpertiseTaskSubjectModel(subjectModelId));
        const { hiddenFormModel, commonFormModel } = subjectModel;

        useEffect(() => {
            expertiseTaskStore.loadTaskSubjectView(id, false).then(subjectModel.load);
        }, [expertiseTaskStore, subjectModel.load, id]);

        return (
            <React.Fragment>
                <FormView locale={locale} form={commonFormModel} multiLang={true} />
                {hiddenFormModel && <FormView locale={locale} form={hiddenFormModel} multiLang={true} />}
            </React.Fragment>
        );
    },
);
