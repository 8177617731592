import { ExpansionPanel, Grid, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ExpandMore } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useLocation } from 'react-router-dom';
import {
    ClearFilterButton as ClearFilterButtonInj,
    Select as SelectInj,
    TotObjectFilterPanelDetails as TotObjectFilterPanelDetailsInj,
    TotObjectFilterPanelSummary as TotObjectFilterPanelSummaryInj,
} from '../../../components';
import { AutocompleteProps, checkboxProps, pickerProps, textFieldProps } from '../../../constants';
import { useDateBoundaries } from '../../../hooks';
import { SubjectListModel } from '../../../models';
import { setFiltersFromUrl } from '../../../utils';

export type SubjectListFilterPanelProps = {
    model: SubjectListModel;
};

export const SubjectListFilterPanel = observer((props: SubjectListFilterPanelProps): JSX.Element => {
    const [ClearFilterButton] = di([ClearFilterButtonInj], SubjectListFilterPanel);
    const [Select] = di([SelectInj], SubjectListFilterPanel);
    const [TotObjectFilterPanelDetails] = di([TotObjectFilterPanelDetailsInj], SubjectListFilterPanel);
    const [TotObjectFilterPanelSummary] = di([TotObjectFilterPanelSummaryInj], SubjectListFilterPanel);

    const { model } = props;
    const { filtering } = model;
    const intl = useIntl();
    const location = useLocation();

    useEffect(() => {
        setFiltersFromUrl(location, filtering);
    }, [location]);

    const createdDate = {
        dateTo: filtering.created.value.to,
        dateFrom: filtering.created.value.from,
    };

    const createdDateBoundaries = useDateBoundaries(createdDate);

    return (
        <ExpansionPanel>
            <TotObjectFilterPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="common.filters" />
                </Typography>
            </TotObjectFilterPanelSummary>
            <TotObjectFilterPanelDetails>
                <Grid container direction="column" spacing={6}>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="subject.fields.identifier" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.identifier.value}
                                onChange={filtering.identifier.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="subject.fields.title" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.title.value}
                                onChange={filtering.title.onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.state.values}
                                label={<FormattedMessage id="subject.fields.state" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.stateFilterData}
                                onChange={filtering.state.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={6}>
                        <Grid item xs={4}>
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="subject.fields.category" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.category.value}
                                onChange={filtering.category.onChange}
                            />
                        </Grid>
                        <Grid item xs={4} container justify="space-between">
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    label={<FormattedMessage id="subject.fields.created" />}
                                    placeholder={intl.formatMessage({ id: 'common.from' })}
                                    value={filtering.created.value.from}
                                    onChange={filtering.created.onFromChange}
                                    maxDate={createdDateBoundaries.maxDate}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton
                                                clear={(): void => filtering.created.onFromChange(null)}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    {...pickerProps}
                                    placeholder={intl.formatMessage({ id: 'common.until' })}
                                    value={filtering.created.value.to}
                                    onChange={filtering.created.onToChange}
                                    minDate={createdDateBoundaries.minDate}
                                    InputProps={{
                                        endAdornment: (
                                            <ClearFilterButton clear={(): void => filtering.created.onToChange(null)} />
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                values={filtering.campaigns.values}
                                label={<FormattedMessage id="subject.fields.campaign" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                selectData={model.campaignFilterData}
                                onChange={filtering.campaigns.onChange}
                                textFieldProps={{ ...textFieldProps }}
                                autoCompleteProps={{ ...AutocompleteProps }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={6} justify="flex-end">
                        <Grid item container xs={4} justify="flex-end">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...checkboxProps}
                                        checked={filtering.withoutExpertise.value}
                                        onChange={filtering.withoutExpertise.onChange}
                                    />
                                }
                                label={<FormattedMessage id="subject.fields.withoutExpertise" />}
                            />
                        </Grid>
                        <Grid item container xs={4} alignContent="center">
                            <TextField
                                {...textFieldProps}
                                label={<FormattedMessage id="subject.fields.expertise" />}
                                placeholder={intl.formatMessage({ id: 'common.all' })}
                                value={filtering.expertise.value}
                                onChange={filtering.expertise.onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectFilterPanelDetails>
        </ExpansionPanel>
    );
});
