import { Box, createStyles, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import {
    FooterButton as FooterButtonInj,
    InstructionsButton as InstructionsButtonInj,
    LanguageButton as LanguageButtonInj,
    LogoConfig,
    LogoImg as LogoImgInj,
} from '../../components';
import { useStore } from '../../hooks';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainContainer: {
            height: '100%',
        },
        email: {
            paddingLeft: '1px',
            paddingRight: '1px',
        },
        productText: {
            color: theme.variables.palette.productText.color,
        },
    }),
);

export const HomePageLeftSide = observer((): JSX.Element => {
    const [FooterButton] = di([FooterButtonInj], HomePageLeftSide);
    const [InstructionsButton] = di([InstructionsButtonInj], HomePageLeftSide);
    const [LanguageButton] = di([LanguageButtonInj], HomePageLeftSide);
    const [LogoImg] = di([LogoImgInj], HomePageLeftSide);

    const { env } = useStore();
    const classes = useStyles();
    const { totMail } = env;
    const [logo, logoConfig] = useFeature('logo');
    const [instructions] = useFeature('instructions');

    return (
        <Grid container direction="column" justify="space-between" wrap="nowrap" className={classes.mainContainer}>
            <Grid item>
                <Link component={RouterLink} to={clientRoute.root} underline="none">
                    <Grid container direction="row" alignItems="center">
                        <Grid container direction="row" alignItems="center">
                            <LogoImg logoConfig={logoConfig as LogoConfig} />
                        </Grid>
                    </Grid>
                </Link>
            </Grid>
            <Grid item>
                <Typography variant="h1" component="p" gutterBottom className={classes.productText}>
                    <Box fontWeight={700} component="span">
                        <FormattedMessage id="startPage.productName" />
                    </Box>
                </Typography>
                <Typography variant="h1" component="p" className={classes.productText}>
                    <Box fontSize="16px" fontWeight={500} component="span">
                        <FormattedMessage id="startPage.productDescription" />
                    </Box>
                </Typography>
            </Grid>
            <Grid container item alignItems="center">
                <Grid item xs={6}>
                    <FooterButton
                        component="a"
                        className={classes.email}
                        href={`mailto:${totMail}`}
                        variant="text"
                        color="secondary"
                    >
                        {totMail}
                    </FooterButton>
                </Grid>
                <Grid container item direction="row" justify="flex-end" spacing={3} xs={6}>
                    {instructions && (
                        <Grid item>
                            <InstructionsButton />
                        </Grid>
                    )}
                    <Grid item>
                        <LanguageButton />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
