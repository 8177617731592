import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { PageHeader as PageHeaderInj, TotObjectHeader as TotObjectHeaderInj } from '../../../components';
import { CategoryOfSubjectsModel } from '../../../models';
import { CategoryOfSubjectsPageHeaderLeft as CategoryOfSubjectsPageHeaderLeftInj } from './CategoryOfSubjectsPageHeaderLeft';
import { CategoryOfSubjectsPageHeaderTitle as CategoryOfSubjectsPageHeaderTitleInj } from './CategoryOfSubjectsPageHeaderTitle';

export type CategoriesSubjectsHeaderProps = {
    categoryOfSubjectsModel: CategoryOfSubjectsModel;
};

export const CategoryOfSubjectsHeader = observer((props: CategoriesSubjectsHeaderProps): JSX.Element => {
    const [PageHeader] = di([PageHeaderInj], CategoryOfSubjectsHeader);
    const [TotObjectHeader] = di([TotObjectHeaderInj], CategoryOfSubjectsHeader);
    const [CategoryOfSubjectsPageHeaderLeft] = di([CategoryOfSubjectsPageHeaderLeftInj], CategoryOfSubjectsHeader);
    const [CategoryOfSubjectsPageHeaderTitle] = di([CategoryOfSubjectsPageHeaderTitleInj], CategoryOfSubjectsHeader);

    const { categoryOfSubjectsModel } = props;
    const { title, metaInfo } = categoryOfSubjectsModel;
    const { created, modified } = metaInfo;
    return (
        <TotObjectHeader>
            <PageHeader
                title={<CategoryOfSubjectsPageHeaderTitle title={title} />}
                left={<CategoryOfSubjectsPageHeaderLeft created={created} modified={modified} />}
            />
        </TotObjectHeader>
    );
});
