import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../hooks';
import { AppHeaderLinkData } from '../../types';

export type HeaderLinksListProps = {
    links: AppHeaderLinkData[];
};

export const HeaderLinksList = observer((props: HeaderLinksListProps): JSX.Element => {
    const { links } = props;
    const { headerLinksStore } = useStore();
    const { linksPermissions } = headerLinksStore;

    return (
        <React.Fragment>
            {links.map((link) => {
                const { key } = link;
                return (
                    linksPermissions[key] && (
                        <Grid item>
                            <Button
                                component={NavLink}
                                key={key}
                                to={link.to}
                                variant="text"
                                color="secondary"
                                exact={link.exact}
                                startIcon={link.startIcon}
                            >
                                <FormattedMessage id={link.messageId} />
                            </Button>
                        </Grid>
                    )
                );
            })}
        </React.Fragment>
    );
});
