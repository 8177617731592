import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import clientRoute from '../clientRoute';
import { useStore } from '../hooks';
import { AuthorizationCheck as AuthorizationCheckInj, AuthorizationCheckProps } from './AuthorizationCheck';

export type AuthorizedRouteProps = AuthorizationCheckProps & RouteProps;

export const AuthorizedRoute = observer(
    (props: AuthorizedRouteProps): JSX.Element => {
        const [AuthorizationCheck] = di([AuthorizationCheckInj], AuthorizedRoute);

        const { permCode, entityCode, entityId, pendingElement, onAllowed, errorElement, ...other } = props;
        const routeProps = other as RouteProps;
        const rootStore = useStore();

        const onNotAllowed = (): void => {
            rootStore.history.replace(clientRoute.notAllowed);
        };

        return (
            <AuthorizationCheck
                entityCode={entityCode}
                permCode={permCode}
                entityId={entityId}
                pendingElement={pendingElement}
                onAllowed={onAllowed}
                onNotAllowed={onNotAllowed}
            >
                <Route {...routeProps} />
            </AuthorizationCheck>
        );
    },
);
