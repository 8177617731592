import { action, computed, observable } from 'mobx';

export type SortDirection = 'asc' | 'desc' | undefined;

export class Sort {
    @observable direction: SortDirection;

    constructor(defaultDirection?: SortDirection) {
        this.direction = defaultDirection;
    }

    @action.bound
    changeDirection(): void {
        this.direction = this.nextDirection;
    }

    @action.bound
    off(): void {
        this.direction = undefined;
    }

    @computed
    get isActive(): boolean {
        return !!this.direction;
    }

    @computed
    get nextDirection(): SortDirection {
        switch (this.direction) {
            case 'asc':
                return 'desc';
            case 'desc':
                return undefined;
            default:
                return 'asc';
        }
    }
}
