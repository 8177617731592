import { Grid } from '@material-ui/core';
import React from 'react';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../../authSchemeConfig';
import {
    AuthorizationCheck as AuthorizationCheckInj,
    TotObjectDeleteButton as TotObjectDeleteButtonInj,
} from '../../../../components';
import { useStore } from '../../../../hooks';

export type ExpertiseControlPanelLeftProps = {
    expertiseId: string;
    deleteExpertise: () => Promise<void>;
    title: string;
};

export const ExpertiseControlPanelLeft = (props: ExpertiseControlPanelLeftProps): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseControlPanelLeft);
    const [TotObjectDeleteButton] = di([TotObjectDeleteButtonInj], ExpertiseControlPanelLeft);

    const { expertiseId, deleteExpertise, title } = props;
    const { intlStore: intl } = useStore();

    const wrappedComponentProps = {
        tooltipTitleId: 'expertise.actions.deleteExpertise',
    };
    return (
        <Grid spacing={3} alignItems="center" justify="center" container item>
            <AuthorizationCheck
                entityCode={entities.Expertise}
                entityId={expertiseId}
                permCode={permissions.Expertise.Delete}
            >
                <TotObjectDeleteButton
                    id="delete-expertise"
                    title={intl.formatMessage('common.confirmDeletion')}
                    message={intl.formatMessage('expertise.confirmDeletionInfoText', { title })}
                    onConfirm={deleteExpertise}
                    wrappedComponentProps={wrappedComponentProps}
                />
            </AuthorizationCheck>
        </Grid>
    );
};
