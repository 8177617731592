import { Box, Grid } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Link as RouterLink } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import { RedirectByRole as RedirectByRoleInj } from '../../components';
import { useStore } from '../../hooks';
import { HeaderLink as HeaderLinkInj } from './HeaderLink';

export const StartAuthHeaderLink = observer((): JSX.Element => {
    const [HeaderLink] = di([HeaderLinkInj], StartAuthHeaderLink);
    const [RedirectByRole] = di([RedirectByRoleInj], StartAuthHeaderLink);

    const { api, personStore } = useStore();
    switch (api.authStatus) {
        case 'ok':
            return (
                <Grid item>
                    <Box pt={2.25}>
                        <HeaderLink component={RedirectByRole} color="secondary" underline="none">
                            {personStore.person.lastNameWithInitials}
                        </HeaderLink>
                    </Box>
                </Grid>
            );
        default:
            return (
                <Grid item>
                    <Box pt={0.4}>
                        <HeaderLink component={RouterLink} to={clientRoute.login} color="secondary" underline="none">
                            <Grid container item alignItems="center">
                                <FormattedMessage id="startPage.headerLinks.login" />
                                <Box ml={1} style={{ lineHeight: 1 }}>
                                    <ExitToAppIcon />
                                </Box>
                            </Grid>
                        </HeaderLink>
                    </Box>
                </Grid>
            );
    }
});
