import { observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useLocation } from 'react-router-dom';
import { RedirectByRole as RedirectByRoleInj } from '../../../components';
import { useStore } from '../../../hooks';
import { AuthenticationError as AuthenticationErrorInj } from './AuthenticationError';
import { PendingHandler as PendingHandlerInj } from './PendingHandler';

export const EsiaLoginPage = observer((): JSX.Element => {
    const [AuthenticationError] = di([AuthenticationErrorInj], EsiaLoginPage);
    const [PendingHandler] = di([PendingHandlerInj], EsiaLoginPage);
    const [RedirectByRole] = di([RedirectByRoleInj], EsiaLoginPage);

    const { search } = useLocation();
    const { authenticationStore, personStore } = useStore();

    const getPersonInfo = useCallback((): void => {
        personStore.getInfo();
    }, [personStore.getInfo]);

    const [login, setLogin] = useState(authenticationStore.esiaLogin(search).then(getPersonInfo));

    useEffect(() => {
        setLogin(authenticationStore.esiaLogin(search).then(getPersonInfo));
    }, [authenticationStore.esiaLogin, search, getPersonInfo]);

    const fulfilledHandler = useCallback((): JSX.Element => {
        return <RedirectByRole />;
    }, [personStore]);

    return fromPromise(login).case({
        pending: PendingHandler,
        fulfilled: fulfilledHandler,
        rejected: AuthenticationError,
    });
});
