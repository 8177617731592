import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react';
import React, { CSSProperties, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { permissionsConfig } from '../../../authSchemeConfig';
import { AuthorizationCheck } from '../../../components';
import { useFlag, useStore } from '../../../hooks';
import { UserTableRow } from '../../../types';
import { UserAddDialog as UserAddDialogInj } from './user-add';
import { UserRegistryActionsBtns as UserRegistryActionsBtnsInj } from './user-registry/UserRegistryActionsBtns';
import { UserRegistryTable as UserRegistryTableInj } from './user-registry/UserRegistryTable';

const tableWrapperStyle: CSSProperties = { width: '100%' };

export const UserListPage = observer((): JSX.Element => {
    const [UserAddDialog] = di([UserAddDialogInj], UserListPage);
    const [UserRegistryTable] = di([UserRegistryTableInj], UserListPage);
    const [UserRegistryActionsBtns] = di([UserRegistryActionsBtnsInj], UserListPage);

    const [isModalOpen, openModal, closeModal] = useFlag();
    const [userAdding] = useFeature('userAdding');
    const { userStore } = useStore();
    const { getRoleListWithoutAdministration } = userStore;

    useEffect(() => {
        getRoleListWithoutAdministration();
    }, []);

    const setRowActions = (row: UserTableRow, reloadData: () => void): JSX.Element => {
        return <UserRegistryActionsBtns userTableRow={row} reloadData={reloadData} />;
    };

    return (
        <Container maxWidth="lg">
            {isModalOpen && (
                <UserAddDialog
                    open={isModalOpen}
                    setModalIsClosed={closeModal}
                    createUser={userStore.createUser}
                    userRoleList={userStore.roleListWithoutAdministration}
                />
            )}
            <Box pt={5} pb={5}>
                <Grid container spacing={10} direction="column">
                    <Grid item container direction="row" justify="space-between">
                        <Grid item>
                            <Typography variant="h1">
                                <FormattedMessage id="users.listTitle" />
                            </Typography>
                        </Grid>

                        {userAdding && (
                            <AuthorizationCheck {...permissionsConfig.accountAdministration}>
                                <Grid item>
                                    <Button variant="contained" onClick={openModal} color="primary">
                                        <FormattedMessage id="users.addUser" />
                                    </Button>
                                </Grid>
                            </AuthorizationCheck>
                        )}
                    </Grid>
                    <Grid item style={tableWrapperStyle}>
                        <UserRegistryTable setRowActions={setRowActions} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});
