import { Box, Button, Dialog, Grid, Link, Portal, TextField, Typography, useTheme } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React, { CSSProperties, FormEvent, ReactNode, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { NavLink } from 'react-router-dom';
import clientRoute from '../../clientRoute';
import {
    ErrorDialog as ErrorDialogInj,
    TotObjectPanelLabel as TotObjectPanelLabelInj,
    TotObjectPanelValue as TotObjectPanelValueInj,
} from '../../components';
import { useStore } from '../../hooks';
import { CodeTitle, IdTitleNumber } from '../../models';
import { IdTitle } from '../../types';
import { getOrElse } from '../../utils';
import { PlanEntryExpertise as PlanEntryExpertiseInj } from './PlanEntryExpertise';

export type ExpertiseCreateDialogProps = {
    onClose: () => void;
    onSubmit?: () => void;
    subject?: IdTitle;
    byCampaign?: boolean;
    campaign?: IdTitleNumber;
    open: boolean;
};

const buttonWrapperStyle: CSSProperties = { flexGrow: 1 };

export const ExpertiseCreateDialog = observer((props: ExpertiseCreateDialogProps): JSX.Element => {
    const [PlanEntryExpertise] = di([PlanEntryExpertiseInj], ExpertiseCreateDialog);
    const [TotObjectPanelValue] = di([TotObjectPanelValueInj], ExpertiseCreateDialog);
    const [TotObjectPanelLabel] = di([TotObjectPanelLabelInj], ExpertiseCreateDialog);
    const [ErrorDialog] = di([ErrorDialogInj], ExpertiseCreateDialog);

    const { subject, byCampaign, campaign, onClose, onSubmit, open } = props;
    const { expertiseStore, subjectStore } = useStore();
    const expertiseCreateModel = useMemo(expertiseStore.getExpertiseCreateModel, [expertiseStore]);
    const { errorMessage } = expertiseCreateModel;
    const intl = useIntl();
    const theme = useTheme();

    useEffect(() => {
        if (subject) {
            expertiseCreateModel.setSubject(subject.id);
        } else {
            subjectStore.loadSubjectsSelectList().then(expertiseCreateModel.loadSubjects);
        }
    }, []);

    const onCloseErrorMessageModal = (): void => {
        expertiseCreateModel.resetErrorMessage();
    };

    const renderInput = (
        label: string,
        required: boolean,
        errorText?: string,
    ): ((params: AutocompleteRenderInputParams) => ReactNode) => {
        return (params: AutocompleteRenderInputParams): ReactNode => {
            return (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    variant="outlined"
                    error={!!errorText}
                    helperText={errorText}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />
            );
        };
    };

    const onSubmitCallback = (event: FormEvent<HTMLFormElement>): void => {
        if (byCampaign && campaign?.id) {
            expertiseCreateModel.onSubmitByCampaign(campaign.id, onClose, onSubmit)(event);
        } else {
            expertiseCreateModel.onSubmitByTemplate(onClose)(event);
        }
    };

    const handleCloseModal = (): void => {
        expertiseCreateModel.setTemplate();
        expertiseCreateModel.disableTemplateIsSelected();
        onClose();
    };

    const getOptionSelectedById = (option: IdTitle, value: IdTitle): boolean => option.id === value.id;

    const getOptionSelectedByCode = (option: CodeTitle, value: CodeTitle): boolean => option.code === value.code;

    const getOptionTitle = <T extends CodeTitle | IdTitle>(option: T): string => option.title;

    const expertiseSubjectsSlice = expertiseCreateModel.subjects.slice();
    const expertiseTemplatesSlice = expertiseCreateModel.templates.slice();
    const expertiseLifeCyclesSlice = expertiseCreateModel.lifeCycles.slice();

    const lightBorder = `1px solid ${theme?.variables.palette.hoverInLists}`;

    return (
        <React.Fragment>
            <Portal>
                <Dialog maxWidth="md" fullWidth open={open}>
                    <Box>
                        <Typography variant="h5" className="t-new-expertise-title">
                            <Box pl={6.5} pt={8} pb={7.25} fontWeight="fontWeightBold">
                                <FormattedMessage
                                    id={byCampaign ? 'expertise.newExpertises' : 'expertise.newExpertise'}
                                />
                            </Box>
                        </Typography>
                        <Box>
                            <form noValidate onSubmit={onSubmitCallback}>
                                <Box
                                    borderBottom={lightBorder}
                                    borderTop={lightBorder}
                                    pt={15}
                                    pl={32.25}
                                    pr={32.25}
                                    pb={15}
                                >
                                    <Grid container spacing={6} direction="column" justify="center">
                                        <Grid item>
                                            {byCampaign ? (
                                                <Link component={NavLink} to={clientRoute.subjects}>
                                                    <FormattedMessage
                                                        id="expertise.expertiseSubjectsOfCampaign"
                                                        values={{ ...campaign }}
                                                    />
                                                </Link>
                                            ) : (
                                                <Autocomplete
                                                    onChange={expertiseCreateModel.onChangeSubjectSelect}
                                                    getOptionSelected={getOptionSelectedById}
                                                    getOptionLabel={getOptionTitle}
                                                    {...(subject && { defaultValue: subject })}
                                                    options={expertiseSubjectsSlice}
                                                    renderInput={renderInput(
                                                        intl.formatMessage({
                                                            id: 'expertise.createDialogFields.expertiseSubject',
                                                        }),
                                                        true,
                                                        expertiseCreateModel.errorSubject,
                                                    )}
                                                    disabled={!!subject}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                onChange={expertiseCreateModel.onChangeTemplateSelect}
                                                getOptionSelected={getOptionSelectedById}
                                                getOptionLabel={getOptionTitle}
                                                options={expertiseTemplatesSlice}
                                                renderInput={renderInput(
                                                    intl.formatMessage({
                                                        id: 'expertise.createDialogFields.templateExpertise',
                                                    }),
                                                    !!byCampaign,
                                                    expertiseCreateModel.errorTemplate,
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {getOrElse(
                                                expertiseCreateModel.templateIsSelected,
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <TotObjectPanelLabel>
                                                            <FormattedMessage id="expertise.createDialogFields.lifeCycle" />
                                                        </TotObjectPanelLabel>
                                                    </Grid>
                                                    <Grid item>
                                                        <TotObjectPanelValue>
                                                            {expertiseCreateModel.processTitle || '-'}
                                                        </TotObjectPanelValue>
                                                    </Grid>
                                                </Grid>,
                                                <Autocomplete
                                                    onChange={expertiseCreateModel.onChangeLifeCycleSelect}
                                                    getOptionSelected={getOptionSelectedByCode}
                                                    getOptionLabel={getOptionTitle}
                                                    options={expertiseLifeCyclesSlice}
                                                    renderInput={renderInput(
                                                        intl.formatMessage({
                                                            id: 'expertise.createDialogFields.lifeCycle',
                                                        }),
                                                        true,
                                                        expertiseCreateModel.errorLifeCycle,
                                                    )}
                                                />,
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                                {expertiseCreateModel.templateIsSelected && !!expertiseCreateModel.planEntries.length && (
                                    <Box pb={15} borderBottom={lightBorder}>
                                        <Box>
                                            <Typography variant="h6" className="t-new-expertise-title">
                                                <Box pl={6.5} pt={4} pb={4} borderBottom={lightBorder}>
                                                    <FormattedMessage id="expertise.plan.listTitle" />
                                                </Box>
                                            </Typography>
                                            {expertiseCreateModel.planEntries.map((i, index) => {
                                                const number = ++index;
                                                return (
                                                    <PlanEntryExpertise
                                                        key={number}
                                                        index={number}
                                                        taskTypeTitle={i.taskTypeTitle}
                                                        taskMin={i.tasksMin}
                                                        taskMax={i.tasksMax}
                                                        acceptanceDays={i.acceptanceDays}
                                                        acceptance={i.acceptance}
                                                        deadlineDays={i.deadlineDays}
                                                        deadline={i.deadline}
                                                    />
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                )}
                                <Box pt={6} pb={6} pl={30.75} pr={30.75}>
                                    <Grid container spacing={10} justify="center">
                                        <Grid style={buttonWrapperStyle} item>
                                            <Button
                                                size="large"
                                                fullWidth
                                                variant="contained"
                                                onClick={handleCloseModal}
                                            >
                                                <FormattedMessage id="common.cancel" />
                                            </Button>
                                        </Grid>
                                        <Grid style={buttonWrapperStyle} item>
                                            <Button
                                                disabled={expertiseCreateModel.isLoading}
                                                size="large"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >
                                                <FormattedMessage id="common.save" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Dialog>
            </Portal>
            <ErrorDialog message={errorMessage} open={!!errorMessage} onClose={onCloseErrorMessageModal} />
        </React.Fragment>
    );
});
