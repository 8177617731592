import { SelectData } from '@platform/ttable';
import { action, IObservableArray, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import apiConfigs from '../apiConfigs';
import { CodeTitle } from '../models/CodeTitle';
import { Api } from './Api';
import { IntlStore } from './IntlStore';
import { RootStore } from './RootStore';

export const CatalogCodes = {
    ReasonsOfRejectInvite: 'ReasonsOfRejectInvite',
    ScienceFields: 'OECD',
};

export const CatalogItemCodes = {
    ReasonsOfRejectInvite: {
        Other: '99',
    },
};

type CatalogDTO = {
    code: string;
    catalogCode: string;
    title: string;
};

export class CatalogStore {
    @observable rootStore: RootStore;
    @observable protected api: Api;
    @observable intlStore: IntlStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.api;
        this.intlStore = rootStore.intlStore;
    }

    @action.bound
    catalogItems(catalogCode: string): CodeTitle[] {
        const items: IObservableArray<CodeTitle> = observable.array();
        this.fetchCatalog(catalogCode, this.intlStore.locale).then((data: CodeTitle[]) => items.replace(data));
        return items;
    }

    // todo: используется в ExpertiseTaskInviteToRejectDialog, проверить нужно ли там преобразование
    @action.bound
    fetchCatalog(catalogCode: string, lang: string): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.catalogItems(catalogCode, lang)).then((r) => r.data);
    }

    @action.bound
    fetchCatalogForTTable(catalogCode: string, lang: string): Promise<CodeTitle[]> {
        return this.api
            .client(apiConfigs.catalogItems(catalogCode, lang))
            .then((r) => r.data)
            .then((arr) =>
                arr.map((item: CatalogDTO) => ({ code: `${item.code}@${item.catalogCode}`, title: item.title })),
            );
    }

    @action.bound
    fetchSelectDataByUrl(url: string): Promise<SelectData> {
        return this.api
            .client({
                url: url,
                method: 'GET',
            })
            .then((r) => r.data);
    }
}

export const getCatalogStore = (): any => {
    const [_CatalogStore] = di([CatalogStore], getCatalogStore);
    return _CatalogStore;
};
