import { Radio } from '@material-ui/core';
import { TTableRow } from '@platform/ttable';
import { observer } from 'mobx-react';
import React from 'react';
import { SelectionOfExpertListModel } from '../../../models';
import { ReactComponent as RadioIcon } from '../../../resources/images/icons/radio-icon.svg';

export type SelectionOfExpertTableProps = {
    tableModel: SelectionOfExpertListModel;
    row: TTableRow;
};

const radioIcon = <RadioIcon />;

export const SelectionOfExpertTableActions = observer((props: SelectionOfExpertTableProps): JSX.Element => {
    const { tableModel, row } = props;
    const { expertId, onChecked } = tableModel;
    const { id } = row;

    const isChecked = expertId === id;

    return (
        <Radio
            value={id}
            onChange={onChecked}
            checked={isChecked}
            color="secondary"
            checkedIcon={radioIcon}
            icon={radioIcon}
        />
    );
});
