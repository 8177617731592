import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const AppContentContainer = withTheme(
    styled.main.attrs(() => ({
        className: 'app-content-container',
    }))`
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
    `,
);
