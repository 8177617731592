import { Tabs, TabsActions, TabsProps } from '@material-ui/core';
import { debounce } from 'debounce';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { matchPath, RouteProps, useLocation } from 'react-router-dom';

type OuterTabsProps = Omit<TabsProps, 'value' | 'onChange'>;
export type RouteSetting = Pick<RouteProps, 'path' | 'exact' | 'sensitive' | 'strict'> & { tab: number | string };

type RenderChildren = (updateIndicator: () => void) => JSX.Element;

export type RoutedTabsProps = {
    tabsProps: OuterTabsProps;
    settings: RouteSetting[];
    children: RenderChildren | JSX.Element | JSX.Element[];
};

export const RoutedTabs = observer(
    (props: RoutedTabsProps): JSX.Element => {
        const { settings, tabsProps, children } = props;
        const location = useLocation();
        const { pathname } = location;

        const match = settings.find((s) => !!matchPath(pathname, s));
        const selected = match === undefined ? false : match.tab;

        const tabsActions = useRef<TabsActions>(null);

        // debounce сильно сокращает количество реальных расчетов индикатора
        const updateIndicator = debounce((): void => {
            tabsActions.current?.updateIndicator();
        }, 50);

        const renderChildren = (): JSX.Element | JSX.Element[] => {
            if (typeof children === 'function') {
                const render = children as RenderChildren;
                return render(updateIndicator);
            }
            return children;
        };

        return (
            <Tabs value={selected} action={tabsActions} {...tabsProps}>
                {renderChildren()}
            </Tabs>
        );
    },
);
