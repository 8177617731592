import { Collapse, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../hooks';
import { HeaderLinksList as HeaderLinksListInj } from './HeaderLinksList';

export type BurgerMenuProps = {
    isOpenMenu: boolean;
};

export const BurgerMenu = observer((props: BurgerMenuProps): JSX.Element => {
    const [HeaderLinksList] = di([HeaderLinksListInj], BurgerMenu);

    const { isOpenMenu } = props;
    const { headerLinksStore } = useStore();
    const { burgerLinksConfigs, burgerLinksIsAllowed } = headerLinksStore;

    return (
        <React.Fragment>
            {burgerLinksIsAllowed && (
                <Collapse in={isOpenMenu} timeout="auto">
                    <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
                        <HeaderLinksList links={burgerLinksConfigs} />
                    </Grid>
                </Collapse>
            )}
        </React.Fragment>
    );
});
