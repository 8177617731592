import debounce from 'debounce';
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx';
import { actionAsync } from 'mobx-utils';
import { ChangeEvent } from 'react';
import { FilterValue } from '../../types/filter/FilterValue';
import { Filter } from './Filter';
import { Sort } from './Sort';

export type TableQueryData = {
    filter: object;
    sort: object[];
    page: number;
    pageSize: number;
};

export type RowsData<T> = { rows: T[]; count: number };

export abstract class TableModel<
    RowData extends {},
    Filtering extends Record<string, Filter<FilterValue>>,
    Sorting extends Record<string, Sort>
> {
    @observable filtering: Filtering;
    @observable sorting: Sorting;
    @observable isLoading = true;

    @observable rows: RowData[] = [];
    @observable pageNumber = 0;
    @observable pageSize: number;
    @observable rowsCount = 0;

    pageSizeOptions: Array<number>;

    @observable loadingDisposer: IReactionDisposer;
    @observable pageNumberDisposer: IReactionDisposer;
    @observable loadRowsDisposer: IReactionDisposer;

    protected constructor(
        defaultFilter: Filtering,
        defaultSorting: Sorting,
        pageSizeOptions: Array<number> = [10, 25, 50, 100, 500],
    ) {
        this.filtering = observable(defaultFilter);
        this.sorting = observable(defaultSorting);
        this.pageSizeOptions = pageSizeOptions;
        this.pageSize = this.pageSizeOptions[0] | 10;

        this.loadingDisposer = reaction(
            () => this.queryData,
            () => (this.isLoading = true),
            { fireImmediately: true },
        );
        this.pageNumberDisposer = reaction(
            () => this.filterData,
            () => (this.pageNumber = 0),
        );
        this.loadRowsDisposer = reaction(() => this.queryData, debounce(this.loadRowsData, 500), {
            fireImmediately: true,
        });
    }

    @action.bound
    @actionAsync
    async loadRowsData(queryData: TableQueryData): Promise<void> {
        const data = await this.getRowsData(queryData);
        this.rows = data.rows;
        this.rowsCount = data.count;
        this.isLoading = false;
    }

    abstract getRowsData(queryData: TableQueryData): Promise<RowsData<RowData>>;

    @action.bound
    reloadData(): Promise<void> {
        return this.loadRowsData(this.queryData);
    }

    @action.bound
    clearFilters(): void {
        Object.keys(this.filtering).forEach((name) => this.filtering[name].clear());
    }

    @action.bound
    changeSorting = (sort: Sort) => (): void => {
        if (this.currentSorting !== sort) {
            this.currentSorting?.off();
        }
        sort.changeDirection();
        this.pageNumber = 0;
    };

    @action.bound
    onChangePageSize(e: ChangeEvent<HTMLInputElement>): void {
        this.pageNumber = 0;
        this.pageSize = Number(e.target.value);
    }

    @action.bound
    onChangePage(e: unknown, newPage: number): void {
        this.pageNumber = newPage;
    }

    @action.bound
    dispose(): void {
        this.loadingDisposer();
        this.loadRowsDisposer();
        this.pageNumberDisposer();
    }

    @computed
    get currentSorting(): Sort | undefined {
        return Object.values<Sort>(this.sorting).find((s) => s.isActive);
    }

    @computed
    get filterNames(): string[] {
        return Object.keys(this.filtering);
    }

    @computed
    get filterData(): object {
        return this.filterNames
            .filter((name) => this.filtering[name].isActive)
            .map((name) => ({ [name]: this.filtering[name].asJson }))
            .reduce((acc, f) => Object.assign(acc, f), {});
    }

    @computed
    get sortNames(): string[] {
        return Object.keys(this.sorting);
    }

    @computed
    get sortData(): object[] {
        return this.sortNames
            .filter((name) => this.sorting[name].isActive)
            .map((name) => ({ [name]: this.sorting[name].direction }));
    }

    @computed
    get queryData(): TableQueryData {
        return {
            page: this.pageNumber + 1,
            pageSize: this.pageSize,
            filter: this.filterData,
            sort: this.sortData,
        };
    }
}
