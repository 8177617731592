import {
    Box,
    Button,
    Collapse,
    createStyles,
    Dialog,
    DialogContent,
    Grid,
    makeStyles,
    Typography,
    useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React, { CSSProperties, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useAntiDoubleClick, useStore } from '../../../hooks';
import { SelectionOfExpertListModel } from '../../../models';
import { SelectionOfExpertTable as SelectionOfExpertTableInj } from './SelectionOfExpertTable';

type RouterProps = {
    id: string;
};

export type SelectionOfExpertiseDialogProps = {
    onClose: () => void;
    onSuccessSubmit: () => void;
};

const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

const buttonWrapperSx: CSSProperties = { width: '35%' };

export const SelectionOfExpertDialog = observer((props: SelectionOfExpertiseDialogProps): JSX.Element => {
    const [SelectionOfExpertTable] = di([SelectionOfExpertTableInj], SelectionOfExpertDialog);

    const { id } = useParams<RouterProps>();
    const rootStore = useStore();
    const tableModel = useMemo(() => new SelectionOfExpertListModel(id, rootStore), [id, rootStore]);
    const theme = useTheme();
    const classes = useStyles();
    const { isError, expertId, onSubmitCreator, dropIsError } = tableModel;

    const { onClose, onSuccessSubmit } = props;
    const lightBorder = theme?.variables.lightFormBorder;

    const [isSending, endIcon, handleSubmit] = useAntiDoubleClick(onSubmitCreator(onSuccessSubmit));

    return (
        <Dialog maxWidth="xl" fullWidth open={true}>
            <DialogContent className={classes.dialogContent}>
                <Box borderBottom={lightBorder}>
                    <Typography variant="h5" className="t-new-expertise-title">
                        <Box pl={6.5} pt={4} pb={3.25} fontWeight="fontWeightBold">
                            <FormattedMessage id="expertiseTask.selectionOfExpert.title" />
                        </Box>
                    </Typography>
                </Box>

                <SelectionOfExpertTable tableModel={tableModel} />

                <Box px={4} py={6}>
                    <Collapse in={isError} timeout="auto">
                        <Alert variant="standard" severity="error" onClose={dropIsError}>
                            <FormattedMessage
                                id={expertId ? 'expertiseTask.prohibitedErrorText' : 'expertiseTask.errorText'}
                            />
                        </Alert>
                    </Collapse>
                </Box>

                <Box borderTop={lightBorder} pt={4} pb={6} pl={30.75} pr={30.75}>
                    <Grid container spacing={10} justify="center">
                        <Grid style={buttonWrapperSx} item>
                            <Button size="large" fullWidth variant="contained" onClick={onClose}>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                        </Grid>
                        <Grid style={buttonWrapperSx} item>
                            <Button
                                size="large"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSending}
                                endIcon={endIcon}
                                onClick={handleSubmit}
                            >
                                <FormattedMessage id="expertiseTask.selectionOfExpert.buttonLabel" />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
});
