import { Grid } from '@material-ui/core';
import { FormApi, FormView, MultiLangFormEdit } from '@platform/formiojs-react';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';
import { entities, permissions } from '../../authSchemeConfig';
import clientRoute from '../../clientRoute';
import { AuthorizationCheck as AuthorizationCheckInj, TotObjectMain as TotObjectMainInj } from '../../components';
import { pageGridContainerStyle, maxWidth100 } from '../../constants';
import { useStore } from '../../hooks';
import { UserPersonModel } from '../../models';
import { UserControlPanel as UserControlPanelInj } from './UserControlPanel';
import { UserPageHeader as UserPageHeaderInj } from './UserPageHeader';

export type UserPageRouteParams = {
    id: string;
};

export const UserPage = observer((): JSX.Element => {
    const [UserPageHeader] = di([UserPageHeaderInj], UserPage);
    const [UserControlPanel] = di([UserControlPanelInj], UserPage);
    const [TotObjectMain] = di([TotObjectMainInj], UserPage);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], UserPage);

    const { userStore, personStore, intlStore, history } = useStore();
    const { id } = useParams<UserPageRouteParams>();
    const [userModel] = useState<UserPersonModel>(userStore.getUserPersonModel(id));
    const [formApi, setFormApi] = useState<FormApi>();

    const { personForm } = userModel;
    const { locale } = intlStore;

    useEffect(() => {
        userStore.loadUser(id).then((data) => {
            userModel.load(data);
        });
    }, [id, userStore]);

    const onFormReady = (form: FormApi): void => {
        setFormApi(form);
    };

    const savePersonForm = async (): Promise<void> => {
        if (formApi && formApi.validate()) {
            await formApi.submit(false);
            await userStore.saveUser(userModel.id, formApi.getSubmissionWithAdditionalInfo());
            history.push(generatePath(clientRoute.user, { id }));
        }
    };

    const loginAsUser = (): void => {
        userStore.loginAsUser(userModel.id).then(() => {
            personStore.getInfo().then(() => {
                history.push(generatePath(personStore.redirectPath));
            });
        });
    };

    const onNotAllowed = (): void => {
        history.push(clientRoute.notAllowed);
    };

    return (
        <Grid container direction="column" wrap="nowrap" style={pageGridContainerStyle}>
            <Grid item>
                <UserPageHeader userPersonModel={userModel} loginAsUser={loginAsUser} />
            </Grid>
            <TotObjectMain>
                <Grid container spacing={10}>
                    <Grid item container direction="column" spacing={10}>
                        <Grid item style={maxWidth100}>
                            <Switch>
                                <Route exact path={clientRoute.userEdit}>
                                    <AuthorizationCheck
                                        entityCode={entities.Account}
                                        entityId={userModel.id}
                                        permCode={permissions.User.UpdateForm}
                                        onNotAllowed={onNotAllowed}
                                    >
                                        <MultiLangFormEdit
                                            intlStore={intlStore}
                                            form={personForm}
                                            onFormReady={onFormReady}
                                        />
                                    </AuthorizationCheck>
                                </Route>

                                <Route exact path={clientRoute.user}>
                                    <AuthorizationCheck
                                        entityCode={entities.Account}
                                        entityId={userModel.id}
                                        permCode={permissions.User.View}
                                        onNotAllowed={onNotAllowed}
                                    >
                                        <FormView
                                            locale={locale}
                                            form={personForm}
                                            onFormReady={onFormReady}
                                            multiLang={true}
                                        />
                                    </AuthorizationCheck>
                                </Route>
                            </Switch>
                        </Grid>
                    </Grid>
                </Grid>
            </TotObjectMain>
            <Grid item>
                <UserControlPanel userPersonModel={userModel} saveForm={savePersonForm} />
            </Grid>
        </Grid>
    );
});
