import { Button, Grid, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, NavLink, useRouteMatch } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import clientRoute from '../../../../clientRoute';
import {
    ActionsButton as ActionsButtonInj,
    AuthorizationCheck as AuthorizationCheckInj,
    DeleteActionMenuItem as DeleteActionMenuItemInj,
    GridNoOverflow as GridNoOverflowInj,
    MenuButton as MenuButtonInj,
    TotObjectTransitions as TotObjectTransitionsInj,
} from '../../../../components';
import { useModal, useStore } from '../../../../hooks';
import { ExpertiseTaskModel } from '../../../../models';
import { AuthorizationCheckQuery } from '../../../../store';
import { SaveStatus } from '../../../../types';
import { SelectionOfExpertDialog as SelectionOfExpertDialogInj } from '../../selection-of-expert-dialog';
import { TaskViewControlPanelContainer as TaskViewControlPanelContainerInj } from './ExpertiseTaskViewPage.styled';
import { SaveButton as SaveButtonInj } from './SaveButton';

export type ExpertiseTaskViewControlPanelProps = {
    expertiseTaskModel: ExpertiseTaskModel;
    deleteExpertiseTask: () => Promise<void>;
    onSubmit: () => Promise<void>;
    onLifeCycleTransition: (transitionId: string, taskId: string, validate?: boolean) => Promise<void>;
    updateTaskViewPage: () => void;
    saveProgress: boolean;
    status: SaveStatus;
};

export const ExpertiseTaskViewControlPanel = observer((props: ExpertiseTaskViewControlPanelProps): JSX.Element => {
    const [ActionsButton] = di([ActionsButtonInj], ExpertiseTaskViewControlPanel);
    const [AuthorizationCheck] = di([AuthorizationCheckInj], ExpertiseTaskViewControlPanel);
    const [DeleteActionMenuItem] = di([DeleteActionMenuItemInj], ExpertiseTaskViewControlPanel);
    const [GridNoOverflow] = di([GridNoOverflowInj], ExpertiseTaskViewControlPanel);
    const [MenuButton] = di([MenuButtonInj], ExpertiseTaskViewControlPanel);
    const [TotObjectTransitions] = di([TotObjectTransitionsInj], ExpertiseTaskViewControlPanel);
    const [SelectionOfExpertDialog] = di([SelectionOfExpertDialogInj], ExpertiseTaskViewControlPanel);
    const [TaskViewControlPanelContainer] = di([TaskViewControlPanelContainerInj], ExpertiseTaskViewControlPanel);
    const [SaveButton] = di([SaveButtonInj], ExpertiseTaskViewControlPanel);

    const { updateTaskViewPage, onSubmit, onLifeCycleTransition, saveProgress, status, deleteExpertiseTask } = props;

    const intl = useIntl();
    const match = useRouteMatch();
    const { expertiseTaskStore, authorizationStore } = useStore();
    const { id, activeInviteId, identifier } = props.expertiseTaskModel;

    const { isModalOpen, setModalIsClosed, setModalIsOpen } = useModal();

    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);

    const deleteCheck: AuthorizationCheckQuery = {
        entityCode: entities.ExpertiseTask,
        permCode: permissions.ExpertiseTask.Delete,
        entityId: id,
    };
    const editCheck: AuthorizationCheckQuery = {
        entityCode: entities.ExpertiseTask,
        permCode: permissions.ExpertiseTask.Edit,
        entityId: id,
    };

    useEffect(() => {
        const checkAllAuth = async (): Promise<void> => {
            try {
                const [canEditResult, canDeleteResult] = await authorizationStore.checkAll([editCheck, deleteCheck]);
                setCanEdit(canEditResult);
                setCanDelete(canDeleteResult);
            } catch (error) {
                console.error(error.message);
            }
        };

        checkAllAuth();
    }, [authorizationStore, deleteCheck, editCheck]);

    const renderMenuActionItems = (): ReactNode[] => {
        const backUrl = match.url;
        const nodes: ReactNode[] = [];
        if (canDelete) {
            nodes.push(
                <DeleteActionMenuItem
                    id="delete"
                    key="delete"
                    wrappedComponentProps={{ tabIndex: 0 }}
                    title={intl.formatMessage({ id: 'common.confirmDeletion' })}
                    message={intl.formatMessage(
                        { id: 'expertiseTask.confirmDeletionInfoText' },
                        {
                            number: identifier,
                        },
                    )}
                    onConfirm={deleteExpertiseTask}
                />,
            );
        }
        if (canEdit) {
            nodes.push(
                <MenuItem
                    dense
                    button={true}
                    tabIndex={1}
                    component={NavLink}
                    key="edit"
                    to={`${generatePath(clientRoute.expertiseTaskEdit, {
                        id,
                    })}?backUrl=${backUrl}`}
                >
                    <FormattedMessage id="expertiseTask.actions.edit" />
                </MenuItem>,
            );
        }
        return nodes;
    };

    const renderMenuActionsButton = (): ReactNode => {
        if (canEdit || canDelete) {
            return (
                <Grid item>
                    <MenuButton
                        disablePortal={false}
                        renderButton={ActionsButton}
                        renderMenuItems={renderMenuActionItems}
                    />
                </Grid>
            );
        } else {
            return null;
        }
    };

    const taskInviteLifeCycleTransition = async (transitionId: string, objectId: string): Promise<void> => {
        await expertiseTaskStore.taskInviteLifeCycleTransition(transitionId, objectId);
        updateTaskViewPage();
    };

    const lifeCycleTransition = async (transitionId: string, objectId: string, validate?: boolean): Promise<void> => {
        await onLifeCycleTransition(transitionId, objectId, validate);
        updateTaskViewPage();
    };

    const selectExpertButtonText = intl.formatMessage({ id: 'expertiseTask.selectExpertButton' });

    return (
        <TaskViewControlPanelContainer spacing={3} wrap="nowrap">
            {renderMenuActionsButton()}
            <AuthorizationCheck
                entityCode={entities.ExpertiseTask}
                entityId={id}
                permCode={permissions.ExpertiseTask.SetExpert}
            >
                <GridNoOverflow>
                    <Tooltip title={selectExpertButtonText}>
                        <Button variant="contained" color="secondary" onClick={setModalIsOpen}>
                            <Typography variant="inherit" noWrap>
                                {selectExpertButtonText}
                            </Typography>
                        </Button>
                    </Tooltip>
                    {isModalOpen && (
                        <SelectionOfExpertDialog onClose={setModalIsClosed} onSuccessSubmit={updateTaskViewPage} />
                    )}
                </GridNoOverflow>
            </AuthorizationCheck>
            {activeInviteId && (
                <TotObjectTransitions
                    objectId={activeInviteId}
                    updateObjectPage={updateTaskViewPage}
                    getTransitions={expertiseTaskStore.getTaskInviteLifeCycleTransitions}
                    lifeCycleTransition={(transitionId: string, objectId: string): Promise<void> =>
                        taskInviteLifeCycleTransition(transitionId, objectId)
                    }
                    noOverflow
                />
            )}

            <TotObjectTransitions
                objectId={id}
                updateObjectPage={updateTaskViewPage}
                getTransitions={expertiseTaskStore.getLifeCycleTransitions}
                lifeCycleTransition={(transitionId: string, objectId: string, validate?: boolean): Promise<void> =>
                    lifeCycleTransition(transitionId, objectId, validate)
                }
                noOverflow
            />

            <AuthorizationCheck
                entityCode={entities.ExpertiseTask}
                entityId={id}
                permCode={permissions.ExpertiseTask.EditConclusion}
            >
                <GridNoOverflow>
                    <SaveButton status={status} onSubmit={onSubmit} saveProgress={saveProgress} noOverflow />
                </GridNoOverflow>
            </AuthorizationCheck>
        </TaskViewControlPanelContainer>
    );
});
