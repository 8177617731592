const startPage: Record<string, Record<string, string> | string> = {
    headerLinks: {
        login: 'Личный кабинет',
    },
    productNameShort: 'TOT Expert',
    productName: 'Система управления процессами экспертизы',
    productDescription: 'Контролируйте процессы оценки процессов и объектов от заявки до заключения',
};

export default startPage;
