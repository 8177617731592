import { CheckboxProps, TextFieldProps } from '@material-ui/core';
import { DatePickerProps } from '@material-ui/pickers';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CheckboxChecked } from '../resources/images/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxDefault } from '../resources/images/icons/checkbox-default.svg';

export const textFieldProps: TextFieldProps = {
    variant: 'outlined',
    fullWidth: true,
    InputLabelProps: {
        shrink: true,
    },
    size: 'small',
};

export const AutocompleteProps = {
    disableClearable: true,
    freeSolo: false,
    fullWidth: true,
};

export const pickerProps: Partial<DatePickerProps> = {
    autoOk: true,
    variant: 'inline',
    inputVariant: 'outlined',
    format: 'dd.MM.yyyy',
    disableFuture: true,
    size: 'small',
    InputLabelProps: {
        shrink: true,
    },
    invalidDateMessage: <FormattedMessage id="common.invalidDateMessage" />,
    maxDateMessage: <FormattedMessage id="common.maxDateMessage" />,
};

export const checkboxProps: CheckboxProps = {
    icon: <CheckboxDefault />,
    checkedIcon: <CheckboxChecked />,
    color: 'primary',
};
